import { del } from "../../improve-lib";

const useDeleteApplicationArea = (afterDelete?: () => void) => {
    const deleteApplicationArea = async (id: number) => {
        const response = await del("applicationareas/" + id);

        if (response.status === 200) {
            if (afterDelete)
                afterDelete();
        }
        else
            throw response;
    }

    return { deleteApplicationArea }
}

export default useDeleteApplicationArea;