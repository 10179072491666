export const tryGetCookie = (name: string) => {
    name = name + "=";
    const chunks = decodeURIComponent(document.cookie).split(";");
    for (let i = 0; i < chunks.length; i++) {
        let c = chunks[i];
        while (c.charAt(0) === " ")
            c = c.substring(1);

        if (c.indexOf(name) === 0)
            return c.substring(name.length, c.length);
    }
    return null;
}

export const setCookie = (name: string, value: string, expiresInDays: number) => {
    const d = new Date();
    d.setTime(d.getTime() + (expiresInDays * 24 * 60 * 60 * 1000));
    const expires = "expires=" + d.toUTCString();
    document.cookie = name + "=" + value + ";" + expires + ";path=/;SameSite=Lax";
}
