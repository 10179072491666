import { useCallback, useMemo, useState } from "react";
import { useResources } from "../../../../improve-lib";
import { RowAction } from "../../../../lib";
import { ApplicationAreaPermission, DocumentsApplicationAreaPermissionType, IdValuePair } from "../../../../model";

const useNewAction = (
    subsidiariesCount: number,
    permittedApplicationAreas: ApplicationAreaPermission[]
) => {
    const resources = useResources();

    const [newDocumentPopupVisible, setNewDocumentPopupVisible] = useState(false);
    const [newDocumentInfo, setNewDocumentInfo] = useState<{ applicationArea: IdValuePair, subsidiary: IdValuePair | null }>();

    const onClick = useCallback((applicationArea: IdValuePair, subsidiary: IdValuePair | null) => {
        setNewDocumentInfo({ applicationArea, subsidiary: subsidiariesCount > 1 ? subsidiary : null });
        setNewDocumentPopupVisible(true);
    }, [subsidiariesCount]);

    const onPopupClose = () => {
        setNewDocumentPopupVisible(false);
        setNewDocumentInfo(undefined);
    }

    const getSubsidiarySelection = useCallback((applicationArea: IdValuePair, subsidiaryPermission: { subsidiary: IdValuePair; permission: DocumentsApplicationAreaPermissionType }[]) => {
        const subsidiariesWithWritePermission = subsidiaryPermission.filter(sp => sp.permission >= DocumentsApplicationAreaPermissionType.Write);
        if (subsidiariesWithWritePermission.length === 1)
            return undefined;

        const subsidiarySelection = subsidiariesWithWritePermission.map(s => ({ text: s.subsidiary.value, onClick: () => onClick(applicationArea, s.subsidiary) }));
        if (subsidiariesCount === subsidiariesWithWritePermission.length)
            subsidiarySelection.unshift({ text: resources.allSubsidiaries, onClick: () => onClick(applicationArea, null) });

        return subsidiarySelection;
    }, [subsidiariesCount, onClick, resources.allSubsidiaries]);

    const getApplicationAreaSelection = useCallback((applicationAreas: ApplicationAreaPermission[]) => {
        if (applicationAreas.length === 1)
            return getSubsidiarySelection({ id: applicationAreas[0].id, value: applicationAreas[0].name }, applicationAreas[0].subsidiaryPermissions)
        else
            return applicationAreas.map(aa => (
                {
                    text: aa.name,
                    onClick: () => {
                        const subsidiariesWithWritePermission = aa.subsidiaryPermissions.filter(sp => sp.permission >= DocumentsApplicationAreaPermissionType.Write);
                        if (subsidiariesWithWritePermission.length === 1)
                            onClick({ id: aa.id, value: aa.name }, subsidiariesWithWritePermission[0].subsidiary);
                    },
                    childrenAction: getSubsidiarySelection({ id: aa.id, value: aa.name }, aa.subsidiaryPermissions)
                })
            );
    }, [getSubsidiarySelection, onClick]);

    const newAction = useMemo(() => {
        const applicationAreas = permittedApplicationAreas.filter(aa => aa.subsidiaryPermissions.some(sp => sp.permission >= DocumentsApplicationAreaPermissionType.Write));
        if (applicationAreas.length <= 0)
            return [];

        const newDocumentAction: RowAction = {
            iconName: "Add",
            onClick: () => {
                if (applicationAreas.length === 1) {
                    const subsidiaryPermissions = applicationAreas[0].subsidiaryPermissions.filter(sp => sp.permission >= DocumentsApplicationAreaPermissionType.Write);
                    if (subsidiaryPermissions.length === 1)
                        onClick({ id: applicationAreas[0].id, value: applicationAreas[0].name }, subsidiaryPermissions[0].subsidiary);
                }
            },
            text: resources.new,
            childrenAction: getApplicationAreaSelection(applicationAreas)
        };
        return [newDocumentAction];
    }, [permittedApplicationAreas, resources.new, getApplicationAreaSelection, onClick]);

    return { newAction, newDocumentPopupVisible, newDocumentInfo, onPopupClose };
}

export default useNewAction;