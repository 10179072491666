import "./CustomPalette.js.scss";

export default class CustomPalette {
    static $inject =
        [
            "create",
            "modeling",
            "elementFactory",
            "palette",
            "config.getResources"
        ];

    static EntryName = "create.element";

    constructor(create, modeling, elementFactory, palette, getResources) {
        this.Create = create; 
        this.Modeling = modeling;
        this.Palette = palette;
        this.ElementFactory = elementFactory;
        this.getResources = getResources;

        this.Palette.registerProvider(this);
    }

    getCustomEntries = () => [
        {
            title: this.getResources().createTextbox,
            className: "createCustomTextBox",
            func: () => this.createTextBox()
        },
        {
            title: this.getResources().createImage,
            className: "createCustomImage",
            func: () => this.createImage()
        }
    ];

    getPaletteEntries() {
        return entries => {
            delete entries["hand-tool"];
            delete entries["create.subprocess-expanded"];
            delete entries["create.data-object"];
            delete entries["create.data-store"];

            let customEntries = this.getCustomEntries();

            if (customEntries.length > 0) {
                entries["custom-separator"] = {
                    group: "custom",
                    separator: true
                };

                for (let i = 0; i < customEntries.length; i++) {
                    entries[CustomPalette.EntryName + String(i)] = {
                        group: "custom",
                        className: customEntries[i].className,
                        title: customEntries[i].title,
                        action: {
                            dragstart: customEntries[i].func(),
                            click: customEntries[i].func()
                        }
                    };
                }
            }

            return entries;
        };
    }

    createTextBox() {
        return event => {
            const shape = this.ElementFactory.createShape({
                type: "custom:TextBox",
                width: 100,
                height: 30
            });

            this.Modeling.updateLabel(shape, shape.id);

            this.Create.start(event, shape);
        };
    }

    createImage() {
        return event => {
            const shape = this.ElementFactory.createShape({
                type: "custom:Image"
            });

            this.Create.start(event, shape);
        };
    }
}
