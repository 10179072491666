import { useResources } from "../../../improve-lib";
import { MemoryTable, OrderColumn, RowAction,TableColumnType,TableData } from "../../../lib";
import { SubsidiaryModel } from "../../../model";

type SubsidiaryTableProps = {
    subsidiaries: SubsidiaryModel[];
    orderNameAsc: boolean;
    onSorting: (orderNameAsc: boolean) => void;
    onDeleteSubsidiary: (id: number, displayName: string) => void;
    onSubsidiaryClick: (id: number) => void
}

const SubsidiaryTable = (props: SubsidiaryTableProps): JSX.Element => {
    const resources = useResources();

    const onActionsCreate = (data: SubsidiaryModel): RowAction[] => {
        return [{
            text: resources.delete,
            iconName: "Delete",
            onClick: () => props.onDeleteSubsidiary(data.id, data.name)
        }];
    }

    const subsidiariesTableData: TableData<SubsidiaryModel> = {
        columns: [
            { name: "name", displayName: resources.label, dataType: TableColumnType.Text }
        ],
        rows: props.subsidiaries
    }

    const orderColumn: OrderColumn = {
        ascending: props.orderNameAsc,
        columnName: "name"
    };

    const onSubsidiariesReorder = (orderColumn: OrderColumn) => props.onSorting(orderColumn.ascending);

    const onSubsidiaryClick = (item: SubsidiaryModel) => {
        props.onSubsidiaryClick(item.id);
    };

    return (
        <MemoryTable
            data={subsidiariesTableData}
            onActionsCreate={props.subsidiaries.length > 1 ? onActionsCreate : undefined}
            onRowClick={onSubsidiaryClick}
            orderColumn={orderColumn}
            onReorder={onSubsidiariesReorder}
        />
    )
}

export default SubsidiaryTable;
