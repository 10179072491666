import { useContext } from "react";
import { DialogContext } from "./DialogContext";

const useConfirmDeleteCallback = <T>(onDelete: (args: T) => any, extended = false): (args: T, name: string) => void => {
    const ctx = useContext(DialogContext);

    return (args: T, name: string) => ctx.openDialog({ callback: () => onDelete(args), dialog: { type: "delete", toBeDeleted: name, extended } })
}

export default useConfirmDeleteCallback;
