import { NavigationList } from "../../../lib";
import { IdValuePair } from "../../../model";

type ProcessesSearchResultListProps = {
    processes: IdValuePair[];
    searchText: string;
    onProcessClick: (id: number, path: number[]) => void;
}

const ProcessesSearchResultList = (props: ProcessesSearchResultListProps): JSX.Element => {
    const filterProcesses = () => {
        return props.processes.filter(p => p.value.toLowerCase().includes(props.searchText.toLowerCase()));
    }

    const onRenderLink = (name: string) => {
        const startSubStr = name.toLowerCase().indexOf(props.searchText.toLowerCase());
        const endSubStr = startSubStr + props.searchText.length;

        return (
            <div>{name.substring(0, startSubStr)}
                <span style={{ fontWeight: 700 }}>{name.substring(startSubStr, endSubStr)}</span>
                {name.substring(endSubStr)}
            </div>
        );
    }

    return (
        <NavigationList
            items={filterProcesses()}
            onRenderLink={onRenderLink}
            onLinkClick={props.onProcessClick}
        />
    );
}

export default ProcessesSearchResultList;