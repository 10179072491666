import { useEffect, useState } from "react";
import { useApplicationAreas, useCreateRole, useProcessGroups, useSubsidiariesList } from "../../../api";
import { Container, useLanguage, useRegisterSaveCheck, useResources } from "../../../improve-lib";
import { RowAction, Section } from "../../../lib";
import { DocumentsApplicationAreaPermissionType, ProcessGroupPermissionType, RoleModel } from "../../../model";
import BasicDetails from "./basic/BasicDetails";
import ConfigurationPermissions from "./permissions/configuration/ConfigurationPermissions";
import InputMaskPermissions from "./permissions/input-mask/InputMaskPermissions";
import UsersDetails from "./users/UsersDetails";

type NewRoleDetailsPageProps = {
    refresh: () => void;
    onRoleCreateCancel: () => void;
    onRoleCreated: (newRoleId: number) => void;
}

const NewRoleDetailsPage = (props: NewRoleDetailsPageProps): JSX.Element => {
    const resources = useResources();
    const lang = useLanguage();

    const [role, setRole] = useState<RoleModel>();

    const { applicationAreas } = useApplicationAreas();
    const { subsidiaries } = useSubsidiariesList();
    const { processGroups } = useProcessGroups();
    const { createRole, duplicatedIsoCodes } = useCreateRole((id: number) => {
        props.onRoleCreated(id);
    })

    useEffect(() => {
        if (applicationAreas && processGroups)
            setRole({
                name: lang.map(l => { return { isoCode: l, label: "" } }),
                permissions: {
                    subsidiariesAll: false, rolesAll: false, usersAll: false, globalData: false,
                    documentsEditApplicationAreas: false, documentsEditTags: false, documentsConfiguration: false, processesEditProcessGroups: false
                },
                documentPermissions: applicationAreas.map(a => { return { applicationAreaId: a.id, name: a.value, permission: DocumentsApplicationAreaPermissionType.None } }),
                processPermissions: processGroups.map(p => { return { processGroupId: p.id, name: p.value, permission: ProcessGroupPermissionType.None } }),
                users: [],

                //TODO: use separate model
                userCreated: undefined!,
                dateTimeCreated: undefined!,
                userLastEdited: undefined!,
                dateTimeLastEdited: undefined!,
                versionCount: undefined!
            });
    }, [applicationAreas, processGroups, lang]);

    let allowSave = true;
    let anyChanges = false;

    if (role?.name) {
        for (let i = 0; i < role.name.length; i++)
            if (role.name[i].label.length < 1)
                allowSave = false;
            else
                anyChanges = true;
    }

    useRegisterSaveCheck(anyChanges);

    const cancelAction: RowAction = {
        onClick: props.onRoleCreateCancel,
        iconName: "Cancel",
        text: resources.cancel
    };

    const onCreateRole = async () => {
        await createRole({
            name: role?.name,
            permissions: role?.permissions,
            documentPermissions: role?.documentPermissions.map(d => { return { id: d.applicationAreaId, permission: d.permission } }),
            processPermissions: role?.processPermissions.map(d => { return { id: d.processGroupId, permission: d.permission } }),
            users: role?.users.map(u => ({ userId: u.user.id, subsidiaryIds: u.subsidiaries.map(s => s.id) }))
        })
    }

    return (
        <>
            {subsidiaries && applicationAreas && processGroups && role &&
                <Container onSave={onCreateRole} saveDisabled={!allowSave} title={resources.newRole} actions={[cancelAction]}>
                    <Section headerText={resources.label} collapsible highlighted={"blue"}>
                        <BasicDetails roleName={role.name} alterRoleName={name => setRole({ ...role, name: [...name] })} duplicatedIsoCodes={duplicatedIsoCodes} newRole />
                    </Section>

                    <Section headerText={resources.permissionConfiguration} collapsible highlighted={"blue"}>
                        <ConfigurationPermissions
                            permission={role.permissions}
                            alterPermission={permissions => setRole({ ...role, permissions: { ...permissions } })} />
                    </Section>

                    <Section headerText={resources.permissionInputMasks} collapsible highlighted={"blue"}>
                        <InputMaskPermissions
                            applicationAreaPermissions={role.documentPermissions}
                            applicationAreas={applicationAreas}
                            onApplicationAreaPermissionsUpdated={newPermissions => setRole({ ...role, documentPermissions: [...newPermissions] })}
                            processGroupPermissions={role.processPermissions}
                            processGroups={processGroups}
                            onProcessGroupPermissionsUpdated={newPermissions => setRole({ ...role, processPermissions: [...newPermissions] })}
                        />
                    </Section>

                    <Section headerText={resources.users} collapsible highlighted={"blue"}>
                        <UsersDetails users={role.users} alterUsers={users => setRole({ ...role, users: users })} availableSubsidiaries={subsidiaries} />
                    </Section>
                </Container>
            }
        </>
    )
}

export default NewRoleDetailsPage;
