import { createContext, ReactNode, useContext } from "react";
import { useProcesses, useProcessSubprocess } from "../../api";
import { IdValuePair, ProcessSubprocess } from "../../model";

const ProcessesContext = createContext<IdValuePair[]>([]);
const ProcessSubprocessContext = createContext<ProcessSubprocess[]>([]);

const useProcessesContext = () => useContext(ProcessesContext);
const useProcessSubprocessContext = () => useContext(ProcessSubprocessContext);

const ProcessesProvider = (props: { children: ReactNode, flag?: boolean }) => {
    const { processes } = useProcesses(props.flag);
    const { processSubprocess } = useProcessSubprocess(props.flag);

    if (!processes || !processSubprocess)
        return null;

    return (
        <ProcessesContext.Provider value={processes}>
            <ProcessSubprocessContext.Provider value={processSubprocess}>
                {props.children}
            </ProcessSubprocessContext.Provider>
        </ProcessesContext.Provider>
    );
};

export { ProcessesProvider, useProcessesContext, useProcessSubprocessContext };