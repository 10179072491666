/* eslint-disable @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call */

import { IdValuePair } from "../../model";
import { OrderColumn, TableColumn, TableColumnType } from "../table";

const helperFunctions = {

    getColumnToBeSorted: (columns: TableColumn[], orderColumn: OrderColumn): TableColumn => {
        return columns.find(c => c.name === orderColumn.columnName) as TableColumn;
    },

    sortIdValuePairs: (data: IdValuePair[], asc = true): any[] => {
        return data.sort((a, b) => {
            return sortStrings(a.value, b.value, asc);
        });
    },

    sort: (data: any[], column: TableColumn, asc = true): any[] => {
        const field = column.name;
        return data.sort((a, b) => {
            if (a[field] === null || a[field] === undefined)
                return 1;
            else if (b[field] === null || b[field] === undefined)
                return -1;
            switch (column.dataType) {
                case TableColumnType.Number:
                    if (asc)
                        return a[field] - b[field];
                    else
                        return b[field] - a[field];
                case TableColumnType.Date:
                case TableColumnType.DueDate:
                case TableColumnType.Text:
                default:
                    return sortStrings(a[field] as string, b[field] as string, asc);
            }
        });
    }
}

const sortStrings = (a: string, b: string, asc: boolean) => {
    if (asc)
        //return (a[field] < b[field]) ? -1 : (a[field] > b[field]) ? 1 : 0;    -> 'Ab' would be before 'aa'...
        return a.localeCompare(b);
    else
        //return (a[field] < b[field]) ? 1 : (a[field] > b[field]) ? -1 : 0;
        return b.localeCompare(a);
}

export default helperFunctions;