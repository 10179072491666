import { post, useSaveCallout } from "../../improve-lib";
import { NewUserModel } from "../../model";

const useCreateUser = (afterCreated?: (id: number) => void) => {
    const saveCallout = useSaveCallout();

    const createUser = async (user: NewUserModel) => {
        const response = await post("users", user);

        switch (response.status) {
            case 200:
                saveCallout.show();
                if (afterCreated)
                    afterCreated(response.body as number);
                break;
            default:
                throw response;
        }
    }

    return { createUser }
}

export default useCreateUser;