import { Position, SpinButton as FluentSpinButton } from "@fluentui/react";

type SpinButtonProps = {
    label: string;
    value: number;
    min: number;
    max: number;
    onChange: (newValue: number) => void;
}

const SpinButton = (props: SpinButtonProps): JSX.Element => {
    const onChange = (_event: React.SyntheticEvent<HTMLElement>, value?: string): void => {
        if (value)
            props.onChange(parseInt(value));
    }

    return (
        <FluentSpinButton
            label={props.label}
            labelPosition={Position.top}
            value={props.value.toString()}
            min={props.min}
            max={props.max}
            step={1}
            styles={{ spinButtonWrapper: { width: 75 } }}
            onChange={onChange}
        />
    );
}

export default SpinButton;