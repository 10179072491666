import { Stack } from "@fluentui/react";
import { useState } from "react";
import { useLogout } from "../../api";
import Logo from "../../public/image/improve-logo.svg";
import { useLanguage, useLanguageHandle, useResources } from "../language";
import { useUserSettings } from "../settings";
import TopBarCommandButtonAction from "./model/topBarCommandButtonAction";
import SupportPopup from "./SupportPopup";
import TopBarCommandButton from "./TopBarCommandButton";
import "./TopBar.tsx.scss";

type TopBarProps = {
    open: boolean;
    onToggle: () => void;
}

const TopBar = (props: TopBarProps): JSX.Element => {
    const resources = useResources();
    const userSettings = useUserSettings();
    const languageUpdateHandle = useLanguageHandle();
    const languages = useLanguage();

    const [supportPopupVisible, setSupportPopupVisible] = useState(false);

    const showLogout = userSettings !== undefined;

    const { logout } = useLogout();

    const userCommands: TopBarCommandButtonAction[] = [
        { text: resources.support, onClick: () => setSupportPopupVisible(true), iconName: "headset" },
        { text: resources.manual, onClick: () => window.open("/documentation", "_blank"), iconName: "documentation" },
        { text: resources.logout, onClick: logout, iconName: "followuser" }
    ];

    const languageCommands: TopBarCommandButtonAction[] = languages.map(iso => {
        return {
            // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
            text: (resources as any)[iso.toLowerCase() + "Static"],
            onClick: () => onLanguageClick(iso)
        }
    });

    const onLanguageClick = (isoCode: string) => {
        if (resources.isoCode !== isoCode)
            languageUpdateHandle(isoCode)
    }

    return (
        <>
            <SupportPopup isOpen={supportPopupVisible} onAbort={() => setSupportPopupVisible(false)} />
            <Stack className={"top-bar-header-wrapper" + (showLogout ? "" : " top-bar-header-wrapper-transparent")} horizontal>
                <img className="top-bar-header-logo" src={Logo} alt="logo" />
                <div className="top-bar-header-toggle-controls">
                    {languages.length > 1 && <TopBarCommandButton text={resources.isoCode} commands={languageCommands} />}
                    <div className="top-bar-logout">
                        {showLogout && <TopBarCommandButton text={userSettings.displayName} commands={userCommands} />}
                    </div>
                    <div className="top-bar-logout-mobile">
                        {showLogout && <TopBarCommandButton text={userSettings.displayName} commands={userCommands} iconName="ReminderPerson" />}
                    </div>
                </div>
                <div onClick={props.onToggle}
                    className={"navbar top-bar-header-burger" + (showLogout ? " top-bar-header-burger-controls" : "")}>
                    <div className={"container nav-container" + (props.open ? " burger-open" : "")}>
                        <div className="hamburger-lines">
                            <span className="line line1"></span>
                            <span className="line line2"></span>
                            <span className="line line3"></span>
                        </div>
                    </div>
                </div>
            </Stack>
        </>
    )
}

export default TopBar;
