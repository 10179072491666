import { useSearchTags, useUsersWithWritePersmissionDocuments } from "../../../../api";
import { CreatedEditedFooter, PdfFileUpload, useLabels, useLanguage, useResources } from "../../../../improve-lib";
import { DatePicker, MultiLanguageDescription, TextBox, UserSelection } from "../../../../lib";
import { DocumentTagModel, EditDocumentModel, IdValuePair, MultiLanguageDescriptionModel } from "../../../../model";
import TagPicker from "../../_lib/tags/TagPicker";
import TagList from "../../_lib/tags/TagsList";

type EditFormProps = {
    document: EditDocumentModel;
    onDocumentChanged: (document: EditDocumentModel) => void;
}

const EditForm = (props: EditFormProps): JSX.Element => {
    const resources = useResources();
    const labels = useLabels();
    const lang = useLanguage();

    const { searchTags } = useSearchTags();
    const { userList } = useUsersWithWritePersmissionDocuments(props.document.applicationArea.id, props.document.subsidiary?.id ?? null);

    const onFileChanged = (file?: File) => {
        if (file !== undefined) {
            const newDoc = { ...props.document, file: file, filename: file.name };
            props.onDocumentChanged(newDoc);
        }
    }

    const onConvertToggle = (checked: boolean) => {
        props.onDocumentChanged({ ...props.document, convertToPdf: checked })
    }

    const onFilenameChanged = (filename: string) => {
        const newDoc = { ...props.document, filename: filename };
        props.onDocumentChanged(newDoc);
    }

    const pickerOnTextChanged = async (filterText: string): Promise<DocumentTagModel[]> =>
        await searchTags(filterText, props.document.tags.map(tag => tag.id));

    const pickerOnItemSelected = (tag: DocumentTagModel) => {
        const newDoc = { ...props.document, tags: [...props.document.tags] };
        newDoc.tags.push(tag);
        props.onDocumentChanged(newDoc);
    }

    const onRemoveTagClick = (id: number) => {
        const newDoc = { ...props.document, tags: [...props.document.tags] };
        newDoc.tags = newDoc.tags.filter(t => t.id !== id);
        props.onDocumentChanged(newDoc);
    }

    const userOnGet = (_search: string | null): Promise<{ results: IdValuePair[], full: boolean }> => {
        return new Promise((resolve, _reject) => {
            resolve({ results: userList, full: true })
        });
    }

    const onUserChanged = (user: IdValuePair) => {
        const newDoc = { ...props.document, owner: user };
        props.onDocumentChanged(newDoc);
    }

    const onVersionChanged = (version: string) => {
        const newDoc = { ...props.document, version: version };
        props.onDocumentChanged(newDoc);
    }

    const onDateChanged = (date: Date | null) => {
        const newDoc = { ...props.document, dateValidUntil: date };
        props.onDocumentChanged(newDoc);
    }

    const onDescriptionChanged = (description: MultiLanguageDescriptionModel) => {
        const newDoc = { ...props.document, description: description };
        props.onDocumentChanged(newDoc);
    }

    if (!props.document.description) {
        props.document.description = {};

        for (let i = 0; i < lang.length; i++)
            props.document.description[lang[i]] = "";
    }

    return (
        <article>
            <div className="article-form-element">
                <PdfFileUpload
                    onFileChanged={onFileChanged}
                    onFilenameChanged={onFilenameChanged}
                    filename={props.document.filename}
                    onConvertToggle={onConvertToggle}
                    convertToPdfDefault={props.document.convertToPdf}
                />
            </div>
            <div className="article-content">

                <TagPicker label={resources.tags} onTextChanged={pickerOnTextChanged} onItemSelected={pickerOnItemSelected} />

                <TagList tags={props.document.tags} size="normal" onRemoveClick={onRemoveTagClick} />

            </div>

            <div className="article-content">

                <UserSelection
                    label={labels.documentOwner}
                    selectedUser={props.document.owner}
                    onUserChanged={onUserChanged}
                    onGet={userOnGet}
                    mandatory
                />
                <TextBox label={labels.documentVersion} maxLength={40} text={props.document.version} onChange={newVersion => onVersionChanged(newVersion)} />
                <DatePicker label={labels.documentValidUntil} date={props.document.dateValidUntil} onDateChanged={onDateChanged} />
            </div>
            <div className="article-form-element">
                <MultiLanguageDescription
                    languageDescriptions={props.document.description}
                    onChange={onDescriptionChanged}
                    textBoxHeader={labels.documentDescription}
                />
            </div>
            <CreatedEditedFooter
                tracked={props.document}
            />
        </article>
    )
}

export default EditForm;
