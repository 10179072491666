import { useEffect, useState } from "react";
import { get, useResources, useToggle } from "../../improve-lib";
import { IdValuePair } from "../../model";

const useRoles = () => {
    const resources = useResources();

    const [reloadFlag, reload] = useToggle();

    const [roles, setRoles] = useState<IdValuePair[]>();

    useEffect(() => {
        const getRoles = async () => {
            const response = await get("roles");

            if (response.status !== 200)
                throw response;

            setRoles(response.body as IdValuePair[]);
        }

        void getRoles();
    }, [resources.isoCode, reloadFlag]);

    return { roles, reload }
}

export default useRoles;