import { ReactNode } from "react";
import "./Hidden.tsx.scss";

type HiddenProps = {
    visible: boolean;
    children: ReactNode;
}

const Hidden = (props: HiddenProps): JSX.Element | null => {
    if (props.visible)
        return <>{props.children}</>;

    return <div className="visibility-hidden">{props.children}</div>;
};

export default Hidden;
