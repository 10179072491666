import { useEffect, useState } from "react";
import { get, useGlobalSettings } from "../../improve-lib";

const useDomainName = () => {
    const [ssoUser, setSsoUser] = useState<string | null>(null);
    const [userLoaded, setUserLoaded] = useState(false);

    const settings = useGlobalSettings();

    useEffect(() => {
        const getDomainName = async () => {
            const response = await get("authentication/kerberos");
            
            switch (response.status) {
                case 200:
                    setSsoUser(response.body as string);
                    setUserLoaded(true);
                    break;
                case 401:
                    setSsoUser(null);
                    setUserLoaded(true);
                    break;
                default:
                    throw response;
            }
        }

        if (settings.allowSso)
            void getDomainName();
        else
            setUserLoaded(true);
    }, [settings.allowSso]);

    return { ssoUser, userLoaded }
}

export default useDomainName;