import { createContext } from "react";

export type SaveCheckContextType = {
    hasChanges: boolean;
    add: () => void;
    remove: () => void;
    promptSaveCheck: (callback: (args?: any) => void, unblockNav: boolean) => void;
    isRoot?: boolean;
    optOut: (unsave: () => void) => void;
}

const SaveCheckContext = createContext<SaveCheckContextType>({
    hasChanges: false,
    add: () => { },
    remove: () => { },
    promptSaveCheck: () => { },
    isRoot: true,
    optOut: () => { }
});

export { SaveCheckContext };