import { useEffect, useState } from "react";
import { IdValuePair } from "../../model";
import { DropDown, TextBox } from "..";

type UserSelectionProps = {
    label: string;
    selectedUser: IdValuePair;
    onUserChanged: (user: IdValuePair) => void;
    readOnly?: boolean;
    onGet: (search: string | null) => Promise<{ results: IdValuePair[], full: boolean }>;
    mandatory?: boolean;
}

const UserSelection = (props: UserSelectionProps): JSX.Element => {
    const [dropdownOptions, setDropdownOptions] = useState<IdValuePair[]>([]);

    const readOnly = props.readOnly;
    const onGet = props.onGet;

    useEffect(() => {
        const handleDataGet = async () => {
            if (!readOnly) {
                const v = await onGet(null);
                if (v.full)
                    setDropdownOptions(v.results);
            }
        }
        void handleDataGet();
    }, [readOnly, onGet])

    return (
        <>
            {props.readOnly ?
                <TextBox readOnly label={props.label} text={props.selectedUser.value} onChange={() => null} />
                :
                <DropDown
                    label={props.label}
                    placeholder="Select a user"
                    onChange={props.onUserChanged}
                    selectedId={props.selectedUser.id}
                    options={[...dropdownOptions].sort((a,b) => a.value > b.value ? 1 : -1)}
                    mandatory={props.mandatory}
                />
            }
        </>
    )
}

export default UserSelection;
