import { ContextualMenu, Dialog as FluentDialog, DialogType, ResponsiveMode } from "@fluentui/react";
import { ReactNode } from "react";

type DialogProps = {
    title: string;
    description?: string;
    hidden: boolean;
    onDismiss?: () => void;
    children?: ReactNode;
    minWidth?: number;
}

const Dialog = (props: DialogProps): JSX.Element => {
    const draggable = {
        moveMenuItemText: "Move",
        closeMenuItemText: "Close",
        menu: ContextualMenu
    }

    return (
        <FluentDialog
            hidden={props.hidden}
            responsiveMode={/* Ohne dies wird der Dialog auf schmalen geräten nicht angezeigt!*/ResponsiveMode.small}
            onDismiss={props.onDismiss}
            dialogContentProps={{
                type: DialogType.normal,
                title: props.title,
                subText: props.description,
                showCloseButton: true
            }}
            minWidth={props.minWidth ?? 560}
            modalProps={{
                isBlocking: true,
                styles: { main: { maxWidth: 450 } },
                dragOptions: draggable
            }}
        >
            {props.children}
        </FluentDialog>
    )
}

export default Dialog;
