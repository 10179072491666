import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useCreateSubsidiary } from "../../api";
import { Container, useRegisterSaveCheck, useResources, useUnsafeNavigate } from "../../improve-lib";
import { NavigationHeader } from "../../lib";
import SubsidiaryFormNew from "./components/SubsidiaryFormNew";

const CreateSubsidiaryPage = (): JSX.Element => {
    const navigate = useNavigate()
    const unsafeNavigate = useUnsafeNavigate()
    const resources = useResources();

    const [subsidiaryName, setSubsidiaryName] = useState("");

    const { createSubsidiary, nameDuplicated } = useCreateSubsidiary((id: number) => {
        unsafeNavigate("/settings/subsidiaries/" + id);
    });

    const unsavedChanges = subsidiaryName.trim() !== ""

    useRegisterSaveCheck(unsavedChanges);

    const navigateBack = () => navigate("/settings/subsidiaries");

    const onCreateSubsidiary = async () => {
        await createSubsidiary({ name: subsidiaryName })
    }

    return (
        <>
            <NavigationHeader backLabel={resources.backToSubsidiaries} onNavigateBack={navigateBack} />
            <section>
                <div className="content-container">
                    <Container onSave={onCreateSubsidiary} saveDisabled={!unsavedChanges} title={resources.subsidiaryCreate}>
                        <SubsidiaryFormNew
                            nameDuplicated={nameDuplicated}
                            subsidiaryName={subsidiaryName}
                            alterSubsidiary={setSubsidiaryName}
                        />
                    </Container>
                </div>

            </section>
        </>
    )
}

export default CreateSubsidiaryPage;
