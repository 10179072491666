import { MessageBar, MessageBarType } from "@fluentui/react";
import { CreatedEditedFooter, useLabels, useResources } from "../../../../improve-lib";
import { ChildWithLabel, TextWithLabel } from "../../../../lib";
import { DocumentModel } from "../../../../model";
import TagList from "../../_lib/tags/TagsList";
import "./DocumentDisplay.tsx.scss";

type DocumentDisplayProps = {
    document: DocumentModel;
}

const DocumentDisplay = (props: DocumentDisplayProps): JSX.Element => {
    const resources = useResources();
    const labels = useLabels();

    const options: Intl.DateTimeFormatOptions = {
        year: "2-digit",
        month: "numeric",
        day: "numeric",
        hour: "numeric",
        minute: "numeric"
    };

    return (
        <div className="document-container">

            {props.document.locked ?

                <MessageBar messageBarType={MessageBarType.warning} isMultiline={false}>
                    {resources.locked + ": " + props.document.userLocked?.value + " - " + props.document.dateTimeLocked?.toLocaleString(undefined, options)}
                </MessageBar>
                :
                <></>
            }

            <TextWithLabel label={resources.filename} text={props.document.filename} />

            <TextWithLabel label={resources.applicationArea} text={props.document.applicationArea.value} />

            {props.document.tags === null || props.document.tags.length === 0 ?
                <></>
                :
                <ChildWithLabel label={resources.tags}>
                    <div className="documentdisplay-tags">
                        <TagList tags={props.document.tags} size="normal" onRemoveClick={undefined} />
                    </div>
                </ChildWithLabel>

            }
            <div className="document-sidebyside">
                <TextWithLabel label={labels.documentOwner} text={props.document.owner.value} />

                <TextWithLabel label={labels.documentVersion} text={props.document.version} />
            </div>

            {props.document.dateValidUntil &&
                <TextWithLabel
                    label={labels.documentValidUntil}
                    //TODO: why not directly 'props.document.dateValidUntil.toLocaleDateString()'?
                    text={props.document.dateValidUntil?.toLocaleDateString() ?? ""} />
            }


            <TextWithLabel label={labels.documentDescription} text={props.document.description ? props.document.description[resources.isoCode] : ""} />



            <CreatedEditedFooter
                tracked={props.document}
            />

        </div>

    )
}

export default DocumentDisplay;
