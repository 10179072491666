import { createRef, useRef } from "react";
import { useResources } from "../../improve-lib";
import { Button, TextBox } from "..";
import "./FileUpload.tsx.scss";

type FileUploadProps = {
    onFileChanged: (file?: File) => void;
    onFilenameChanged: (newFileName: string) => void;
    filename?: string;
    requireFile?: boolean;
}

const FileUpload = (props: FileUploadProps): JSX.Element => {
    const resources = useResources();

    const fileSelected = useRef(false);

    const fileUploadRef = createRef<HTMLInputElement>();

    const clickBrowse = () => {
        if (fileUploadRef.current) {
            fileUploadRef.current.click()
        }
    }

    const onFileChanged = (event: React.ChangeEvent<HTMLInputElement>) => {
        props.onFileChanged(event.target.files![0]);
        fileSelected.current = true;
    }

    const isValid = () => {
        let isValid = true;
        if (props.requireFile && !fileSelected.current)
            isValid = false;

        if (!props.filename || props.filename?.length === 0)
            isValid = false;

        return isValid;
    }

    return (
        <>
            <div className="file-upload-div">
                <Button secondary text={resources.browse} onClick={clickBrowse} />
                <div className="file-upload-space">
                    <input className="file-upload-input" ref={fileUploadRef} type="file" onChange={onFileChanged} />
                </div>
                <div style={{ width: "100%" }}>
                    <TextBox
                        label={resources.filename}
                        text={props.filename ?? ""}
                        onChange={props.onFilenameChanged}
                        valid={isValid()}
                        mandatory
                    />
                </div>
            </div>
        </>
    )
}

export default FileUpload;
