import { useResources } from "../../../../../../improve-lib";
import { CheckBoxList, CheckedListType } from "../../../../../../lib";
import { Permission } from "../../../../../../model";

type ProcessPermissionsProps = {
    permission: Permission;
    onPermissionChanged: (newPermission: Permission) => void;
}

const ProcessPermissions = (props: ProcessPermissionsProps): JSX.Element => {
    const resources = useResources();

    const permissionsList: CheckedListType[] = [
        { id: 0, text: resources.manageProcessGroups, checked: props.permission.processesEditProcessGroups },
    ];

    const oncheckedChanged = (id: number, newValue: boolean) => {
        const newPermission = { ...props.permission };
        switch (id) {
            case 0:
                newPermission.processesEditProcessGroups = newValue;
                break;
        }

        props.onPermissionChanged(newPermission);
    }

    return (
            <CheckBoxList items={permissionsList} onCheckedChanged={oncheckedChanged} />
    )
}

export default ProcessPermissions;