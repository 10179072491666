import { DatePicker as FluentDatePicker, DayOfWeek, IDatePickerStrings, mergeStyleSets } from "@fluentui/react";
import { useResources } from "../../improve-lib";
import { IconButton, TextBox } from "../../lib";
import "./DatePicker.tsx.scss";

type DatePickerProps = {
    label: string;
    date: Date | null;
    onDateChanged: (date: Date | null) => void;
    readOnly?: boolean;
    mandatory?: boolean;
}

const DatePicker = (props: DatePickerProps): JSX.Element => {
    const resources = useResources();

    const DatePickerStrings: IDatePickerStrings = {
        months: [
            resources.dateMonthLongJan,
            resources.dateMonthLongFeb,
            resources.dateMonthLongMar,
            resources.dateMonthLongApr,
            resources.dateMonthLongMay,
            resources.dateMonthLongJun,
            resources.dateMonthLongJul,
            resources.dateMonthLongAug,
            resources.dateMonthLongSep,
            resources.dateMonthLongOct,
            resources.dateMonthLongNov,
            resources.dateMonthLongDec
        ],

        shortMonths: [
            resources.dateMonthShortJan,
            resources.dateMonthShortFeb,
            resources.dateMonthShortMar,
            resources.dateMonthShortApr,
            resources.dateMonthShortMay,
            resources.dateMonthShortJun,
            resources.dateMonthShortJul,
            resources.dateMonthShortAug,
            resources.dateMonthShortSep,
            resources.dateMonthShortOct,
            resources.dateMonthShortNov,
            resources.dateMonthShortDec
        ],

        days: [
            resources.dateDayLongSu,
            resources.dateDayLongMo,
            resources.dateDayLongTu,
            resources.dateDayLongWe,
            resources.dateDayLongTh,
            resources.dateDayLongFr,
            resources.dateDayLongSa
        ],

        shortDays: [
            resources.dateDayShortSu,
            resources.dateDayShortMo,
            resources.dateDayShortTu,
            resources.dateDayShortWe,
            resources.dateDayShortTh,
            resources.dateDayShortFr,
            resources.dateDayShortSa
        ],

        goToToday: resources.goToToday,

    };

    const controlClass = mergeStyleSets({
        control: {
            margin: "0 0 15px 0",
            maxWidth: "200px",
        },
    });

    const onSelectDate = (date: Date | null | undefined): void => {
        props.onDateChanged(date!);
    };

    const formatDate = (date: Date | null): string => {
        return date === null ? "" : new Date(date).toLocaleDateString();
    };

    return (
        <>
            {props.readOnly ?
                <TextBox readOnly label={props.label} text={formatDate(props.date)} onChange={() => null} />
                :
                <FluentDatePicker
                    className={controlClass.control}
                    label={props.label}
                    firstDayOfWeek={DayOfWeek.Monday}
                    strings={DatePickerStrings}
                    placeholder={resources.selectADate}
                    initialPickerDate={props.date === null ? undefined : props.date}
                    value={props.date === null ? undefined : props.date}
                    onSelectDate={onSelectDate}
                    formatDate={() => formatDate(props.date)}
                    textField={{
                        inputClassName: "date-picker-text-field",
                        onRenderSuffix: props.date != null ? () => <IconButton iconName={"Cancel"} onClick={() => props.onDateChanged(null)} tooltipText={resources.clearDate} size={"small"} /> : undefined,
                        styles: {
                            suffix: { padding: 0 },
                            fieldGroup: props.mandatory && props.date === null ? "mandatory-date-picker" : ""
                        }
                    }}
                    styles={{
                        icon: { left: 9, right: "unset", paddingRight: 0, paddingLeft: 0 },
                    }}
                    showGoToToday
                />
            }
        </>
    )
}

export default DatePicker;
