import { useState } from "react";
import { useResources } from "../../../improve-lib";
import { SearchBox } from "../../../lib";
import { IdValuePair } from "../../../model";
import { useProcessesContext, useProcessSubprocessContext } from "../ProcessesProvider";
import ProcessesSearchResultList from "./ProcessesSearchResultList";
import ProcessTree from "./ProcessTree";

type ProcessesSearchProps = {
    subsidiaries: IdValuePair[];
    onSubsidiaryClick: (subsidiaryid: number) => void;
    onProcessClick: (id: number, path: number[]) => void;
}

const ProcessesSearch = (props: ProcessesSearchProps): JSX.Element => {
    const resources = useResources();
    const processes = useProcessesContext();
    const processSubprocess = useProcessSubprocessContext();

    const [searchText, setSearchText] = useState("");

    return (
        <>
            <SearchBox
                text={searchText}
                placeholder={resources.filter}
                iconName={"Filter"}
                onChange={setSearchText}
            />            
            {searchText.length > 0 ?
                <ProcessesSearchResultList
                    searchText={searchText}
                    processes={processes}
                    onProcessClick={props.onProcessClick}
                />
                :
                <ProcessTree
                    processes={processes}
                    subsidiaries={props.subsidiaries}
                    processSubprocess={processSubprocess}
                    onSubsidiaryClick={props.onSubsidiaryClick}
                    onProcessClick={props.onProcessClick}
                />
            }
        </>
    );
}

export default ProcessesSearch;