import { useState } from "react";
import { useResources } from "../../../../improve-lib";
import { IconButton, Separator } from "../../../../lib";
import { EditProcessModel, ITracked, LinkType, ProcessElementModel } from "../../../../model";
import ProcessInfo from "./ProcessInfo";
import "./InfoPanel.tsx.scss";

type InfoPanelProps = {
    process: EditProcessModel;
    selectedElement: ProcessElementModel | undefined;
    expand?: boolean;
    tracked: ITracked;
}

const InfoPanel = (props: InfoPanelProps): JSX.Element => {
    const resources = useResources();

    const [infoPanelVisible, setInfoPanelVisible] = useState(props.expand ?? true);

    const getInfoData = () => {
        const element = props.selectedElement;
        if (element) {
            return ({
                label: element.subprocess?.value ?? element.label?.find(n => n.isoCode === resources.isoCode)!.label,
                description: element.description ? element.description[resources.isoCode] : undefined,
                documentLinks: element.links?.filter(l => l.targetType === LinkType.Documents),
                externalLinks: element.links?.filter(l => l.targetType === LinkType.ExternalLink)
            });
        }
        else {
            return ({
                label: props.process.name.find(n => n.isoCode === resources.isoCode)!.label,
                owner: props.process.owner.value,
                description: props.process.description ? props.process.description[resources.isoCode] : undefined,
                documentLinks: props.process.links.filter(l => l.targetType === LinkType.Documents),
                externalLinks: props.process.links.filter(l => l.targetType === LinkType.ExternalLink),
                customFields: props.process.customFields,
                tracked: props.tracked
            });
        }
    }

    return (
        <>
            <div className="process-info-separator" >
                <Separator vertical >
                    <IconButton
                        size="large"
                        onClick={() => setInfoPanelVisible(!infoPanelVisible)}
                        iconName={infoPanelVisible ? "ChevronRight" : "ChevronLeft"}
                    />
                </Separator>
            </div>

            <div className="process-info-panel" hidden={!infoPanelVisible}>
                <ProcessInfo {...getInfoData()} />
            </div>
        </>
    );
}

export default InfoPanel;