import { get } from "../../improve-lib";
import { IdValuePair } from "../../model";

const useAllUsers = () => {
    const allUsers = async () => {
        const response = await get("users/all");

        if (response.status !== 200)
            throw response;

        return response.body as IdValuePair[];
    };

    return { allUsers }
}

export default useAllUsers;