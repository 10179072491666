import { Separator } from "@fluentui/react";
import { useResources } from "../../improve-lib";
import { ITracked } from "../../model";
import "./CreatedEditedFooter.tsx.scss";

type CreatedEditedFooterProps = {
    tracked: ITracked;
}

const CreatedEditedFooter = (props: CreatedEditedFooterProps): JSX.Element => {
    const resources = useResources();

    const options: Intl.DateTimeFormatOptions = {
        year: "2-digit",
        month: "numeric",
        day: "numeric",
        hour: "numeric",
        minute:"numeric"
    };

    return (
        <div>
            <Separator />
            <div className="created-edited-footer">
                <div>
                    {`${resources.documentCreated}: ${props.tracked.userCreated.value} - ${props.tracked.dateTimeCreated.toLocaleString(undefined, options)}`}
                </div>
                <div>
                    {`${resources.documentEdited}: ${props.tracked.userLastEdited.value} - ${props.tracked.dateTimeLastEdited.toLocaleString(undefined, options)}`}
                </div>
            </div>
        </div>
    )
}

export default CreatedEditedFooter;
