import { useRoles } from "../../api";
import { Container, useQueryValue, useResources, useUnsafeQueryValue } from "../../improve-lib";
import { RowAction } from "../../lib";
import NewRoleDetailsPage from "./details/NewRoleDetailsPage";
import RoleDetailsPage from "./details/RoleDetailsPage";
import RolesList from "./list/RolesList";

const RolesPage = (): JSX.Element => {
    const [id, setView] = useQueryValue("view", value => {
        if (value) {
            const number = parseInt(value);

            if (!isNaN(number))
                return number;
        }
        return undefined;
    });
    const [, setViewUnsafe] = useUnsafeQueryValue("view", value => value);

    const [newRole] = useQueryValue("view", value => value === "new");

    const { roles, reload } = useRoles();

    const resources = useResources();

    const onRoleIdChanged = (newId: number) => setView(newId.toString());

    const onRoleCreate = () => setView("new");

    const onRoleCreateCancel = () => setView();

    const onNewRoleCreated = (id: number) => {
        setViewUnsafe(id.toString());
        reload();
    }

    const onRoleDeleted = () => {
        setView();
        reload();
    }

    const roleCreateAction: RowAction = { iconName: "Add", onClick: onRoleCreate, text: resources.roleCreate, disabled: newRole };

    return (
        <section className="split-view-4-8">
            <div className="content-container">
                <Container title={resources.administrationOfRoles} actions={[roleCreateAction]}>
                    {roles && <RolesList roles={roles} onRoleIdChanged={onRoleIdChanged} selectedRoleId={id} />}
                </Container>
            </div>
            <div className="content-container">
                {newRole && <NewRoleDetailsPage
                    refresh={reload}
                    onRoleCreateCancel={onRoleCreateCancel}
                    onRoleCreated={onNewRoleCreated}
                />}
                {id && <RoleDetailsPage
                    roleId={id}
                    refresh={reload}
                    onRoleDeleted={onRoleDeleted}
                />}
            </div>
        </section>
    )
}

export default RolesPage;
