import { createContext, ReactNode, useContext } from "react";
import { useFetchGlobalSettings } from "../../api";
import GlobalSettingsModel from "./model/globalSettingsModel";

const GlobalSettingsContext = createContext<GlobalSettingsModel>(undefined as unknown as GlobalSettingsModel);

const useGlobalSettings = () => useContext(GlobalSettingsContext);

type GlobalSettingsProviderProps = {
    children: ReactNode;
}

const GlobalSettingsProvider = (props: GlobalSettingsProviderProps) => {
    const { globalSettings } = useFetchGlobalSettings();

    if (!globalSettings)
        return null;

    return (
        <GlobalSettingsContext.Provider value={globalSettings}>
            {props.children}
        </GlobalSettingsContext.Provider>
    );
};

export { GlobalSettingsProvider, useGlobalSettings };
