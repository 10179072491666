import { useConfigurationDocuments, useUpdateConfigurationDocuments } from "../../../../../api";
import { Container, useCopy, useResources } from "../../../../../improve-lib";
import { MultiLanguageTextbox } from "../../../../../lib";

const Configuration = () => {
    const resources = useResources();

    const { configDb, reload } = useConfigurationDocuments();
    const { updateConfiguration, duplicatedIsoCodes } = useUpdateConfigurationDocuments();

    const [ownerLabels, setOwnerLabels, changedOwnerLabels] = useCopy(configDb?.ownerLabels, "isoCode");
    const [versionLabels, setVersionLabels, changedVersionLabels] = useCopy(configDb?.versionLabels, "isoCode");
    const [dateValidUntilLabel, setDateValidUntilLabel, changedDateValidUntilLabel] = useCopy(configDb?.dateValidUntilLabel, "isoCode");
    const [descriptionLabel, setDescriptionLabel, changedDescriptionLabel] = useCopy(configDb?.descriptionLabel, "isoCode");

    if (!configDb || !ownerLabels || !versionLabels || !dateValidUntilLabel || !descriptionLabel)
        return null;


    let allowSave = true;
    if (ownerLabels || versionLabels || dateValidUntilLabel || descriptionLabel) {
        for (let i = 0; i < ownerLabels.length; i++)
            if (ownerLabels[i].label.length < 1)
                allowSave = false;
        for (let i = 0; i < versionLabels.length; i++)
            if (versionLabels[i].label.length < 1)
                allowSave = false;
        for (let i = 0; i < dateValidUntilLabel.length; i++)
            if (dateValidUntilLabel[i].label.length < 1)
                allowSave = false;
        for (let i = 0; i < descriptionLabel.length; i++)
            if (descriptionLabel[i].label.length < 1)
                allowSave = false;
    }
    const onSave = async () => {
        await updateConfiguration({ ownerLabels, versionLabels, dateValidUntilLabel, descriptionLabel })
        reload();
    }

    return (
        <>
            <Container title={resources.labels} onSave={onSave} saveDisabled={(!changedOwnerLabels || !changedVersionLabels || !changedDateValidUntilLabel || !changedDescriptionLabel) && !allowSave}>
                <section className="split-view-6-6">
                    <div className="content-container">
                        <article>
                            <div className="article-content">
                                <MultiLanguageTextbox
                                    languageFields={ownerLabels}
                                    onChange={setOwnerLabels}
                                    duplicatedIsoCodes={duplicatedIsoCodes}
                                    textBoxHeader={resources.label + " " + resources.owner}
                                    mandatory
                                />

                                <MultiLanguageTextbox
                                    languageFields={versionLabels}
                                    onChange={setVersionLabels}
                                    duplicatedIsoCodes={duplicatedIsoCodes}
                                    textBoxHeader={resources.label + " " + resources.version}
                                    mandatory
                                />
                                <MultiLanguageTextbox
                                    languageFields={dateValidUntilLabel}
                                    onChange={setDateValidUntilLabel}
                                    duplicatedIsoCodes={duplicatedIsoCodes}
                                    textBoxHeader={resources.label + " " + resources.validUntil}
                                    mandatory
                                />

                                <MultiLanguageTextbox
                                    languageFields={descriptionLabel}
                                    onChange={setDescriptionLabel}
                                    duplicatedIsoCodes={duplicatedIsoCodes}
                                    textBoxHeader={resources.label + " " + resources.description}
                                    mandatory
                                />

                            </div>
                        </article>
                    </div>
                </section>
            </Container>
        </>
    )
}

export default Configuration;
