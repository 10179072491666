import { ReactNode, useState } from "react";
import { ConfirmDeleteDialog, ConfirmDialog } from ".";
import { DialogContext, DialogOptions } from "./DialogContext";
import SaveCheckDialog from "./SaveCheckDialog";

type DialogProviderProps = {
    children: ReactNode;
}

const DialogProvider = (props: DialogProviderProps) => {
    const [options, setOptions] = useState<DialogOptions>();
    const [dialogHidden, setDialogHidden] = useState(true);

    const hideDialog = () => setDialogHidden(true);

    const openDialog = (options: DialogOptions) => {
        setOptions(options);
        setDialogHidden(false);
    }

    const getDialog = (options: DialogOptions) => {
        const onCallback = () => {
            options.callback();
            hideDialog();
        }

        switch (options.dialog.type) {
            case "confirm":
                return (<ConfirmDialog
                    hidden={dialogHidden}
                    onCancel={hideDialog}
                    onConfirm={onCallback}
                    confirmationText={options.dialog.confirmationText}
                />)
            case "delete":
                return (<ConfirmDeleteDialog
                    hidden={dialogHidden}
                    onCancel={hideDialog}
                    onDelete={onCallback}
                    resourceName={options.dialog.toBeDeleted}
                    extendedConfirmation={options.dialog.extended}
                />)
            case "savecheck":
                return (<SaveCheckDialog
                    hidden={dialogHidden}
                    onCancel={hideDialog}
                    onLeaveAndDiscard={onCallback}
                />)
        }
    }

    return (
        <DialogContext.Provider value={{ openDialog }}>
            <>
                {!dialogHidden && options && getDialog(options)}
                {props.children}
            </>
        </DialogContext.Provider >
    );
};

export default DialogProvider;
