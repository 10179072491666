import { IContextualMenuItem } from "@fluentui/react";
import { ContextualMenu } from "../../../../../lib";

type ColorPaletteProps = {
    isOpen: boolean;
    point: { left: number, top: number };
    onDismiss: () => void;
    onColorChange: (fill: string, stroke: string) => void;
    onOpenColorDialog: () => void;
}

const ColorPalette = (props: ColorPaletteProps): JSX.Element => {
    const getColorPalette = () => {
        const colors = [
            { key: "default", fill: "#FFFFFF", stroke: "#000000" },
            { key: "blue", fill: "#BBDEFB", stroke: "#1E88E5" },
            { key: "ornage", fill: "#FFE0B2", stroke: "#FB8C00" },
            { key: "green", fill: "#C8E6C9", stroke: "#43A047" },
            { key: "red", fill: "#FFCDD2", stroke: "#E53935" },
            { key: "purple", fill: "#E1BEE7", stroke: "#8E24AA" },
        ];

        const rootStyle = { margin: 4, height: 20, borderRadius: 5 };

        const colorPalette: IContextualMenuItem[] = colors.map(color => ({
                key: color.key,
                itemProps: {
                    styles: {
                        root: {
                            ...rootStyle,
                            backgroundColor: color.fill,
                            border: "2px solid " + color.stroke
                        }
                    }
                },
                onClick: () => props.onColorChange(color.fill, color.stroke),
            })
        );

        colorPalette.push({
            key: "custom",
            itemProps: {
                styles: {
                    root: {
                        ...rootStyle,
                        backgroundImage: "linear-gradient(to left, violet, indigo, blue, green, yellow, orange, red)",
                    }
                }
            },
            onClick: () => props.onOpenColorDialog(),
        });

        return colorPalette;
    }

    return (
        <ContextualMenu
            items={getColorPalette()}
            hidden={!props.isOpen}
            point={props.point}
            onDismiss={props.onDismiss}
        />
    );
}

export default ColorPalette;
