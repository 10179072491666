import { useDocument } from "../../../../api";

type ViewerProps = {
    documentId: number;
}

const Viewer = (props: ViewerProps): JSX.Element | null => {
    const { url, contentType } = useDocument(props.documentId);

    if (!contentType)
        return null;

    switch (contentType) {
        case "image/bmp":
        case "image/gif":
        case "image/png":
        case "image/jpeg":
        case "image/svg+xml":
        case "image/tiff":
            return (
                <img src={url} alt="" style={{ width: "100%" }} />
            );
        case "application/pdf":
        case "text/plain":
            return (
                <object aria-label="document viewer" width="100%" height="770" data={url} />
            );
        case "audio/flac":
        case "audio/mpeg":
        case "audio/ogg":
        case "audio/wav":
            return (
                <audio controls src={url} />
            );
        case "video/mp4":
        case "video/ogg":
        case "video/webm":
            return (
                <video controls width="100%" src={url} />
            );
        default:
            return (
                <>
                    <div>Content-Type unknown...</div>
                    <div>{contentType}</div>
                </>
            );
    }
}

export default Viewer;
