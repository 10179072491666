import { useEffect, useState } from "react";
import { useAssignedSubsidiaries, useOpenTasksFilter } from "../../api";
import { Container, useResources, useUserSettings } from "../../improve-lib";
import { Separator } from "../../lib";
import OpenTasksDateBased from "./components/OpenTasksDateBased";
import OpenTasksFilter from "./components/OpenTasksFilter";
import OpenTasksModuleBased from "./components/OpenTasksModuleBased";

const OpenTasksPage = (): JSX.Element => {
    const resources = useResources();
    const userSettings = useUserSettings();

    const [basedOnDate, setBasedOnDate] = useState(localStorage.getItem("openTasksDateBased") === "true" ? true : false);
    const [selectedUserId, setSelectedUserId] = useState<number | null>(userSettings.userId);

    const { subsidiaries } = useAssignedSubsidiaries();

    const [selectedSubsidiaries, setSelectedSubsidiaries] = useState<number[]>([]);
    useEffect(() => {
        if (subsidiaries !== null) {
            setSelectedSubsidiaries(subsidiaries.map(s => s.id));
        }
    }, [subsidiaries])

    const { allOpenTasks } = useOpenTasksFilter(selectedUserId, selectedSubsidiaries, subsidiaries.length);

    const onSubsidiarySelectionChanged = (ids: number[]) => {
        const selectedSubs: number[] = subsidiaries.reduce((filtered: number[], s) => {
            if (ids.includes(s.id))
                filtered.push(s.id);
            return filtered;
        }, []);
        setSelectedSubsidiaries(ids.length === 0 ? subsidiaries.map(s => s.id) : selectedSubs);
    }

    const onUserSelectionChangend = (userId: number | null) => {
        setSelectedUserId(userId);
    }

    const onBasedOnDateChanged = (checked: boolean) => {
        setBasedOnDate(checked);
        localStorage.setItem("openTasksDateBased", (checked).toString());
    }

    const componentSelector = () => {
        if (basedOnDate) {
            return (
                <OpenTasksDateBased
                    data={allOpenTasks}
                    showSubsidiary={selectedSubsidiaries.length > 1}
                    showUser={selectedUserId === null}
                />
            );
        }
        else {
            return (
                <OpenTasksModuleBased
                    data={allOpenTasks}
                    showSubsidiary={selectedSubsidiaries.length > 1}
                    showUser={selectedUserId === null}
                />
            );
        }
    }

    return (
        <section>
            <div className="content-container">
                <Container title={resources.openTasks}>
                    <OpenTasksFilter
                        assignedSubsidiaries={subsidiaries}
                        onSubsidiarySelectionChanged={onSubsidiarySelectionChanged}
                        onUserSelectionChangend={onUserSelectionChangend}
                        basedOnDate={basedOnDate}
                        onBasedOnDateChanged={onBasedOnDateChanged}
                    />
                    <div style={{ marginTop: 20 }}></div>
                    <Separator />
                    {componentSelector()}

                </Container>
            </div>
        </section >
    )
}

export default OpenTasksPage;
