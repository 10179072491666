import { useEffect, useState } from "react";
import { get } from "../../improve-lib";
import { IdValuePair } from "../../model";

const useUsersWithWritePersmissionDocuments = (applicationAreaId: number, subsidiaryId: number | null) => {
    const [userList, setUserList] = useState<IdValuePair[]>([]);

    useEffect(() => {
        const getUserList = async () => {
            const response = await get("documents/users-with-write-permission?applicationAreaId=" + applicationAreaId + (subsidiaryId ? "&subsidiaryId=" + subsidiaryId : ""));

            if (response.status !== 200)
                throw response;

            setUserList(response.body as IdValuePair[]);
        }

        void getUserList();
    }, [applicationAreaId, subsidiaryId]);

    return { userList }
}

export default useUsersWithWritePersmissionDocuments;