import { post, useSaveCallout } from "../../improve-lib";
import { NewProcessModel } from "../../model";

const useCreateProcess = (afterCreated?: (id: number) => void) => {
    const saveCallout = useSaveCallout();

    const createProcess = async (process: NewProcessModel) => {
        const response = await post("process/new/", process);

        if (response.status !== 200)
            throw response;

        saveCallout.show();

        if (afterCreated)
            afterCreated(response.body as number)
    }

    return { createProcess }
}

export default useCreateProcess;
