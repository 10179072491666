import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useCountAllSubsidiaries, useDeletableProcess, useDeleteProcess, useUpdateProcess } from "../../../../api";
import { useCopy, useRegisterSaveCheck, useResources, useSaveCheck } from "../../../../improve-lib";
import { Color, colorToHex, hexToColor, RowAction } from "../../../../lib";
import { EditProcessElementModel, EditProcessModel, IdValuePair, ITracked, ProcessElementsModel, ProcessGroupPermissionType, ProcessUpdateModel } from "../../../../model";
import ChangeSubsidiaryDialog from "../edit/components/ChangeSubsidiaryDialog";
import EditElementPopup from "../edit/EditElementPopup";
import EditProcessPopup from "../edit/EditProcessPopup";
import InfoPanel from "../info/InfoPanel";
import ProcessHeader from "../info/ProcessHeader";
import BpmnModeler from "./bpmn/BpmnModeler";
import ColorDialog from "./color/ColorDialog";
import ColorPalette from "./color/ColorPalette";
import useModelerElements from "./elements/useModelerElements";
import ZoomControls from "./zoom/ZoomControls";
import "./ProcessModeler.tsx.scss";

type ProcessModelerProps = {
    id: number;
    flag: boolean;
    process: EditProcessModel;
    elements: ProcessElementsModel;
    bpmn: string;
    subsidiary: IdValuePair | null;
    processGroup: IdValuePair;
    versionCount: number;
    tracked: ITracked;
    processPermission: ProcessGroupPermissionType;
    reload: () => void;
    onChangeToReadOnlyMode: () => void;
    onProcessIdChanged: (id: number) => void;
}

const ProcessModeler = (props: ProcessModelerProps): JSX.Element => {
    const navigate = useNavigate();
    const resources = useResources();

    const bpmnContainer = useRef(null);
    const bpmnModeler = useRef<BpmnModeler>();
    const [bpmnChanged, setBpmnChanged] = useState(false);

    const [colorPaletteOpen, setColorPaletteOpen] = useState(false);
    const [colorPalettePoint, setColorPalettePoint] = useState({ left: 0, top: 0 });
    const [colorDialogOpen, setColorDialogOpen] = useState(false);

    const [editProcessPopupVisible, setEditProcessPopupVisible] = useState(false);
    const [editElementPopupVisible, setEditElementPopupVisible] = useState(false);

    const { subsidiariesCount } = useCountAllSubsidiaries();
    const [changeSubsidiaryDialogHidden, setChangeSubsidiaryDialogHidden] = useState(true);

    const [name, setName, nameChanged] = useCopy(props.process.name, "isoCode");
    const [owner, setOwner, ownerChanged] = useCopy(props.process.owner);
    const [description, setDescription, descriptionChanged] = useCopy(props.process.description);
    const [links, setLinks, linksChanged] = useCopy(props.process.links);
    const [fields, setFields, fieldsChanged] = useCopy(props.process.customFields);

    const {
        elements,
        selectedElement,
        onSelectionChange,
        onElementChanged,
        elementsChanged,
        elementHelper
    } = useModelerElements(props.elements, props.onProcessIdChanged);

    const anyChanges = nameChanged || ownerChanged || descriptionChanged || linksChanged || fieldsChanged || elementsChanged || bpmnChanged;

    useRegisterSaveCheck(bpmnChanged);
    const onChangeToReadOnlyMode = useSaveCheck(props.onChangeToReadOnlyMode);

    const { updateProcess, getChangedElements, getChangedFields } = useUpdateProcess(() => {
        setBpmnChanged(false);
        props.reload();
    });

    const { deleteProcess } = useDeleteProcess(() => { navigate("/process/"); });
    const { processCanBeDeleted } = useDeletableProcess(props.id, props.processPermission);

    const saveProcess = async () => {
        let bpmn: string | undefined;

        if (bpmnChanged) {
            bpmn = await bpmnModeler.current?.getXML();
        }

        const updateModel: ProcessUpdateModel = {
            bpmn: bpmn,
            ownerId: ownerChanged ? owner.id : undefined,
            name: nameChanged ? name : undefined,
            elements: elementsChanged ? getChangedElements(elements, props.elements) : undefined,
            descriptionChanged: descriptionChanged,
            description: descriptionChanged ? description : null,
            links: linksChanged ? links : undefined,
            customFields: fieldsChanged ? getChangedFields(fields, props.process.customFields) : undefined,
            versionCount: props.versionCount
        };

        await updateProcess(props.id, updateModel);
    }

    const processName = props.process.name.find(n => n.isoCode === resources.isoCode)?.label ?? "";

    const saveAction: RowAction = { text: resources.save, iconName: "Save", onClick: saveProcess, disabled: !anyChanges };
    const editAction: RowAction = { text: resources.edit, iconName: "Edit", onClick: () => setEditProcessPopupVisible(true) };
    const deleteAction: RowAction = { text: resources.delete, iconName: "Delete", onClick: () => deleteProcess(props.id, processName) };
    const changeSubsidiaryAction: RowAction = { text: resources.changeSubsidiary, iconName: "Switch", onClick: () => setChangeSubsidiaryDialogHidden(false) };

    const getActions = () => {
        const actions = [saveAction];
        actions.push(editAction);
        if (subsidiariesCount > 1 && props.processPermission >= ProcessGroupPermissionType.Create)
            actions.push(changeSubsidiaryAction);
        if (processCanBeDeleted)
            actions.push(deleteAction);

        return actions;
    }

    const initModeler = () => {
        const contextPadEntries =
            [
                {
                    title: "edit",
                    className: "iconEditElement",
                    showEntry: (element: any) => bpmnModeler.current?.showContextPadEditEntry(element),
                    func: (_event: any, element: { id: string }) => {
                        setEditElementPopupVisible(true);
                        onSelectionChange(element.id);
                    }
                },
                {
                    title: "changeColors",
                    className: "iconColorPicker",
                    showEntry: (element: any) => bpmnModeler.current?.showContextPadColorEntry(element),
                    func: (event: { x: number, y: number }, element: { id: string }) => {
                        setColorPalettePoint({ left: event.x, top: event.y });
                        onColorPickerClick(element.id);
                    }
                }
            ];

        const modeler = new BpmnModeler(
            bpmnContainer.current,
            resources,
            elementHelper,
            contextPadEntries,
            onSelectionChange,
            () => setBpmnChanged(true)
        );

        return modeler;
    }

    const onProcessEdited = (processEdited: EditProcessModel) => {
        setName(processEdited.name);
        setOwner(processEdited.owner);
        setDescription(processEdited.description);
        setLinks(processEdited.links);
        setFields(processEdited.customFields);
    }

    const onElementEdited = (elementEdited: EditProcessElementModel) => {
        onElementChanged({
            bpmnId: elementEdited.bpmnId,
            label: elementEdited.name,
            subprocess: elementEdited.subprocess,
            description: elementEdited.description,
            links: elementEdited.links
        });

        bpmnModeler.current?.updateElement(elementEdited.bpmnId, elementEdited.font, elementEdited.imageSource);
    }

    const getElementColor = (id: string) => {
        const color = bpmnModeler.current!.getElementColor(id) as { fill: string, stroke: string };
        return { fillColor: hexToColor(color.fill), strokeColor: hexToColor(color.stroke) };
    }

    const onColorPickerClick = (id: string) => {
        onSelectionChange(id);
        setColorPaletteOpen(true);
    }

    const onColorChange = (newColor: { fillColor: Color; strokeColor: Color }) => {
        updateElementColor(
            colorToHex(newColor.fillColor),
            colorToHex(newColor.strokeColor)
        );
    }

    const updateElementColor = (fillColor: string, strokeColor: string) => {
        bpmnModeler.current?.updateElementColor(
            selectedElement?.bpmnId,
            fillColor,
            strokeColor
        );
    }

    useEffect(() => { bpmnModeler.current?.updateResources(resources); }, [resources]);

    useEffect(() => {
        if (!bpmnModeler.current)
            bpmnModeler.current = initModeler();

        void bpmnModeler.current?.updateDiagram(props.bpmn);
        setBpmnChanged(false);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.flag]);

    return (
        <>
            <ProcessHeader
                processId={props.id}
                readonly={false}
                processPermission={props.processPermission}
                saveAction={saveAction}
                actions={getActions()}
                onReadonlyChanged={onChangeToReadOnlyMode}
                onProcessIdChanged={props.onProcessIdChanged}
            />
            <div className="modeler-main-container">
                {/* ACHTUNG: tabIndex ist zwingend nötig, damit keyboard-events funktionieren */}
                {/* http://app2019/Synprovis_2019/Improve/_workitems/edit/161/ */}
                <div tabIndex={-1} className="modeler-bpmn-container" ref={bpmnContainer} >
                    <ZoomControls
                        onZoomReset={() => bpmnModeler.current?.zoomReset()}
                        onZoomIn={() => bpmnModeler.current?.zoomIn()}
                        onZoomOut={() => bpmnModeler.current?.zoomOut()}
                        className={"modeler-zoom-controls"}
                    />
                </div>
                <InfoPanel
                    process={{
                        name: name,
                        owner: owner,
                        description: description,
                        links: links,
                        customFields: fields
                    }}
                    selectedElement={selectedElement}
                    tracked={props.tracked}
                />
            </div>

            <ChangeSubsidiaryDialog
                processId={props.id}
                subsidiaryId={props.subsidiary?.id ?? null}
                processGroupId={props.processGroup.id}
                subsidiariesCount={subsidiariesCount}
                hidden={changeSubsidiaryDialogHidden}
                onDismiss={() => setChangeSubsidiaryDialogHidden(true)}
                onChanged={() => { setChangeSubsidiaryDialogHidden(true); props.reload(); }}
            />

            <ColorPalette
                isOpen={colorPaletteOpen}
                point={colorPalettePoint}
                onColorChange={updateElementColor}
                onDismiss={() => setColorPaletteOpen(false)}
                onOpenColorDialog={() => setColorDialogOpen(true)}
            />

            {bpmnModeler.current && selectedElement &&
                <ColorDialog
                    color={getElementColor(selectedElement.bpmnId)}
                    isOpen={colorDialogOpen}
                    showFillColor={bpmnModeler.current.showFillColor(selectedElement.bpmnId)}
                    showStrokeColor={bpmnModeler.current.showStrokeColor(selectedElement.bpmnId)}
                    onColorChange={newColor => onColorChange(newColor)}
                    onDismiss={() => { setColorDialogOpen(false); onSelectionChange(undefined); }}
                />
            }

            {editProcessPopupVisible &&
                <EditProcessPopup
                    id={props.id}
                    name={name}
                    owner={owner}
                    description={description}
                    customFields={fields}
                    links={links}
                    processGroup={props.processGroup}
                    subsidiary={props.subsidiary}
                    tracked={props.tracked}
                    isOpen={editProcessPopupVisible}
                    onProcessEdited={onProcessEdited}
                    onAbort={() => { setEditProcessPopupVisible(false) }}
                />
            }

            {bpmnModeler.current && selectedElement && editElementPopupVisible &&
                <EditElementPopup
                    element={selectedElement}
                    processId={props.id}
                    subsidiary={props.subsidiary}
                    fontStyle={bpmnModeler.current.getElementFont(selectedElement.bpmnId)}
                    imageSource={bpmnModeler.current.getElementImageSource(selectedElement.bpmnId)}
                    showLabel={bpmnModeler.current.allowToAddLabel(selectedElement.bpmnId)}
                    showNavigation={bpmnModeler.current.allowToAddNavigation(selectedElement.bpmnId)}
                    showSubprocess={bpmnModeler.current.allowToAddSubprocess(selectedElement.bpmnId)}
                    showDescription={bpmnModeler.current.allowToAddDescription(selectedElement.bpmnId)}
                    allowToAddLinks={bpmnModeler.current.allowToAddLinks(selectedElement.bpmnId)}
                    isOpen={editElementPopupVisible}
                    onElementEdited={onElementEdited}
                    onAbort={() => { setEditElementPopupVisible(false) }} />
            }
        </>
    );
}

export default ProcessModeler;