import { DocumentTagModel } from "../../../../model";
import Tag from "./Tag";

type TagListProps = {
    tags: DocumentTagModel[];
    onRemoveClick?: (id: number) => void;
    size: "normal" | "small";
}

const TagList = (props: TagListProps): JSX.Element => {
    return (
        <div style={{ display: "flex", flexWrap: "wrap", alignItems: "end" }}>
            {props.tags.map(t =>
                <div key={t.id} style={{ padding: "1px" }}>
                    <Tag tag={t} onRemoveClick={props.onRemoveClick} size={props.size} />
                </div>
            )}
        </div>
    )
}

export default TagList;
