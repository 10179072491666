import { useEffect, useState } from "react";
import { EditDocumentModel } from "../../model";
import { useDisplayDocument } from ".";

const useEditDocument = (id: number, isOpen: boolean) => {
    const [documentDb, setDocumentDb] = useState<EditDocumentModel>();
    const { document } = useDisplayDocument(id);

    useEffect(() => {
        if (document) {
            const data: EditDocumentModel = {
                filename: document.filename,
                subsidiary: document.subsidiary,
                applicationArea: document.applicationArea,
                tags: document.tags,
                owner: document.owner,
                version: document.version,
                dateValidUntil: document.dateValidUntil,
                descriptionId: document.descriptionId,
                description: document.description,
                file: undefined,
                versionCount: document.versionCount,
                convertToPdf: document.pdf !== undefined,
                userCreated: document.userCreated,
                dateTimeCreated: document.dateTimeCreated,
                userLastEdited: document.userLastEdited,
                dateTimeLastEdited: document.dateTimeLastEdited
            }
            setDocumentDb(data);
        }
        //eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id, isOpen, document]);

    return { documentDb }
}

export default useEditDocument;
