import { useContext } from "react";
import { SaveCheckContext } from "./SaveCheckContext";

const useSaveCheck = <T = void>(callback: (args: T) => any, unblockNav = false): (args: T) => void => {
    const handle = useContext(SaveCheckContext);

    return args => {
        if (handle.hasChanges)
            handle.promptSaveCheck(() => callback(args), unblockNav);
        else
            callback(args);
    }
}

export default useSaveCheck;
