import { CheckBox, CheckedListType } from "../../lib";
import "./CheckBoxList.tsx.scss";

type CheckBoxListProps = {
    items: CheckedListType[]
    onCheckedChanged: (id: number, newValue: boolean) => void;
}

const CheckBoxList = (props: CheckBoxListProps): JSX.Element => {
    return (
        <div className="check-box-list">
            {
                props.items.map((item, index) =>
                    <div key={index} className="check-box-list-item">
                        <CheckBox
                            label={item.text}
                            indeterminate={item.indeterminate}
                            checked={item.checked}
                            onChange={checked => props.onCheckedChanged(item.id, checked)}
                        />
                    </div>
                )
            }
        </div>
    )
}

export default CheckBoxList;
