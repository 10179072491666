import { CreatedEditedFooter,useResources } from "../../../../../../../improve-lib";
import { Color, ColorSelector, MultiLanguageTextbox,Section } from "../../../../../../../lib";
import { EditTagModel,MultiLanguageFieldModel } from "../../../../../../../model";

type FormProps = {
    tag: EditTagModel;
    onTagChange: (newTag: EditTagModel) => void;
    duplicatedIsoCodes: string[];
}

const Form = (props: FormProps): JSX.Element => {
    const resources = useResources();

    const onNameChange = (newName: MultiLanguageFieldModel) => props.onTagChange({ ...props.tag, name: newName });
    const onColorChange = (newColor: Color) => props.onTagChange({ ...props.tag, color: newColor });

    return (
        <>
            <Section headerText={resources.label}>
                <MultiLanguageTextbox
                    languageFields={props.tag.name}
                    onChange={onNameChange}
                    duplicatedIsoCodes={props.duplicatedIsoCodes}
                    textBoxHeader={resources.label + " " + resources.tag}
                    mandatory
                />
            </Section>
            <Section headerText={resources.color}>
                <ColorSelector selectedColor={props.tag.color} onColorChange={onColorChange} />
            </Section>
            <CreatedEditedFooter
                tracked={props.tag}
            />
        </>
    )
}

export default Form;
