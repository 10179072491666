import { useDeletableProcessGroup, useDeleteProcessGroup, useProcessGroup, useUpdateProcessGroup } from "../../../../../api";
import { Container, CreatedEditedFooter, useCopy, useResources } from "../../../../../improve-lib";
import { MultiLanguageTextbox, RowAction, Section } from "../../../../../lib";

type ProcessGroupDetailsProps = {
    processGroupId: number;
    refresh: () => void;
    onDeleted: () => void;
}

const ProcessGroupDetails = (props: ProcessGroupDetailsProps) => {
    const resources = useResources();

    const { processGroup, reload } = useProcessGroup(props.processGroupId);
    const { processGroupCanBeDeleted } = useDeletableProcessGroup(props.processGroupId);
    const { updateProcessGroup, duplicatedIsoCodes } = useUpdateProcessGroup(() => {
        props.refresh();
        reload();
    })
    const { deleteProcessGroup } = useDeleteProcessGroup(() => {
        props.onDeleted();
    });

    const [name, setName, hasChanged] = useCopy(processGroup?.name, "isoCode");

    if (!processGroup)
        return null;
    if (!name)
        return null;

    const processGroupIsValid = () => name.every(n => n.label.trim().length > 0);

    const deleteAction: RowAction = {
        onClick: () => deleteProcessGroup(props.processGroupId),
        iconName: "Delete",
        text: resources.delete
    };

    const saveAction: RowAction = { iconName: "Save", onClick: () => updateProcessGroup(props.processGroupId, { name, versionCount: processGroup.versionCount }), text: resources.save, disabled: !hasChanged || !processGroupIsValid() };

    const actions: RowAction[] = [saveAction];

    if (processGroupCanBeDeleted)
        actions.push(deleteAction);

    return (
        <Container title={processGroup.name.find(n => n.isoCode === resources.isoCode)!.label ?? ""} actions={actions} >
            <Section headerText={resources.label}>
                <MultiLanguageTextbox
                    languageFields={name}
                    onChange={setName}
                    duplicatedIsoCodes={duplicatedIsoCodes}
                    textBoxHeader={resources.label + " " + resources.processGroup}
                    mandatory
                />
            </Section>
            <CreatedEditedFooter tracked={processGroup} />
        </Container>
    );
}

export default ProcessGroupDetails;