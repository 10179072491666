import { ReactNode, useRef, useState } from "react";
import { useResources } from "../../improve-lib";
import { Callout } from "../../lib";
import { SaveCalloutContext } from "./SaveCalloutContext";

type SaveCalloutProviderProps = {
    children: ReactNode;
}

const SaveCalloutProvider = (props: SaveCalloutProviderProps) => {
    const resources = useResources();

    const [hideTemporary, setHideTemporary] = useState(false);
    const [containerStyle, setContainerStyle] = useState({ opacity: 0, transition: "unset", visibility: "hidden" });

    const timeout = useRef<NodeJS.Timeout>();

    const show = () => {
        setContainerStyle({ opacity: 1, transition: "unset", visibility: "unset" });

        if (timeout.current) {
            clearTimeout(timeout.current);

            setHideTemporary(true);
            setTimeout(() => setHideTemporary(false), 50);
        }

        timeout.current = setTimeout(() => {
            setContainerStyle({ opacity: 0, transition: "opacity 2s, visibility 2s", visibility: "hidden" })
            timeout.current = undefined;
        }, 2000)
    }

    return (
        <SaveCalloutContext.Provider value={{ show }}>
            {props.children}
            {!hideTemporary && <Callout
                iconName={"Completed"}
                message={resources.successfullySavedMessage}
                messageColor={"green"}
                containerStyle={containerStyle}
            />}
        </SaveCalloutContext.Provider >
    );
};

export default SaveCalloutProvider;
