import { CreatedEditedFooter, useResources } from "../../../improve-lib"
import { ErrorType, TextBox } from "../../../lib";
import { EditSubsidiaryModel, FilteredList } from "../../../model";
import "./SubsidiaryForm.tsx.scss";

type SubsidiaryFormProps = {
    subsidiary: FilteredList<EditSubsidiaryModel>;
    alterSubsidiary: (subsidiaryName: string) => void;
    nameDuplicated: boolean;
}

const SubsidiaryForm = (props: SubsidiaryFormProps): JSX.Element => {
    const resources = useResources();

    return (
        <div className="subsidiary-form-wrapper">
            <TextBox label={resources.label} placeholder text={props.subsidiary.entity.name} mandatory
                onChange={newName => props.alterSubsidiary(newName)}
                maxLength={40} error={props.nameDuplicated ? ErrorType.duplicated : undefined} autoFocus />

            <CreatedEditedFooter
                tracked={props.subsidiary.entity}
            />

        </div>
    )
}

export default SubsidiaryForm;
