import { createContext, ReactNode, useContext } from "react";
import { useLanguageResource } from "../../api";
import LanguageContextModel from "./model/languageContextModel";
import LanguageLabels from "./model/languageLabels";
import LanguageResources from "./model/languageResources";

type ResourcesContextType = {
    resources: LanguageResources;
    labels: LanguageLabels;
}

const LanguageContext = createContext<LanguageContextModel>(undefined as unknown as LanguageContextModel);
const ResourcesContext = createContext<ResourcesContextType>(undefined as unknown as ResourcesContextType);

const useLanguage = () => useContext(LanguageContext).isoCodes;
const useLabels = () => useContext(ResourcesContext).labels;
const useResources = () => useContext(ResourcesContext).resources;
const useLanguageHandle = () => useContext(LanguageContext).updateLanguage;

type LanguageProviderProps = {
    children: ReactNode;
}

const LanguageProvider = (props: LanguageProviderProps) => {
    const { languageModel, setLanguageFromIsoCode } = useLanguageResource();

    if (!languageModel)
        return null;

    return (
        <LanguageContext.Provider value={{ isoCodes: languageModel.languages, updateLanguage: setLanguageFromIsoCode }}>
            <ResourcesContext.Provider value={{ resources: languageModel.resources, labels: languageModel.labels }}>
                {props.children}
            </ResourcesContext.Provider>
        </LanguageContext.Provider>
    );
};

export { LanguageProvider, useLabels, useLanguage, useLanguageHandle, useResources };
