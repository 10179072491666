import { useResources } from "../../../../../improve-lib";
import { Section } from "../../../../../lib";
import { DocumentsApplicationAreaPermission, IdValuePair, ProcessGroupPermission } from "../../../../../model";
import DocumentPermissions from "./components/DocumentPermissions";
import ProcessPermissions from "./components/ProcessPermissions";

type InputMaskPermissionProps = {
    applicationAreaPermissions: DocumentsApplicationAreaPermission[];
    applicationAreas: IdValuePair[];
    onApplicationAreaPermissionsUpdated: (newNewPermissions: DocumentsApplicationAreaPermission[]) => void;
    processGroupPermissions: ProcessGroupPermission[];
    processGroups: IdValuePair[];
    onProcessGroupPermissionsUpdated: (newNewPermissions: ProcessGroupPermission[]) => void;
}

const InputMaskPermissions = (props: InputMaskPermissionProps): JSX.Element => {
    const resources = useResources();

    return (
        <>
            <Section headerText={resources.documents} collapsible noSeparator highlighted={"grey"}>
                <DocumentPermissions
                    applicationAreaPermissions={props.applicationAreaPermissions}
                    applicationAreas={props.applicationAreas}
                    onApplicationAreaPermissionsUpdated={props.onApplicationAreaPermissionsUpdated}
                />
            </Section>
            <Section headerText={resources.processes} collapsible noSeparator highlighted={"grey"}>
                <ProcessPermissions
                    processGroupPermissions={props.processGroupPermissions}
                    processGroups={props.processGroups}
                    onProcessGroupPermissionsUpdated={props.onProcessGroupPermissionsUpdated}
                />
            </Section>
        </>
    )
}

export default InputMaskPermissions;
