import { useEffect, useState } from "react";
import { get, useResources, useToggle } from "../../improve-lib";
import { IdValuePair } from "../../model";

const useProcessGroups = () => {
    const resources = useResources();

    const [reloadFlag, reload] = useToggle();

    const [processGroups, setProcessGroups] = useState<IdValuePair[]>();

    useEffect(() => {
        const getProcessGroups = async () => {
            const response = await get("processgroups");

            if (response.status !== 200)
                throw response;

            setProcessGroups(response.body as IdValuePair[]);
        }

        void getProcessGroups();
    }, [resources.isoCode, reloadFlag]);

    return { processGroups, reload }
}

export default useProcessGroups;