import { useEffect, useState } from "react";
import { usePermittedApplicationAreas } from "../../../../api";
import useChangeDocumentSubsidiary from "../../../../api/documents/useChangeDocumentSubsidiary";
import { useResources, useSaveCheck } from "../../../../improve-lib";
import { Button, Dialog, DialogFooter, DropDown } from "../../../../lib";
import { DocumentsApplicationAreaPermissionType, IdValuePair } from "../../../../model";

type ChangeSubsidiaryDialogProps = {
    documentId: number;
    subsidiaryId: number | null;
    applicationAreaId: number;
    subsidiariesCount: number;
    hidden: boolean;
    onDismiss: () => void;
    onChanged: () => void;
}

const ChangeSubsidiaryDialog = (props: ChangeSubsidiaryDialogProps): JSX.Element => {
    const resources = useResources();

    const [subsidiaries, setSubsidiaries] = useState<IdValuePair[]>([]);
    const [subsidiaryId, setSubsidiaryId] = useState(props.subsidiaryId);
    const [subsidiaryChanged, setSubsidiaryChanged] = useState(false);

    const { changeDocumentSubsidiary } = useChangeDocumentSubsidiary();
    const { applicationAreaPermission } = usePermittedApplicationAreas();

    useEffect(() => {
        if (props.subsidiariesCount > 1) {
            const applicationArea = applicationAreaPermission?.find(aa => aa.id === props.applicationAreaId)

            if (applicationArea) {
                const subsidiariesWithWritePermission =
                    applicationArea.subsidiaryPermissions
                        .filter(sp => sp.permission >= DocumentsApplicationAreaPermissionType.Write).map(sp => sp.subsidiary);

                if (props.subsidiaryId === null || props.subsidiariesCount === subsidiariesWithWritePermission.length)
                    subsidiariesWithWritePermission.unshift({ id: -1, value: resources.allSubsidiaries });

                setSubsidiaries(subsidiariesWithWritePermission);
            }
        }
    }, [props.subsidiariesCount, applicationAreaPermission, resources.allSubsidiaries, props.applicationAreaId, props.subsidiaryId]);

    useEffect(() => {
        setSubsidiaryChanged(subsidiaryId !== props.subsidiaryId);
    }, [subsidiaryId, props.subsidiaryId]);

    useEffect(() => {
        if (!props.hidden) {
            setSubsidiaryId(props.subsidiaryId);
        }
    }, [props.hidden, props.subsidiaryId]);

    const onChange = useSaveCheck(async () => {
        await changeDocumentSubsidiary(props.documentId, subsidiaryId);
        props.onChanged();
    });

    return (
        <Dialog
            title={resources.changeSubsidiary}
            hidden={props.hidden}
            onDismiss={props.onDismiss}
        >
            <DropDown
                label={resources.subsidiary}
                placeholder={resources.subsidiary}
                selectedId={subsidiaryId ?? -1}
                onChange={item => setSubsidiaryId(item.id > 0 ? item.id : null)}
                options={subsidiaries}
            />
            <DialogFooter>
                <Button onClick={onChange} text={resources.save} disabled={!subsidiaryChanged} />
                <Button onClick={props.onDismiss} text={resources.cancel} secondary />
            </DialogFooter>
        </Dialog>
    )
}

export default ChangeSubsidiaryDialog;