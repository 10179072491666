import { useCallback, useEffect, useState } from "react";
import { IconButton, TextButton } from "../../../lib";

type GroupHeaderProps = {
    title: string;
    onTitleClick: () => void;
    onExpandCollapseClick: (isExpand: boolean) => void;
}

const GroupHeader = (props: GroupHeaderProps): JSX.Element => {
    const [expand, setExpand] = useState<boolean>(false);

    const [expandCollapseIcon, setExpandCollapseIcon] = useState("ChevronDown");

    useEffect(() => {
        setExpandCollapseIcon(expand ? "ChevronUp" : "ChevronDown");
    }, [expand]);

    const onExpandCollapseClick = useCallback(() => {
        setExpand(!expand);
        props.onExpandCollapseClick(expand);
    }, [expand, props]);

    return (
        <div style={{ display: "flex" }}>
            <TextButton
                text={props.title}
                onClick={() => { props.onTitleClick() }}
            />
            <IconButton
                iconName={expandCollapseIcon}
                size="inherit"
                onClick={onExpandCollapseClick} />
        </div>
    );
}

export default GroupHeader;