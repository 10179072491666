import { Component, ErrorInfo, ReactNode } from "react";
import { ApiResponse } from "../misc/api";
import ErrorHandler from "./ErrorHandler";

type ErrorBoundaryProps = {
    children: ReactNode;
}

type ErrorBoundaryState = {
    errorType: "render" | "unhandledrejection" | "error" | "none";
    error?: any;
}

class ErrorBoundary extends Component<ErrorBoundaryProps, ErrorBoundaryState> {
    constructor(props: ErrorBoundaryProps) {
        super(props);
        this.state = { errorType: "none" };
    }

    componentDidMount() {
        window.addEventListener("error", this.onError);
        window.addEventListener("unhandledrejection", this.onUnhandledRejection);
    }

    componentWillUnmount() {
        window.removeEventListener("error", this.onError);
        window.removeEventListener("unhandledrejection", this.onUnhandledRejection);
    }

    componentDidCatch(error: Error, _errorInfo: ErrorInfo) {
        this.setState({ errorType: "render", error: error });
    }

    onError = (event: ErrorEvent) => {
        this.setState({ errorType: "error", error: event })
    }

    onUnhandledRejection = (event: PromiseRejectionEvent) => {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
        this.setState({ errorType: "unhandledrejection", error: event.reason })
    }

    onDismiss = () => {
        this.setState({ errorType: "none" });
    }


    render() {
        if (this.state.error instanceof ApiResponse) {
            return <ErrorHandler statusCode={this.state.error.status} bodyResponse={this.state.error.body} />
        }

        return (
            <>
                {this.props.children}
            </>);
    }
}

export default ErrorBoundary;
