import { IconButton } from "../../../../lib";
import { DocumentTagModel } from "../../../../model";
import "./Tag.tsx.scss";

type TagProps = {
    tag: DocumentTagModel;
    onRemoveClick?: (id: number) => void;
    size: "normal" | "small";
}

const Tag = (props: TagProps): JSX.Element => {
    const alpha = props.tag.color.a === undefined ? 100 : props.tag.color.a / 100;

    return (
        <div
            style={
                {
                    borderColor: "rgba(" +
                        props.tag.color.r + "," +
                        props.tag.color.g + "," +
                        props.tag.color.b + "," +
                        alpha + ")"
                }} className={props.size === "normal" ? "documenttag-div-normal" : "documenttag-div-small"} >
            {props.tag.name}
            {props.onRemoveClick &&
                <div style={{ marginLeft: "3px" }}>
                    <IconButton iconName={"Cancel"} onClick={() => props.onRemoveClick!(props.tag.id)} size="small" />
                </div>
            }
        </div>
    )
}

export default Tag;
