import { useApplicationAreas, useDeleteRole, useProcessGroups, useRole, useSubsidiariesList, useUpdateRole } from "../../../api";
import { Container, CreatedEditedFooter, useCopy, useResources } from "../../../improve-lib";
import { RowAction, Section } from "../../../lib";
import BasicDetails from "./basic/BasicDetails";
import ConfigurationPermissions from "./permissions/configuration/ConfigurationPermissions";
import InputMaskPermissions from "./permissions/input-mask/InputMaskPermissions";
import UsersDetails from "./users/UsersDetails";

type RoleDetailsPageProps = {
    roleId: number;
    refresh: () => void;
    onRoleDeleted: () => void;
}

const RoleDetailsPage = (props: RoleDetailsPageProps) => {
    const resources = useResources();

    const { applicationAreas } = useApplicationAreas();
    const { subsidiaries } = useSubsidiariesList();
    const { processGroups } = useProcessGroups();
    const { role, reload } = useRole(props.roleId);
    const { deleteRole } = useDeleteRole(() => {
        props.onRoleDeleted();
    });
    const { updateRole, duplicatedIsoCodes } = useUpdateRole(() => {
        props.refresh();
        reload();
    })
    const [name, setName, nameChanged] = useCopy(role?.name, "isoCode");
    const [permissions, setPermissions, permissionChanged] = useCopy(role?.permissions);
    const [documentPermissions, setDocumentPermissions, documentsApplicationAreasPermissionsChanged] = useCopy(role?.documentPermissions, "applicationAreaId");
    const [processPermissions, setProcessPermissions, processGroupPermissionsChanged] = useCopy(role?.processPermissions, "processGroupId");
    const [users, setUsers, usersChanged] = useCopy(role?.users);

    if (!applicationAreas || !subsidiaries || !processGroups || !role || !name || !permissions || !documentPermissions || !processPermissions || !users)
        return null;

    const anyChanges = nameChanged || permissionChanged || documentsApplicationAreasPermissionsChanged || processGroupPermissionsChanged || usersChanged;

    let allowSave = true;

    if (name) {
        for (let i = 0; i < name.length; i++)
            if (name[i].label.length < 1)
                allowSave = false;
    }

    const deleteAction: RowAction = {
        onClick: () => deleteRole(props.roleId, role.name.find(n => n.isoCode === resources.isoCode)!.label ?? ""),
        iconName: "Delete",
        text: resources.delete
    };

    const onUpdateRole = async () => {
        await updateRole(props.roleId, {
            name: nameChanged ? name : undefined,
            permissions: permissionChanged ? permissions : undefined,
            documentPermissions: documentsApplicationAreasPermissionsChanged ? documentPermissions.map(d => { return { id: d.applicationAreaId, permission: d.permission } }) : undefined,
            processPermissions: processGroupPermissionsChanged ? processPermissions.map(d => { return { id: d.processGroupId, permission: d.permission } }) : undefined,
            users: usersChanged ? users.map(u => ({ userId: u.user.id, subsidiaryIds: u.subsidiaries.map(s => s.id) })) : undefined,
            versionCount: role?.versionCount
        })
    }

    return (
        <Container title={role.name.find(n => n.isoCode === resources.isoCode)!.label ?? ""} onSave={onUpdateRole} saveDisabled={!anyChanges || !allowSave} actions={[deleteAction]}>
            <Section headerText={resources.label} collapsible collapsed highlighted={"blue"}>
                <BasicDetails
                    roleName={name}
                    alterRoleName={setName} duplicatedIsoCodes={duplicatedIsoCodes} />
            </Section>

            <Section headerText={resources.permissionConfiguration} collapsible highlighted={"blue"}>
                <ConfigurationPermissions
                    permission={permissions}
                    alterPermission={setPermissions} />
            </Section>

            <Section headerText={resources.permissionInputMasks} collapsible highlighted={"blue"}>
                <InputMaskPermissions
                    applicationAreaPermissions={documentPermissions}
                    applicationAreas={applicationAreas}
                    onApplicationAreaPermissionsUpdated={setDocumentPermissions}
                    processGroupPermissions={processPermissions}
                    processGroups={processGroups}
                    onProcessGroupPermissionsUpdated={setProcessPermissions} />
            </Section>

            <Section headerText={resources.users + " (" + users.length + ")"} collapsible highlighted={"blue"}>
                <UsersDetails users={users} alterUsers={setUsers} availableSubsidiaries={subsidiaries} />
            </Section>
            <CreatedEditedFooter tracked={role} />
        </Container>
    )
}

export default RoleDetailsPage;
