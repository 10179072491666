import { INavLink, INavLinkGroup, INavStyleProps, INavStyles, IStyleFunctionOrObject, Nav, Text, useTheme } from "@fluentui/react";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import MenuGroup from "./model/menuGroup";
import "./MenuBase.tsx.scss";

type MenuBaseProps = {
    menuGroups: MenuGroup[];
    onAfterNavigation: () => void;
}

const MenuBase = (props: MenuBaseProps): JSX.Element => {
    const navigate = useNavigate()
    const [gdHidden, setGdHidden] = useState(false);

    const theme = useTheme();

    const onLinkClick = (e: React.MouseEvent | undefined, element: INavLink | undefined) => {
        e!.preventDefault();
        if (element && element.url) {
            navigate(element.url);
            props.onAfterNavigation();
        }
    }

    const onRenderGroupHeader = (navGroup: INavLinkGroup | undefined): JSX.Element => {
        return (
            <Text className="menu-group-header" styles={{ root: { fontSize: 19, marginLeft: 20 } }}>
                {navGroup!.name}
            </Text>
        )
    };

    const onLinkExpandClick = () => setGdHidden(h => !h);

    const groups: INavLinkGroup[] = props.menuGroups.map(group => {
        return {
            name: group.title,
            links: group.links.map(link => {
                return {
                    url: link.url,
                    name: link.text,
                    isExpanded: !gdHidden,
                    links: link.links?.map(l => ({
                        name: l.text,
                        icon: l.icon,
                        url: l.url
                    } as INavLink)),
                    iconProps: link.icon ? ({
                        iconName: link.icon,
                        styles: {
                            root: {
                                paddingLeft: 3
                            }
                        }
                    }) : undefined
                }
            })
        } as INavLinkGroup
    })

    const navStyles: IStyleFunctionOrObject<INavStyleProps, INavStyles> = {
        groupContent: {
            animation: "none",
            marginBottom: 0,
            marginLeft: 15,
        },
        group: {
            marginTop: 5,
            marginRight: 3,
            marginLeft: -12,
        },
        link: {
            // https://github.com/microsoft/fluentui/discussions/16206#discussioncomment-197268
            ".ms-Nav-compositeLink:hover &": {
                backgroundColor: theme.palette.neutralQuaternary
            },
            ".is-selected &": {
                backgroundColor: theme.palette.neutralQuaternaryAlt
            }
        },
    };

    return (
            <div className="nav-grid-container">
                <Nav
                    styles={navStyles}
                    onLinkClick={onLinkClick}
                    onRenderGroupHeader={onRenderGroupHeader}
                    groups={groups}
                    onLinkExpandClick={onLinkExpandClick}
                />
            </div>           
    )
}

export default MenuBase;
