import { useEffect, useState } from "react";
import { get } from "../../improve-lib";
import ApplicationAreaPermission from "../../model/documents/applicationAreaPermission";

const usePermittedApplicationAreas = () => {
    const [applicationAreaPermission, setApplicationAreaPermission] = useState<ApplicationAreaPermission[]>();

    useEffect(() => {
        const getPermittedApplicationAreas = async () => {
            const response = await get("applicationAreas/permissions");

            if (response.status !== 200)
                throw response;

            setApplicationAreaPermission(response.body as ApplicationAreaPermission[]);
        }

        void getPermittedApplicationAreas();
    }, []);

    return { applicationAreaPermission }
}

export default usePermittedApplicationAreas;
