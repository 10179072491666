import { Pivot, PivotItem } from "@fluentui/react";
import { ReactNode } from "react";
import "./Tabs.tsx.scss";

type TabsProps = {
    selectedIndex: number,
    tabs: TabItem[];
    onClick: (index: number) => void;
}

type TabItem = {
    header: string,
    control: ReactNode,
}

const Tabs = (props: TabsProps): JSX.Element => {
    const onLinkClick = (item?: PivotItem) => {
        if (item)
            props.onClick(parseInt(item.props.itemKey!));
    }

    return (
        <Pivot selectedKey={props.selectedIndex.toString()} onLinkClick={onLinkClick} >
            {props.tabs.map((tab, index) =>
                <PivotItem className="tabs-tab-content" key={index} itemKey={index.toString()} headerText={tab.header} >
                    {tab.control}
                </PivotItem>
            )}
        </Pivot>
    )
}

export default Tabs;
