import { useCallback, useMemo, useState } from "react";
import { useCopy, useFields, useLanguage, useResources } from "../../../../improve-lib";
import { Dialog, Popup, Tabs } from "../../../../lib";
import { CustomFieldModel, EditProcessModel, IdValuePair, ITracked, LinkModel, LinkType, MultiLanguageDescriptionModel, MultiLanguageFieldModel } from "../../../../model";
import EditDocumentLinks from "./EditDocumetLinks";
import EditExternalLinks from "./EditExternalLinks";
import EditProcessForm from "./EditProcessForm";

type EditProcessPopupProps = {
    id: number;
    name: MultiLanguageFieldModel;
    owner: IdValuePair;
    description: MultiLanguageDescriptionModel | null;
    links: LinkModel[];
    customFields: CustomFieldModel[];
    processGroup: IdValuePair;
    subsidiary: IdValuePair | null;
    tracked: ITracked;
    isOpen: boolean;
    onProcessEdited: (processEdited: EditProcessModel) => void;
    onAbort: () => void;
}

const EditProcessPopup = (props: EditProcessPopupProps): JSX.Element => {
    const lang = useLanguage();
    const resources = useResources();

    const [tabIndex, setTabIndex] = useState(0);

    const emptyDescription = useMemo(() => lang.reduce((dic, isoCode) => ({ ...dic, [isoCode]: "" }), {} as MultiLanguageDescriptionModel), [lang]);

    const [owner, setOwner] = useCopy(props.owner);
    const [name, setName] = useCopy(props.name, "isoCode");
    const [description, setDescription] = useCopy(props.description ?? emptyDescription);
    const [links, setLinks] = useCopy(props.links);
    const { fields, updateField } = useFields(props.customFields);

    const [showDialog, setShowDialog] = useState(false);

    const isValid = useCallback(() => {
        if (name.some(n => n.label.trim().length < 1))
            return false;

        const anyDescriptionFilled = Object.values(description).some(d => d.length > 0);
        const allDescriptionFilled = Object.values(description).every(d => d.trim().length > 0);
        if (anyDescriptionFilled && !allDescriptionFilled)
            return false;

        if (fields.some(f => !f.isValid()))
            return false;

        return true;
    }, [name, description, fields]);

    const onProcessEdited = () => {
        const anyDescriptionFilled = Object.values(description).some(d => d.length > 0);
        props.onProcessEdited({
            name: name,
            owner: owner,
            description: anyDescriptionFilled ? description : null,
            links: links,
            customFields: fields.map(f => f.getModel())
        });
    }

    const onClose = () => {
        if (isValid()) {
            onProcessEdited();
            props.onAbort();
        }
        setShowDialog(true);
    }

    const onDismiss = () => {
        setShowDialog(false);
    }

    return (
        <>
            <Popup
                isOpen={props.isOpen}
                title={resources.edit}
                onDismiss={onClose}               
                closeText={resources.close}
                size={"big"}
            >
                <Tabs tabs={[
                    {
                        header: resources.information,
                        control:
                            <EditProcessForm
                                name={name}
                                owner={owner}
                                description={description}
                                fields={fields}
                                processGroup={props.processGroup}
                                subsidiary={props.subsidiary}
                                onNameChanged={setName}
                                onOwnerChanged={setOwner}
                                onDescriptionChanged={setDescription}
                                onFieldChanged={updateField}
                                tracked={props.tracked}
                            />
                    },
                    {
                        header: resources.documents,
                        control: <EditDocumentLinks
                            links={links}
                            sourceId={props.id}
                            sourceType={LinkType.Processes}
                            onChanged={setLinks}
                        />
                    },
                    {
                        header: resources.externalLinks,
                        control: <EditExternalLinks
                            links={links}
                            sourceId={props.id}
                            sourceType={LinkType.Processes}
                            onChanged={setLinks}
                        />
                    }
                ]} selectedIndex={tabIndex} onClick={setTabIndex} />

            </Popup>
            <Dialog hidden={!showDialog} onDismiss={onDismiss} title={resources.invalidData} description={resources.closeNotPossible}></Dialog>;
        </>
    )
}

export default EditProcessPopup;
