import { ReactNode, useContext, useEffect, useRef, useState } from "react";
import { unstable_useBlocker } from "react-router-dom";
import { DialogContext } from "../dialog/DialogContext";
import SaveCheckDialog from "../dialog/SaveCheckDialog";
import { GlobalSaveCheckContext } from "./GlobalSaveCheckContext";
import { SaveCheckContext } from "./SaveCheckContext";

type SaveCheckProviderProps = {
    children: ReactNode;
}

const SaveCheckProvider = (props: SaveCheckProviderProps) => {
    const changeCount = useRef(0);
    const [hasChanges, setHasChanges] = useState(false);

    const dialog = useContext(DialogContext);
    const global = useContext(GlobalSaveCheckContext);
    const isRoot = useContext(SaveCheckContext).isRoot;

    const hasUnsavedChanges = useRef(false);
    const shouldOptOut = useRef(false);
    hasUnsavedChanges.current = isRoot === true && global.hasChanges

    const blocker = unstable_useBlocker(() => {
        if (shouldOptOut.current)
            return false;

        return hasUnsavedChanges.current;
    });

    useEffect(() => {
        const onUnload = (e: BeforeUnloadEvent) => {
            if (hasUnsavedChanges.current)
                e.returnValue = "";
        }

        addEventListener("beforeunload", onUnload);

        return () => window.removeEventListener("beforeunload", onUnload);
    }, []);

    const promptSaveCheck = (callback: () => void, unblockNav: boolean) => {
        dialog.openDialog({
            callback: () => {
                if (unblockNav) {
                    shouldOptOut.current = true;
                    callback();
                    shouldOptOut.current = false;
                }
                else {
                    callback();
                }
            },
            dialog: {
                type: "savecheck"
            }
        });
    }

    const add = () => {
        changeCount.current++;
        global.add();

        if (changeCount.current === 1)
            setHasChanges(true);
    };
    const remove = () => {
        changeCount.current--;
        global.remove();

        if (changeCount.current === 0)
            setHasChanges(false);
    }

    const optOut = (callback: () => void) => {
        shouldOptOut.current = true;
        callback();
        shouldOptOut.current = false;
    }

    return (
        <SaveCheckContext.Provider value={{ add, remove, hasChanges, promptSaveCheck, optOut }}>
            <SaveCheckDialog hidden={blocker.state !== "blocked"} onCancel={() => { blocker.reset!() }} onLeaveAndDiscard={() => { blocker.proceed!() }} />
            {props.children}
        </SaveCheckContext.Provider>
    );
};

export default SaveCheckProvider;
