import { useRef } from "react";
import { useResources } from "../../../../../improve-lib";
import { Color, ColorSelector, Dialog,Section } from "../../../../../lib";

type ColorDialogProps = {
    color: { fillColor: Color; strokeColor: Color };
    isOpen: boolean;
    showFillColor: boolean;
    showStrokeColor: boolean;
    onDismiss: () => void;
    onColorChange: (newColor: { fillColor: Color; strokeColor: Color }) => void;
}

const ColorDialog = (props: ColorDialogProps): JSX.Element => {
    const resources = useResources();
    const color = useRef(props.color);

    const onFillColorChange = (newColor: Color) => {
        color.current = { fillColor: newColor, strokeColor: color.current.strokeColor };
        props.onColorChange(color.current);
    }

    const onStrokeColorChange = (newColor: Color) => {
        color.current = { fillColor: color.current.fillColor, strokeColor: newColor };
        props.onColorChange(color.current);
    }

    return (
        <Dialog
            hidden={!props.isOpen}
            onDismiss={props.onDismiss}
            title={resources.elementcolors}
        >
            {props.showFillColor &&
                <Section headerText={resources.fillcolor}>
                    <ColorSelector
                        selectedColor={color.current.fillColor}
                        onColorChange={onFillColorChange}
                        hiddenAlpha
                    />
                </Section>
            }
            {props.showStrokeColor &&
                <Section headerText={resources.strokecolor + " / " + resources.fontcolor}  >
                    <ColorSelector
                        selectedColor={color.current.strokeColor}
                        onColorChange={onStrokeColorChange}
                        hiddenAlpha
                    />
                </Section>
            }
        </Dialog>
    )
}

export default ColorDialog;
