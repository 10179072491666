import { useResources } from "../../../../improve-lib";
import { MultiLanguageTextbox } from "../../../../lib";
import { MultiLanguageFieldModel, } from "../../../../model";
import "./BasicDetails.tsx.scss";

type BasicDetailsProps = {
    roleName: MultiLanguageFieldModel;
    alterRoleName: (newRoleName: MultiLanguageFieldModel) => void;
    duplicatedIsoCodes: string[];
    newRole?: boolean;
}

const BasicDetails = (props: BasicDetailsProps): JSX.Element => {
    const resources = useResources();

    return (
        <div className="roles-details-basics-wrapper">
                <MultiLanguageTextbox
                    languageFields={props.roleName}
                    onChange={props.alterRoleName}
                    duplicatedIsoCodes={props.duplicatedIsoCodes}
                    textBoxHeader={resources.label + " " + resources.role}
                    mandatory
                />
        </div>
    )
}

export default BasicDetails;
