import { SearchBox } from "@fluentui/react";
import { useEffect } from "react";
import { useResources } from "../../improve-lib";
import "./TableFilter.tsx.scss";

type TableFilterProps = {
    fulltextFilter: string;
    setFulltextFilter: (newFulltextFilter: string) => void;
    onDebounce: () => void;
    fullCount: number;
    filteredCount: number;
}

const TableFilter = (props: TableFilterProps): JSX.Element => {
    const resources = useResources();

    useEffect(() => {
        const handle = setTimeout(props.onDebounce, 300);

        return () => clearTimeout(handle);
        // falls onDebounce in dependency-array aufgenommen wird, entsteht eine Endlosschleife :)
    }, [props.fulltextFilter])// eslint-disable-line react-hooks/exhaustive-deps

    // Wenn mit esc oder über das 'X' (Kreutz) die Suche zurückgesetzt wird, ist newValue undefined
    // Ohne '??' würde dann auch 'undefined' im Suchfeld stehen
    const onChange = (_event?: React.ChangeEvent<HTMLInputElement>, newValue?: string) => props.setFulltextFilter(newValue ?? "");

    return (
        <div id="table-filter-searchbox-parent" style={{ "--countEntries": "'" + props.filteredCount + " " + resources.of + " " + props.fullCount + "'" } as React.CSSProperties}>
            <SearchBox
                placeholder={resources.filter}
                onChange={onChange}
                iconProps={{ iconName: "Filter" }}
                value={props.fulltextFilter}
            />
        </div>
    )
}

export default TableFilter;
