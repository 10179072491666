import { useEffect, useState } from "react";
import { useLocation, useSearchParams } from "react-router-dom";

const useQueryValue = <T,>(name: string, convert: (value?: string) => T): [T, (value?: string) => void] => {
    const [value, setValue] = useState<T>(() => convert(undefined));
    const [params, setParams] = useSearchParams();
    const location = useLocation();

    useEffect(() => {
        const stringValue = params.get(name);

        const convertedValue = convert(stringValue ?? undefined);

        setValue(convertedValue);
        // eslint-disable-next-line  react-hooks/exhaustive-deps
    }, [location.search, name]);

    const updateValue = (value?: string) => {
        if (value)
            params.set(name, value);
        else
            params.delete(name);

        setParams(params);
    }

    return [value, updateValue];
}

export default useQueryValue;