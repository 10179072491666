import { Icon } from "@fluentui/react";
import { ListActions, RowAction } from "../../lib";
import Status from "../../page/documents/_lib/status/Status";
import "./ListItem.tsx.scss";

type ListItemProps = {
    title: string;
    attributes: string[];
    iconName?: string;
    footer?: JSX.Element;
    status?: string;
    rowAction?: RowAction[];
}

const ListItem = (props: ListItemProps): JSX.Element => {
    return (
        <>
            {props.iconName &&
                <div className={"list-item-div-icon"}>
                    <Icon iconName={props.iconName} />
                </div>
            }
            <div data-is-focusable style={{ width: "100%" }} className={"list-item-div, list-item-div-outline"}>
                <div className={"list-item-div"}>
                    <div style={{ display: "inline-block" }}>
                        {props.title}
                    </div>
                    {props.status &&
                        <div className="list-status-wrapper">
                            <Status status={props.status} />
                        </div>}
                </div>
                <div className={"list-item-div-secondrow"} >
                    {props.attributes.map(attribute =>
                        <div key={attribute} className={"list-item-div-inner"}>
                            {attribute}
                        </div>)
                    }
                </div>
                {props.footer}
            </div>
            {props.rowAction && props.rowAction.length > 0 ?
                <div style={{ alignSelf: "center", flex: 1, flexDirection: "row", justifyItems: "right" }}>
                    <ListActions items={props.rowAction} />
                </div>
                :
                <></>
            }
        </>
    )
}

export default ListItem;
