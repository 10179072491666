import { SearchBox as SearchBoxFluent } from "@fluentui/react";
import "./TableFilter.tsx.scss";

type SearchBoxProps = {
    text: string;
    placeholder: string;
    iconName?: string;
    onChange: (newText: string) => void;
}

const SearchBox = (props: SearchBoxProps): JSX.Element => {
    return (
        <SearchBoxFluent
            value={props.text}
            placeholder={props.placeholder}
            iconProps={{ iconName: props.iconName ?? "Search" }}
            // Wenn mit esc oder über das 'X' (Kreuz) die Suche zurückgesetzt wird, ist newValue undefined
            // Ohne '??' würde dann auch 'undefined' im Suchfeld stehen
            onChange={(_, newValue) => props.onChange(newValue ?? "")}
        />
    )
}

export default SearchBox;