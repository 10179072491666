import { Text } from "@fluentui/react";
import { ReactNode } from "react";
import { Elevation, RowAction, Separator } from "../../lib";
import ContainerActions from "./ContainerActions";
import "./Container.tsx.scss";

type ContainerProps = {
    title: string;
    children: ReactNode;
    actions?: RowAction[];
    onSave?: () => Promise<any>;
    saveDisabled?: boolean;
}

const Container = (props: ContainerProps): JSX.Element => {
    const checkActions = () => {
        return props.actions && props.actions.length > 0 || props.onSave;
    }

    return (
        <Elevation>
            <>
                {checkActions() &&
                    <>
                        <ContainerActions onSave={props.onSave} saveDisabled={props.saveDisabled} actions={props.actions ?? []} farItems={[]} overflow={[]} />
                        <Separator color="gray" />
                    </>
                }
                {(props.title) &&
                    <div className="container-title">
                        <Text variant="xLarge" style={{ paddingLeft: 8, wordBreak: "break-word" }} >
                            {props.title}
                        </Text>
                    </div>
                }
            </>
            {props.children}
        </Elevation>
    )
}

export default Container;
