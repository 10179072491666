import { useDeleteTag, useTag, useUpdateTag } from "../../../../../../api";
import { Container, useCopy, useResources } from "../../../../../../improve-lib";
import { RowAction } from "../../../../../../lib";
import { EditTagModel } from "../../../../../../model";
import Form from "./components/Form";

type DetailsProps = {
    tagId: number;
    refresh: () => void;
    onDeleted: () => void;
}

const Details = (props: DetailsProps) => {
    const resources = useResources();

    const { reload, tag } = useTag(props.tagId);
    const { deleteTag } = useDeleteTag(() => {
        props.onDeleted();
    })
    const { duplicatedIsoCodes, updateTag } = useUpdateTag(() => {
        props.refresh();
        reload();
    })

    const [name, setName, nameChanged] = useCopy(tag?.name, "isoCode");
    const [color, setColor, colorChanged] = useCopy(tag?.color);

    if (!tag)
        return null;
    if (!name)
        return null;
    if (!color)
        return null;

    const onTagChanged = (newTag: EditTagModel) => {
        setName(newTag.name);
        setColor(newTag.color);
    };

    let allowSave = true;

    if (name) {
        for (let i = 0; i < name.length; i++)
            if (name[i].label.length < 1)
                allowSave = false;
    }

    const deleteAction: RowAction = {
        onClick: () => deleteTag(props.tagId, tag.name.find(n => n.isoCode === resources.isoCode)!.label ?? ""),
        iconName: "Delete",
        text: resources.delete
    };


    const onUpdateTag = async () => {
        await updateTag(props.tagId, { name, color, versionCount: tag?.versionCount });
    }

    return (
        <Container title={tag.name.find(n => n.isoCode === resources.isoCode)!.label ?? ""} onSave={onUpdateTag} saveDisabled={(!nameChanged || !colorChanged) && !allowSave} actions={[deleteAction]}>
            <Form tag={{ ...tag, name: name, color: color }} onTagChange={onTagChanged} duplicatedIsoCodes={duplicatedIsoCodes} />
        </Container>
    )
}

export default Details;
