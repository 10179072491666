import { useEffect, useState } from "react";
import { get } from "../../improve-lib";

const useCountAllSubsidiaries = () => {
    const [subsidiariesCount, setSubsidiariesCount] = useState<number>(0);

    useEffect(() => {
        const countAllSubsidiaries = async () => {
            const response = await get("subsidiaries/countall");

            if (response.status !== 200)
                throw response;

            setSubsidiariesCount(response.body as number);
        }

        void countAllSubsidiaries();
    }, []);

    return { subsidiariesCount }
}

export default useCountAllSubsidiaries;