import { ContainerActions, useResources } from "../../../../improve-lib";
import { RowAction, Separator, Toggle } from "../../../../lib";
import { ProcessGroupPermissionType } from "../../../../model";
import ProcessBreadcrumb from "./ProcessBreadcrumb";
import "./ProcessHeader.tsx.scss";


type ProcessHeaderProps = {
    processId: number;
    readonly: boolean;
    processPermission: ProcessGroupPermissionType;
    saveAction?: RowAction;
    actions?: RowAction[];
    onReadonlyChanged: () => void;
    onProcessIdChanged: (id: number) => void;
}

const ProcessHeader = (props: ProcessHeaderProps): JSX.Element => {
    const resources = useResources();
    return (
        <>

            <div>
                <div className="process-header-container">
                    {props.processPermission >= ProcessGroupPermissionType.Write && !props.readonly &&
                        <div className="process-header-action-container">
                            <ContainerActions
                                actions={props.actions ? props.actions : []}
                                overflow={[]}
                                farItems={[]}
                            />
                            <Separator color="gray" />
                        </div>
                    }
                    <div className="process-header-breadcrumb">
                        <ProcessBreadcrumb
                            processId={props.processId}
                            onProcessIdChanged={props.onProcessIdChanged}
                        />
                        {props.processPermission >= ProcessGroupPermissionType.Write &&
                            <div className="process-toggle">
                                <Toggle
                                    label={resources.edit}
                                    checked={!props.readonly}
                                    onChange={props.onReadonlyChanged}
                                />
                            </div>
                        }
                    </div>
                </div>
                <Separator />
            </div>
        </>
    );
}

export default ProcessHeader;