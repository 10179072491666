import { Text } from "@fluentui/react";
import { ReactNode } from "react";
import "./HistoryRecord.tsx.scss";

type HistoryRecordProps = {
    title: string;
    children: ReactNode;
    headerControls?: ReactNode;
}

const HistoryRecord = (props: HistoryRecordProps): JSX.Element => {
    return (
        <div>
            <div className="header-title-container">
                <Text variant="xLarge" className="title-title-container">
                    {props.title}
                </Text>
                {props.headerControls}
            </div>
            <div className="content-title-container">
                {props.children}
            </div>
        </div>
    )
}

export default HistoryRecord;
