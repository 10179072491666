import { useLocation, useNavigate, useParams } from "react-router-dom";
import { usePermittedProcessGroups, useProcess } from "../../api";
import { Container } from "../../improve-lib";
import ProcessDetails from "./details/ProcessDetails";
import { ProcessesProvider } from "./ProcessesProvider";

const ProcessDetailsPage = (): JSX.Element | null => {
    const location = useLocation();
    const navigate = useNavigate();

    const { id } = useParams();
    const processId = parseInt(id!);

    const { permittedProcessGroups } = usePermittedProcessGroups();
    const { dbProcess, processPermission, reload, dataReadyFlag } = useProcess(processId, permittedProcessGroups);

    const onProcessIdChanged = (newId: number) => {
        const { processPath } = (location?.state ?? { processPath: [] }) as { processPath: number[] };

        if (processPath.length > 0) {
            let newPath = [...processPath];
            const i = processPath.indexOf(newId);

            if (i >= 0)
                newPath = processPath.slice(0, i + 1);
            else
                newPath.push(newId);

            navigate("/process/" + newId, {
                state: {
                    processPath: newPath
                }
            });
        }
        else
            navigate("/process/" + newId);
    }

    return (
        <ProcessesProvider flag={dataReadyFlag}>
            {dbProcess && processPermission &&
                <section>
                    <div className="content-container">
                        <Container title="" >
                            <ProcessDetails
                                process={dbProcess}
                                processPermission={processPermission}
                                flag={dataReadyFlag}
                                reload={reload}
                                onProcessIdChanged={onProcessIdChanged}
                            />
                        </Container>
                    </div>
                </section>
            }
        </ProcessesProvider>
    );
}

export default ProcessDetailsPage;