import { Link as FluentLink } from "@fluentui/react";

type LinkProps = {
    text: string;
    href?: string;
    openInSameTab?: boolean;
    onClick?: () => void;
}

const Link = (props: LinkProps): JSX.Element => {
    return (
        <FluentLink href={props.href} target={props.openInSameTab ? undefined : "_blank"} onClick={props.onClick}>{props.text}</FluentLink>
    );
}

export default Link;