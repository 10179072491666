import { useState } from "react";
import { useResources } from "../../../../../improve-lib";
import { Color, ColorSelector, Dialog, IconButton } from "../../../../../lib";

type ColorPreviewProps = {
    color: Color;
    onColorChange: (newColor: Color) => void;
}

const ColorPreview = (props: ColorPreviewProps): JSX.Element => {
    const resources = useResources();

    const [dialogHidden, setDialogHidden] = useState(true);

    return (
        <>
            <IconButton
                iconName={"Color"}
                color={props.color}
                tooltipText={resources.fontcolor}
                onClick={() => setDialogHidden(false)}
            />
            <Dialog
                hidden={dialogHidden}
                title={resources.fontcolor}
                onDismiss={() => setDialogHidden(true)}
            >
                <ColorSelector
                    hiddenAlpha
                    selectedColor={props.color}
                    onColorChange={props.onColorChange}
                />
            </Dialog>
        </>
    );
}

export default ColorPreview;