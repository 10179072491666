import { useUserWithWritePermissionProcess } from "../../../../api";
import { CreatedEditedFooter, FieldBase, useResources } from "../../../../improve-lib";
import { MultiLanguageDescription, MultiLanguageTextbox, UserSelection } from "../../../../lib";
import { IdValuePair, ITracked, MultiLanguageDescriptionModel, MultiLanguageFieldModel } from "../../../../model";

type EditProcessFormProps = {
    name: MultiLanguageFieldModel;
    owner: IdValuePair;
    description: MultiLanguageDescriptionModel;
    fields: FieldBase[];
    processGroup: IdValuePair;
    subsidiary: IdValuePair | null;
    tracked: ITracked;
    onNameChanged: (name: MultiLanguageFieldModel) => void;
    onOwnerChanged: (user: IdValuePair) => void;
    onDescriptionChanged: (description: MultiLanguageDescriptionModel) => void;
    onFieldChanged: (newField: FieldBase) => void;
}

const EditProcessForm = (props: EditProcessFormProps): JSX.Element => {
    const resources = useResources();

    const { userList } = useUserWithWritePermissionProcess(props.processGroup.id, props.subsidiary?.id ?? null);

    const userOnGet = (): Promise<{ results: IdValuePair[], full: boolean }> => {
        return new Promise(resolve => {
            resolve({ results: userList, full: true })
        });
    }

    return (
        <article>
            <div className="article-content">
                <MultiLanguageTextbox
                    languageFields={props.name}
                    onChange={props.onNameChanged}
                    duplicatedIsoCodes={[]}
                    textBoxHeader={resources.label}
                    mandatory={true}
                />
            </div>
            <div className="article-content">
                <UserSelection
                    label={resources.owner}
                    selectedUser={props.owner}
                    onUserChanged={props.onOwnerChanged}
                    onGet={userOnGet}
                    mandatory={true}
                />
            </div>
            <div className="article-content">
                {props.fields.map(field =>
                    <div className="" key={field.id}>
                        {field.getEditControl(props.onFieldChanged)}
                    </div>
                )}
            </div>
            <div className="article-form-element">
                <MultiLanguageDescription
                    languageDescriptions={props.description}
                    onChange={props.onDescriptionChanged}
                    textBoxHeader={resources.description}
                />
            </div>

            <CreatedEditedFooter
                tracked={props.tracked}
            />
        </article>

    );
}

export default EditProcessForm;