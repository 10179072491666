import { CommandBar, getTheme, ICommandBarItemProps, IIconProps } from "@fluentui/react";
import { useResources } from "../../improve-lib";

type NavigationHeaderProps = {
    backLabel: string;
    onNavigateBack: () => void;

    unitNavigation?: {
        onNavigateUp: () => void;
        upAvailable: boolean;
        onNavigateDown: () => void;
        downAvailable: boolean;
        unitCount: number;
        currentUnit: number;
        filteredUnitCount: number;
    }
}

const NavigationHeader = (props: NavigationHeaderProps): JSX.Element => {
    const resources = useResources();

    const theme = getTheme();

    const getNavIconProps = (iconName: string): IIconProps => {
        return {
            iconName: iconName, style: { fontSize: 13, fontWeight: "bold", color: theme.palette.themePrimary }
        }
    }

    const navCommands = props.unitNavigation;

    const navigationItemsRight: ICommandBarItemProps[] = navCommands ? [
        {
            key: "0",
            text: navCommands.unitCount === navCommands.filteredUnitCount ?
                (navCommands.currentUnit + " " + resources.of + " " + navCommands.unitCount) :
                (navCommands.currentUnit + " " + resources.of + " " + navCommands.filteredUnitCount + " (" + navCommands.unitCount + ")")
        },
        {
            key: "1",
            iconProps: getNavIconProps("Up"),
            onClick: navCommands.onNavigateUp,
            disabled: !navCommands.upAvailable
        },
        {
            key: "2",
            iconProps: getNavIconProps("Down"),
            onClick: navCommands.onNavigateDown,
            disabled: !navCommands.downAvailable
        },
    ] : [];

    const navigationItemsLeft: ICommandBarItemProps[] = [
        {
            key: "0",
            text: props.backLabel,
            iconProps: getNavIconProps("RevToggleKey"),
            onClick: props.onNavigateBack
        }
    ];

    return (
        <>
            <CommandBar styles={{ root: { padding: 3, height: 35, borderBottom: "1px solid #F3F2F1" } }} items={navigationItemsLeft} farItems={navigationItemsRight} />
        </>
    )
}

export default NavigationHeader;
