import { useEffect, useState } from "react";
import { get } from "../../improve-lib";
import { IdValuePair } from "../../model";

const useUserWithWritePermissionProcess = (processGroupId: number, subsidiaryId: number | null) => {
    const [userList, setUserList] = useState<IdValuePair[]>([]);

    useEffect(() => {
        const getUserList = async () => {
            const response = await get("process/getUsersWithWritePermission?processGroupId=" + processGroupId + (subsidiaryId ? "&subsidiaryId=" + subsidiaryId : ""));

            if (response.status !== 200)
                throw response;

            setUserList(response.body as IdValuePair[])
        }

        void getUserList();
    }, [processGroupId, subsidiaryId]);

    return { userList };
}

export default useUserWithWritePermissionProcess;