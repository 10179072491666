import { useState } from "react";
import { useCreatePdfToken, usePdfConvertible } from "../../api";
import { CheckBox, FileUpload, Hidden, IconButton } from "../../lib";
import { useResources } from "..";
import "./PdfFileUpload.tsx.scss";

type PdfFileUploadProps = {
    onFileChanged: (file?: File) => void;
    onFilenameChanged: (newFileName: string) => void;
    filename?: string;
    requireFile?: boolean;

    onConvertToggle: (convert: boolean) => void;
    convertToPdfDefault: boolean;
}

const PdfFileUpload = (props: PdfFileUploadProps): JSX.Element => {
    const [file, setFile] = useState<File>();
    const [convertToPdf, setConvertToPdf] = useState(props.convertToPdfDefault);

    const resources = useResources();

    const { createPdfToken } = useCreatePdfToken();
    const { isConvertible } = usePdfConvertible(file?.name);

    const coverToPdfIndeterminate = convertToPdf && file !== undefined;

    const onConvertToggle = (checked: boolean) => {
        setConvertToPdf(checked);
        props.onConvertToggle(checked);
    }

    const onFileChanged = (file?: File) => {
        if (file !== undefined)
            setFile(file)

        props.onFileChanged(file);
    }

    const onShowPreview = async () => {
        const token = await createPdfToken(file!);

        window.open("/api/pdf?token=" + token);
    }

    return (
        <div className="pdf-fu-container">
            <FileUpload
                onFileChanged={onFileChanged}
                onFilenameChanged={props.onFilenameChanged}
                filename={props.filename}
                requireFile={props.requireFile}
            />
            <div className="pdf-fu-cb-container">
                <CheckBox
                    label={resources.convertToPdf}
                    onChange={onConvertToggle}
                    checked={coverToPdfIndeterminate}
                    disabled={!isConvertible}
                    indeterminate={file === undefined}
                />
            </div>
            <Hidden visible={coverToPdfIndeterminate}>
                <div className="pdf-fu-eye-container">
                    <IconButton
                        iconName={"RedEye"}
                        size="large"
                        onClick={onShowPreview}
                        disabled={!isConvertible}
                        tooltipText={resources.preview}
                    />
                </div>
            </Hidden>
        </div>
    )
}

export default PdfFileUpload;
