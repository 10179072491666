import { IconButton } from "../../../../../lib";

type ZoomControlsProps = {
    onZoomReset: () => void;
    onZoomIn: () => void;
    onZoomOut: () => void;
    className?: string;
}

const ZoomControls = (props: ZoomControlsProps): JSX.Element => {
    return (
        <div className={props.className} >
            <IconButton
                iconName={"ZoomToFit"}
                onClick={props.onZoomReset}
            />
            <IconButton
                iconName={"Add"}
                onClick={props.onZoomIn}
            />
            <IconButton
                iconName={"Remove"}
                onClick={props.onZoomOut}
            />
        </div>
    );
}

export default ZoomControls;