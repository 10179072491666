import { useCallback, useEffect, useState } from "react";
import { useCountAllSubsidiaries, usePermittedProcessGroups } from "../../../../../api";
import { ContainerActions, useResources } from "../../../../../improve-lib";
import { DropDown } from "../../../../../lib";
import { IdValuePair, ProcessSubprocess } from "../../../../../model";
import NewProcessPopup from "../../../new/NewProcessPopup";
import useNewAction from "../../../new/useNewAction";
import { useProcessesContext, useProcessSubprocessContext } from "../../../ProcessesProvider";

type SubprozessSelectorProps = {
    subprocess: IdValuePair | null;
    processId: number;
    subsidiary: IdValuePair | null;
    onSubprocessChanged: (subprocess: IdValuePair) => void
}

const SubprozessSelector = (props: SubprozessSelectorProps): JSX.Element => {
    const resources = useResources();

    const processes = useProcessesContext();
    const processSubprocess = useProcessSubprocessContext();

    const { subsidiariesCount } = useCountAllSubsidiaries();
    const { permittedProcessGroups } = usePermittedProcessGroups();

    const { newAction, newProcessPopupVisible, newProcessInfo, onPopupClose } = useNewAction(subsidiariesCount, permittedProcessGroups);

    const [filteredProcesses, setFilteredProcesses] = useState<IdValuePair[]>([]);

    const getParentProcesses = useCallback((processId: number, processSubprocess: ProcessSubprocess[]) => {
        const parentIds: number[] = [];
        if (processSubprocess) {
            const parentProcesses = processSubprocess.filter(ps => ps.subprocessId === processId);
            for (const process of parentProcesses) {
                parentIds.push(process.processId, ...getParentProcesses(process.processId, processSubprocess));
            }
        }
        return parentIds;
    }, []);

    const getFilteredProcesses = useCallback((processes: IdValuePair[], processSubprocess: ProcessSubprocess[]) => {
        const processIdsToRemove = [props.processId, ...getParentProcesses(props.processId, processSubprocess)];
        return processes.filter(p => {
            const subsidiaryId = processSubprocess.find(ps => ps.processId === p.id)?.subsidiaryId;
            const correctSubsidiary = (props.subsidiary === null || subsidiaryId === null || subsidiaryId === props.subsidiary?.id);
            return correctSubsidiary && !processIdsToRemove.includes(p.id);
        });
    }, [props.processId, props.subsidiary, getParentProcesses]);

    useEffect(() => {
        let filteredProcesses = getFilteredProcesses(processes, processSubprocess);

        filteredProcesses = [{ id: -1, value: "" }, ...filteredProcesses];

        if (props.subprocess && (!filteredProcesses.some(p => p.id === props.subprocess!.id) && props.subprocess.id > 0))
            filteredProcesses = [...filteredProcesses, props.subprocess];

        setFilteredProcesses(filteredProcesses);
    }, [props.subprocess, getFilteredProcesses, processSubprocess, processes]);

    const onNewProcessCreated = (newProcess: IdValuePair) => {
        onPopupClose();
        props.onSubprocessChanged(newProcess);
    }

    return (
        <>
            <div style={{ display: "flex", alignItems: "flex-end" }}>
                <div style={{ flex: 1, marginRight: 10 }} >
                    <DropDown
                        label={resources.subprocess}
                        placeholder={""}
                        selectedId={props.subprocess?.id}
                        onChange={props.onSubprocessChanged}
                        options={filteredProcesses}
                    />
                </div>
                <div>
                    {(!props.subprocess || props.subprocess.id < 1) &&
                        <ContainerActions actions={newAction} overflow={[]} farItems={[]} />
                    } 
                </div>
            </div>
            {newProcessInfo &&
                <NewProcessPopup
                    processGroup={newProcessInfo.processGroup}
                    subsidiary={newProcessInfo.subsidiary}
                    subsidiariesCount={subsidiariesCount}
                    isOpen={newProcessPopupVisible}
                    onAbort={onPopupClose}
                    onProcessCreated={onNewProcessCreated}
                />
            }
        </>
    )
}

export default SubprozessSelector;
