import "./Status.tsx.scss";

type StatusProps = {
    status : string;
}

const Status = (props: StatusProps): JSX.Element => {
    return (
        <div className="documentstatus-div">
            {props.status}
        </div>
    )
}

export default Status;