import Viewer from "bpmn-js/lib/NavigatedViewer"
import CustomOrderingModule from "./custom";
import CustomModdle from "./custom/CustomElements.json";
import CustomRendererModule from "./renderer";
import "bpmn-js/dist/assets/diagram-js.css";
import "bpmn-js/dist/assets/bpmn-font/css/bpmn-embedded.css";


export default class BpmnViewer {
    constructor(
        container,
        resources,
        elementHelper,
        onSelectedElementChange,
    ) {
        this.resources = resources;

        this.Viewer = new Viewer({
            container: container,
            additionalModules: [
                CustomRendererModule,
                CustomOrderingModule
            ],
            getResources: () => this.resources,
            elementHelper: elementHelper,
            moddleExtensions: {
                custom: CustomModdle
            }
        });

        this.Viewer.on("element.dblclick", 1500, event => {
            event.stopPropagation();
            elementHelper.current.onElementDoubleClick(event.element.id);
        });

        this.Viewer.get("eventBus").on("selection.changed", selectionChangedEvent => {
            if (selectionChangedEvent.newSelection.length === 1)
                onSelectedElementChange(selectionChangedEvent.newSelection[0].id);
            else
                onSelectedElementChange(undefined);
        });
    }

    updateResources = resources => {
        this.resources = resources;
    }

    updateDiagram = async xml => {
        await this.Viewer.importXML(xml);
        this.zoomReset();
    }

    zoomReset() {
        const canvas = this.Viewer.get("canvas");
        canvas.zoom("fit-viewport");
        canvas.scroll({ dx: 20, dy: 0 });
    }
    zoomIn() { this.Viewer.get("zoomScroll").stepZoom(1); }
    zoomOut() { this.Viewer.get("zoomScroll").stepZoom(-1); }

    getSVG = async () => {
        const output = await this.Viewer.saveSVG();
        return output.svg;
    }
}