import { useMemo, useState } from "react";
import { useCopy, useLanguage, useResources } from "../../../../improve-lib";
import { Popup, Tabs } from "../../../../lib";
import { EditProcessElementModel, FontStyle, IdValuePair, LinkType, MultiLanguageDescriptionModel, MultiLanguageFieldModel, ProcessElementModel } from "../../../../model";
import EditDocumentLinks from "./EditDocumetLinks";
import EditElementForm from "./EditElementForm";
import EditExternalLinks from "./EditExternalLinks";

type EditElementPopupProps = {
    isOpen: boolean;
    element: ProcessElementModel;
    processId: number;
    subsidiary: IdValuePair | null;
    fontStyle: FontStyle | undefined;
    imageSource: string | undefined;
    showLabel: boolean;
    showNavigation: boolean;
    showSubprocess: boolean;
    showDescription: boolean;
    allowToAddLinks: boolean;
    onElementEdited: (element: EditProcessElementModel) => void;
    onAbort: () => void;
}

const EditElementPopup = (props: EditElementPopupProps): JSX.Element => {
    const lang = useLanguage();
    const resources = useResources();

    const [tabIndex, setTabIndex] = useState(0);

    const emptyLabel = useMemo(() => lang.map(l => ({ isoCode: l, label: "" })) as MultiLanguageFieldModel, [lang]);
    const emptyDescription = useMemo(() => lang.reduce((dic, isoCode) => ({ ...dic, [isoCode]: "" }), {} as MultiLanguageDescriptionModel), [lang]);

    const [name, setName] = useCopy(props.element.label ?? emptyLabel, "isoCode");
    const [description, setDescription] = useCopy(props.element.description ?? emptyDescription);
    const [links, setLinks] = useCopy(props.element.links);
    const [subprocess, setSubprocess] = useCopy(props.element.subprocess);
    const [imageSource, setimageSource] = useCopy(props.imageSource);

    const [font, setFont] = useState(structuredClone(props.fontStyle));
    const [fontChanged, setFontChanged] = useState(false);

    const onFontChanged = (newFont: FontStyle) => {
        setFont(newFont)
        setFontChanged(newFont !== props.fontStyle);
    };

    const elementIsValid = () => {
        if (props.isOpen) {
            if (name.some(n => n.label.length > 0))
                return !name.some(n => n.label.trim().length < 1);

            const anyDescriptionFilled = Object.values(description).some(d => d.length > 0);
            const allDescriptionFilled = Object.values(description).every(d => d.trim().length > 0);
            if (anyDescriptionFilled && !allDescriptionFilled)
                return false;
        }
        return true;
    }

    const onElementEdited = () => {
        const anyLabelFilled = name.some(n => n.label.length > 0);
        const anyDescriptionFilled = Object.values(description).some(d => d.length > 0);
        props.onElementEdited({
            bpmnId: props.element.bpmnId,
            name: anyLabelFilled ? name : null,
            description: anyDescriptionFilled ? description : null,
            links: links,
            subprocess: (subprocess && subprocess.id > 0) ? subprocess : null,
            font: fontChanged ? font : undefined,
            imageSource: imageSource
        });
    }

    const tabItems =
        [
            {
                header: resources.information,
                control:
                    <EditElementForm
                        subprocess={subprocess}
                        name={name}
                        description={description}
                        links={links ?? []}
                        font={font}
                        imageSource={imageSource}
                        processId={props.processId}
                        subsidiary={props.subsidiary}
                        showLabel={props.showLabel}
                        showNavigation={props.showNavigation}
                        showSubprocess={props.showSubprocess}
                        showDescription={props.showDescription}
                        onSubprocessChanged={setSubprocess}
                        onNameChanged={setName}
                        onDescriptionChanged={setDescription}
                        onLinksChanged={setLinks}
                        onFontChanged={onFontChanged}
                        onImageSourceChanged={setimageSource}
                    />
            },
            ...(props.allowToAddLinks ?
                [{
                    header: resources.documents,
                    control: <EditDocumentLinks
                        links={links ?? []}
                        sourceType={LinkType.ProcessElements}
                        onChanged={setLinks}
                    />
                },
                {
                    header: resources.externalLinks,
                    control: <EditExternalLinks
                        links={links ?? []}
                        sourceType={LinkType.ProcessElements}
                        onChanged={setLinks}
                    />
                }] :
                []
            )
        ];

    const onClose = () => {
        if (!elementIsValid()) {
            //TODO: refactor to popup
            alert("Achtung, es sind ungültige Daten vorhanden!")
            return;
        }
        onElementEdited();
        props.onAbort();
    }

    return (
        <Popup
            isOpen={props.isOpen}
            title={resources.edit}
            onDismiss={onClose}
            closeText={resources.close}
            size="big"
        >
            {tabItems.length > 1 ?
                <Tabs selectedIndex={tabIndex} onClick={setTabIndex} tabs={tabItems} />
                :
                tabItems[0].control
            }

            <div style={{ marginBottom: 10 }} />
        </Popup>
    )
}

export default EditElementPopup;
