import { CommandBarButton } from "@fluentui/react";

type ButtonProps = {
    text: string;
    fontSize?: number;
    onClick: () => void;
}

const TextButton = (props: ButtonProps): JSX.Element => {
    return (
        <CommandBarButton
            text={props.text}
            onClick={props.onClick}
            styles={{ root: { flex: 1, height: 42, textAlign: "left" }, label: { fontWeight: 600, fontSize: props.fontSize } }}
        />
    );
}

export default TextButton;