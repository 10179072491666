import { useState } from "react";
import { useResources, useSaveCheck, useUserSettings } from "../../../../improve-lib";
import { Popup, Tabs } from "../../../../lib";
import ApplicationAreaSettings from "./application-area/ApplicationAreaSettings";
import Configuration from "./configuration/Configuration";
import TagSettings from "./tag/TagSettings";

type DocumentsSettingsPopupProps = {
    isOpen: boolean;
    onClose: () => void;
}

const DocumentsSettingsPopup = (props: DocumentsSettingsPopupProps): JSX.Element => {
    const userSettings = useUserSettings();
    const resources = useResources();

    const [tabIndex, setTabIndex] = useState(0);

    const onTabClick = (index: number) => {
        switch (index) {
            case 0:
                if (tabIndex !== 0)
                    onApplicationAreaClick();
                break;
            case 1:
                if (tabIndex !== 1)
                    onTagClick();
                break;
            case 2:
                if (tabIndex !== 2)
                    onConfigClick();
                break;
        }
    }

    const onApplicationAreaClick = useSaveCheck(() => setTabIndex(0));
    const onTagClick = useSaveCheck(() => setTabIndex(1));
    const onConfigClick = useSaveCheck(() => setTabIndex(2));

    const onClose = useSaveCheck(props.onClose);

    return (
        <Popup
            isOpen={props.isOpen}
            title={resources.settings}
            onDismiss={onClose}
            closeText={resources.close}
            size={"big"}
        >
            <Tabs selectedIndex={tabIndex}
                onClick={onTabClick}
                tabs={[
                    ...(userSettings.permissions.documentsEditApplicationAreas ? [{
                        header: resources.applicationAreas,
                        control:
                            <ApplicationAreaSettings />
                    }] : []),
                    ...(userSettings.permissions.documentsEditTags ? [{
                        header: resources.tags,
                        control:
                            <TagSettings />
                    }] : []),
                    ...(userSettings.permissions.documentsConfiguration ? [{
                        header: resources.labels,
                        control:
                            <Configuration />
                    }] : [])]}
            />
        </Popup>
    )
}

export default DocumentsSettingsPopup;
