import { useEffect, useState } from "react";
import { get, useResources } from "../../improve-lib";
import { OpenTasksModule } from "../../model";

const useOpenTasksFilter = (selectedUserId: number | null, selectedSubsidiaries: number[], subsidiariesCount: number) => {
    const resources = useResources();

    const [allOpenTasksDb, setAllOpenTasksDb] = useState<OpenTasksModule[]>([]);
    const [allOpenTasks, setAllOpenTasks] = useState<OpenTasksModule[]>([]);

    useEffect(() => {
        const pathAdd = selectedUserId === null ? "" : "?userId=" + selectedUserId;

        const getOpenTasks = async () => {
            const response = await get("opentasks/" + pathAdd);

            if (response.status !== 200)
                throw response;

            setAllOpenTasksDb(response.body as OpenTasksModule[]);
        }
        void getOpenTasks();
    }, [resources.isoCode, selectedUserId]);

    useEffect(() => {
        const modulesData: OpenTasksModule[] = [];

        if (allOpenTasksDb.length === 0) {
            setAllOpenTasks(allOpenTasksDb);
            return;
        }

        if (selectedSubsidiaries.length === subsidiariesCount) {
            setAllOpenTasks(allOpenTasksDb);
            return;
        }

        allOpenTasksDb.forEach(moduleData => {
            const moduleDataFiltered: OpenTasksModule = {
                label: moduleData.label,
                columns: moduleData.columns,
                rows: moduleData.rows.reduce((filtered: any[], r) => {
                    if (r.subsidiary === null || (r.subsidiary && selectedSubsidiaries.includes(r.subsidiary.id))) {
                        filtered.push(r);
                    }
                    return filtered;
                }, [])
            };
            modulesData.push(moduleDataFiltered);
        });
        setAllOpenTasks(modulesData);
    }, [allOpenTasksDb, selectedSubsidiaries, subsidiariesCount]);

    return { allOpenTasks }
}

export default useOpenTasksFilter;