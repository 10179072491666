import { MultiLanguageBase, useResources } from "../../improve-lib";
import { MultiLanguageFieldModel } from "../../model";
import { ErrorType, IconButton, TextBox } from "..";
import "./MultiLanguageTextField.tsx.scss";

type MultiLanguageTextFieldProps = {
    languageFields: MultiLanguageFieldModel;
    onChange: (content: MultiLanguageFieldModel) => void;
    onEnter?: () => void;
    duplicatedIsoCodes: string[];
    placeholderText?: string;
    mandatory?: boolean;
    autoFocus?: boolean;
    error?: ErrorType;
}

const MultiLanguageTextField = (props: MultiLanguageTextFieldProps): JSX.Element => {
    const resources = useResources();

    const render = (multilang: boolean, isValid: boolean, onChangeText: (newValue: string) => void, onEdit: () => void) => {
        return (multilang ?
            <div className="multi-language-txtfield-wrapper">
                <div className="multi-language-txtfield">
                    <TextBox
                        label={""}
                        placeholderText={props.placeholderText}
                        text={props.languageFields.find(n => n.isoCode === resources.isoCode)!.label}
                        onChange={onChangeText}
                        onEnter={props.onEnter}
                        valid={isValid}
                        maxLength={40}
                        mandatory={props.mandatory}
                        autoFocus={props.autoFocus}
                        underlined
                        error={props.error}
                    />
                </div>
                <IconButton iconName={"Edit"} onClick={onEdit} />
            </div>
            :
            <div className="single-language-txtfield">
                <TextBox
                    label={""}
                    placeholderText={props.placeholderText}
                    autoFocus={props.autoFocus}
                    placeholder
                    text={props.languageFields.find(n => n.isoCode === resources.isoCode)!.label}
                    onChange={onChangeText}
                    onEnter={props.onEnter}
                    maxLength={40}
                    mandatory={props.mandatory}
                    underlined
                    error={props.error}
                />
            </div>
        );
    }

    return (
        <MultiLanguageBase
            languageFields={props.languageFields}
            onChange={props.onChange}
            onRender={render}
            textBoxHeader={resources.label}
            duplicatedIsoCodes={props.duplicatedIsoCodes}
            mandatory={props.mandatory}
        />
    );
}

export default MultiLanguageTextField;
