import LanguageResources from "../../../../../../improve-lib/language/model/languageResources";

// https://github.com/bpmn-io/bpmn-js-i18n/tree/master/translations
const translate = (resources: LanguageResources): { [key: string]: string } => {
    return ({
        "Activate the create/remove space tool": resources.activateSpaceTool,
        "Activate the global connect tool": resources.activateGlobalConnectTool,
        "Activate the lasso tool": resources.activateLassoTool,
        "Add Lane above": resources.addLaneAbove,
        "Add Lane below": resources.addLaneBelow,
        "Append EndEvent": resources.appendEndEvent,
        "Append Gateway": resources.appendGateway,
        "Append Intermediate/Boundary Event": resources.appendIntermediateEvent,
        "Append Task": resources.appendTask,
        "Change type": resources.changeType,
        "Compensation Boundary Event": resources.compensationBoundaryEvent,
        "Compensation End Event": resources.compensationEndEvent,
        "Compensation Intermediate Throw Event": resources.compensationIntermediateThrowEvent,
        "Complex Gateway": resources.complexGateway,
        "Conditional Boundary Event (non-interrupting)": resources.conditionalBoundaryEventNonInterrupting,
        "Conditional Boundary Event": resources.conditionalBoundaryEvent,
        "Conditional Flow": resources.conditionalFlow,
        "Conditional Intermediate Catch Event": resources.conditionalIntermediateCatchEvent,
        "Conditional Start Event": resources.conditionalStartEvent,
        "Connect using Sequence/MessageFlow or Association": resources.connectSequenceOrMessageFlow,
        "Create EndEvent": resources.createEndEvent,
        "Create Gateway": resources.createGateway,
        "Create Group": resources.createGroup,
        "Create Intermediate/Boundary Event": resources.createIntermediateEvent,
        "Create Pool/Participant": resources.createPool,
        "Create StartEvent": resources.createStartEvent,
        "Create Task": resources.createTask,
        "Default Flow": resources.defaultFlow,
        "Divide into three Lanes": resources.divideIntoThreeLanes,
        "Divide into two Lanes": resources.divideIntoTwoLanes,
        "Empty Pool": resources.emptyPool,
        "Empty Pool (removes content)": resources.emptyPoolRemovesContent,
        "End Event": resources.endEvent,
        "Error Boundary Event": resources.errorBoundaryEvent,
        "Error End Event": resources.errorEndEvent,
        "Escalation Boundary Event (non-interrupting)": resources.escalationBoundaryEventNonInterrupting,
        "Escalation Boundary Event": resources.escalationBoundaryEvent,
        "Escalation End Event": resources.escalationEndEvent,
        "Escalation Intermediate Throw Event": resources.escalationIntermediateThrowEvent,
        "Event based Gateway": resources.eventBasedGateway,
        "Exclusive Gateway": resources.exclusiveGateway,
        "Inclusive Gateway": resources.inclusiveGateway,
        "Intermediate Throw Event": resources.intermediateThrowEvent,
        "Link Intermediate Catch Event": resources.linkIntermediateCatchEvent,
        "Link Intermediate Throw Event": resources.linkIntermediateThrowEvent,
        "Message Boundary Event (non-interrupting)": resources.messageBoundaryEventNonInterrupting,
        "Message Boundary Event": resources.messageBoundaryEvent,
        "Message End Event": resources.messageEndEvent,
        "Message Intermediate Catch Event": resources.messageIntermediateCatchEvent,
        "Message Intermediate Throw Event": resources.messageIntermediateThrowEvent,
        "Message Start Event": resources.messageStartEvent,
        "Parallel Gateway": resources.parallelGateway,
        Remove: resources.delete,
        "Sequence Flow": resources.sequenceFlow,
        "Signal Boundary Event (non-interrupting)": resources.signalBoundaryEventNonInterrupting,
        "Signal Boundary Event": resources.signalBoundaryEvent,
        "Signal End Event": resources.signalEndEvent,
        "Signal Intermediate Catch Event": resources.signalIntermediateCatchEvent,
        "Signal Intermediate Throw Event": resources.signalIntermediateThrowEvent,
        "Signal Start Event": resources.signalStartEvent,
        "Start Event": resources.startEvent,
        Task: resources.task,
        "Terminate End Event": resources.terminateEndEvent,
        "Timer Boundary Event (non-interrupting)": resources.timerBoundaryEventNonInterrupting,
        "Timer Boundary Event": resources.timerBoundaryEvent,
        "Timer Intermediate Catch Event": resources.timerIntermediateCatchEvent,
        "Timer Start Event": resources.timerStartEvent,
        "flow elements must be children of pools/participants": resources.flowElementsMustBeChildrenOfPools
    });
}

export default translate;