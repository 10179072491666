import { createContext } from "react";

export type GlobalSaveCheckContextType = {
    hasChanges: boolean;
    add: () => void;
    remove: () => void;
}

const GlobalSaveCheckContext = createContext<GlobalSaveCheckContextType>({
    hasChanges: false,
    add: () => { },
    remove: () => { }
});

export { GlobalSaveCheckContext };