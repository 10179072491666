import { ErrorType, TextBox } from "../../../lib";
import { CustomFieldModel, FieldType } from "../../../model";
import FieldBase from "./fieldBase";

class NumberField extends FieldBase {
    private number: string;
    private readonly dbNumber: string;

    readonly fieldType: FieldType = FieldType.Number;

    constructor(id: number, name: string, mandatory: boolean, number: number | null) {
        super(id, name, mandatory);
        this.dbNumber = number?.toLocaleString() ?? "";
        this.number = number?.toLocaleString() ?? "";
    }

    isValid() {
        const valid = this.isValidValue();
        if (this.mandatory)
            return this.number.trim().length > 0 && valid;
        else
            return valid;
    }

    hasChange() { return this.number !== this.dbNumber; }

    getModel(): CustomFieldModel {
        return {
            id: this.id,
            fieldType: this.fieldType,
            settings: null,
            name: this.name,
            mandatory: this.mandatory,
            number: this.number === "" ? null : parseFloat(this.number.replaceAll(NumberField.getSeparator("group"), "")),
            boolean: null,
            date: null,
            selection: null,
            text: null
        };
    }

    private static getSeparator(separatorType: "group" | "decimal") {
        const numberWithGroupAndDecimalSeparator = 1000.1;
        return Intl.NumberFormat()
            .formatToParts(numberWithGroupAndDecimalSeparator)
            .find(part => part.type === separatorType)
            ?.value ?? "";
    }

    private isValidValue() {
        const value = this.number.replaceAll(NumberField.getSeparator("group"), "");
        if (value === "")
            return true;

        if (isNaN(value as unknown as number))
            return false;

        if (isNaN(parseFloat(value)))
            return false;

        return true;
    }

    getEditControl(onChange: (newField: FieldBase) => void) {
        return (
            <TextBox
                label={this.name}
                text={this.number}
                onChange={newValue => { this.number = newValue; onChange(this); }}
                mandatory={this.mandatory}
                error={this.isValidValue() ? undefined : ErrorType.format}
            />
        );
    }
}

export default NumberField;