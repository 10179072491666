import { useEffect, useState } from "react";
import { useCredentialsAuthentication } from "../../api";
import { Button, Dialog, DialogFooter, PasswordBox } from "../../lib";
import { useResources } from "..";

type SetCredentialsDialogProps = {
    userId: number;
    hidden: boolean;
    onDismiss: () => void;
    userDisplayName: string;
    onChanged: () => void;
}

const SetCredentialsDialog = (props: SetCredentialsDialogProps): JSX.Element => {
    const resources = useResources();

    const [newPassword, setNewPassword] = useState("");

    const { setCredentialsAuthentication } = useCredentialsAuthentication();

    useEffect(() => {
        if (!props.hidden) {
            setNewPassword("");
        }
    }, [props.hidden])

    const onChange = async () => {
        await setCredentialsAuthentication(props.userId, newPassword);
        props.onChanged();
    }

    return (
        <Dialog
            title={resources.changeToImproveAuthentication + " (" + props.userDisplayName + ")"}
            hidden={props.hidden}
            onDismiss={props.onDismiss}
        >
            <PasswordBox label={resources.temporaryPassword} text={newPassword} onChange={setNewPassword} />
            <DialogFooter>
                <Button onClick={onChange} text={resources.change} />
                <Button onClick={props.onDismiss} text={resources.cancel} secondary />
            </DialogFooter>
        </Dialog >
    )
}

export default SetCredentialsDialog;
