import { Text } from "@fluentui/react";
import { ReactNode, useEffect, useState } from "react";
import { Dialog, Link, Section } from "../../lib";
import { useResources } from "../language";

type SupportPopupProps = {
    isOpen: boolean;
    onAbort: () => void;
}

type WrapperProps = {
    children: ReactNode;
    headerText: string;
}

const Wrapper = (props: WrapperProps): JSX.Element => {
    return (
        <div style={{ height: "80%", width: "100%", marginBottom: 5, marginLeft: 5 }}>
            <Section headerText={props.headerText}>
                {props.children}
            </Section>
        </div>
    )
};

const SupportPopup = (props: SupportPopupProps): JSX.Element => {
    const resources = useResources();

    const [improveVersion, setImproveVersion] = useState<string>();

    useEffect(() => {
        const metas = document.getElementsByTagName("meta");

        for (let i = 0; i < metas.length; i++) {
            if (metas[i].getAttribute("name") === "improve-version")
                setImproveVersion(metas[i].getAttribute("content")?.toString());
        }
    }, []);

    return (
        <Dialog
            hidden={!props.isOpen}
            title={resources.support}
            onDismiss={props.onAbort}
        >
            <Wrapper headerText={resources.request}>
                <div>
                    <Link text="Synprovis GmbH" href="https://www.synprovis.ch" />
                </div>
                <div>
                    <Link text="support@synprovis.ch" href={"mailto:support@synprovis.ch?subject=Supportanfrage%20-%20Improve-Version%20" + improveVersion} />
                </div>
                <div>
                    <Link text="+41 41 785 20 70" openInSameTab href="tel:+41417852070" />
                </div>
            </Wrapper>
            <Wrapper headerText={resources.remoteMaintenance}>
                <div>
                    <Link text={resources.downloadTeamViewer} href="https://get.teamviewer.com/c437vtz" />
                </div>
            </Wrapper>
            <Wrapper headerText={resources.information}>
                <div>
                    <Text variant="medium">{resources.version}: {improveVersion}</Text>
                </div>
            </Wrapper>
        </Dialog >
    )
}

export default SupportPopup;
