import { Label } from "@fluentui/react";
import { useResources } from "../../../../../../improve-lib";
import { RadioButton } from "../../../../../../lib";
import "./PermissionMatrix.tsx.scss";

type MatrixValue<T extends number> = {
    id: number;
    name: string;
    value: T;
}

type MatrixOption<T extends number> = {
    name: string;
    value: T;
}

type MatrixProps<T extends number> = {
    title: string;
    values: MatrixValue<T>[];
    options: MatrixOption<T>[];
    onMatrixUpdate: (newMatrix: MatrixValue<T>[]) => void;
}

const PermissionMatrix = <T extends number>(props: MatrixProps<T>): JSX.Element => {
    const resources = useResources();

    const onChangeSingle = (index: number, value: T) => {
        const newValues = [...props.values];
        newValues[index].value = value;
        props.onMatrixUpdate(newValues);
    }

    const onChangeAll = (value: T) => {
        const newValues = [...props.values];
        for (let i = 0; i < newValues.length; i++)
            newValues[i].value = value;
        props.onMatrixUpdate(newValues);
    }

    return (
        <div className="RolesDetailsPermissionMatrix">
            <div className="divTitles">
                <div className="divSpacer"><Label>{props.title}</Label></div>
                <div className="divMatrix"><Label>{resources.permission}</Label></div>
            </div>
            <div>
                <div className="divSpacer"></div>
                <div className="divMatrix">
                    {props.options.map(option => <label className="Positioning" onClick={() => onChangeAll(option.value)} key={option.name}>{option.name}</label>)}
                </div>
            </div>
            <div>
                {props.values.map((value, index) => {
                    return (
                        <div key={value.id}>
                            <div className="divApplicationAreas">
                                <label className="PositioningLabelApplicationArea" key={value.name}>{value.name}</label>
                            </div>
                            <div className="divMatrix inlineflex">
                                <RadioButton
                                    options={props.options.map(option => { return { value: option.value.toString(), text: "" } })}
                                    onChange={newOption => onChangeSingle(index, parseInt(newOption) as T)}
                                    selectedValue={value.value.toString()}
                                />
                            </div>
                        </div>
                    )
                })}
            </div>
        </div>
    )
}

export default PermissionMatrix;
