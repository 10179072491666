import { post, useConfirmLockDialog } from "../../improve-lib";

const useUnlockDocument = (afterUnlock?: () => void) => {
    const setDocumentUnlocked = useConfirmLockDialog(async (id: number) => {
        const response = await post("documents/" + id + "/setunlocked");

        if (response.status !== 200)
            throw response;

        if (afterUnlock)
            afterUnlock();
    });
    return { setDocumentUnlocked }
}

export default useUnlockDocument;