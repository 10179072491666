import { Toggle as FluentToggle } from "@fluentui/react";
import { useResources } from "../../improve-lib";

type ToggleProps = {
    label: string;
    checked: boolean;
    onChange: () => void;
}

const Toggle = (props: ToggleProps): JSX.Element => {
    const resources = useResources();

    return (
        <FluentToggle
            label={props.label}
            checked={props.checked}
            inlineLabel
            onText={resources.on}
            offText={resources.off}
            onChange={props.onChange}
            styles={{ root: { margin: 0 } }}
        />
    );
}

export default Toggle;