import { useResources } from "../../../../improve-lib";
import { ListItem, RowAction } from "../../../../lib";
import { DocumentTableModel } from "../../../../model";
import TagList from "../../_lib/tags/TagsList";
import "./DocumentsListItem.tsx.scss";

type DocumentsListItemProps = {
    item: DocumentTableModel;
    rowAction?: RowAction[];
}

const DocumentsListItem = (props: DocumentsListItemProps): JSX.Element => {
    //#region filetype-icons
    const resources = useResources();

    const getIcon = (filename: string | undefined) => {
        let ext: string | undefined = "";
        if (filename !== undefined)
            ext = filename.split(".").pop()?.toLowerCase();

        switch (ext) {
            case "doc":
            case "docx":
                return "WordDocument";
            case "bmp":
            case "gif":
            case "jpeg":
            case "jpg":
            case "png":
            case "svg":
                return "Photo2";
            case "pdf":
                return "PDF";
            case "ppt":
            case "pptx":
            case "ppsx":
                return "PowerPointDocument";
            case "txt":
                return "TextDocument";
            case "vsd":
            case "vsdx":
                return "VisioDocument";
            case "xls":
            case "xlsx":
            case "xlsm":
                return "ExcelDocument";
            case "flac":
            case "ogg":
            case "ogv":
            case "mp3":
            case "mp4":
            case "webm":
            case "wav":
                return "Media";
            default:
                return "Document";
        }
    }

    //#endregion

    const calcSize = (bytes: number) => {
        //source: https://stackoverflow.com/a/18650828
        if (bytes === 0)
            return "0 Bytes";

        const k = 1024;
        const dm = 2;
        const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];
        const i = Math.floor(Math.log(bytes) / Math.log(k));
        return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
    }

    return (
        <ListItem
            title={props.item.filename}
            attributes={[
                calcSize(props.item.fileSize),
                props.item.userDisplayName,
                props.item.dateTimeLastEdited.toLocaleDateString()
            ]}
            iconName={getIcon(props.item.filename)}
            status={props.item.locked ? resources.locked : undefined}
            footer={
                props.item.tags?.length > 0 ?
                    <div className={"documents-list-item-div-secondrow"}>
                        <TagList tags={props.item.tags} size="small" />
                    </div>
                    :
                    <></>
            }
            rowAction={props.rowAction}
        />
    )
}

export default DocumentsListItem;
