import { ITag, Label,TagPicker } from "@fluentui/react";
import { useState } from "react";
import { useResources } from "../../improve-lib";
import { IdValuePair } from "../../model";

type ItemPickerProps = {
    possibleValues: IdValuePair[];
    defaultSelectedIds?: number[];
    onSelectionChanged: (ids: number[]) => void;
    placeholder: string;
    title: string;
}

const ItemPicker = (props: ItemPickerProps) : JSX.Element => {
    const resources = useResources();

    const [anySelected, setAnySelected] = useState(false);

    const getAvailableValues = (selectedItems?: ITag[]): ITag[] => {
        const values: ITag[] = props.possibleValues.map(v => { return { key: v.id, name: v.value }; });

        if (!selectedItems || selectedItems?.length === 0)
            return values;

        const selectedIds = selectedItems.map(i => i.key);

        return values.filter(v => !selectedIds.includes(v.key));
    }

    const onPickerAutocompleteOrSelect = (filterText: string, selectedItems?: ITag[]): ITag[] => {
        let values = getAvailableValues(selectedItems);
        if (filterText)
            values = values.filter(v => v.name.toLowerCase().indexOf(filterText.toLowerCase()) !== -1);
        return values;
    };

    const onPickerEmptyClick = (selectedItems?: ITag[]): ITag[] => {
        return getAvailableValues(selectedItems);
    };

    const getTextFromItem = (item: ITag) => item.name;

    const onChange = (items?: ITag[]) => {
        const selectedIds = items ? items.map(i => i.key as number) : [];

        setAnySelected(selectedIds.length > 0);

        props.onSelectionChanged(selectedIds);
    }

    const selectedItems = props.possibleValues.filter(v => props.defaultSelectedIds?.includes(v.id)).map(v => { return { key: v.id, name: v.value } });

    return (
        <>
            <Label>
                {props.title}
            </Label>
            <TagPicker
                defaultSelectedItems={selectedItems}
                getTextFromItem={getTextFromItem}
                inputProps={{
                    placeholder: anySelected ? "" : props.placeholder,
                }}
                onResolveSuggestions={onPickerAutocompleteOrSelect}
                onEmptyInputFocus={onPickerEmptyClick}
                onChange={onChange}
                pickerSuggestionsProps={{
                    noResultsFoundText: resources.noSearchResults,
                }}
                resolveDelay={100}
            />
        </>
    )
}
export default ItemPicker;