import { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useCreateUser } from "../../api";
import { Container, useIsEqual, useRegisterSaveCheck, useResources, useUnsafeNavigate } from "../../improve-lib";
import { NavigationHeader } from "../../lib";
import { NewUserModel } from "../../model";
import UserFormNew from "./components/UserFormNew";

const CreateUserPage = (): JSX.Element | null => {
    const location = useLocation();

    const navigate = useNavigate();
    const navigateUnsafe = useUnsafeNavigate();

    const resources = useResources();

    const defaultUser: NewUserModel = {
        username: "",
        password: null,
        lastName: "",
        firstName: "",
        displayName: ""
    }

    const [user, setUser] = useState<NewUserModel>(defaultUser);

    const { createUser } = useCreateUser((id: number) => {
        navigateUnsafe("/settings/users/" + id);
    })

    const onBackNavigation = () => navigate("/settings/users" + location.search);

    const unfinished = user.username === "" || user.displayName === "";

    const anyChanges = !useIsEqual(user, defaultUser);

    useRegisterSaveCheck(anyChanges);

    const onCreateUser = async () => {
        await createUser(user)
    }

    return (
        <>
            <NavigationHeader backLabel={resources.backToUseradministration} onNavigateBack={onBackNavigation} />
            <section>
                <div className="content-container">
                    <Container title={resources.userCreate} onSave={onCreateUser} saveDisabled={unfinished} >
                    <UserFormNew
                        user={user}
                        alterUser={setUser}
                    />
                    </Container>
                </div>
            </section>

        </>
    )
}

export default CreateUserPage;
