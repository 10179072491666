import { useRef, useState } from "react";
import { useSearchUser } from "../../../../api";
import { useResources } from "../../../../improve-lib";
import { Button, MultiSelectList, MultiSelectRowAction, Picker, TableColumnType, TableData } from "../../../../lib";
import { IdValuePair, RoleUserModel } from "../../../../model";
import RolesDetailsSubsidiaries from "../subsidiaries/SubsidiariesDetails";
import "./UsersDetails.tsx.scss";

type UsersDetailsProps = {
    users: RoleUserModel[];
    alterUsers: (newUsers: RoleUserModel[]) => void;
    availableSubsidiaries: IdValuePair[];
}

type RoleUserTableType = {
    id: number;
    displayName: string;
    subsidiaries: string;
}

export type SubsidiarySelectionType = {
    id: number;
    name: string;
    checked: boolean;
    indeterminate: boolean;
}

const UsersDetails = (props: UsersDetailsProps): JSX.Element => {
    const resources = useResources();

    const selectedUsersRef = useRef<RoleUserModel[]>([]);
    const [subsidiaries, setSubsidiaries] = useState<SubsidiarySelectionType[]>(props.availableSubsidiaries.map(s => { return { id: s.id, name: s.value, checked: false, indeterminate: false } }));
    const { searchUser } = useSearchUser();

    const onRemoveUser = () => {
        const tempUserList = [...props.users];
        selectedUsersRef.current.forEach(u => {
            const index = tempUserList.findIndex(x => x.user.id === u.user.id);
            if (index !== -1)
                tempUserList.splice(index, 1);
        });
        props.alterUsers(tempUserList);
        selectedUsersRef.current = [];
        setSubsidiaries(subsidiaries.map(s => { return { id: s.id, name: s.name, checked: false, indeterminate: false } }));
    };

    const onAddAllButtonClick = async () => {
        const searchedUser = await searchUser("");

        if (searchedUser.length === props.users.length)
            return;

        const newUserList = [...props.users];

        searchedUser.forEach(user => {
            if (newUserList.find(u => u.user.id === user.id) === undefined)
                newUserList.push({ user: { id: user.id, value: user.value }, subsidiaries: [] });
        });

        props.alterUsers(newUserList);
    };

    const pickerOnTextChanged = (filterText: string): Promise<IdValuePair[]> => {
        const filter = async () => {
            let searchedUser: IdValuePair[] = [];
            if (filterText.length > 0)
                searchedUser = await searchUser(filterText);

            return searchedUser.filter(user => props.users.every(addedUser => addedUser.user.id !== user.id));
        }

        return filter();
    }

    const pickerOnItemSelected = (user: IdValuePair) => {
        const newUserList = [...props.users];
        newUserList.push({ user: { id: user.id, value: user.value }, subsidiaries: subsidiaries.filter(s => s.checked).map(s => ({ id: s.id, value: s.name })) });
        props.alterUsers(newUserList);
    }

    const rowActions: MultiSelectRowAction[] = [{
        text: resources.remove,
        iconName: "UserRemove",
        onClick: onRemoveUser
    }];

    const data: TableData<RoleUserTableType> = {
        columns: subsidiaries.length > 1 ?
            [
                { name: "displayName", displayName: resources.displayname, dataType: TableColumnType.Text },
                { name: "subsidiaries", displayName: resources.subsidiaries, dataType: TableColumnType.Text }
            ]
            :
            [{ name: "displayName", displayName: resources.displayname, dataType: TableColumnType.Text }],
        rows: props.users.map(u => {
            const subsSorted = u.subsidiaries.sort((a, b) => a.value.localeCompare(b.value));
            const subs = subsSorted.map(s => { return s.value }).join(", ");
            return { id: u.user.id, displayName: u.user.value, subsidiaries: subs }
        })
    };

    const onSubsidiariesChanged = (newSubs: SubsidiarySelectionType[]) => {
        const tempUserList = [...props.users];
        const selectedUserIds: number[] = selectedUsersRef.current.map(u => u.user.id);

        tempUserList.forEach(u => {
            if (selectedUserIds.includes(u.user.id)) {
                newSubs.forEach(s => {
                    if (s.checked && !s.indeterminate) {
                        if (u.subsidiaries.findIndex(x => x.id === s.id) === -1)
                            u.subsidiaries.push({ id: s.id, value: s.name });
                    }
                    else if (!s.checked) {
                        const index = u.subsidiaries.findIndex(x => x.id === s.id);
                        if (index !== -1)
                            u.subsidiaries.splice(index, 1);
                    }
                });
            }
        });

        setSubsidiaries(newSubs);
        props.alterUsers(tempUserList);
    }

    // Needed for use in onUserSelectionChanged because of async call (https://reactjs.org/docs/hooks-faq.html#why-am-i-seeing-stale-props-or-state-inside-my-function)
    // Otherwise props.users would be stale in onUserSelectionChanged
    const usersRef = useRef<RoleUserModel[]>([]);
    usersRef.current = props.users;

    const onUserSelectionChanged = (items: RoleUserTableType[]) => {
        const tempSelectedUsers: RoleUserModel[] = usersRef.current.filter(u => items.map(i => i.id).includes(u.user.id));
        const tempSubsidiaries: SubsidiarySelectionType[] = subsidiaries.map(s => { return { id: s.id, name: s.name, checked: false, indeterminate: false } });

        tempSubsidiaries.forEach(s => {
            let count = 0;
            tempSelectedUsers.forEach(u => {
                if (u.subsidiaries.map(s => s.id).includes(s.id))
                    count++;
            });
            if (tempSelectedUsers.length > 0) {
                if (count === tempSelectedUsers.length)
                    s.checked = true;
                else if (count > 0) {
                    s.checked = true;
                    s.indeterminate = true;
                }
            }
        });

        selectedUsersRef.current = tempSelectedUsers;
        setSubsidiaries(tempSubsidiaries);
    }

    return (
        <>
            <div className="roles-details-users-search-wrapper">
                <div className="roles-details-users-width">
                    <Picker
                        noResultsFoundText={resources.noSearchResults}
                        onTextChanged={pickerOnTextChanged}
                        onItemSelected={pickerOnItemSelected}
                        placeholder={resources.searchAndAdd}
                    />
                </div>
                <div className="roles-details-users-search-button">
                    <Button text={resources.addAll} secondary onClick={onAddAllButtonClick} />
                </div>

            </div>

            <div style={{ display: "flex" }}>
                <div className="roles-details-users-width">
                    {props.users.length > 0 &&
                        <MultiSelectList
                            data={data}
                            actions={rowActions}
                            selectedItems={onUserSelectionChanged}
                        />
                    }
                </div>
                <div style={{ marginLeft: "20px", marginTop: "20px" }}>
                    {props.availableSubsidiaries.length > 1 &&
                        <RolesDetailsSubsidiaries subsidiaries={subsidiaries} alterSubsidiaries={onSubsidiariesChanged} />}
                </div>
            </div>
        </>
    )
}

export default UsersDetails;
