import { useEffect, useState } from "react";
import { get, useToggle } from "../../improve-lib";
import { FetchedTableData, TableUserViewModel } from "../../model";

const useUserTable = (orderBy: string, orderAsc: boolean, fulltext: string, records: number) => {
    const [reloadFlag, reload] = useToggle();

    const [usersData, setUsersData] = useState<FetchedTableData<TableUserViewModel>>();

    useEffect(() => {
        const getUserTable = async () => {
            const response = await get("users?records=" + records + "&orderBy=" + orderBy + "&ascending=" + orderAsc + "&fulltext=" + fulltext);

            if (response.status !== 200)
                throw response;

            setUsersData(response.body as FetchedTableData<TableUserViewModel>);
        }

        void getUserTable();
    }, [fulltext, orderAsc, orderBy, records, reloadFlag]);

    return { usersData, reload }
}

export default useUserTable;