import { useResources } from "../../../../../../improve-lib/";
import { CheckBoxList, CheckedListType } from "../../../../../../lib";
import { Permission } from "../../../../../../model";

type AdministrationPermissionProps = {
    permission: Permission;
    alterPermission: (newPermission: Permission) => void;
}

const AdministrationPermissions = (props: AdministrationPermissionProps): JSX.Element => {
    const resources = useResources();

    const permissionsList: CheckedListType[] = [
        { id: 0, text: resources.users, checked: props.permission.usersAll },
        { id: 1, text: resources.roles, checked: props.permission.rolesAll },
        { id: 2, text: resources.subsidiaries, checked: props.permission.subsidiariesAll },
        { id: 3, text: resources.globalData, checked: props.permission.globalData },
    ];

    const checkedChanged = (id: number, newChecked: boolean) => {
        const newPermission = { ...props.permission };
        switch (id) {
            case 0:
                newPermission.usersAll = newChecked;
                break;
            case 1:
                newPermission.rolesAll = newChecked;
                break;
            case 2:
                newPermission.subsidiariesAll = newChecked;
                break;
            case 3:
                newPermission.globalData = newChecked;
                break;
        }

        props.alterPermission(newPermission);
    }

    return (      
            <CheckBoxList items={permissionsList} onCheckedChanged={checkedChanged} />
    )
}

export default AdministrationPermissions;