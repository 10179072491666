import { useState } from "react";
import { MultiLanguageFieldModel } from "../../model";
import { MultiLanguageDialog, useResources } from "..";

type MultiLanguageBaseProps = {
    languageFields: MultiLanguageFieldModel;
    onChange: (content: MultiLanguageFieldModel) => void;
    onRender: (multilang: boolean, isValid: boolean, onChangeText: (newValue: string) => void, openDialog: () => void) => JSX.Element;
    duplicatedIsoCodes: string[];
    textBoxHeader?: string;
    mandatory?: boolean;
}

const MultiLanguageBase = (props: MultiLanguageBaseProps): JSX.Element => {
    const resources = useResources();

    const [dialogOpen, setDialogOpen] = useState(false);

    const isValid = () => {
        const anyFieldFilled = props.languageFields.some(lf => lf.label.length > 0);
        const allFieldsFilled = props.languageFields.every(lf => lf.label.trim().length > 0);

        if (props.mandatory || anyFieldFilled)
            return allFieldsFilled;

        return true;
    }

    const onChangeText = (newValue: string) => {
        const newLanguageFields = [...props.languageFields];

        if (props.languageFields.length > 1) {
            if (props.languageFields.every(l => l.label === props.languageFields[0].label))
                newLanguageFields.forEach(l => l.label = newValue);
            else {
                setDialogOpen(true);
                newLanguageFields.find(n => n.isoCode === resources.isoCode)!.label = newValue;
            }
        }
        else
            newLanguageFields.find(n => n.isoCode === resources.isoCode)!.label = newValue;

        props.onChange(newLanguageFields);
    }

    return (
        <>
            {props.onRender(props.languageFields.length > 1, isValid(), onChangeText, () => { setDialogOpen(true); })}
            <MultiLanguageDialog
                hidden={!dialogOpen}
                resourceLabel={props.textBoxHeader ?? ""}
                onDismiss={() => setDialogOpen(false)}
                languageFields={props.languageFields}
                onChange={props.onChange}
                duplicatedIsoCodes={props.duplicatedIsoCodes}
                mandatory={props.mandatory}
            />
        </>
    );
}

export default MultiLanguageBase;
