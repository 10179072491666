import { useState } from "react";
import { useCreateProcessGroup } from "../../../../../api";
import { Container, useLanguage, useResources } from "../../../../../improve-lib";
import { MultiLanguageTextbox, RowAction, Section } from "../../../../../lib";
import { MultiLanguageFieldModel, ProcessGroupModel } from "../../../../../model";


type NewProcessGroupProps = {
    onCreated: (newId: number) => void;
    onCancel: () => void;
}

const NewProcessGroup = (props: NewProcessGroupProps): JSX.Element => {
    const resources = useResources();

    const lang = useLanguage();

    const [processGroupModel, setProcessGroupModel] = useState<ProcessGroupModel>({ name: lang.map(l => { return { isoCode: l, label: "" } }) });

    const { createProcessGroup, duplicatedIsoCodes } = useCreateProcessGroup((id: number) => {
        props.onCreated(id);
    });

    const onNameChange = (newName: MultiLanguageFieldModel) => setProcessGroupModel({ name: newName });

    let allowSave = true;

    if (processGroupModel?.name) {
        for (let i = 0; i < processGroupModel.name.length; i++)
            if (processGroupModel.name[i].label.length < 1)
                allowSave = false;
    }

    const cancelAction: RowAction = {
        onClick: props.onCancel,
        iconName: "Cancel",
        text: resources.cancel
    };

    const saveAction: RowAction = { iconName: "Save", onClick: () => createProcessGroup(processGroupModel), text: resources.save, disabled: !allowSave };

    return (
        <Container title={resources.newProcessGroup} actions={[saveAction, cancelAction]}>
            <Section headerText={resources.label}>
                <MultiLanguageTextbox
                    languageFields={processGroupModel.name}
                    onChange={onNameChange}
                    duplicatedIsoCodes={duplicatedIsoCodes}
                    textBoxHeader={resources.label + " " + resources.processGroup}
                    mandatory
                />
            </Section>
        </Container>
    );
}

export default NewProcessGroup;