import { Section, TableColumn, TableData } from "../../../lib";
import { OpenTasksModule, OpenTasksTableModelDisplay, OpenTasksTableRowModel } from "../../../model";
import OpenTasksTable from "./OpenTasksTable";

type OpenTasksModuleBasedProps = {
    data: OpenTasksModule[];
    showSubsidiary: boolean;
    showUser: boolean;
}

const OpenTasksModuleBased = (props: OpenTasksModuleBasedProps): JSX.Element => {
    const data: OpenTasksTableModelDisplay[] = [];

    props.data.forEach(moduleData => {
        const cols: TableColumn[] = [];
        moduleData.columns.forEach(c => {
            if (c.name === "subsidiary") {
                if (props.showSubsidiary)
                    cols.push(c);
            }
            else if (c.name === "user") {
                if (props.showUser)
                    cols.push(c);
            }
            else
                cols.push(c);
        });

        const tableData: TableData<OpenTasksTableRowModel> = {
            columns: cols,
            rows: moduleData.rows.map(r => {
                return r.tableData
            })
        };
        data.push({ title: moduleData.label, data: tableData })
    });

    return (
        <>
            {data && data.map((t, i) =>
                <Section headerText={t.title} collapsible key={i}>
                    <OpenTasksTable data={t.data} />
                </Section>
            )}
        </>
    )
}

export default OpenTasksModuleBased;
