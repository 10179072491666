import { getIcon } from "@fluentui/react";
import { getFillColor, getRoundRectPath, getStrokeColor } from "bpmn-js/lib/draw/BpmnRenderUtil";
import { isAny } from "bpmn-js/lib/features/modeling/util/ModelingUtil";
import { is } from "bpmn-js/lib/util/ModelUtil";
import BaseRenderer from "diagram-js/lib/draw/BaseRenderer";
import {
    append as svgAppend,
    attr as svgAttr,
    classes as svgClasses,
    create as svgCreate,
    innerSVG,
    remove as svgRemove
} from "tiny-svg";
import "./CustomRenderer.js.scss";

const HIGH_PRIORITY = 1500,
    TASK_BORDER_RADIUS = 10,
    DEFAULT_TEXT_SIZE = 12;

const permittedElementTypes = ["bpmn:Task", "bpmn:SequenceFlow", "bpmn:Event", "bpmn:Gateway", "bpmn:Participant", "bpmn:Lane", "bpmn:Artifact"];

export default class CustomRenderer extends BaseRenderer {    
    static $inject = ["eventBus", "bpmnRenderer", "textRenderer", "bpmnjs", "config.getResources", "config.elementHelper"];

    constructor(eventBus, bpmnRenderer, textRenderer, modeler, getResources, elementHelper) {
        super(eventBus, HIGH_PRIORITY);
        this.BpmnRenderer = bpmnRenderer;
        this.TextRenderer = textRenderer;
        this.Modeler = modeler;
        this.getResources = getResources;
        this.getElementName = id => elementHelper.current.getElementName(id);
        this.getElementIcons = id => elementHelper.current.getElementIcons(id);
        this.hasElementSubprocess = id => elementHelper.current.hasElementSubprocess(id);
    }

    canRender(element) {
        return element && isAny(element, permittedElementTypes) && element.type !== "label";
    }

    drawConnection(parentNode, element) {
        if (isAny(element, ["bpmn:Flow", "bpmn:SequenceFlow"])) {
            element.businessObject.name = this.getElementName(element.id);
            this.BpmnRenderer.drawConnection(parentNode, element);
        }
    }

    drawShape(parentNode, element) {
        if (isAny(element, permittedElementTypes))
            setLabel(element, this.getElementName(element.businessObject.id));

        if (is(element, "custom:TextBox")) {
            const color = getStrokeColor(element, "#000");
            return renderEmbeddedLabel(parentNode, this.TextRenderer, element, color, this.getResources().addLabel);
        }

        if (is(element, "custom:Image")) {
            return drawImage(parentNode, this.TextRenderer, element, this.getResources().selectImage);
        }

        let shape = this.BpmnRenderer.drawShape(parentNode, element);

        if (is(element, "bpmn:Task")) { 
            if (this.hasElementSubprocess(element.id)) {
                const rect = drawRect(
                    parentNode,
                    element.width,
                    element.height,
                    TASK_BORDER_RADIUS,
                    4,
                    getStrokeColor(element, "#000"),
                    getFillColor(element, "#fff")
                );

                prependTo(rect, parentNode);

                svgRemove(shape);
            }

            const icons = this.getElementIcons(element.id);

            if (icons) {
                const iconCssClass = "improveIcon";

                const elementsToRemove = [...parentNode.parentNode.getElementsByClassName(iconCssClass)];

                for (let x = 0; x < elementsToRemove.length; x++)
                    svgRemove(elementsToRemove[x]);

                let pos = 0;

                for (let i = 0; i < icons.length; i++) {
                    const svgIcon = createIcon(pos * 16 + 5, 5, 15, 15, iconCssClass, icons[i].iconName, icons[i].title, icons[i].func, element.id);
                    svgAppend(parentNode.parentNode, svgIcon);
                    pos++;
                }
            }
        }

        return shape;
    }

    getShapePath(shape) {
        if (is(shape, "bpmn:Task"))
            return getRoundRectPath(shape, TASK_BORDER_RADIUS);

        return this.BpmnRenderer.getShapePath(shape);
    }
}

function setLabel(element, text) {
    const businessObject = element.businessObject;

    if (isAny(businessObject, ["bpmn:FlowElement", "bpmn:Participant", "bpmn:Lane", "custom:TextBox"])){
        businessObject.name = text;
    }

    if (is(businessObject, "bpmn:Group") && businessObject.categoryValueRef) {
        businessObject.categoryValueRef.value = text;
    }

    return element;
}

function getLabel(element) {
    const businessObject = element.businessObject;

    if (isAny(businessObject, ["bpmn:FlowElement", "bpmn:Participant", "bpmn:Lane", "custom:TextBox"])) {
        return businessObject.name;
    }

    if (is(businessObject, "bpmn:Group") && businessObject.categoryValueRef) {
        return businessObject.categoryValueRef.value;
    }
}


function createIcon(x, y, width, height, cls, iconName, title, func, eId) {
    const element = svgCreate("svg");

    element.addEventListener("click", function () { func(eId); }, false);

    svgAttr(element, {
        x: x,
        y: y,
        width: width,
        height: height,
        cursor: "pointer",
        class: cls
    });

    const icon =
        `<g>
            <foreignObject x="0" y="0" width="15" height="15" >
                <div xmlns="http://www.w3.org/1999/xhtml"
                    title="${String(title)}" class="processIcon" style="--processIcon:'${getIcon(iconName)?.code ?? ""}'" />
            </foreignObject>
        </g>`;

    innerSVG(element, icon);

    return element;
}

function drawRect(parentNode, width, height, borderRadius, strokeWidth, strokeColor, fillColor) {
    const rect = svgCreate("rect");

    svgAttr(rect, {
        width: width,
        height: height,
        rx: borderRadius,
        ry: borderRadius,
        stroke: strokeColor || "none",
        strokeWidth: strokeWidth,
        fill: fillColor || "none"
    });

    svgAppend(parentNode, rect);

    return rect;
}

function renderLabel(parentNode, textRenderer, label, options) {
    const text = textRenderer.createText(label, options);

    svgClasses(text).add("djs-label");

    svgAppend(parentNode, text);

    return text;
}

function renderEmbeddedLabel(parentNode, textRenderer, element, color, placeholder) {
    const label = getLabel(element) || placeholder;
    const rotation = parseInt(element.businessObject.rotation ?? 0);

    return renderLabel(parentNode, textRenderer, label, {
        box: element,
        align: "center-middle",
        padding: 5,
        style: { 
            fill: color,
            fontSize: element.di.label?.labelStyle?.font.size ?? DEFAULT_TEXT_SIZE,
            fontWeight: element.di.label?.labelStyle?.font.isBold ? "bold" : "normal",
            fontStyle: element.di.label?.labelStyle?.font.isItalic ? "italic" : "normal",
            textDecoration: element.di.label?.labelStyle?.font.isUnderline ? "underline" : "",
            transform: `rotate(${rotation} ${element.width / 2} ${element.height / 2})`
        },
    });
}

function prependTo(newNode, parentNode) {
    parentNode.insertBefore(newNode, parentNode.firstChild);
}

function drawImage(parentNode, textRenderer, element, placeholder) {
    const imageSource = element.businessObject.source;

    let gfx;
    if (!imageSource) {
        // placeholder
        gfx = renderLabel(parentNode, textRenderer, placeholder, {
            box: element,
            align: "center-middle",
            padding: 5,
            style: {
                fill: "black",
                fontSize: DEFAULT_TEXT_SIZE
            },
        });
    } else {
        gfx = svgCreate("image", {
            x: 0,
            y: 0,
            width: element.width,
            height: element.height,
            href: imageSource
        });

        svgAppend(parentNode, gfx);
    }

    return gfx;
}
