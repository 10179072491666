import { useState } from "react";
import { CreatedEditedFooter, getFieldViewControl, useResources } from "../../../../improve-lib";
import { ChildWithLabel,Link, Text, TextWithLabel } from "../../../../lib";
import { CustomFieldModel, ITracked, LinkModel } from "../../../../model";
import DocumentPopup from "./DocumentPopup";

type ProcessInfoProps = {
    label?: string;
    owner?: string;
    description?: string;
    documentLinks?: LinkModel[];
    externalLinks?: LinkModel[];
    customFields?: CustomFieldModel[];
    tracked?: ITracked;
}

const ProcessInfo = (props: ProcessInfoProps): JSX.Element => {
    const resources = useResources();

    const [selectedDocumentId, setSelectedDocumentId] = useState<number>();
    const [documentPopupVisible, setDocumentPopupVisible] = useState(false);

    const getExternalLinksBlockLabel = () => {
        let label = "";
        if (props.externalLinks) {
            if (props.externalLinks.length > 1 || props.externalLinks[0].label.length > 0)
                label = resources.externalLinks;
            else
                label = resources.link;
        }

        return label;
    }

    return (
        <>
            <article>
                <div className="article-form-element">
                    <Text size="xLarge" text={props.label ?? ""} />
                </div>
                {props.description && props.description.length > 0 &&
                    <div className="article-form-element">
                        <Text size="medium" text={props.description} numberOfLines={2} />
                    </div>
                }
                {props.owner &&
                    <div className="article-form-element">
                        <TextWithLabel label={resources.owner} text={props.owner} />
                    </div>
                }
                {props.documentLinks && props.documentLinks.length > 0 &&
                    <div className="article-form-element">
                        <ChildWithLabel label={resources.documents} >
                            {props.documentLinks.map((l, index) =>
                                <div key={index} style={{ lineHeight: 1 }}>
                                    <Link text={l.label} onClick={() => { setSelectedDocumentId(l.targetId); setDocumentPopupVisible(true); }} /><br />
                                </div>
                            )}
                        </ChildWithLabel>
                    </div>
                }
                {props.externalLinks && props.externalLinks.length > 0 &&
                    <div className="article-form-element">
                        <ChildWithLabel label={getExternalLinksBlockLabel()} >
                            {props.externalLinks.map((l, index) =>
                                <div key={index} style={{ lineHeight: 1, overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap" }}>
                                    <Link text={l.label.length > 0 ? l.label : l.targetLink ?? ""} href={l.targetLink} /><br />
                                </div>
                            )}
                        </ChildWithLabel>
                    </div>
                }
                {props.customFields?.map(field =>
                    <div className="article-form-element" key={field.id}>
                        {getFieldViewControl(field)}
                    </div>
                )
                }
            </article>
            {props.tracked &&
                <CreatedEditedFooter tracked={props.tracked} />
            }
            {selectedDocumentId &&
                <DocumentPopup
                    isOpen={documentPopupVisible}
                    onCancel={() => setDocumentPopupVisible(false)}
                    documentId={selectedDocumentId}
                />
            }
        </>
    );
}

export default ProcessInfo;