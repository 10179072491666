import { useState } from "react";
import { post, useSaveCallout } from "../../improve-lib";
import { ApplicationAreaUpdateModel } from "../../model";

const useCreateApplicationArea = (afterCreated?: (id: number) => void) => {
    const saveCallout = useSaveCallout();

    const [duplicatedIsoCodes, setDuplicatedIsoCodes] = useState<string[]>([]);

    const createApplicationArea = async (applicationArea: ApplicationAreaUpdateModel) => {
        const response = await post("applicationareas/", applicationArea);

        switch (response.status) {
            case 200:
                saveCallout.show();
                if (afterCreated)
                    afterCreated(response.body as number);
                break;
            case 400:
                setDuplicatedIsoCodes(response.body as string[]);
                break;
            default:
                throw response;
        }
    }

    return { createApplicationArea, duplicatedIsoCodes }
}

export default useCreateApplicationArea;