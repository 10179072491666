import { getTheme,Separator as FluentSeparator } from "@fluentui/react";
import { ReactNode } from "react";

type SeparatorProps = {
    children?: ReactNode;
    vertical?: boolean;
    color?: "blue" | "gray" ;
}

const Separator = (props: SeparatorProps): JSX.Element => {
    const theme = getTheme();

    let backColor;
    switch (props.color) {
        case "gray":
            backColor = theme.palette.neutralLight;
            break;
        case "blue":
        default:
            backColor = theme.palette.themePrimary;
            break;
    }

    return (
        <FluentSeparator
            vertical={props.vertical}
            styles={{
                root: {
                    padding: 0,
                    lineHeight: props.children ? undefined : 0,
                    selectors: {
                        "::before": {
                            background: backColor
                        },
                        "::after": {
                            background: backColor
                        },
                    },
                }
            }}
        >
            {props.children}
        </FluentSeparator >
    );
}

export default Separator;
