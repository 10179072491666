import { post } from "../../improve-lib";

const useResetPassword = (afterReset?: () => void) => {
    const resetPassword = async (id: number, newPassword: string) => {
        const response = await post("authentication/passwords/" + id, newPassword);

        if (response.status !== 200)
            throw response;

        if (afterReset)
            afterReset();
    }

    return { resetPassword }
}

export default useResetPassword;