import { ChoiceGroup, IChoiceGroupOption } from "@fluentui/react";

type RadioButtonOption = {
    value: string;
    text: string;
    disabled?: boolean;
}

type RadioButtonProps = {
    options: RadioButtonOption[];
    selectedValue: string;
    label?: string;
    onChange: (newValue: string) => void;
}

const RadioButton = (props: RadioButtonProps): JSX.Element => {
    const onChange = (_ev?: React.FormEvent<HTMLElement | HTMLInputElement>, option?: IChoiceGroupOption) => {
        if (option)
            props.onChange(option.key);
    }

    return (
        <ChoiceGroup
            label={props.label}
            options={props.options.map(option => {
                return {
                    key: option.value, text: option.text, disabled: option.disabled
                } as IChoiceGroupOption
            })}
            selectedKey={props.selectedValue}
            onChange={onChange}
        />
    );
};

export default RadioButton;
