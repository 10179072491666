import { CheckBox, ChildWithLabel, TextWithLabel } from "../../../lib";
import { CustomFieldModel, FieldType } from "../../../model";
import BooleanField from "./booleanField";
import DateField from "./dateField";
import FieldBase from "./fieldBase";
import NumberField from "./numberField";
import SelectionField from "./selectionField";
import TextField from "./textField";

const createField = (model: CustomFieldModel) => {
    switch (model.fieldType) {
        case FieldType.Boolean:
            return new BooleanField(model.id, model.name, model.mandatory, model.boolean) as FieldBase;
        case FieldType.Date:
            return new DateField(model.id, model.name, model.mandatory, model.date) as FieldBase;
        case FieldType.Number:
            return new NumberField(model.id, model.name, model.mandatory, model.number) as FieldBase;
        case FieldType.Selection:
            return new SelectionField(model.id, model.name, model.mandatory, model.selection) as FieldBase;
        case FieldType.Text:
            return new TextField(model.id, model.name, model.mandatory, model.settings, model.text) as FieldBase;
        default:
            throw new Error();
    }
}

const getFieldViewControl = (model: CustomFieldModel): JSX.Element | undefined => {
    let value = "";

    switch (model.fieldType) {
        case FieldType.Boolean:
            if (model.boolean !== null)
                return (
                    <ChildWithLabel label={model.name}>
                        <div style={{ paddingTop: 5 }}>
                            <CheckBox
                                label={""}
                                checked={model.boolean ?? false}
                                onChange={() => { }}
                                disabled
                            />
                        </div>
                    </ChildWithLabel>
                );
            break;
        case FieldType.Date:
            value = model.date ? new Date(model.date).toLocaleDateString() : "";
            break;
        case FieldType.Number:
            value = model.number?.toLocaleString() ?? "";
            break;
        case FieldType.Selection:
            value = model.selection?.value ?? "";
            break;
        case FieldType.Text:
            value = model.text ?? "";
            break;
        default:
            throw new Error();
    }

    if (value !== "")
        return (<TextWithLabel label={model.name} numberOfLines={model.settings?.multiline ? 2 : undefined} text={value} />);
}

export { createField, getFieldViewControl }