import { useEffect, useState } from "react";
import { get } from "../../improve-lib";

const useDeletableApplicationArea = (id: number) => {
    const [applicationAreaCanBeDeleted, setApplicationAreaCanBeDeleted] = useState(false);

    useEffect(() => {
        const checkIfApplicationAreaCanBeDeleted = async () => {
            const response = await get("applicationareas/" + id + "/deletable");

            if (response.status !== 200)
                throw response;

            setApplicationAreaCanBeDeleted(response.body as boolean);
        }

        void checkIfApplicationAreaCanBeDeleted();
    }, [id]);

    return { applicationAreaCanBeDeleted }
}

export default useDeletableApplicationArea;