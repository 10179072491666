import { useEffect, useState } from "react";
import { get, useResources, useToggle } from "../../improve-lib";
import { DocumentModel } from "../../model";

const useDisplayDocument = (id: number) => {
    const resources = useResources();

    const [reloadFlag, reload] = useToggle();

    const [document, setDocument] = useState<DocumentModel>();

    useEffect(() => {
        const getDisplayDocument = async () => {
            const response = await get("documents/" + id);

            if (response.status !== 200)
                throw response;

            const data: DocumentModel = response.body;

            data.dateValidUntil = data.dateValidUntil ? new Date(data.dateValidUntil) : null;
            data.dateTimeLocked = data.dateTimeLocked ? new Date(data.dateTimeLocked) : null;
            data.dateTimeCreated = new Date(data.dateTimeCreated);
            data.dateTimeLastEdited = new Date(data.dateTimeLastEdited);

            setDocument(data);
        }

        void getDisplayDocument();
    }, [id, resources.isoCode, reloadFlag]);

    return { document, reloadFlag, reload }
}

export default useDisplayDocument;
