import { useResources } from "../language";
import ErrorTheme from "./ErrorTheme";

type ErrorHandlerProps = {
    statusCode: number,
    bodyResponse: string,
}

const ErrorHandler = (props: ErrorHandlerProps) => {
    const resources = useResources();

    switch (props.statusCode) {
        case 403:
            return <ErrorTheme
                statusCode={props.statusCode}
                bodyResponse={props.bodyResponse}
                title={resources.error}
                subTitle="Forbidden"
                message={resources.forbiddenError} />
        case 404:
            return <ErrorTheme
                statusCode={props.statusCode}
                bodyResponse={props.bodyResponse}
                title={resources.error}
                subTitle="Not Found"
                message={resources.notFoundError} />
        case 500:
            return <ErrorTheme
                statusCode={props.statusCode}
                bodyResponse={props.bodyResponse}
                title={resources.error}
                subTitle="Internal Server Error"
                message={resources.internalServerError} />
        default:
            return <ErrorTheme
                statusCode={props.statusCode}
                bodyResponse={props.bodyResponse}
                title={resources.error}
                message={resources.unexpectedError} />
    }
};

export default ErrorHandler;