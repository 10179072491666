import { useResources } from "../../../improve-lib";
import { TableColumn, TableColumnType, TableData } from "../../../lib";
import { OpenTasksModule, OpenTasksTableRowModel } from "../../../model";
import OpenTasksTable from "./OpenTasksTable";

type OpenTasksDateBasedProps = {
    data: OpenTasksModule[];
    showSubsidiary: boolean;
    showUser: boolean;
}

const OpenTasksDateBased = (props: OpenTasksDateBasedProps) => {
    const resources = useResources();

    const columns: TableColumn[] = [];
    if (props.showSubsidiary)
        columns.push({ name: "subsidiary", displayName: resources.subsidiary, dataType: TableColumnType.Text });
    if (props.showUser)
        columns.push({ name: "user", displayName: resources.user, dataType: TableColumnType.Text });
    columns.push({ name: "moduleLabel", displayName: resources.module, dataType: TableColumnType.Text });
    columns.push({ name: "moduleSection", displayName: resources.type, dataType: TableColumnType.Text });
    columns.push({ name: "dueDate", displayName: resources.due, dataType: TableColumnType.DueDate });
    columns.push({ name: "summary", displayName: resources.information, dataType: TableColumnType.Text });

    const moduleRowsData: OpenTasksTableRowModel[] = [];
    props.data.forEach(moduleData => {
        const singleModuleRowsData: OpenTasksTableRowModel[] = moduleData.rows.map(row => {
            const rowData: OpenTasksTableRowModel = {
                moduleLabel: moduleData.label,
                subsidiary: row.subsidiary === null ? "" : row.subsidiary.value,
                /* eslint-disable @typescript-eslint/no-unsafe-member-access */
                moduleSection: row.tableData.moduleSection,
                id: row.tableData.id,
                user: row.tableData.user,
                dueDate: row.tableData.dueDate,
                summary: row.tableData.summary
                /* eslint-enable @typescript-eslint/no-unsafe-member-access */
            };
            return rowData;
        });
        moduleRowsData.push(...singleModuleRowsData);
    });

    const tableData: TableData<OpenTasksTableRowModel> = {
        columns: columns,
        rows: moduleRowsData
    };

    if (!tableData)
        return null;

    return (
        <OpenTasksTable data={tableData} />
    )
}

export default OpenTasksDateBased;
