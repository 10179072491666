import { useEffect, useState } from "react";
import { useResources } from "../../../../../../improve-lib";
import { DocumentsApplicationAreaPermission, DocumentsApplicationAreaPermissionType, IdValuePair } from "../../../../../../model";
import PermissionMatrix from "./PermissionMatrix";

type DocumentPermissionProps = {
    applicationAreaPermissions: DocumentsApplicationAreaPermission[];
    applicationAreas: IdValuePair[];
    onApplicationAreaPermissionsUpdated: (newNewPermissions: DocumentsApplicationAreaPermission[]) => void;
}

const DocumentPermissions = (props: DocumentPermissionProps): JSX.Element => {
    const resources = useResources();

    const matrixOptions = [
        { name: resources.permissionInvisible, value: DocumentsApplicationAreaPermissionType.None },
        { name: resources.permissionRead, value: DocumentsApplicationAreaPermissionType.Read },
        { name: resources.history, value: DocumentsApplicationAreaPermissionType.History },
        { name: resources.permissionEdit, value: DocumentsApplicationAreaPermissionType.Write },
        { name: resources.permissionDelete, value: DocumentsApplicationAreaPermissionType.Delete }
    ];

    const [matrixValue, setMatrixValue] = useState<{ id: number, name: string, value: DocumentsApplicationAreaPermissionType }[]>([]);

    const onMatrixUpdate = (newMatrix: { id: number, name: string, value: DocumentsApplicationAreaPermissionType }[]) => {
        props.onApplicationAreaPermissionsUpdated(newMatrix.reduce(
            (filteredMatrix: { applicationAreaId: number, name: string, permission: DocumentsApplicationAreaPermissionType }[], p) => {
                if (p.value > 0)
                    filteredMatrix.push({ applicationAreaId: p.id, name: p.name, permission: p.value });

                return filteredMatrix;
            }, []));
    };

    useEffect(() => {
        const permissions = [...props.applicationAreaPermissions];

        props.applicationAreas.forEach((aa: IdValuePair) => {
            if (props.applicationAreaPermissions.findIndex(x => x.applicationAreaId === aa.id) === -1) {
                permissions.push({ applicationAreaId: aa.id, name: aa.value, permission: DocumentsApplicationAreaPermissionType.None });
            }
        });

        permissions.sort((a, b) => a.name.localeCompare(b.name));

        setMatrixValue(permissions.map(p => { return { id: p.applicationAreaId, name: p.name, value: p.permission } }));
    }, [props.applicationAreaPermissions, props.applicationAreas]);

    return (
        <PermissionMatrix<DocumentsApplicationAreaPermissionType>
            title={resources.applicationAreas}
            values={matrixValue}
            options={matrixOptions}
            onMatrixUpdate={onMatrixUpdate}
        />
    )
}

export default DocumentPermissions;
