import { useState } from "react";
import { post, useSaveCallout } from "../../improve-lib";
import { TagUpdateModel } from "../../model";

const useCreateTag = (afterCreated?: (id: number) => void) => {
    const saveCallout = useSaveCallout();

    const [duplicatedIsoCodes, setDuplicatedIsoCodes] = useState<string[]>([]);

    const createTag = async (tag: TagUpdateModel) => {
        const response = await post("tags/", tag)

        switch (response.status) {
            case 200:
                saveCallout.show();
                if (afterCreated)
                    afterCreated(response.body as number);
                break;
            case 400:
                setDuplicatedIsoCodes(response.body as string[]);
                break;
            default:
                throw response;
        }
    }

    return { createTag, duplicatedIsoCodes }
}

export default useCreateTag;