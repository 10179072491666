import { useEffect, useState } from "react";
import { get, useLanguage, useToggle } from "../../improve-lib";
import { RoleModel } from "../../model";

const useRole = (id: number) => {
    const lang = useLanguage();

    const [reloadFlag, reload] = useToggle();
    const [role, setRole] = useState<RoleModel>();

    useEffect(() => {
        const getRole = async () => {
            const response = await get("roles/" + id);

            if (response.status !== 200)
                throw response;

            const role: RoleModel = response.body;
            role.dateTimeCreated = new Date(role.dateTimeCreated);
            role.dateTimeLastEdited = new Date(role.dateTimeLastEdited);
            setRole(role);
        }

        void getRole();
    }, [id, lang, reloadFlag]);

    return { role, reload }
}

export default useRole;