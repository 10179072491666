import { useEffect } from "react";
import { useHistoryDocuments } from "../../../../api";
import { ContainerActions, useResources } from "../../../../improve-lib";
import DocumentDisplay from "../display/DocumentDisplay";
import HistoryRecord from "./HistoryRecord";

type DocumentHistoryProps = {
    documentId: number;
    refreshToken: boolean;
}

const DocumentHistory = (props: DocumentHistoryProps): JSX.Element => {
    const resources = useResources();

    const { history, reload } = useHistoryDocuments(props.documentId);

    useEffect(() => {
        reload();
        //eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.refreshToken])

    return (
        <>
            {history && history.map(historyRecord =>
                <HistoryRecord
                    key={historyRecord.id}
                    title={historyRecord.filename}
                    headerControls={<ContainerActions
                        actions={[{
                            iconName: "Download",
                            onClick: () => window.open("api/documents/" + props.documentId + "/history/" + historyRecord.id),
                            text: resources.download
                        }]}
                        overflow={[]}
                        farItems={[]} />
                    }>
                    <DocumentDisplay document={historyRecord} />
                </HistoryRecord>
            )}
        </>
    );
}

export default DocumentHistory;
