import { useEffect, useState } from "react";
import { useCountAllSubsidiaries, usePermittedApplicationAreas } from "../../api";
import { useToggle, useUnsafeQueryValue } from "../../improve-lib";
import { DocumentsApplicationAreaPermissionType } from "../../model";
import DocumentContainer from "./document-container/DocumentContainer";
import DocumentsContainer from "./documents-container/DocumentsContainer";

const DocumentsPage = () => {
    const [refreshToken, toggleToken] = useToggle();
    const [allSubsidiariesHaveWritePermission, setAllSubsidiariesHaveWritePermission] = useState<boolean>(false);

    const { applicationAreaPermission } = usePermittedApplicationAreas();
    const { subsidiariesCount } = useCountAllSubsidiaries();

    const [id, setId] = useUnsafeQueryValue("id", value => {
        if (value) {
            const number = parseInt(value);

            if (!isNaN(number))
                return number;
        }
        return undefined;
    });

    const changeDocument = (newId: number | undefined) => setId(newId?.toString());

    const onDocumentDeleted = () => {
        toggleToken();
        changeDocument(undefined);
    }

    useEffect(() => {
        if (!applicationAreaPermission)
            return;

        const subIdsWithWritePermission: number[] = [];
        for (let i = 0; i < applicationAreaPermission.length; i++) {
            applicationAreaPermission[i].subsidiaryPermissions.forEach(sp => {
                if (sp.permission === DocumentsApplicationAreaPermissionType.Write || sp.permission === DocumentsApplicationAreaPermissionType.Delete) {
                    if (subIdsWithWritePermission.indexOf(sp.subsidiary.id) === -1)
                        subIdsWithWritePermission.push(sp.subsidiary.id);
                }
            })
        }

        setAllSubsidiariesHaveWritePermission(subsidiariesCount === subIdsWithWritePermission.length);
    }, [applicationAreaPermission, subsidiariesCount]);

    return (
        <>
            {applicationAreaPermission && subsidiariesCount &&
                <section className="split-view-6-6">
                    <div className="content-container">
                        <DocumentsContainer
                            subsidiariesCount={subsidiariesCount}
                            applicationAreas={applicationAreaPermission}
                            selectedDocumentId={id}
                            onSelectionChanged={changeDocument}
                            refreshToken={refreshToken} />
                    </div>
                    <div className="content-container">
                        {id &&
                            <DocumentContainer
                                subsidiariesCount={subsidiariesCount}
                                applicationAreas={applicationAreaPermission}
                                allSubsidiariesHaveWritePermission={allSubsidiariesHaveWritePermission}
                                documentId={id}
                                onDocumentDeleted={onDocumentDeleted}
                                onDocumentEdited={toggleToken} />
                        }
                    </div>
                </section>
            }
        </>
    )
}

export default DocumentsPage;