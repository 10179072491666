import { post, useSaveCallout } from "../../improve-lib";

const useAdAuthentication = () => {
    const saveCallout = useSaveCallout();

    const setAdAuthentication = async (id: number) => {
        const response = await post("users/" + id + "/ad");

        switch (response.status) {
            case 200:
                saveCallout.show();
                break;
            default:
                throw response;
        }
    }

    return { setAdAuthentication }
}

export default useAdAuthentication;