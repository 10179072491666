import { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useActivateDeactivateUser, useAdAuthentication, useDeleteUser, useNavigationUser, useUpdateUser } from "../../api";
import { Container, PasswordResetDialog, SetCredentialsDialog, useCopy, useGlobalSettings, useQueryValue, useResources } from "../../improve-lib";
import { MessageBarProps, MessageStack, NavigationHeader, RowAction } from "../../lib";
import UserForm from "./components/UserForm";

const EditUserPage = () => {
    const { id } = useParams();
    const navigate = useNavigate()
    const location = useLocation()
    const resources = useResources();
    const settings = useGlobalSettings();

    const [resetPasswordDialogHidden, setResetPasswordDialogHidden] = useState(true);
    const [credentialsDialogHidden, setCredentialsDialogHidden] = useState(true);

    const [orderBy] = useQueryValue("orderBy", value => value ?? "displayName");
    const [orderAsc] = useQueryValue("orderAsc", value => value === "true");
    const [fulltextQuery] = useQueryValue("fulltext", value => value ?? "");
    const [records] = useQueryValue("records", value => {
        if (value) {
            const number = parseInt(value);

            if (!isNaN(number))
                return number;
        }
        return 20;
    });

    const { updateUser, setErrors, errors } = useUpdateUser(() => {
        reload();
    })
    const { dbUser, navState, reload } = useNavigationUser(parseInt(id!), orderBy, orderAsc, fulltextQuery, records)
    const [user, setUser, userChanged] = useCopy(dbUser);
    const { setUserActiveInactive } = useActivateDeactivateUser(() => {
        reload();
    });
    const { deleteUser } = useDeleteUser(() => {
        navigate("/settings/users");
    });
    const { setAdAuthentication } = useAdAuthentication();

    useEffect(() => {
        setErrors({ displayNameDuplicated: false, usernameDuplicated: false });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dbUser])

    if (!dbUser || !user)
        return null;

    const anyErrors = dbUser.username === "" || dbUser.displayName === "";

    const navUp = () => navigate("/settings/users/" + navState.previousId + location.search);

    const navDown = () => navigate("/settings/users/" + navState.nextId + location.search);

    const messages: MessageBarProps[] | undefined = (!dbUser.active) ? [{ message: resources.userIsInactive, type: "info" }] : undefined;

    const onNavigateBack = () => navigate("/settings/users" + location.search);

    const navigation = {
        onNavigateUp: navUp,
        onNavigateDown: navDown,
        unitCount: navState.unitCount,
        currentUnit: navState.currentUnit,
        filteredUnitCount: navState.filteredUnitCount,
        upAvailable: navState.previousId !== null,
        downAvailable: navState.nextId !== null
    }

    const showResetPasswordDialog = () => {
        setResetPasswordDialogHidden(false);
    }

    const resetPasswordDialogCancelOrClose = () => {
        setResetPasswordDialogHidden(true);
    }

    const deleteAction: RowAction = {
        text: resources.delete,
        iconName: "Delete",
        onClick: () => deleteUser(parseInt(id!), dbUser.displayName)
    };

    const toggleAction: RowAction = {
        text: dbUser.active ? resources.deactivate : resources.activate,
        iconName: "UserWarning",
        onClick: () => setUserActiveInactive(parseInt(id!), !dbUser.active)
    };

    const resetPasswordAction: RowAction = {
        onClick: showResetPasswordDialog,
        iconName: "Rotate90CounterClockwise",
        text: resources.passwordReset
    }

    const switchToAdAction: RowAction = {
        onClick: async () => {
            await setAdAuthentication(parseInt(id!))
            reload();
        },
        iconName: "Lock",
        text: resources.changeToAdAuthentication
    }

    const switchToCredentialsAction: RowAction = {
        onClick: () => setCredentialsDialogHidden(false),
        iconName: "Lock",
        text: resources.changeToImproveAuthentication
    }

    const overflowActions: RowAction[] = [toggleAction];

    if (user.usesAdAuthentication) {
        overflowActions.push(switchToCredentialsAction);
    }
    else {
        overflowActions.push(resetPasswordAction);

        if (settings.allowAd)
            overflowActions.push(switchToAdAction);
    }

    overflowActions.push(deleteAction);


    const onUpdateUser = async () => {
        await updateUser(parseInt(id!),user)
    }

    return (
        <>
            <NavigationHeader backLabel={resources.backToUseradministration} onNavigateBack={onNavigateBack} unitNavigation={navigation} />
            <section>
                <div className="content-container">
                    <Container title={resources.userEdit} onSave={onUpdateUser} saveDisabled={anyErrors || !userChanged} actions={[...overflowActions]}>
                        <MessageStack messages={messages ?? []} />
                        <UserForm
                            user={user}
                            alterUser={setUser}
                            persistedUser={dbUser}
                            errors={errors}
                        />
                        <PasswordResetDialog
                            userId={parseInt(id!)}
                            hidden={resetPasswordDialogHidden}
                            onDismiss={resetPasswordDialogCancelOrClose}
                            userDisplayName={dbUser.displayName}
                        />
                        <SetCredentialsDialog
                            userId={parseInt(id!)}
                            hidden={credentialsDialogHidden}
                            onDismiss={() => setCredentialsDialogHidden(true)}
                            userDisplayName={dbUser.displayName}
                            onChanged={() => {
                                setCredentialsDialogHidden(true);
                                reload();
                            }}
                        />
                    </Container>
                </div>
            </section>
        </>
    )
}

export default EditUserPage;
