import { useEffect, useState } from "react";
import { useDeleteDocument, useDisplayDocument, useLockDocument, useUnlockDocument } from "../../../api";
import { Container, useResources } from "../../../improve-lib";
import { RowAction, Tabs } from "../../../lib";
import { ApplicationAreaPermission, DocumentsApplicationAreaPermissionType } from "../../../model";
import ChangeSubsidiaryDialog from "../document-container/edit/ChangeSubsidiaryDialog";
import DocumentDisplay from "./display/DocumentDisplay";
import EditPopup from "./edit/EditPopup";
import DocumentHistory from "./history/DocumentHistory";
import DocumentViewer from "./viewer/Viewer";

type DocumentContainerProps = {
    subsidiariesCount: number;
    applicationAreas: ApplicationAreaPermission[];
    allSubsidiariesHaveWritePermission: boolean;
    documentId: number;
    onDocumentDeleted: () => void;
    onDocumentEdited: () => void;
}

const DocumentContainer = (props: DocumentContainerProps): JSX.Element | null => {
    const resources = useResources();

    const [tabIndex, setTabIndex] = useState(0);
    const [documentPermission, setDocumentPermission] = useState<DocumentsApplicationAreaPermissionType>();
    // true -> lock, false -> unlock, undefinen -> none
    const [canLock, setCanLock] = useState<boolean>();
    const [canDelete, setCanDelete] = useState(false);
    const [changeSubsidiaryDialogHidden, setChangeSubsidiaryDialogHidden] = useState(true);

    const [editDocumentPopupVisible, setEditDocumentPopupVisible] = useState<boolean>(false);

    const { document, reloadFlag, reload } = useDisplayDocument(props.documentId)
    const { deleteDocument } = useDeleteDocument(() => {
        props.onDocumentDeleted();
    })
    const { setDocumentLocked } = useLockDocument(() => {
        reload();
        props.onDocumentEdited();
    })
    const { setDocumentUnlocked } = useUnlockDocument(() => {
        reload();
        props.onDocumentEdited();
    })

    useEffect(() => {
        if (document) {
            const applicationArea = props.applicationAreas.find(a => a.id === document.applicationArea.id)!;
            if (document.subsidiary?.id === undefined) {
                let highestPermission = DocumentsApplicationAreaPermissionType.Delete;
                for (let i = 0; i < applicationArea.subsidiaryPermissions.length; i++) {
                    if (applicationArea.subsidiaryPermissions[i].permission < highestPermission)
                        highestPermission = applicationArea.subsidiaryPermissions[i].permission;
                }
                setDocumentPermission(highestPermission);
            }
            else
                setDocumentPermission(applicationArea.subsidiaryPermissions.find(s => s.subsidiary.id === document.subsidiary?.id)?.permission);
        }
    }, [props.applicationAreas, document])

    useEffect(() => {
        setCanLock(((documentPermission! & DocumentsApplicationAreaPermissionType.Write) === DocumentsApplicationAreaPermissionType.Write) ? !document?.locked : undefined)
        setCanDelete(((documentPermission! & DocumentsApplicationAreaPermissionType.Delete) === DocumentsApplicationAreaPermissionType.Delete))

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [documentPermission, document?.locked, resources.isoCode, document?.id])

    const onDocumentEdited = () => {
        reload();
        props.onDocumentEdited();
    }
    const showLocked = !document?.locked || (documentPermission! & DocumentsApplicationAreaPermissionType.Write) === DocumentsApplicationAreaPermissionType.Write;

    const deleteAction: RowAction = { iconName: "Delete", onClick: () => deleteDocument(document!.id, document?.filename ?? ""), text: resources.delete };
    const lockAction: RowAction = { iconName: "Lock", onClick: () => setDocumentLocked(document!.id, canLock!), text: resources.lock, disabled: false };
    const unlockAction: RowAction = { iconName: "Unlock", onClick: () => setDocumentUnlocked(document!.id, canLock!), text: resources.unlock, disabled: false };
    const openAction: RowAction = {
        iconName: "OpenInNewTab",
        onClick: () => window.open("api/documents/" + props.documentId + "/file?download=false"),
        text: resources.open
    };
    const downloadAction: RowAction = {
        iconName: "DownloadDocument",
        onClick: () => window.open("api/documents/" + props.documentId + "/file?download=true"),
        text: resources.download
    };
    const changeSubsidiaryAction: RowAction = {
        iconName: "Switch",
        onClick: () => setChangeSubsidiaryDialogHidden(false),
        text: resources.changeSubsidiary
    }
    const editAction: RowAction = { iconName: "Edit", onClick: () => { setEditDocumentPopupVisible(true) }, text: resources.edit };

    const actions: RowAction[] = [];

    if (showLocked)
        actions.push(openAction, downloadAction);

    if (documentPermission! === DocumentsApplicationAreaPermissionType.Write || documentPermission! === DocumentsApplicationAreaPermissionType.Delete)
        actions.push(editAction);

    if (canLock)
        actions.push(lockAction);
    else if (canLock === false)
        actions.push(unlockAction)

    if (props.subsidiariesCount > 1 && props.allSubsidiariesHaveWritePermission)
        actions.push(changeSubsidiaryAction);

    if (canDelete)
        actions.push(deleteAction)

    return (
        <>
            {document && documentPermission &&
                <Container title={resources.document} actions={actions} >
                    <Tabs tabs={[
                        {
                            control: <DocumentDisplay document={document} />,
                            header: resources.information
                        },
                        ...showLocked ? [{
                            control: <DocumentViewer documentId={props.documentId} />,
                            header: resources.view
                        }] : [],
                        ...(documentPermission & DocumentsApplicationAreaPermissionType.History) === DocumentsApplicationAreaPermissionType.History ? [{
                            control: <DocumentHistory documentId={props.documentId} refreshToken={reloadFlag} />,
                            header: resources.history
                        }] : []
                    ]}
                        selectedIndex={tabIndex} onClick={setTabIndex} />
                    {editDocumentPopupVisible &&
                        <EditPopup
                            isOpen={editDocumentPopupVisible}
                            documentId={props.documentId}
                            onAbort={() => { setEditDocumentPopupVisible(false) }}
                            onDocumentEdited={onDocumentEdited}
                        />
                    }
                    <ChangeSubsidiaryDialog
                        documentId={props.documentId}
                        subsidiaryId={document.subsidiary?.id ?? null}
                        applicationAreaId={document.applicationArea.id}
                        subsidiariesCount={props.subsidiariesCount}
                        hidden={changeSubsidiaryDialogHidden}
                        onDismiss={() => setChangeSubsidiaryDialogHidden(true)}
                        onChanged={() => { setChangeSubsidiaryDialogHidden(true); reload(); }}
                    />
                </Container>}
        </>
    )
}

export default DocumentContainer;
