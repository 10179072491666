import { useEffect, useState } from "react";
import { get, useResources, useToggle } from "../../improve-lib";
import { CustomFieldItem, ImproveModule } from "../../model";

const useCustomFields = (module: ImproveModule) => {
    const resources = useResources();

    const [refreshFlag, refresh] = useToggle();
    const [customFields, setCustomFields] = useState<CustomFieldItem[]>();

    useEffect(() => {
        const getCustomFields = async () => {
            const response = await get("customFields/" + module);

            if (response.status !== 200)
                throw response;

            setCustomFields(response.body as CustomFieldItem[]);
        }

        void getCustomFields();
    }, [module, resources.isoCode, refreshFlag]);

    return { customFields, refresh }
}

export default useCustomFields;