import { useEffect, useState } from "react";
import { useResetPassword } from "../../api";
import { useResources } from "../../improve-lib";
import { Button, Dialog, DialogFooter, MessageBar, PasswordBox } from "../../lib";

type PasswordResetDialogProps = {
    userId: number;
    hidden: boolean;
    onDismiss: () => void;
    userDisplayName: string;
}

const PasswordResetDialog = (props: PasswordResetDialogProps): JSX.Element => {
    const resources = useResources();

    const [newPassword, setNewPassword] = useState("");
    const [passwordChanged, setPasswordChanged] = useState(false);

    const { resetPassword } = useResetPassword(() => {
        setPasswordChanged(true);
    })

    useEffect(() => {
        if (!props.hidden) {
            setNewPassword("");
            setPasswordChanged(false);
        }
    }, [props.hidden])

    return (
        <Dialog
            title={resources.passwordReset + " (" + props.userDisplayName + ")"}
            hidden={props.hidden}
            onDismiss={props.onDismiss}
        >
            {passwordChanged && <MessageBar message={resources.temporaryPasswordSaved} type="success" />}
            <PasswordBox label={resources.temporaryPassword} text={newPassword} onChange={setNewPassword} readOnly={passwordChanged} />
            {passwordChanged ?
                <DialogFooter>
                    <Button onClick={props.onDismiss} text={resources.close} secondary />
                </DialogFooter>
                :
                <DialogFooter>
                    <Button onClick={() => resetPassword(props.userId, newPassword)} text={resources.reset} />
                    <Button onClick={props.onDismiss} text={resources.cancel} secondary />
                </DialogFooter>}
        </Dialog >
    )
}

export default PasswordResetDialog;
