import { post, useConfirmLockDialog } from "../../improve-lib";

const useLockDocument = (afterLocked?: () => void) => {
    const setDocumentLocked = useConfirmLockDialog(async (id: number) => {
        const response = await post("documents/" + id + "/setlocked");

        if (response.status !== 200)
            throw response;

        if (afterLocked)
            afterLocked();
    });
    return { setDocumentLocked }
}

export default useLockDocument;