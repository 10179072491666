import { useEffect, useState } from "react";
import { get, useToggle } from "../../improve-lib";
import { DocumentsConfigurationModel } from "../../model";

const useConfigurationDocuments = () => {
    const [reloadFlag, reload] = useToggle();

    const [configDb, setConfigDb] = useState<DocumentsConfigurationModel>();

    useEffect(() => {
        const getConfiguration = async () => {
            const response = await get("documents/configuration");

            if (response.status !== 200)
                throw response;

            setConfigDb(response.body as DocumentsConfigurationModel)
        }

        void getConfiguration();
    }, [reloadFlag]);

    return { configDb, reload }
}

export default useConfigurationDocuments;
