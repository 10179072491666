import { FocusZone } from "@fluentui/react";
import { useResources } from "../../improve-lib";
import { Button } from "..";
import "./List.tsx.scss";

type ListProps<T extends { id: number }> = {
    items: T[];
    selectedItemId?: number;
    onSelectionChanged: (newSelectedId: number) => void;
    onRender: (item: T) => JSX.Element;
    onLoadMore?: () => void;
}

const List = <T extends { id: number }>(props: ListProps<T>): JSX.Element => {
    const resources = useResources();

    return (
        <FocusZone as="div">
            {props.items.map(item =>
                <div key={item.id} onClick={() => props.onSelectionChanged(item.id)} className={item.id === props.selectedItemId ? "list-item-selected" : "list-item"}>
                    {props.onRender(item)}
                </div>
            )}
            {props.onLoadMore &&
                <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                    <Button
                        onClick={props.onLoadMore}
                        text={resources.showMore}
                    />
                </div>
            }
        </FocusZone>
    )
}

export default List;
