import { post, useSaveCallout } from "../../improve-lib";
import { NewDocumentModel } from "../../model";

const useCreateDocument = (afterCreated?: (id: number) => void) => {
    const saveCallout = useSaveCallout();

    const createDocument = async (document: NewDocumentModel) => {
        const formData = new FormData();

        formData.append("file", document.file);
        formData.append("name", document.filename);
        formData.append("applicationAreaId", document.applicationArea.id.toString());
        formData.append("ownerId", document.owner.id.toString());
        formData.append("version", document.version);
        if (document.dateValidUntil !== null)
            formData.append("dateValidUntil", document.dateValidUntil.toDateString());

        const keys = Object.keys(document.description);
        for (let i = 0; i < keys.length; i++) {
            const key = keys[i];
            const value = document.description[key];

            if (value.trim().length === 0)
                break;

            formData.append(`description.${key}`, value);
        }

        // TODO: mit remodel anders lösbar
        formData.append("convertToPdf", (document.convertToPdf ?? false).toString());

        for (let i = 0; i < document.tags.length; i++)
            formData.append("tagIds", document.tags[i].id.toString());

        if (document.subsidiary !== null && document.subsidiary.id !== -1)
            formData.append("subsidiaryId", document.subsidiary.id.toString());

        const response = await post("documents/new/", formData);

        if (response.status !== 200)
            throw response;

        saveCallout.show();

        if (afterCreated)
            afterCreated(response.body as number)
    }

    return { createDocument }
}

export default useCreateDocument;