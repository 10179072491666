import { put, useSaveCallout } from "../../improve-lib";

const useChangeProcessSubsidiary = () => {
    const saveCallout = useSaveCallout();

    const changeProcessSubsidiary = async (processId: number, subsidiaryId: number | null) => {
        let requestUrl = `process/${processId}/changeSubsidiary`;
        if (subsidiaryId !== null)
            requestUrl += `?subsidiaryId=${subsidiaryId}`;

        const response = await put(requestUrl);

        switch (response.status) {
            case 200:
                saveCallout.show();
                break;
            default:
                throw response;
        }
    }

    return { changeProcessSubsidiary }
}

export default useChangeProcessSubsidiary;