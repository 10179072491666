import { useEffect, useState } from "react";
import { post } from "../../improve-lib";

const usePdfConvertible = (fileName: string | undefined) => {
    const [isConvertible, setIsConvertible] = useState(false);

    useEffect(() => {
        const checkPdfConvertible = async () => {
            if (!fileName)
                return;

            const response = await post("pdf/check?fileName=" + fileName);

            if (response.status !== 200)
                throw response;

            setIsConvertible(response.body as boolean);
        }
        void checkPdfConvertible();
    }, [fileName]);

    return { isConvertible }
}

export default usePdfConvertible;