const format = (resource: string, ...args: string[]): string => {
    let formated = resource;

    for (let i = 0; i < args.length; i++)
        formated = formated.replace("{" + i + "}", args[i]);

    return formated;
}

const formatElementBold = (resource: string, ...args: string[]): JSX.Element => {
    const formated: any[] = [];

    let lastIndex = 0;

    for (let i = 0; i < args.length; i++) {
        const index = resource.indexOf("{" + i + "}");

        const text = resource.substring(lastIndex, index);

        if (text)
            formated.push(text);

        formated.push(<b key={i}>{args[i]}</b>);

        lastIndex = index;
    }

    const text = resource.substring(lastIndex + 3, resource.length);

    if (text)
        formated.push(text);

    return (<>
        {formated}
    </>)
}

export {
    format,
    formatElementBold
}
