import { del, useConfirmDeleteCallback } from "../../improve-lib";

const useDeleteProcess = (afterDelete?: () => void) => {
    const deleteProcess = useConfirmDeleteCallback(async (id: number) => {
        const response = await del("process/" + id);

        if (response.status === 200) {
            if (afterDelete)
                afterDelete();
        }
        else
            throw response;
    }, true);

    return { deleteProcess }
}

export default useDeleteProcess;