import { useState } from "react";
import { useLanguage, useResources } from "../../../../../improve-lib";
import { DropDown, MultiLanguageTextbox } from "../../../../../lib";
import { IdValuePair, MultiLanguageFieldModel } from "../../../../../model";
import SubprozessSelector from "./SubprocessSelector";

type EditLabelProps = {
    label: MultiLanguageFieldModel;
    subprocess: IdValuePair | null;
    processId: number;
    subsidiary: IdValuePair | null;
    onLabelChanged: (label: MultiLanguageFieldModel) => void;
    onSubprocessChanged: (subprocess: IdValuePair | null) => void
}

const DisplaySelector = (props: EditLabelProps): JSX.Element => {
    const resources = useResources();
    const lang = useLanguage();

    const getSelectedType = () => {
        return props.subprocess ? 2 : 1;
    }

    const [selectedType, setSelectedType] = useState(getSelectedType());

    const displaySelectorTypes = [{ id: 1, value: resources.label }, { id: 2, value: resources.subprocess }];

    const onSelectedTypeChange = (id: number) => {
        if (id === 2)
            props.onLabelChanged(lang.map(l => { return { isoCode: l, label: "" } }));
        else
            props.onSubprocessChanged(null);

        setSelectedType(id);
    }

    const getComponent = () => {
        switch (selectedType) {
            case 1:
                return (
                    <MultiLanguageTextbox
                        languageFields={props.label}
                        onChange={name => props.onLabelChanged(name)}
                        duplicatedIsoCodes={[]}
                        textBoxHeader={resources.label}
                    />
                );
            case 2:
                return (
                    <SubprozessSelector
                        subprocess={props.subprocess}
                        processId={props.processId}
                        subsidiary={props.subsidiary}
                        onSubprocessChanged={props.onSubprocessChanged}
                    />
                );
        }
    }

    return (
        <>
            <DropDown
                label={resources.display}
                placeholder={""}
                selectedId={selectedType}
                onChange={item => onSelectedTypeChange(item.id)}
                options={displaySelectorTypes}
            />
            {getComponent()}
        </>
    )
}

export default DisplaySelector;