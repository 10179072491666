import { useEffect, useRef, useState } from "react";
import { useResources } from "../../../../../improve-lib";
import { Popup, TextBox } from "../../../../../lib";
import { ExternalLinkModel } from "../../../../../model";

type NewLinkPopupProps = {
    isOpen: boolean;
    onAbort: () => void;
    onLinkAdded: (newLink: ExternalLinkModel) => void;
}

const NewLinkPopup = (props: NewLinkPopupProps): JSX.Element => {
    const resources = useResources();

    const [anyChanges, setAnyChanges] = useState(false);

    const initLink = useRef<ExternalLinkModel>({
        url: "",
        linkText: ""
    });

    const [link, setLink] = useState<ExternalLinkModel>(initLink.current);

    useEffect(() => {
        if (props.isOpen) {
            setLink(initLink.current);
        }
    }, [props.isOpen]);

    const detectChanges = (linkEdited: ExternalLinkModel) => {
        return linkEdited.url.trim().length > 0 || linkEdited.linkText.trim().length > 0;
    }

    const urlIsValid = () => {
        try {
            new URL(link.url);
        } catch {
            return false;
        }

        return true;
    }

    const linkIsValid = () => {
        return urlIsValid() && link.linkText.trim().length > 0;
    }

    const onLinkChanged = (linkEdited: ExternalLinkModel) => {
        setLink(linkEdited);
        setAnyChanges(detectChanges(linkEdited));
    }

    const onLinkTextChanged = (linkText: string) => {
        const newLink = { ...link, linkText: linkText };
        onLinkChanged(newLink);
    }

    const onUrlChanged = (url: string) => {
        const newLink = { ...link, url: url };
        onLinkChanged(newLink);
    }

    return (
        <Popup
            isOpen={props.isOpen}
            title={resources.new + " " + resources.link}
            onDismiss={props.onAbort}
            actions={[
                { iconName: "accept", onClick: () => { props.onLinkAdded(link); props.onAbort() }, text: resources.ok, disabled: !linkIsValid() || !anyChanges }              
            ]}
            closeText={resources.cancel}
            size={"small"}
        >
            <div style={{ marginBottom: "15px" }}>
                <TextBox
                    label={resources.label}
                    text={link.linkText}
                    onChange={onLinkTextChanged}
                    mandatory={true}
                />
                <TextBox
                    label={resources.link}
                    text={link.url}
                    onChange={onUrlChanged}
                    mandatory={true}
                    valid={urlIsValid()}
                />
            </div>
        </Popup>
    );
}

export default NewLinkPopup;