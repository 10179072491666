import { useNavigate } from "react-router-dom";
import { TopBar, useResources } from "../../improve-lib";
import Copyright from "../login/components/Copyright";
import img from "./../../public/image/LoginImage.jpg";
import "./NotFoundPage.tsx.scss";

const DefaultErrorPage = (): JSX.Element => {
    const navigate = useNavigate();
    const resources = useResources();
    const loginRedirect = () => navigate("/opentasks"); //TODO: change to dashboard once available

    return (
        <>
            <TopBar open={false} onToggle={() => { }} />
            <div className="default-error-page-wrapper">
                <div className="default-error-page-image-container">
                    <img className="default-error-page-image" src={img} alt="" />
                </div>
                <div className="default-error-page-status-wrapper">
                    <div className="default-error-page-status">
                        {resources.pageNotFound}
                    </div>
                    <div className="default-error-page-return">
                        <label style={{ cursor: "pointer" }} onClick={loginRedirect}>{resources.backToImprove}</label>
                    </div>
                    <div className="default-error-page-copyright">
                        <Copyright />
                    </div>
                </div>
            </div>
        </>
    )
}

export default DefaultErrorPage;
