import { useEffect, useState } from "react";
import { useCustomField, useDeleteCustomField, useUpdateCustomField } from "../../../api";
import { CheckBox, EditableMultiLanguageList, MultiLanguageTextbox, RowAction, Section } from "../../../lib";
import { CustomFieldSettingsModel, FieldType, ImproveModule, MultiLanguageFieldModel, SubsidiarySettingModel, UpdateCustomFieldModel } from "../../../model";
import UpdateType from "../../../model/misc/updateType";
import { Container, CreatedEditedFooter, useRegisterSaveCheck, useResources } from "../..";
import SubsidiarySettings from "./SubsidiarySettings";

type CustomFieldDetailsProps = {
    module: ImproveModule;
    customFieldId: number;
    refresh: () => void;
    onDeleted: () => void;
}

const CustomFieldDetails = (props: CustomFieldDetailsProps) => {
    const resources = useResources();

    const { customField, reload } = useCustomField(props.module, props.customFieldId);

    const [name, setName] = useState<MultiLanguageFieldModel>();
    const [selections, setSelections] = useState<{ id?: number, name: MultiLanguageFieldModel }[]>();
    const [subsidiarySettings, setSubsidiarySettings] = useState<SubsidiarySettingModel[]>();
    const [customFieldSettings, setCustomFieldSettings] = useState<CustomFieldSettingsModel>();

    const [nameChanged, setNameChanged] = useState(false);
    const [selectionsChanged, setSelectionsChanged] = useState(false);
    const [subsidiarySettingsChanged, setSubsidiarySettingsChanged] = useState(false);
    const [customFieldSettingsChanged, setCustomFieldSettingsChanged] = useState(false);
    const anyChanges = nameChanged || subsidiarySettingsChanged || (customFieldSettings ? customFieldSettingsChanged : false) || (selections ? selectionsChanged : false);

    useRegisterSaveCheck(anyChanges);

    useEffect(() => {
        setName(structuredClone(customField?.name));
        setSelections(structuredClone(customField?.selections));
        setSubsidiarySettings(structuredClone(customField?.subsidiarySettings));
        setCustomFieldSettings(structuredClone(customField?.settings));
        setNameChanged(false);
        setSelectionsChanged(false);
        setSubsidiarySettingsChanged(false);
        setCustomFieldSettingsChanged(false);
    }, [customField]);

    const customFieldIsValid = () => {
        if (name?.some(n => n.label.trim().length < 1))
            return false;

        if (selections) {
            if (selections.some(s => s.name.some(n => n.label.trim().length < 1)))
                return false;

            const duplicatedSelections = selections.some((entry1, idx1) =>
                entry1.name.some(lf1 => {
                    const trimmedLabel = lf1.label.trim().toLowerCase();
                    return trimmedLabel.length > 0 && selections.some((entry2, idx2) => {
                        return (idx1 !== idx2) && entry2.name.find(lf2 => lf2.isoCode === lf1.isoCode)?.label.toLowerCase() === trimmedLabel;
                    });
                })
            );

            if (duplicatedSelections)
                return false;
        }

        return true;
    }
    const [isValid, setIsValid] = useState(customFieldIsValid());

    const { updateCustomField, duplicatedIsoCodes } = useUpdateCustomField(() => {
        reload();
        props.refresh();
    });
    const { deleteCustomField } = useDeleteCustomField(props.module, props.onDeleted);

    if (!customField || !name || !subsidiarySettings)
        return null;

    const customFieldName = customField.name.find(n => n.isoCode === resources.isoCode)!.label ?? "";

    let fieldTypeLabel;

    switch (customField.fieldType) {
        case FieldType.Boolean:
            fieldTypeLabel = "Checkbox";
            break;
        case FieldType.Date:
            fieldTypeLabel = resources.date;
            break;
        case FieldType.Number:
            fieldTypeLabel = resources.numeric;
            break;
        case FieldType.Text:
            fieldTypeLabel = resources.text;
            break;
        case FieldType.Selection:
            fieldTypeLabel = resources.selection;
            break;
    }

    const hasNameChanged = (editedName: MultiLanguageFieldModel) => {
        return customField.name.some(n1 => n1.label !== editedName.find(n2 => n2.isoCode === n1.isoCode)!.label);
    }
    const onNameChange = (newName: MultiLanguageFieldModel) => {
        setName(newName);
        setIsValid(customFieldIsValid());
        setNameChanged(hasNameChanged(newName));
    }

    const hasSelectionsChanged = (editedSelections: { id?: number, name: MultiLanguageFieldModel }[]) => {
        return (
            customField.selections?.length !== editedSelections.length ||
            editedSelections.some(s => s.id === undefined) ||
            customField.selections.some(s1 => s1.name.some(n1 =>
                n1.label !== editedSelections.find(s2 => s2.id === s1.id)?.name.find(n2 => n2.isoCode === n1.isoCode)!.label)
            )
        );
    }
    const onSelectionsChange = (newSelections: { id?: number, name: MultiLanguageFieldModel }[]) => {
        setSelections(newSelections);
        setIsValid(customFieldIsValid());
        setSelectionsChanged(hasSelectionsChanged(newSelections));
    }

    const hasSubsidiarySettingsChanged = (editedSettings: SubsidiarySettingModel[]) => {
        return (
            customField.subsidiarySettings.some(s1 => s1.visible !== editedSettings.find(s2 => s2.subsidiaryId === s1.subsidiaryId)!.visible) ||
            customField.subsidiarySettings.some(s1 => s1.mandatory !== editedSettings.find(s2 => s2.subsidiaryId === s1.subsidiaryId)!.mandatory)
        );
    }
    const onSubsidiarySettingsChange = (newSettings: SubsidiarySettingModel[]) => {
        setSubsidiarySettings(newSettings);
        setIsValid(customFieldIsValid());
        setSubsidiarySettingsChanged(hasSubsidiarySettingsChanged(newSettings));
    }

    const getChangedSelections = (editedSelections: { id?: number, name: MultiLanguageFieldModel }[]) => {
        if (customField.selections && selectionsChanged) {
            const selections = customField.selections.flatMap(dbSelection => {
                const selection = editedSelections.find(s2 => s2.id === dbSelection.id);
                if (selection) {
                    const hasChanged = dbSelection.name.some(n1 =>
                        n1.label !== selection.name.find(n2 => n2.isoCode === n1.isoCode)!.label);

                    return hasChanged ? ({ ...selection, type: UpdateType.update }) : [];
                }
                else
                    return ({ ...dbSelection, type: UpdateType.delete });
            });

            selections.push(...editedSelections.filter(s =>
                s.id === undefined).map(s => ({ ...s, type: UpdateType.insert })));

            return selections;
        }
        else
            return undefined;
    }

    const onSave = async () => {
        const updateModel: UpdateCustomFieldModel =
        {
            name: nameChanged ? name : undefined,
            selections: selections ? getChangedSelections(selections) : undefined,
            settings: customFieldSettingsChanged ? customFieldSettings : undefined,
            subsidiarySettings: subsidiarySettingsChanged ? subsidiarySettings : undefined,
            versionCount: customField.versionCount
        };

        await updateCustomField(props.module, props.customFieldId, updateModel);
    }
    const saveAction: RowAction = { iconName: "Save", onClick: onSave, text: resources.save, disabled: !anyChanges || !isValid };

    const onDelete = () => deleteCustomField(props.customFieldId, customFieldName);
    const deleteAction: RowAction = { iconName: "Delete", onClick: onDelete, text: resources.delete };

    const hasCustomFieldSettingsChanged = (editedSettings: CustomFieldSettingsModel) => {
        return (
            customField.settings?.multiline !== editedSettings.multiline
        );
    }

    const onMultilineCheckedChanged = (newValue: boolean) => {
        const newCustomFieldSettings: CustomFieldSettingsModel =
        {
            multiline: newValue
        }
        setCustomFieldSettings(newCustomFieldSettings);
        setCustomFieldSettingsChanged(hasCustomFieldSettingsChanged(newCustomFieldSettings))
    };

    return (
        <Container title={customFieldName + " (" + fieldTypeLabel + ")"} actions={[saveAction, deleteAction]}>
            <div style={{ marginLeft: 10 }} >
                <article>
                    <div className="article-form-element">
                        <MultiLanguageTextbox
                            languageFields={name}
                            onChange={onNameChange}
                            duplicatedIsoCodes={duplicatedIsoCodes}
                            textBoxHeader={resources.label}
                            mandatory
                        />
                    </div>
                    <div className="article-form-element">
                        {customField.fieldType === FieldType.Selection && selections &&
                            <Section headerText={resources.selectionItems} collapsible >
                                <EditableMultiLanguageList
                                    entries={selections}
                                    onChange={onSelectionsChange}
                                />
                            </Section>
                        }
                    </div>
                    <div className="article-form-element">
                        <Section headerText={resources.settings} collapsible >
                            {customField.fieldType === FieldType.Text &&
                                <div style={{ marginBottom: 10 }}>
                                    <CheckBox label={resources.multiline} checked={customFieldSettings?.multiline ?? false} onChange={onMultilineCheckedChanged} />
                                </div>
                            }
                            <SubsidiarySettings subsidiarySettings={subsidiarySettings} onChange={onSubsidiarySettingsChange} />
                        </Section>
                    </div>
                </article>
                <CreatedEditedFooter tracked={customField} />
            </div>
        </Container>
    );
}

export default CustomFieldDetails;