import { useEditDocument, useUpdateDocument } from "../../../../api";
import { useCopy, useResources, useSaveCheck } from "../../../../improve-lib";
import { Popup } from "../../../../lib";
import { EditDocumentModel } from "../../../../model";
import EditForm from "./EditForm";


type EditPopupProps = {
    isOpen: boolean;
    documentId: number;
    onDocumentEdited: () => void;
    onAbort: () => void;
}

const EditPopup = (props: EditPopupProps) => {
    const resources = useResources();

    const { documentDb } = useEditDocument(props.documentId, props.isOpen);
    const [document, setDocument, documentChanged] = useCopy(documentDb);

    const { updateDocument } = useUpdateDocument(() => {
        props.onDocumentEdited();
        props.onAbort();
    })

    const onAbort = useSaveCheck(props.onAbort);

    if (!documentDb || !document)
        return null;

    const onDocumentChanged = (documentEdited: EditDocumentModel) => {
        const document: EditDocumentModel = { ...documentEdited, description: { ...documentEdited.description } }
        setDocument(document);
    }

    const documentIsValid = () => document.filename !== "" && document.applicationArea?.id !== -1;

    const requestUpdateDocument = async () => await updateDocument(props.documentId, document, documentDb);

    return (
        <Popup
            isOpen={props.isOpen}
            title={resources.edit}
            onDismiss={onAbort}
            onSave={requestUpdateDocument}
            saveDisabled={!documentIsValid() || !documentChanged}
            closeText={resources.cancel}
            size={"medium"}
        >
            <EditForm
                document={document}
                onDocumentChanged={onDocumentChanged}
            />
        </Popup >
    )
}

export default EditPopup;
