import { useEffect, useState } from "react";
import { get, useResources } from "../../improve-lib";
import ProcessGroupPermission from "../../model/process/processGroupPermission";

const usePermittedProcessGroups = () => {
    const resources = useResources();

    const [permittedProcessGroups, setPermittedProcessGroups] = useState<ProcessGroupPermission[]>([]);

    useEffect(() => {
        const getPermittedProcessGroups = async () => {
            const response = await get("processgroups/permissions");

            if (response.status !== 200)
                throw response;

            setPermittedProcessGroups(response.body as ProcessGroupPermission[]);
        }

        void getPermittedProcessGroups();
    }, [resources.isoCode]);

    return { permittedProcessGroups }
}

export default usePermittedProcessGroups;