import { useResources } from "../../../improve-lib";
import { ErrorType, TextBox } from "../../../lib";
import "./SubsidiaryFormNew.tsx.scss";

type SubsidiaryFormNewProps = {
    subsidiaryName: string;
    alterSubsidiary: (subsidiaryName: string) => void;
    nameDuplicated: boolean;
}

const SubsidiaryFormNew = (props: SubsidiaryFormNewProps): JSX.Element => {
    const resources = useResources();

    return (
        <div className="subsidiary-form-new-wrapper">
            <TextBox label={resources.label} placeholder text={props.subsidiaryName} mandatory
                onChange={newName => props.alterSubsidiary(newName)}
                maxLength={40} error={props.nameDuplicated ? ErrorType.duplicated : undefined} autoFocus />
        </div>
    )
}

export default SubsidiaryFormNew;
