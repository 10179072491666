import { ContactKind, useContactLabels, useUpdateContactLabels } from "../../../api/contacts";
import { Container, useCopy, useHasChanges, useResources } from "../../../improve-lib";
import { MultiLanguageTextbox } from "../../../lib";

type ContactLabelsContainerProps = {
    kind: "customer" | "supplier";
}

const ContactLabelsContainer = (props: ContactLabelsContainerProps) => {
    const resources = useResources();
    const hasChanges = useHasChanges();

    const kind = props.kind === "customer" ? ContactKind.Customer : ContactKind.Supplier;

    const updateLabels = useUpdateContactLabels();
    const contactLabels = useContactLabels(kind);

    const [name, setName] = useCopy(contactLabels?.name, "isoCode");
    const [nr, setNr] = useCopy(contactLabels?.nr, "isoCode");

    if (!contactLabels || !name || !nr)
        return null;

    const onSave = async () => {
        await updateLabels(kind, { name, nr })
        contactLabels.name = name;
        contactLabels.nr = nr;
    };

    const isValid = () => {
        if (name.some(el => el.label !== "") || nr.some(el => el.label !== "")) {
            return false;
        }
        return true;
    }  

    return (
        <article>
            <Container onSave={onSave} saveDisabled={!hasChanges || isValid()} title={resources.labels}>
                    <div className="article-content article-multilanguage">
                            <MultiLanguageTextbox
                                languageFields={name}
                                onChange={setName}
                                duplicatedIsoCodes={[]}
                                textBoxHeader={`${resources.label} ${props.kind === "customer" ? resources.customer : resources.supplier}`}
                                mandatory
                            />
                            <MultiLanguageTextbox
                                languageFields={nr}
                                onChange={setNr}
                                duplicatedIsoCodes={[]}
                                textBoxHeader={`${resources.label} ${props.kind === "customer" ? resources.customerNumber : resources.supplierNumber}`}
                                mandatory
                            />
                    </div>
            </Container>
        </article>
    )
}

export default ContactLabelsContainer;
