import { useResources } from "../../../../../improve-lib";
import { Color, colorToHex, hexToColor, IconButton, MultiLanguageTextbox, SpinButton } from "../../../../../lib";
import { FontStyle, MultiLanguageFieldModel } from "../../../../../model";
import ColorPreview from "./ColorPreview";


type EditFontProps = {
    name: MultiLanguageFieldModel;
    font: FontStyle;
    onNameChanged: (content: MultiLanguageFieldModel) => void;
    onFontChanged: (font: FontStyle) => void;
}

const EditFont = (props: EditFontProps): JSX.Element => {
    const resources = useResources();

    const onFontBoldChange = () => props.onFontChanged({ ...props.font, isBold: !props.font.isBold });

    const onFontItalicChange = () => props.onFontChanged({ ...props.font, isItalic: !props.font.isItalic });

    const onFontUnderlineChange = () => props.onFontChanged({ ...props.font, isUnderline: !props.font.isUnderline });

    const onFontSizeChange = (value: number) => props.onFontChanged({ ...props.font, size: value });

    const onFontRotationChange = (value: number) => props.onFontChanged({ ...props.font, rotation: value });

    const onFontColorChange = (newColor: Color) => props.onFontChanged({ ...props.font, color: colorToHex(newColor) });

    return (
        <div style={{ display: "flex", justifyContent: "space-between", alignItems: "flex-end", marginBottom: 20 }}>
            <div style={{ flex: 0.75, marginRight: 20 }} >
                <MultiLanguageTextbox
                    languageFields={props.name}
                    onChange={name => props.onNameChanged(name)}
                    duplicatedIsoCodes={[]}
                    textBoxHeader={resources.label}
                />
            </div>
            <div style={{ display: "flex", alignItems: "flex-end" }} >
                <SpinButton
                    label={resources.fontSize}
                    value={props.font.size}
                    min={1}
                    max={100}
                    onChange={onFontSizeChange}
                />
                <div style={{ display: "flex", flexFlow: "row", marginLeft: 20, marginRight: 20 }} >
                    <IconButton
                        iconName={"Bold"}
                        onClick={onFontBoldChange}
                        checked={props.font.isBold}
                    />
                    <IconButton
                        iconName={"Italic"}
                        onClick={onFontItalicChange}
                        checked={props.font.isItalic}
                    />
                    <IconButton
                        iconName={"Underline"}
                        onClick={onFontUnderlineChange}
                        checked={props.font.isUnderline}
                    />
                    <ColorPreview
                        color={hexToColor(props.font.color)}
                        onColorChange={onFontColorChange}
                    />
                </div>
                <SpinButton
                    label={resources.rotation}
                    value={props.font.rotation}
                    min={0}
                    max={360}
                    onChange={onFontRotationChange}
                />
            </div>
        </div>
    )
}

export default EditFont;
