import { post } from "../../improve-lib";

const useCreatePdfToken = () => {
    const createPdfToken = async (file: File) => {
        const formData = new FormData();

        formData.append("file", file);

        const response = await post("pdf", formData);

        if (response.status !== 200)
            throw response;

        return response.body;
    }

    return { createPdfToken }
}

export default useCreatePdfToken;