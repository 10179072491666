import { format } from "@fluentui/react";
import { useEffect,useState } from "react";
import { Button, Dialog, DialogFooter,TextBox } from "../../lib";
import { useResources } from "..";
import { formatElementBold } from "../misc";

type ConfirmDeleteDialogProps = {
    hidden: boolean;
    onCancel: () => void;
    onDelete: () => void;
    resourceName: string;
    extendedConfirmation?: boolean;
}

const ConfirmDeleteDialog = (props: ConfirmDeleteDialogProps): JSX.Element => {
    const resources = useResources();

    const [confirmationText, setConfirmationText] = useState("");

    useEffect(() => {
        if (!props.hidden) {
            setConfirmationText("");
        }
    }, [props.hidden])

    const onEnter = () => {
        if (deleteAllowed)
            props.onDelete();
    }

    const deleteAllowed = (!props.extendedConfirmation || props.resourceName.trimEnd() === confirmationText.trimEnd());

    return (
        <Dialog
            title={format(resources.deleteConfirmationTitle, props.resourceName)}
            description={resources.deleteConfirmationText}
            hidden={props.hidden}
            onDismiss={props.onCancel}
        >
            {props.extendedConfirmation &&
                <>
                    {formatElementBold(resources.entryForDeleteConfirmation, props.resourceName)}
                    <TextBox
                        label={""}
                        text={confirmationText}
                        onEnter={onEnter}
                        onChange={setConfirmationText}
                        autoFocus
                        mandatory
                    />
                </>
            }
            <DialogFooter>
                <Button onClick={props.onDelete} text={resources.delete} disabled={!deleteAllowed} />
                <Button onClick={props.onCancel} text={resources.cancel} secondary />
            </DialogFooter>
        </Dialog>
    )
}

export default ConfirmDeleteDialog;
