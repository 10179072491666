import { useState } from "react";
import { post } from "../../improve-lib";
import { AuthenticationResultType, CredentialsModel } from "../../model";

const useLogin = (afterLogin?: () => void) => {
    const [errorType, setErrorType] = useState<AuthenticationResultType>(AuthenticationResultType.Ok);

    const login = async (user: string, password: string, rememberMe: boolean) => {
        if (user === "") {
            setErrorType(AuthenticationResultType.InvalidCredentials);
            return;
        }

        const credentials: CredentialsModel = {
            username: user,
            password: password,
            rememberMe: rememberMe
        }

        const response = await post("authentication", credentials);

        switch (response.status) {
            case 200:
                if (afterLogin)
                    afterLogin();
                break;
            case 401:
                setErrorType(response.body as AuthenticationResultType);
                break;
            default:
                throw response;
        }
    }

    return { login, errorType }
}

export default useLogin;