import { useState } from "react";
import { put, useLanguageHandle, useResources, useSaveCallout } from "../../improve-lib";
import { DocumentsConfigurationModel } from "../../model";

const useUpdateConfigurationDocuments = () => {
    const saveCallout = useSaveCallout();
    const resources = useResources();
    const reloadLabels = useLanguageHandle();

    const [duplicatedIsoCodes, setDuplicatedIsoCodes] = useState<string[]>([]);

    const updateConfiguration = async (config: DocumentsConfigurationModel) => {
        const response = await put("documents/configuration", config);

        switch (response.status) {
            case 200:
                saveCallout.show();
                reloadLabels(resources.isoCode);
                break;
            case 400:
                setDuplicatedIsoCodes(response.body as string[]);
                break;
            default:
                throw response;
        }
    }

    return { updateConfiguration, duplicatedIsoCodes }
}

export default useUpdateConfigurationDocuments;