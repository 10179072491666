import { CustomFieldModel, FieldType, IdValuePair } from "../../../model";
import FieldBase from "./fieldBase";
import SelectionFieldControl from "./SelectionFieldControl";

class SelectionField extends FieldBase {
    private selection: IdValuePair;
    private readonly dbSelection: IdValuePair;

    readonly fieldType: FieldType = FieldType.Selection;

    constructor(id: number, name: string, mandatory: boolean, selection: IdValuePair | null) {
        super(id, name, mandatory);
        this.selection = selection ?? { id: -1, value: "" };
        this.dbSelection = selection ?? { id: -1, value: "" };
    }

    isValid() {
        if (this.mandatory)
            return this.selection.id > 0;
        else
            return true;
    }

    hasChange() { return this.selection.id !== this.dbSelection.id; }

    getModel(): CustomFieldModel {
        return {
            id: this.id,
            fieldType: this.fieldType,
            settings: null,
            name: this.name,
            mandatory: this.mandatory,
            selection: this.selection.id > 0 ? this.selection : null,
            boolean: null,
            date: null,
            number: null,
            text: null
        };
    }

    getEditControl(onChange: (newField: FieldBase) => void) {
        return (
            <SelectionFieldControl
                fieldId={this.id}
                label={this.name}
                mandatory={this.mandatory}
                selectedItem={this.selection}
                onChange={newItem => { this.selection = newItem; onChange(this); }}
            />
        );
    }
}



export default SelectionField;