import { useState } from "react";
import { Container, useResources } from "../../../../improve-lib";
import { MemoryTable, OrderColumn, RowAction, TableColumnType, TableData } from "../../../../lib";
import { ExternalLinkModel, LinkModel, LinkType } from "../../../../model";
import NewLinkPopup from "./components/NewLinkPopup";


type EditExternalLinksProps = {
    links: LinkModel[];
    sourceId?: number;
    sourceType: LinkType;
    onChanged: (links: LinkModel[]) => void;
}

type ExternalLinkTableModel = {
    id: number;
    name: string;
    url: string;
}

const EditExternalLinks = (props: EditExternalLinksProps): JSX.Element => {
    const resources = useResources();

    const [orderAsc, setOrderAsc] = useState(true);
    const [newLinkPopupVisible, setNewLinkPopupVisible] = useState(false);

    const onLinkAdded = (extLink: ExternalLinkModel) => {
        const editedLinks =
            [...props.links,
            {
                sourceId: props.sourceId,
                sourceType: props.sourceType,
                targetType: LinkType.ExternalLink,
                label: extLink.linkText,
                targetLink: extLink.url
            }
            ];
        props.onChanged(editedLinks);
    }

    const onDeleteLink = (index: number) => {
        const editedLinks = [...props.links];

        editedLinks.splice(index, 1);

        props.onChanged(editedLinks);
    }

    const onActionsCreate = (data: ExternalLinkTableModel): RowAction[] => {
        return [{
            text: resources.delete,
            iconName: "Delete",
            onClick: () => onDeleteLink(data.id)
        }];
    }

    const linksTableData: TableData<ExternalLinkTableModel> = {
        columns: [
            { name: "name", displayName: resources.label, dataType: TableColumnType.Text },
            { name: "url", displayName: resources.link, dataType: TableColumnType.Text }
        ],
        rows: props.links.flatMap((l, index) => l.targetType === LinkType.ExternalLink ? { id: index, name: l.label ?? "", url: l.targetLink ?? "" } : [])
    }

    const orderColumn: OrderColumn = {
        ascending: orderAsc,
        columnName: "name"
    };

    return (
        <>
            <Container title={resources.externalLinks} actions={[{ iconName: "Add", onClick: () => setNewLinkPopupVisible(true), text: resources.new }]}>
                <MemoryTable
                    data={linksTableData}
                    onActionsCreate={onActionsCreate}
                    onRowClick={() => { }}
                    orderColumn={orderColumn}
                    onReorder={orderColumn => { setOrderAsc(orderColumn.ascending) }}
                />
            </Container>
            <NewLinkPopup
                isOpen={newLinkPopupVisible}
                onAbort={() => setNewLinkPopupVisible(false)}
                onLinkAdded={onLinkAdded}
            />
        </>
    );
}

export default EditExternalLinks;