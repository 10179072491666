import { IPartialTheme } from "@fluentui/react"

const fluentTheme: IPartialTheme =
{
    palette: {
        themePrimary: "#02457b",
        themeLighterAlt: "#f0f5fa",
        themeLighter: "#c5d9ea",
        themeLight: "#98bbd7",
        themeTertiary: "#4881af",
        themeSecondary: "#13558a",
        themeDarkAlt: "#023e6e",
        themeDark: "#02345d",
        themeDarker: "#012645",
        neutralLighterAlt: "#faf9f8",
        neutralLighter: "#f3f2f1",
        neutralLight: "#edebe9",
        neutralQuaternaryAlt: "#e1dfdd",
        neutralQuaternary: "#d0d0d0",
        neutralTertiaryAlt: "#c8c6c4",
        neutralTertiary: "#a19f9d",
        neutralSecondary: "#605e5c",
        neutralPrimaryAlt: "#3b3a39",
        neutralPrimary: "#323130",
        neutralDark: "#201f1e",
        black: "#494847",
        white: "#ffffff"
    },
    defaultFontStyle: {
        fontFamily: "Open Sans"
    },
    fonts: {
        small: {
            fontSize: "12px",
        },
        medium: {
            fontSize: "14px",
        },
        mediumPlus: {
            fontSize: "14px",
            lineHeight: "18px",
            fontWeight: "400"
        },
        large: {
            fontSize: "20px",
            fontWeight: "semibold"
        },
        xLarge: {
            fontSize: "22px",
            fontWeight: "semibold"
        }
    }
}

export default fluentTheme;
