import { useEffect, useState } from "react";
import { useResources } from "../../../../../../improve-lib";
import { IdValuePair, ProcessGroupPermission, ProcessGroupPermissionType } from "../../../../../../model";
import PermissionMatrix from "./PermissionMatrix";

type ProcessPermissionsProps = {
    processGroupPermissions: ProcessGroupPermission[];
    processGroups: IdValuePair[];
    onProcessGroupPermissionsUpdated: (newNewPermissions: ProcessGroupPermission[]) => void;
}

const ProcessPermissions = (props: ProcessPermissionsProps): JSX.Element => {
    const resources = useResources();

    const matrixOptions = [
        { name: resources.permissionInvisible, value: ProcessGroupPermissionType.None },
        { name: resources.permissionRead, value: ProcessGroupPermissionType.Read },
        { name: resources.permissionEdit, value: ProcessGroupPermissionType.Write },
        { name: resources.create, value: ProcessGroupPermissionType.Create },
        { name: resources.permissionDelete, value: ProcessGroupPermissionType.Delete }
    ];

    const [matrixValue, setMatrixValue] = useState<{ id: number, name: string, value: ProcessGroupPermissionType }[] | null>([]);

    const onMatrixUpdate = (newMatrix: { id: number, name: string, value: ProcessGroupPermissionType }[]) => {
        props.onProcessGroupPermissionsUpdated(newMatrix.reduce(
            (filteredMatrix: { processGroupId: number, name: string, permission: ProcessGroupPermissionType }[], p) => {
                if (p.value > 0)
                    filteredMatrix.push({ processGroupId: p.id, name: p.name, permission: p.value });

                return filteredMatrix;
            }, []));
    };

    useEffect(() => {
        const permissions = [...props.processGroupPermissions];

        props.processGroups.forEach((aa: IdValuePair) => {
            if (permissions.findIndex(x => x.processGroupId === aa.id) === -1) {
                permissions.push({ processGroupId: aa.id, name: aa.value, permission: ProcessGroupPermissionType.None });
            }
        });

        permissions.sort((a, b) => a.name.localeCompare(b.name));

        setMatrixValue(permissions.map(p => { return { id: p.processGroupId, name: p.name, value: p.permission } }));
    }, [props.processGroupPermissions, props.processGroups]);

    return (
        <PermissionMatrix<ProcessGroupPermissionType>
            title={resources.processGroups}
            values={matrixValue!}
            options={matrixOptions}
            onMatrixUpdate={onMatrixUpdate}
        />
    )
}

export default ProcessPermissions;
