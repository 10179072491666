import { useEffect, useState } from "react";
import { get, useToggle } from "../../improve-lib";
import { EditUserModel, FilteredList } from "../../model";

const useNavigationUser = (id: number, orderBy: string, orderAsc: boolean, fulltext: string, records: number) => {
    const [reloadFlag, reload] = useToggle();

    const [navState, setNavState] = useState({
        currentUnit: 0,
        unitCount: 0,
        filteredUnitCount: 0,
        nextId: null as number | null,
        previousId: null as number | null
    });

    const [dbUser, setDbUser] = useState<EditUserModel>();

    useEffect(() => {
        const getNavigationUser = async () => {
            const response = await get("users/" + id + "/navigation?ascending=" + orderAsc + "&orderBy=" + orderBy + "&fulltext=" + fulltext);

            if (response.status !== 200)
                throw response;

            const user: FilteredList<EditUserModel> = response.body;
            user.entity.dateTimeCreated = new Date(user.entity.dateTimeCreated);
            user.entity.dateTimeLastEdited = new Date(user.entity.dateTimeLastEdited);

            setDbUser(user.entity);

            setNavState({
                currentUnit: user.currentNumber,
                filteredUnitCount: user.filteredCount,
                unitCount: user.fullCount,
                nextId: user.nextId,
                previousId: user.previousId
            });
        }
        void getNavigationUser();
    }, [id, fulltext, orderAsc, orderBy, records, reloadFlag]);

    return { navState, dbUser, reload }
}

export default useNavigationUser;