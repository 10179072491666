import { Container, useResources } from "../../../../../improve-lib";
import { RowAction, SelectionList } from "../../../../../lib";
import { IdValuePair } from "../../../../../model";


type ProcessGroupListProps = {
    processGroups: IdValuePair[];
    onProcessGroupIdChanged: (newId: number) => void;
    selectedProcessGroupId: number | undefined;
    onProcessGroupCreate: () => void;
}

const ProcessGroupList = (props: ProcessGroupListProps): JSX.Element => {
    const resources = useResources();

    const newAction: RowAction = { iconName: "Add", onClick: props.onProcessGroupCreate, text: resources.new };

    return (
        <Container title={resources.processGroups} actions={[newAction]}>
            <SelectionList
                items={props.processGroups}
                columnHeader={resources.processGroups}
                onSelectionChanged={props.onProcessGroupIdChanged}
                selectedItemId={props.selectedProcessGroupId}
            />
        </Container>
    );
}

export default ProcessGroupList;
