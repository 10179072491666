import { useEffect, useState } from "react";
import { useDocuments } from "../../../api";
import { Container, useQueryValue, useResources, useUserSettings } from "../../../improve-lib";
import { RowAction } from "../../../lib";
import { ApplicationAreaPermission } from "../../../model";
import useNewAction from "../../documents/documents-container/new/useNewAction";
import DocumentsList from "./list/DocumentsList";
import NewDocumentPopup from "./new/NewDocumentPopup";
import { DocumentOrderProperty } from "./search/documentOrderProperty";
import DocumentSearch, { DocumentSearchParameter } from "./search/DocumentSearch";
import DocumentsSettingsPopup from "./settings/DocumentsSettingsPopup";

type DocumentsContainerProps = {
    subsidiariesCount: number;
    applicationAreas: ApplicationAreaPermission[];
    refreshToken: boolean;
    selectedDocumentId: number | undefined;
    onSelectionChanged: (selectedDocumentId: number | undefined) => void;
}
const DocumentsContainer = (props: DocumentsContainerProps): JSX.Element => {
    const userSettings = useUserSettings();
    const resources = useResources();

    const [settingsOpen, setSettingsOpen] = useState<boolean>(false);

    const [records, setRecords] = useQueryValue("records", value => {
        if (value) {
            const number = parseInt(value);

            if (!isNaN(number))
                return number;
        }
        return 20;
    });

    const [searchOptions, setSearchOptions] = useState<DocumentSearchParameter>({ fileName: "", fileContent: "", orderAsc: true, orderBy: DocumentOrderProperty.Name, tagIds: [] });

    const { documents, reload } = useDocuments(searchOptions.orderBy, searchOptions.orderAsc, searchOptions.fileName, searchOptions.fileContent, searchOptions.tagIds, searchOptions, records);

    useEffect(() => {
        reload();
        // eslint-disable-next-line  react-hooks/exhaustive-deps
    }, [props.refreshToken]);

    const onDocumentCreated = (id: number) => {
        props.onSelectionChanged(id);
        reload();
    }

    const { newAction, newDocumentPopupVisible, newDocumentInfo, onPopupClose } = useNewAction(props.subsidiariesCount, props.applicationAreas);

    const settingsAction: RowAction = { iconName: "Settings", onClick: () => { setSettingsOpen(true) }, text: resources.settings };

    const settingsAllowed =
        userSettings.permissions.documentsEditApplicationAreas ||
        userSettings.permissions.documentsEditTags ||
        userSettings.permissions.documentsConfiguration;

    const onLoadMore = () => setRecords((records + 20).toString());

    const actions: RowAction[] = [...newAction];

    if (settingsAllowed)
        actions.push(settingsAction);

    return (
        <>
            <Container title={resources.documents} actions={actions}>
                {documents &&
                    <>
                        <DocumentSearch
                            onSearchUpdate={setSearchOptions}
                            subsidiariesCount={props.subsidiariesCount}
                            applicationAreas={props.applicationAreas}
                            filteredCount={documents.filteredCount}
                            fullCount={documents.fullCount}
                        />
                        <DocumentsList
                            items={documents.records}
                            onSelectionChanged={props.onSelectionChanged}
                            selectedItemId={props.selectedDocumentId}
                            onLoadMore={onLoadMore}
                            filteredCount={documents.filteredCount}
                        />
                        {newDocumentInfo &&
                            <NewDocumentPopup
                                isOpen={newDocumentPopupVisible}
                                subsidiariesCount={props.subsidiariesCount}
                                onAbort={onPopupClose}
                                onDocumentCreated={newDocumentId => onDocumentCreated(newDocumentId)}
                                subsidiary={newDocumentInfo.subsidiary}
                                applicationArea={newDocumentInfo.applicationArea} />

                        }
                    </>
                }
                {settingsAllowed &&
                    <DocumentsSettingsPopup
                        isOpen={settingsOpen}
                        onClose={() => { setSettingsOpen(false) }}
                    />
                }
            </Container>
        </>
    )
}

export default DocumentsContainer;
