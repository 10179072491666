import { getTheme, IconButton } from "@fluentui/react";
import { ReactNode, useState } from "react";
import "./Section.tsx.scss";

type SectionProps = {
    headerText: string;
    children: ReactNode;
    noSeparator?: boolean;
    collapsible?: boolean;
    collapsed?: boolean;
    highlighted?: "blue" | "grey";
}

const Section = (props: SectionProps): JSX.Element => {
    const [collapsed, setCollapsed] = useState(props.collapsed ?? false);

    const theme = getTheme();

    const defineBackgroundColor = () => {
        switch (props.highlighted) {
            default:
                return "";
            case "blue":
                return theme.palette.themeLighterAlt;
            case "grey":
                return "#F7F7F7";   //TODO: define global color
        }
    } 

    return (
        <div className="section-main">
            <div className={props.noSeparator ? "" : "section-spacer"} />
            <div className={"section-header"} style={{ backgroundColor: defineBackgroundColor(), borderRadius: 3, }}>
                {props.collapsible ?
                    <div onClick={() => setCollapsed(!collapsed)} style={{ cursor: "pointer" }} >
                        <IconButton iconProps={{ iconName: collapsed ? "ExploreContentSingle" : "CollapseContentSingle" }} />
                        {props.headerText}
                    </div> :
                    <></>
                }
            </div>
            <div className="section-children child-responsive-behavior" style={{ display: collapsed ? "none" : "" }}>
                {props.children}
            </div>
        </div>
    )
}

export default Section;
