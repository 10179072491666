import { IBasePickerSuggestionsProps, ITag, TagPicker } from "@fluentui/react";
import { IdValuePair } from "../../model";

type PickerProps = {
    noResultsFoundText: string;
    onTextChanged: (value: string) => Promise<IdValuePair[]>;
    onItemSelected: (item: IdValuePair) => void;
    placeholder: string;
}

const Picker = (props: PickerProps): JSX.Element => {
    const onResolveSuggestions = async (filterText: string, _tagList?: ITag[]): Promise<ITag[]> =>
        (await props.onTextChanged(filterText)).map(aci => { return { key: aci.id, name: aci.value } });

    const onItemSelected = (item?: ITag) => {
        props.onItemSelected({ id: item!.key as number, value: item!.name });
        return null;
    }

    const pickerSuggestionsProps: IBasePickerSuggestionsProps = {
        noResultsFoundText: props.noResultsFoundText,
    }

    return (
        <TagPicker
            onResolveSuggestions={onResolveSuggestions}
            onItemSelected={onItemSelected}
            pickerSuggestionsProps={pickerSuggestionsProps}
            resolveDelay={100}
            inputProps={{
                placeholder: props.placeholder
            }}
        />
    )
}

export default Picker;
