import { MenuBase, MenuGroup } from "../../lib";
import { useLabels, useResources } from "../language";
import { useUserSettings } from "../settings";

type MenuProps = {
    onAfterNavigation: () => void;
}

const Menu = (props: MenuProps): JSX.Element => {
    const userSettings = useUserSettings();
    const resources = useResources();
    const labels = useLabels();

    const inputMaskGroup: MenuGroup = {
        title: resources.inputMasks,
        links: []
    }

    if (userSettings.anyProcessGroupsAssigned ||
        userSettings.permissions.processesEditProcessGroups)
        inputMaskGroup.links.push({ text: resources.processes, url: "/process", icon: "ModelingView" });

    if (userSettings.anyApplicationAreasAssigned ||
        userSettings.permissions.documentsEditApplicationAreas ||
        userSettings.permissions.documentsEditTags ||
        userSettings.permissions.documentsConfiguration)
        inputMaskGroup.links.push({ text: resources.documents, url: "/documents", icon: "Page" });

    const openTasksGroup: MenuGroup = {
        title: resources.openTasks,
        links: []
    };

    openTasksGroup.links.push({ text: resources.openTasks, url: "/opentasks", icon: "TaskManager" });

    const settingsGroup: MenuGroup = {
        title: resources.settings,
        links: []
    }
    if (userSettings.permissions.usersAll)
        settingsGroup.links.push({ text: resources.users, url: "/settings/users", icon: "PlayerSettings" });
    if (userSettings.permissions.rolesAll)
        settingsGroup.links.push({ text: resources.roles, url: "/settings/roles", icon: "Permissions" });
    if (userSettings.permissions.subsidiariesAll)
        settingsGroup.links.push({ text: resources.subsidiaries, url: "/settings/subsidiaries", icon: "ProductVariant" });

    if (userSettings.permissions.globalData)
        settingsGroup.links.push({
            text: resources.globalData,
            links: [{
                text: labels.customer,
                url: "/settings/customers",
                icon: "ContactLink",
            },
            {
                text: labels.supplier,
                url: "/settings/suppliers",
                icon: "ContactCardSettings",
            }]
        })

    const routes: MenuGroup[] = [];
    if (inputMaskGroup.links.length > 0)
        routes.push(inputMaskGroup);
    routes.push(openTasksGroup);
    if (settingsGroup.links.length > 0)
        routes.push(settingsGroup);

    return (
        <MenuBase menuGroups={routes} onAfterNavigation={props.onAfterNavigation} />
    )
}

export default Menu;
