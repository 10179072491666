import { useEffect, useState } from "react";
import { get, useResources } from "../../improve-lib";
import { IdValuePair } from "../../model";

const useProcesses = (flag?: boolean) => {
    const [processes, setProcesses] = useState<IdValuePair[]>();

    const resources = useResources();

    useEffect(() => {
        const getProcesses = async () => {
            const response = await get("process/getAllProcesses");

            if (response.status !== 200)
                throw response;

            setProcesses(response.body as IdValuePair[]);
        }

        void getProcesses();
    }, [flag, resources.isoCode]);

    return { processes };
}

export default useProcesses