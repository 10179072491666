import { useEffect, useState } from "react";
import { get, useResources, useToggle } from "../../improve-lib";
import { IdValuePair } from "../../model";

const useTags = () => {
    const resources = useResources();

    const [reloadFlag, reload] = useToggle();

    const [tags, setTags] = useState<IdValuePair[]>();

    useEffect(() => {
        const getTags = async () => {
            const response = await get("tags");

            if (response.status !== 200)
                throw response;

            setTags(response.body as IdValuePair[]);
        }

        void getTags();
    }, [resources.isoCode, reloadFlag]);

    return { tags, reload }
}

export default useTags;