import { useResources } from "../../../../../../../improve-lib";
import { MultiLanguageTextbox,Section } from "../../../../../../../lib";
import { MultiLanguageFieldModel } from "../../../../../../../model";

type FormNewProps = {
    name: MultiLanguageFieldModel;
    onNameChange: (newName: MultiLanguageFieldModel) => void;
    duplicatedIsoCodes: string[];
}

const FormNew = (props: FormNewProps): JSX.Element => {
    const resources = useResources();

    return (
        <Section headerText={resources.label}>
            <MultiLanguageTextbox
                languageFields={props.name}
                onChange={props.onNameChange}
                duplicatedIsoCodes={props.duplicatedIsoCodes}
                textBoxHeader={resources.label + " " + resources.applicationArea}
                mandatory
            />
        </Section>
    )
}

export default FormNew;