import { useState } from "react";
import { useApplicationAreas } from "../../../../../api";
import Details from "./components/Details";
import List from "./components/List";
import New from "./components/New";

const ApplicationAreaSettings = (): JSX.Element => {
    const { applicationAreas, reload } = useApplicationAreas();

    const [selectedId, setSelectedId] = useState<number>();
    const [newApplicationArea, setNewApplicationArea] = useState(false);

    const onSelectedIdChanged = (newId: number) => setSelectedId(newId);
    const onDeleted = () => { reload(); setSelectedId(undefined); setNewApplicationArea(false); }
    const onCreate = () => { reload(); setNewApplicationArea(true); }
    const onCreateCancel = () => { setSelectedId(undefined); setNewApplicationArea(false); }
    const onCreated = (newId: number) => { reload(); setNewApplicationArea(false); setSelectedId(newId); }

    return (
        <section className="split-view-4-8">
            <div className="content-container">

                {applicationAreas && <List
                    onApplicationAreaCreate={onCreate}
                    applicationAreas={applicationAreas}
                    onApplicationAreaIdChanged={onSelectedIdChanged}
                    selectedApplicationAreaId={selectedId}
                />}
            </div>
            <div className="content-container">
                {newApplicationArea && <New onCancel={onCreateCancel} onCreated={onCreated} />}
                {!newApplicationArea && selectedId && <Details applicationAreaId={selectedId} refresh={reload} onDeleted={onDeleted} />}
            </div>
        </section>
    )
}

export default ApplicationAreaSettings;
