import { useEffect, useState } from "react";
import { useDomainName, useLogin, useLoginOverKerberos } from "../../../api";
import { format, useResources } from "../../../improve-lib";
import { Button, CheckBox, Hidden, MessageBar, PasswordBox, Separator, Stack, TextBox } from "../../../lib";
import { AuthenticationResultType } from "../../../model";

type LoginProps = {
    onSuccsessfulLogin: () => void;
    onPasswordTemporary: () => void;
}

const Login = (props: LoginProps): JSX.Element => {
    const resources = useResources();

    const [user, setUser] = useState("");
    const [password, setPassword] = useState("");
    const [rememberMe, setRememberMe] = useState(false);

    const { ssoUser, userLoaded } = useDomainName();
    const { login, errorType } = useLogin(() => {
        props.onSuccsessfulLogin();
    })
    const { loginOverKerberos } = useLoginOverKerberos(() => {
        props.onSuccsessfulLogin();
    })

    useEffect(() => {
        if (errorType === AuthenticationResultType.PasswordTemporary)
            props.onPasswordTemporary();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [errorType])

    return (
        <>
            {userLoaded && <Stack gap={15}>
                {ssoUser !== null && <>
                    <Button text={format(resources.loginAs, ssoUser)} onClick={loginOverKerberos} />
                    <Separator>{resources.or}</Separator>
                </>}
                <TextBox autoFocus placeholder label={resources.username} text={user} onChange={newUser => setUser(newUser)} onEnter={() => login(user, password, rememberMe)} />
                <PasswordBox placeholder label={resources.password} text={password} onChange={newPassword => setPassword(newPassword)} onEnter={() => login(user, password, rememberMe)} />
                <CheckBox label={resources.rememberMe} checked={rememberMe} onChange={newRememberMe => setRememberMe(newRememberMe)} onEnter={() => login(user, password, rememberMe)} />
                <Button text={resources.login} onClick={() => login(user, password, rememberMe)} />
                <Hidden visible={errorType !== AuthenticationResultType.Ok}>
                    <MessageBar type="error" message={errorType === AuthenticationResultType.InvalidCredentials ? resources.invalidLogin : resources.userInactive} />
                </Hidden>
            </Stack>}
        </>
    )
}

export default Login;
