import { useEffect, useRef } from "react";
import { ProcessElementsModel } from "../../../../../model";
import { ElementHelper } from "./elementHelper";
import useElementHelper from "./useElementHelper";

const useViewerElements = (
    dbElements: ProcessElementsModel,
    onProcessIdChanged: (id: number) => void
) => {
    const elements = useRef(dbElements);
    const { selectedElement, setSelectedElement, elementHelperBase } = useElementHelper(onProcessIdChanged);

    const getElementName = (id: string) => elementHelperBase.getElementName(elements.current[id]);

    const hasElementSubprocess = (id: string) => elementHelperBase.hasElementSubprocess(elements.current[id]);

    const getElementIcons = (id: string) => elementHelperBase.getElementIcons(elements.current[id]);

    const onElementDoubleClick = (id: string) => elementHelperBase.onElementDoubleClick(elements.current[id]);

    const onSelectionChange = (id?: string) => setSelectedElement(id ? (elements.current[id] ?? { bpmnId: id }) : undefined);

    const elementHelper = useRef<ElementHelper>({ getElementName, hasElementSubprocess, getElementIcons, onElementDoubleClick });

    useEffect(() => {
        elements.current = dbElements;
        elementHelper.current = { getElementName, hasElementSubprocess, getElementIcons, onElementDoubleClick };
        //eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dbElements]);

    return { selectedElement, onSelectionChange, elementHelper };
}

export default useViewerElements;