import { useNavigate } from "react-router-dom";
import { del, useSaveCheck } from "../../improve-lib";

const useLogout = () => {
    const navigate = useNavigate()

    const logout = useSaveCheck(async () => {
        const response = await del("authentication", undefined);

        if (response.status !== 200)
            throw response;

        navigate("/login", { replace: true });
    }, true);

    return { logout }
}

export default useLogout;