import { useEffect, useState } from "react";
import { useRegisterSaveCheck } from "../../../improve-lib";
import { CustomFieldModel } from "../../../model";
import FieldBase from "./fieldBase";
import { createField } from "./fieldHelper";

export const useFields = (fieldModels: CustomFieldModel[]) => { 
    const [fields, setFields] = useState<FieldBase[]>([]);
    const [fieldsChanged, setFieldsChanged] = useState(false);

    useRegisterSaveCheck(fieldsChanged);

    useEffect(() => {
        setFields(fieldModels.map(f => createField(f)));
    }, [fieldModels]);

    useEffect(() => {
        setFieldsChanged(fields.some(f => f.hasChange()));
    }, [fields]);

    const updateField = (newField: FieldBase) => {
        const newFields = [...fields];
        newFields[newFields.findIndex(f => f.id === newField.id)] = newField;
        setFields(newFields);
    }

    return {
        fields,
        setFields,
        updateField,
        fieldsChanged
    };
}