import { useContext } from "react";
import { useNavigate } from "react-router-dom";
import { SaveCheckContext } from "./SaveCheckContext";

const useUnsafeNavigate = () => {
    const saveCheck = useContext(SaveCheckContext);
    const navigate = useNavigate();

    return (to: string) => {
        saveCheck.optOut(() => navigate(to));
    }
}

export default useUnsafeNavigate;
