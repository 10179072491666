import { useEffect, useState } from "react";
import { get } from "../../improve-lib";
import { ProcessSubprocess } from "../../model";

const useProcessSubprocess = (flag?: boolean) => {
    const [processSubprocess, setProcessSubprocess] = useState<ProcessSubprocess[]>();

    useEffect(() => {
        const getProcessSubprocess = async () => {
            const response = await get("process/getProcessSubprocess");

            if (response.status !== 200)
                throw response;

            setProcessSubprocess(response.body as ProcessSubprocess[]);
        }
        void getProcessSubprocess();
    }, [flag]);

    return { processSubprocess };
}

export default useProcessSubprocess;