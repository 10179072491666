import { Stack as FluentStack } from "@fluentui/react";
import { ReactNode } from "react";

type StackProps = {
    gap: number;
    children: ReactNode;
}

const Stack = (props: StackProps): JSX.Element => {
    return (
        <FluentStack tokens={{ childrenGap: props.gap }} >
            {props.children}
        </FluentStack >
    )
}

export default Stack;
