import { CheckBox, ChildWithLabel } from "../../../lib";
import { CustomFieldModel, FieldType } from "../../../model";
import FieldBase from "./fieldBase";

class BooleanField extends FieldBase {
    private value: boolean | null;
    private readonly dbValue: boolean | null;

    readonly fieldType: FieldType = FieldType.Boolean;

    constructor(id: number, name: string, mandatory: boolean, value: boolean | null) {
        super(id, name, mandatory);
        this.dbValue = value;
        this.value = value;
    }

    isValid() {
        if (this.mandatory)
            return this.value ?? false;
        else
            return true;
    }

    hasChange() { return this.value !== this.dbValue; }

    getModel(): CustomFieldModel {
        return {
            id: this.id,
            fieldType: this.fieldType,
            settings: null,
            name: this.name,
            mandatory: this.mandatory,
            boolean: this.value,
            date: null,
            number:  null,
            selection:  null,
            text: null
        };
    }

    getEditControl(onChange: (newField: FieldBase) => void) {
        return (
            //<div style={{ display: "flex", height: "100%", alignItems: "center", paddingTop: 25 }}>
            <ChildWithLabel label={this.name}>
                <div style={{ paddingTop: 10 }}>
                    <CheckBox
                        label={""}
                        checked={this.value ?? false}
                        mandatory={this.mandatory}
                        onChange={newValue => { this.value = newValue; onChange(this); }}
                    />
                </div>
            </ChildWithLabel>
            //</div>
        );
    }
}



export default BooleanField;