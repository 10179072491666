import { put, useSaveCallout } from "../../improve-lib";
import { CustomFieldModel, FieldType, FieldUpdateModel, ProcessElementModel, ProcessElementsModel, ProcessElementsUpdateModel, ProcessUpdateModel, UpdateType } from "../../model";

const useUpdateProcess = (afterSuccessfulSave?: () => void) => {
    const saveCallout = useSaveCallout();

    const hasElementChanged = (editedElement: ProcessElementModel, dbElement: ProcessElementModel) => {
        if (editedElement.subprocess?.id !== dbElement.subprocess?.id)
            return true;

        if (dbElement.label && editedElement.label) {
            for (let i = 0; i < dbElement.label.length; i++)
                if (dbElement.label[i].label !== editedElement.label.find(n => n.isoCode === dbElement.label![i].isoCode)!.label)
                    return true;
        }
        else if (editedElement.label)
            return true;

        if (dbElement.description && editedElement.description) {
            for (const isoCode in dbElement.description)
                if (dbElement.description[isoCode] !== editedElement.description[isoCode])
                    return true;
        }
        else if (editedElement.description)
            return true;

        if (dbElement.links) {
            if (dbElement.links.length !== editedElement.links?.length)
                return true;

            for (let i = 0; i < dbElement.links.length; i++) {
                if (dbElement.links[i].label !== editedElement.links[i].label)
                    return true;

                if (dbElement.links[i].targetLink !== editedElement.links[i].targetLink)
                    return true;
            }
        }

        return false;
    }

    const getChangedElements = (editedElements: ProcessElementsModel, dbElements: ProcessElementsModel) => {
        const elements: ProcessElementsUpdateModel = {};

        for (const bpmnId in dbElements) {
            if (!editedElements[bpmnId])
                elements[bpmnId] = { type: UpdateType.delete };
            else if (hasElementChanged(editedElements[bpmnId], dbElements[bpmnId])) {
                const updatedElement = editedElements[bpmnId];
                elements[bpmnId] = {
                    type: UpdateType.update,
                    name: updatedElement.label?.some(n => n.label.trim().length > 0) ? updatedElement.label : undefined,
                    subprocessId: (updatedElement.subprocess && updatedElement.subprocess.id > 0) ? updatedElement.subprocess.id : undefined,
                    description: updatedElement.description && Object.values(updatedElement.description).some(d => d.trim().length > 0) ? updatedElement.description : undefined,
                    links: updatedElement.links && updatedElement.links.length > 0 ? updatedElement.links : undefined
                };
            }
        }

        for (const bpmnId in editedElements) {
            if (!dbElements[bpmnId]) {
                const newElement = editedElements[bpmnId];

                elements[bpmnId] = {
                    type: UpdateType.insert,
                    name: newElement.label?.some(n => n.label.trim().length > 0) ? newElement.label : undefined,
                    subprocessId: (newElement.subprocess && newElement.subprocess.id > 0) ? newElement.subprocess.id : undefined,
                    description: newElement.description && Object.values(newElement.description).some(d => d.trim().length > 0) ? newElement.description : undefined,
                    links: newElement.links && newElement.links.length > 0 ? newElement.links : undefined
                };
            }
        }

        return elements;
    }

    const getChangedFields = (editedFields?: CustomFieldModel[], dbFields?: CustomFieldModel[]) => {
        const fields: FieldUpdateModel[] = [];
        if (!editedFields || !dbFields)
            return undefined;

        for (const dbField of dbFields) {
            const field = editedFields.find(f => f.id === dbField.id);

            if (field) {
                switch (dbField.fieldType) {
                    case FieldType.Boolean:
                        if (field.boolean !== null) {
                            if (dbField.boolean === null)
                                fields.push({ id: field.id, fieldType: FieldType.Boolean, updateType: UpdateType.insert, boolean: field.boolean });
                            else if (dbField.boolean !== field.boolean)
                                fields.push({ id: field.id, fieldType: FieldType.Boolean, updateType: UpdateType.update, boolean: field.boolean });
                        }
                        else if (dbField.boolean !== null) {
                            fields.push({ id: field.id, fieldType: FieldType.Boolean, updateType: UpdateType.delete });
                        }
                        break;
                    case FieldType.Date:
                        if (field.date) {
                            if (!dbField.date)
                                fields.push({ id: field.id, fieldType: FieldType.Date, updateType: UpdateType.insert, date: field.date });
                            else if (dbField.date !== field.date)
                                fields.push({ id: field.id, fieldType: FieldType.Date, updateType: UpdateType.update, date: field.date });
                        }
                        else if (dbField.date) {
                            fields.push({ id: field.id, fieldType: FieldType.Date, updateType: UpdateType.delete });
                        }
                        break;
                    case FieldType.Number:
                        if (field.number !== null) {
                            if (dbField.number === null)
                                fields.push({ id: field.id, fieldType: FieldType.Number, updateType: UpdateType.insert, number: field.number });
                            else if (dbField.number !== field.number)
                                fields.push({ id: field.id, fieldType: FieldType.Number, updateType: UpdateType.update, number: field.number });
                        }
                        else if (dbField.number !== null) {
                            fields.push({ id: field.id, fieldType: FieldType.Number, updateType: UpdateType.delete });
                        }
                        break;
                    case FieldType.Selection:
                        if (field.selection && field.selection.id > 0) {
                            if (!dbField.selection || dbField.selection.id < 1)
                                fields.push({ id: field.id, fieldType: FieldType.Selection, updateType: UpdateType.insert, selection: field.selection });
                            else if (dbField.selection.id !== field.selection.id)
                                fields.push({ id: field.id, fieldType: FieldType.Selection, updateType: UpdateType.update, selection: field.selection });
                        }
                        else if (dbField.selection && dbField.selection.id > 0) {
                            fields.push({ id: field.id, fieldType: FieldType.Selection, updateType: UpdateType.delete });
                        }
                        break;
                    case FieldType.Text:
                        if (field.text && field.text.trim().length > 0) {
                            if (!dbField.text || dbField.text.trim().length < 1)
                                fields.push({ id: field.id, fieldType: FieldType.Text, updateType: UpdateType.insert, text: field.text });
                            else if (dbField.text !== field.text)
                                fields.push({ id: field.id, fieldType: FieldType.Text, updateType: UpdateType.update, text: field.text });
                        }
                        else if (dbField.text && dbField.text.trim().length > 0) {
                            fields.push({ id: field.id, fieldType: FieldType.Text, updateType: UpdateType.delete });
                        }
                        break;
                    default:
                        throw new Error();
                }
            }
        }

        return fields;
    }

    const updateProcess = async (id: number, editedProcess: ProcessUpdateModel) => {
        const response = await put("process/" + id, editedProcess);
        if (response.status !== 200)
            throw response;

        if (afterSuccessfulSave)
            afterSuccessfulSave();

        saveCallout.show();
    }

    return { updateProcess, getChangedElements, getChangedFields }
}

export default useUpdateProcess;