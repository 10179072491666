import { useEffect, useState } from "react";
import { get, useToggle } from "../../improve-lib";
import { EditSubsidiaryModel, FilteredList } from "../../model";

const useSubsidiary = (id: number, orderAsc: boolean) => {
    const [reloadFlag, reload] = useToggle();

    const [navSubsidiary, setNavSubsidiary] = useState<FilteredList<EditSubsidiaryModel>>();

    useEffect(() => {
        const getSubsidiary = async () => {
            const response = await get("subsidiaries/" + id + "?orderNameAsc=" + orderAsc);

            if (response.status !== 200)
                throw response;

            const subsidiary: FilteredList<EditSubsidiaryModel> = response.body;
            subsidiary.entity.dateTimeCreated = new Date(subsidiary.entity.dateTimeCreated);
            subsidiary.entity.dateTimeLastEdited = new Date(subsidiary.entity.dateTimeLastEdited);
            setNavSubsidiary(subsidiary);
        }

        void getSubsidiary();
    }, [id, reloadFlag, orderAsc]);

    return { navSubsidiary, reload }
}

export default useSubsidiary;