import { Callout as FluentCallout, Icon } from "@fluentui/react";
import "./Callout.tsx.scss";

type CalloutProps = {
    iconName: string;
    message: string;
    messageColor: string;
    containerStyle: { opacity: number, transition: string, visibility: string };
}

const Callout = (props: CalloutProps): JSX.Element => {
    return (
        <>
            <FluentCallout
                coverTarget={false}
                isBeakVisible={false}
                styles={{
                    root: {
                        position: "fixed",
                        top: "unset !important",
                        left: "unset !important",
                        bottom: 15,
                        right: 20,
                        width: 320,
                    },
                    container: {
                        opacity: props.containerStyle.opacity,
                        transition: props.containerStyle.transition,
                        visibility: props.containerStyle.visibility
                    }
                }}
                setInitialFocus>
                <div className={"callout-root"}>
                    <div className={"callout-icon"}>
                        <Icon
                            iconName={props.iconName}
                            style={{ color: props.messageColor }}
                        />
                    </div>
                    <div className={"callout-text"} style={{ color: props.messageColor }}>
                        {props.message}
                    </div>
                </div>
            </FluentCallout>
        </>
    )
}

export default Callout;
