import { Checkbox } from "@fluentui/react";
import "./CheckBox.tsx.scss";

type CheckBoxProps = {
    label: string;
    indeterminate?: boolean;
    checked: boolean;
    onChange: (newValue: boolean) => void;
    onEnter?: () => void;
    disabled?: boolean;
    mandatory?: boolean;
}

const CheckBox = (props: CheckBoxProps): JSX.Element => {
    const onChange = (_ev?: React.FormEvent, checked?: boolean) => {
        if (checked)
            props.onChange(checked);
        else
            props.onChange(false);
    }

    const onKeyDown = (event: React.KeyboardEvent) => {
        if (event.keyCode === 13 && props.onEnter)
            props.onEnter();
    }

    return (
        // Checkbox unterstütz keydown nicht by design => https://github.com/OfficeDev/@fluentui/react/issues/7918 
        <div onKeyDown={onKeyDown} >
            <Checkbox
                label={props.label}
                checked={props.checked}
                onChange={onChange}
                styles={{ checkbox: props.mandatory && !props.checked ? "mandatory-checkbox" : "" }}
                disabled={props.disabled}
                indeterminate={props.indeterminate}
            />
        </div>
    )
}

export default CheckBox;
