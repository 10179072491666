import { useLocation, useNavigate } from "react-router-dom";
import { useDeleteSubsidiary, useSubsidiaries } from "../../api";
import { Container, useQueryValue, useResources } from "../../improve-lib";
import { RowAction } from "../../lib";
import SubsidiaryTable from "./components/SubsidiaryTable";

const SubsidiariesPage = (): JSX.Element => {
    const navigate = useNavigate();
    const location = useLocation();

    const resources = useResources();

    const [orderAsc, setOrderAsc] = useQueryValue("orderAsc", value => value === undefined ? true : value === "true");

    const { subsidiaries, reload } = useSubsidiaries();
    const { deleteSubsidiary } = useDeleteSubsidiary(() => {
        reload();
    });

    const onSubsidiaryClick = (id: number) => navigate("/settings/subsidiaries/" + id + location.search);

    const onCreateClick = () => navigate("/settings/subsidiaries/new");

    const onSubsidiariesSorted = (orderNameAsc: boolean) => setOrderAsc(orderNameAsc ? "true" : "false");

    const createAction: RowAction = { iconName: "CircleAddition", onClick: onCreateClick, text: resources.subsidiaryCreate };

    return (
        <section>
            <div className="content-container">
            <Container title={resources.subsidiaries} actions={[createAction]}>
                {subsidiaries ?
                    <SubsidiaryTable
                        onDeleteSubsidiary={deleteSubsidiary}
                        orderNameAsc={orderAsc}
                        onSubsidiaryClick={onSubsidiaryClick}
                        subsidiaries={subsidiaries}
                        onSorting={onSubsidiariesSorted}
                    />
                    : null}
                </Container>
                </div>
        </section>
    )
}

export default SubsidiariesPage;
