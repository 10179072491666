import { useResources } from "../../../../../../improve-lib";
import { CheckBoxList, CheckedListType } from "../../../../../../lib";
import { Permission } from "../../../../../../model";

type DocumentPermissionProps = {
    permission: Permission;
    onPermissionChanged: (newPermission: Permission) => void;
}

const DocumentPermissions = (props: DocumentPermissionProps): JSX.Element => {
    const resources = useResources();

    const permissionsList: CheckedListType[] = [
        { id: 0, text: resources.manageApplicationAreas, checked: props.permission.documentsEditApplicationAreas },
        { id: 1, text: resources.manageTags, checked: props.permission.documentsEditTags },
        { id: 2, text: resources.labels, checked: props.permission.documentsConfiguration }
    ];

    const oncheckedChanged = (id: number, newValue: boolean) => {
        const newPermission = { ...props.permission };
        switch (id) {
            case 0:
                newPermission.documentsEditApplicationAreas = newValue;
                break;
            case 1:
                newPermission.documentsEditTags = newValue;
                break;
            case 2:
                newPermission.documentsConfiguration = newValue;
                break;
        }

        props.onPermissionChanged(newPermission);
    }

    return (
            <CheckBoxList items={permissionsList} onCheckedChanged={oncheckedChanged}></CheckBoxList>
    )
}

export default DocumentPermissions;
