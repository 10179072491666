import { useState } from "react";
import { useDocuments } from "../../../../api";
import { Container, useQueryValue, useResources } from "../../../../improve-lib";
import { MemoryTable, OrderColumn, RowAction, TableColumnType, TableData } from "../../../../lib";
import { LinkModel, LinkType } from "../../../../model";
import DocumentsList from "../../../documents/documents-container/list/DocumentsList";
import { DocumentOrderProperty } from "../../../documents/documents-container/search/documentOrderProperty";
import DocumentSearch, { DocumentSearchParameter } from "../../../documents/documents-container/search/DocumentSearch";


type EditDocumentLinksProps = {
    links: LinkModel[];
    sourceId?: number;
    sourceType: LinkType;
    onChanged: (links: LinkModel[]) => void;
}

type DocumentLinkTableModel = {
    id: number;
    name: string;
}

const EditDocumentLinks = (props: EditDocumentLinksProps): JSX.Element => {
    const resources = useResources();

    const [orderAsc, setOrderAsc] = useState(true);

    const [searchOptions, setSearchOptions] = useState<DocumentSearchParameter>({ fileName: "", fileContent: "", orderAsc: true, orderBy: DocumentOrderProperty.Name, tagIds: [] });

    const [records, setRecords] = useQueryValue("records", value => {
        if (value) {
            const number = parseInt(value);

            if (!isNaN(number))
                return number;
        }
        return 20;
    });

    const { documents } = useDocuments(searchOptions.orderBy, searchOptions.orderAsc, searchOptions.fileName, searchOptions.fileContent, searchOptions.tagIds, searchOptions, records);

    const onDocumentLinkAdded = (documentId: number) => {
        const document = documents?.records.find(d => d.id === documentId);
        if (!props.links.some(l => l.targetId === documentId) && !document?.locked) {
            const editedLinks =
                [...props.links,
                {
                    sourceId: props.sourceId,
                    sourceType: props.sourceType,
                    targetId: documentId,
                    targetType: LinkType.Documents,
                    label: documents?.records.find(d => d.id === documentId)?.filename ?? ""
                }
                ];
            props.onChanged(editedLinks);
        }
    }

    const onDeleteLink = (index: number) => {
        const editedLinks = [...props.links];

        editedLinks.splice(index, 1);

        props.onChanged(editedLinks);
    }

    const onActionsCreate = (data: DocumentLinkTableModel): RowAction[] => {
        return [{
            text: resources.delete,
            iconName: "Delete",
            onClick: () => onDeleteLink(data.id)
        }];
    }

    const linksTableData: TableData<DocumentLinkTableModel> = {
        columns: [{ name: "name", displayName: resources.label, dataType: TableColumnType.Text }],
        rows: props.links.flatMap((l, index) => l.targetType === LinkType.Documents ? { id: index, name: l.label ?? "" } : []) ?? []
    }

    const orderColumn: OrderColumn = {
        ascending: orderAsc,
        columnName: "name"
    };

    const onLoadMore = () => setRecords((records + 20).toString());

    return (
        <>
            {documents &&
                <Container title={resources.documentSearchSelection}>
                    <DocumentSearch
                        onSearchUpdate={setSearchOptions}
                        subsidiariesCount={1}
                        applicationAreas={[]}
                        filteredCount={documents.filteredCount}
                        fullCount={documents.fullCount}
                    />
                    <DocumentsList
                        items={documents.records.map(r => { return { ...r, canBeViewed: true } })}
                        onSelectionChanged={onDocumentLinkAdded}
                        onLoadMore={onLoadMore}
                        filteredCount={documents.filteredCount}
                    />
                </Container>
            }

            <Container title={resources.linkedDocuments}>
                <MemoryTable
                    data={linksTableData}
                    onActionsCreate={onActionsCreate}
                    onRowClick={() => { }}
                    orderColumn={orderColumn}
                    onReorder={orderColumn => { setOrderAsc(orderColumn.ascending) }}
                />
            </Container>

        </>
    );
}

export default EditDocumentLinks;