import { useContext, useEffect } from "react";
import { SaveCheckContext } from "./SaveCheckContext";

const useRegisterSaveCheck = (hasChanges: boolean) => {
    const handle = useContext(SaveCheckContext);

    useEffect(() => {
        if (hasChanges) {
            handle.add();
            return handle.remove;
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [hasChanges]);

    return handle.hasChanges;
}

export default useRegisterSaveCheck;
