import { useCallback, useState } from "react";
import { useSubsidiariesList } from "../../../api";
import { CheckBox, CheckBoxList, MultiSelectList, TableColumnType, TableData } from "../../../lib";
import { SubsidiarySettingModel } from "../../../model";
import { useResources } from "../../language";

type SubsidiarySetting = {
    id: number;
    name: string;
    visible: string;
    mandatory: string;
}

enum CheckBoxType {
    Visible = 0,
    Mandatory
}

type SubsidiarySettingsProps = {
    subsidiarySettings: SubsidiarySettingModel[];
    onChange: (value: SubsidiarySettingModel[]) => void;
}

const SubsidiarySettings = (props: SubsidiarySettingsProps): JSX.Element => {
    const resources = useResources();

    const { subsidiaries } = useSubsidiariesList();

    const [selectedSubsidiaries, setSelectedSubsidiaries] = useState<number[]>([]);

    const subsidiarySettingsTable = useCallback(() => {
        const subsidiaryData: TableData<SubsidiarySetting> = {
            columns: [
                { name: "name", displayName: resources.subsidiary, dataType: TableColumnType.Text },
                { name: "visible", displayName: resources.visible, dataType: TableColumnType.Text },
                { name: "mandatory", displayName: resources.mandatory, dataType: TableColumnType.Text }
            ],
            rows:
                subsidiaries.map(s => {
                    const subsidiarySettings = props.subsidiarySettings.find(ss => ss.subsidiaryId === s.id);
                    return ({
                        id: s.id,
                        name: s.value,
                        visible: subsidiarySettings?.visible ? resources.yes : resources.no,
                        mandatory: subsidiarySettings?.mandatory ? resources.yes : resources.no
                    });
                })
        };
        return subsidiaryData;
    }, [props.subsidiarySettings, subsidiaries, resources]);

    const onSubsidiarySelectionChanged = (items: SubsidiarySetting[]) => {
        setSelectedSubsidiaries(items.map(item => item.id));
    }

    const getVisibleMandatoryValue = () => {
        const filteredSubsidiaries = props.subsidiarySettings.filter(s => selectedSubsidiaries.includes(s.subsidiaryId));
        return [
            {
                id: CheckBoxType.Visible,
                text: resources.visible,
                checked: filteredSubsidiaries.every(s => s.visible),
                indeterminate: filteredSubsidiaries.some(s => s.visible !== filteredSubsidiaries[0].visible)
            },
            {
                id: CheckBoxType.Mandatory,
                text: resources.mandatory,
                checked: filteredSubsidiaries.every(s => s.mandatory),
                indeterminate: filteredSubsidiaries.some(s => s.mandatory !== filteredSubsidiaries[0].mandatory)
            }
        ];
    }

    const checkedChanged = (id: number, newValue: boolean) => {
        let newSubsidiarySettings: SubsidiarySettingModel[] = [];

        switch (id) {
            case CheckBoxType.Visible:
                newSubsidiarySettings = props.subsidiarySettings.map(s => {
                    if (selectedSubsidiaries.includes(s.subsidiaryId))
                        return ({ ...s, visible: newValue });
                    else
                        return s;
                });
                break;
            case CheckBoxType.Mandatory:
                newSubsidiarySettings = props.subsidiarySettings.map(s => {
                    if (selectedSubsidiaries.includes(s.subsidiaryId))
                        return ({ ...s, mandatory: newValue });
                    else
                        return s;
                });
                break;
        }

        props.onChange(newSubsidiarySettings);
    }

    const onMandatoryCheckedChanged = (newValue: boolean) => {
        const newSubsidiarySettings = [...props.subsidiarySettings];

        newSubsidiarySettings[0].mandatory = newValue;

        props.onChange(newSubsidiarySettings);
    }

    return (
        <>
            {props.subsidiarySettings.length > 1 ?
                <>
                    <MultiSelectList
                        data={subsidiarySettingsTable()}
                        selectedItems={onSubsidiarySelectionChanged}
                    />
                    {selectedSubsidiaries.length > 0 &&
                        <div style={{ marginLeft: "20px", marginTop: "60px" }}>
                            <CheckBoxList
                                items={getVisibleMandatoryValue()}
                                onCheckedChanged={checkedChanged}
                            />
                        </div>
                    }
                </>
                :
                <CheckBox
                    label={resources.mandatory}
                    checked={props.subsidiarySettings[0].mandatory}
                    onChange={onMandatoryCheckedChanged}
                />
            }
        </>
    );
}

export default SubsidiarySettings;