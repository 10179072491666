import { useEffect, useState } from "react";
import { get, useResources, useToggle } from "../../improve-lib";
import { DocumentHistoryModel, DocumentModel, IBlob } from "../../model";

const useHistoryDocuments = (id: number) => {
    const [reloadFlag, reload] = useToggle();

    const resources = useResources();

    const [history, setHistory] = useState<DocumentModel[]>();

    useEffect(() => {
        const getHistory = async () => {
            const response = await get("documents/" + id + "/history");

            if (response.status !== 200)
                throw response;

            const elements: DocumentHistoryModel[] = response.body;

            for (let i = 0; i < elements.length; i++) {
                elements[i].dateTimeCreated = new Date(elements[i].dateTimeCreated);
                elements[i].dateTimeLastEdited = new Date(elements[i].dateTimeLastEdited);
                elements[i].dateValidUntil = elements[i].dateValidUntil ? new Date(elements[i].dateValidUntil!) : null;

                //TODO: should not be needed...
                for (let j = 0; j < elements[i].tags.length; j++) {
                    const argb: number = elements[i].tags[j].color as any;

                    const a = (argb >> 24) & 0xff;
                    const r = (argb >> 16) & 0xff;
                    const g = (argb >> 8) & 0xff;
                    const b = argb & 0xff;

                    elements[i].tags[j].color = { a, r, g, b }
                }
            }

            setHistory(elements.map(record => {
                return {
                    ...record,
                    file: undefined as unknown as IBlob,// TODO: Hack
                    pdf: undefined,// TODO: Hack
                    filename: record.documentName,
                    locked: false, // TODO: Temp
                    userLocked: null, // TODO: Temp
                    dateTimeLocked: null, // TODO: Temp
                    versionCount: undefined!,
                    applicationArea: { id: record.applicationArea.id, value: record.applicationArea.labels.find(a => a.isoCode === resources.isoCode)!.label },
                    tags: record.tags.map(tag => {
                        return {
                            id: tag.id,
                            name: tag.labels.find(l => l.isoCode === resources.isoCode)!.label,
                            color: tag.color,
                        }
                    })
                };
            }));
        }

        void getHistory();
    }, [id, resources.isoCode, reloadFlag]);

    return { history, reload }
}

export default useHistoryDocuments;