import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useDeleteSubsidiary, useSubsidiary, useUpdateSubsidiary } from "../../api";
import { Container, useCopy, useQueryValue, useResources } from "../../improve-lib";
import { NavigationHeader, RowAction } from "../../lib";
import SubsidiaryForm from "./components/SubsidiaryForm";

const EditSubsidiaryPage = () => {
    const { id } = useParams();
    const location = useLocation();
    const navigate = useNavigate()
    const resources = useResources();

    const [orderAsc] = useQueryValue("orderAsc", value => value === "true");
    const { navSubsidiary, reload } = useSubsidiary(parseInt(id!), orderAsc);
    const [name, setName, nameChanged] = useCopy(navSubsidiary?.entity.name);
    const { deleteSubsidiary } = useDeleteSubsidiary(() => {
        navigate("/settings/subsidiaries");
    });
    const { nameDuplicated, updateSubsidiary } = useUpdateSubsidiary(() => {
        reload();
    })

    const navigateUp = () => navigate("/settings/subsidiaries/" + navSubsidiary!.previousId + location.search);

    const navigateDown = () => navigate("/settings/subsidiaries/" + navSubsidiary!.nextId + location.search);

    const navigation = navSubsidiary ? {
        onNavigateUp: navigateUp,
        upAvailable: navSubsidiary?.previousId !== null,
        onNavigateDown: navigateDown,
        downAvailable: navSubsidiary?.nextId !== null,
        unitCount: navSubsidiary.fullCount,
        currentUnit: navSubsidiary.currentNumber,
        filteredUnitCount: navSubsidiary.filteredCount
    } : {
        onNavigateUp: navigateUp, onNavigateDown: navigateDown, unitCount: 0, currentUnit: 0, filteredUnitCount: 0, upAvailable: false, downAvailable: false
    };

    const navigateBack = () => navigate("/settings/subsidiaries" + location.search);

    const deleteAction: RowAction = {
        onClick: () => deleteSubsidiary(parseInt(id!), navSubsidiary?.entity.name ?? ""),
        iconName: "Delete",
        text: resources.delete
    };

    if (!navSubsidiary)
        return null;

    const onUpdateSubsidiary = async () => {
        await updateSubsidiary(parseInt(id!), { ...navSubsidiary.entity, name: name! })
    }

    return (
        <>
            <NavigationHeader unitNavigation={navigation} backLabel={resources.backToSubsidiaries} onNavigateBack={navigateBack} />
            <section>
                <div className="content-container">
                    <Container onSave={onUpdateSubsidiary} saveDisabled={!nameChanged} title={resources.subsidiaryEdit} actions={[deleteAction]}>
                        <SubsidiaryForm
                            subsidiary={{ ...navSubsidiary, entity: { ...navSubsidiary.entity, name: name ?? "" } }}
                            alterSubsidiary={setName}
                            nameDuplicated={nameDuplicated}
                        />
                    </Container>
                </div>
            </section>
        </>
    )
}

export default EditSubsidiaryPage;
