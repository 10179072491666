import { createRef, useState } from "react";
import { useResources } from "../../../../../improve-lib";
import { Button, DropDown, TextBox } from "../../../../../lib";

type FileSelectorProps = {
    imageSource: string;
    onFileChanged: (source: string) => void;
}

const FileSelector = (props: FileSelectorProps): JSX.Element => {
    const resources = useResources();

    const fileUploadRef = createRef<HTMLInputElement>();

    const [filename, setFilename] = useState("");

    const getFileSelectorType = () => {
        let url;
        try {
            if (props.imageSource !== "")
                url = new URL(props.imageSource);
        } catch {
            return 1;
        }

        return url?.protocol.startsWith("http") ? 2 : 1;
    }

    const [selectedType, setSelectedType] = useState(getFileSelectorType());

    const fileSelectorTypes = [{ id: 1, value: resources.file }, { id: 2, value: "URL" }];
    const acceptedExtensions = [".bmp", ".dip", ".exif", ".gif", ".ico", ".jfif", ".jpe", ".jpeg", ".jpg", ".png", ".rle", ".svg" ];

    const clickBrowse = () => {
        if (fileUploadRef.current) {
            fileUploadRef.current.click()
        }
    }

    const fileToDataURL = (file: File) => {
        return new Promise(resolve => {
            const reader = new FileReader();
            reader.onloadend = function () {
                resolve(reader.result);
            };
            reader.readAsDataURL(file);
        });
    }

    const onFileChanged = async (event: React.ChangeEvent<HTMLInputElement>) => {
        const file = event.target.files![0];

        if (file && acceptedExtensions.some(ext => file.name.toLowerCase().endsWith(ext))) {
            setFilename(file.name);
            const imageSource = await fileToDataURL(file) as string;
            props.onFileChanged(imageSource);
        }
    }

    const onSelectedTypeChange = (id: number) => {
        props.onFileChanged("");
        setSelectedType(id);
    }

    const urlIsValid = () => {
        try {
            if (props.imageSource !== "")
                new URL(props.imageSource);
        } catch {
            return false;
        }

        return true;
    }

    const getFileSelectorComponent = () => {
        switch (selectedType) {
            case 1:
                return (
                    <div style={{ display: "flex", alignItems: "flex-end" }}>
                        <Button secondary text={resources.browse} onClick={clickBrowse} />
                        <div style={{ display: "none" }}>
                            <input ref={fileUploadRef} type="file" accept={acceptedExtensions.join()} onChange={onFileChanged} />
                        </div>
                        <div style={{ flex: 1, marginLeft: 3 }}>
                            <TextBox
                                label={""}
                                text={filename}
                                placeholderText={resources.selectImage}
                                onChange={() => { }}
                                readOnly
                            />
                        </div>
                    </div>
                );
            case 2:
                return (
                    <TextBox
                        label={""}
                        text={props.imageSource}
                        onChange={props.onFileChanged}
                        valid={urlIsValid()}
                    />
                );
        }
    }

    return (
        <div style={{ display: "flex", alignItems: "flex-end", marginBottom: 20 }}>
            <div style={{ marginTop: 10, marginRight: 10, flex: 0.2 }}>
                <DropDown
                    label={""}
                    placeholder={""}
                    selectedId={selectedType}
                    onChange={item => onSelectedTypeChange(item.id)}
                    options={fileSelectorTypes}
                />
            </div>
            <div style={{ flex: 1 }}>
                {getFileSelectorComponent()}
            </div>
        </div>
    )
}

export default FileSelector;
