import { useEffect, useState } from "react";
import { get, useResources, useToggle, useUserSettings } from "../../improve-lib";
import { DocumentTableModel, FetchedTableData } from "../../model";
import { DocumentSearchParameter } from "../../page/documents/documents-container/search/DocumentSearch";

const useDocuments = (orderBy: string, orderAsc: boolean, filename: string, fileContent: string, tagIds: number[], searchOptions: DocumentSearchParameter, records: number) => {
    const userSettings = useUserSettings();

    const resources = useResources();

    const [reloadFlag, reload] = useToggle();

    const [documents, setDocuments] = useState<FetchedTableData<DocumentTableModel>>();

    useEffect(() => {
        const getDocuments = async () => {
            let urlParameters = "records=" + records + "&orderBy=" + orderBy + "&ascending=" + orderAsc;

            if (filename)
                urlParameters += ("&fileName=" + filename);

            if (fileContent)
                urlParameters += ("&fileContent=" + fileContent);

            if (tagIds.length > 0)
                urlParameters += "&tagIds=" + tagIds.join("&tagIds=");

            const response = await get("documents?" + urlParameters);

            if (response.status !== 200)
                throw response;

            const data: FetchedTableData<DocumentTableModel> = response.body;

            for (let i = 0; i < data.records.length; i++)
                data.records[i].dateTimeLastEdited = new Date(data.records[i].dateTimeLastEdited);

            setDocuments(data);
        }
        if (userSettings.anyApplicationAreasAssigned)
            void getDocuments();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [searchOptions, userSettings.anyApplicationAreasAssigned, resources.isoCode, reloadFlag, records]);

    return { documents, reload }
}

export default useDocuments;
