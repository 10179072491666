import { useResources } from "../../../../../improve-lib";
import { ChildWithLabel } from "../../../../../lib";
import { Permission } from "../../../../../model";
import AdministrationPermissions from "./components/AdministrationPermissions";
import DocumentPermissions from "./components/DocumentPermissions";
import ProcessPermissions from "./components/ProcessPermissions";

type ConfigurationPermissionsProps = {
    permission: Permission;
    alterPermission: (newPermission: Permission) => void;
}

const ConfigurationPermissions = (props: ConfigurationPermissionsProps): JSX.Element => {
    const resources = useResources();

    return (
        <>
            <ChildWithLabel label={resources.general} >
                <AdministrationPermissions permission={props.permission} alterPermission={props.alterPermission} />
            </ChildWithLabel>
            <ChildWithLabel label={resources.documents} >
                <DocumentPermissions permission={props.permission} onPermissionChanged={props.alterPermission} />
            </ChildWithLabel>
            <ChildWithLabel label={resources.processes} >
                <ProcessPermissions permission={props.permission} onPermissionChanged={props.alterPermission} />
            </ChildWithLabel>
        </>
    )
}

export default ConfigurationPermissions;