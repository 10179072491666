import { Spinner as FluentSpinner,SpinnerSize } from "@fluentui/react/lib/Spinner";

const Spinner = (): JSX.Element => {
    return (
        <div className="spinner">
            <FluentSpinner size={SpinnerSize.small} />
        </div>
    );
}

export default Spinner;
