import { useState } from "react";
import { post, useSaveCallout } from "../../improve-lib";
import { EditSubsidiaryModel } from "../../model";

const useUpdateSubsidiary = (afterSuccessfulSave?: () => void) => {
    const saveCallout = useSaveCallout();

    const [nameDuplicated, setNameDuplicated] = useState(false);

    const updateSubsidiary = async (id: number, subsidiary: EditSubsidiaryModel) => {
        const response = await post("subsidiaries/" + id, subsidiary);

        switch (response.status) {
            case 200:
                saveCallout.show();
                if (afterSuccessfulSave)
                    afterSuccessfulSave();
                break;
            case 400:
                setNameDuplicated(response.body as boolean);
                break;
            default:
                throw response;
        }
    }

    return { updateSubsidiary, nameDuplicated }
}

export default useUpdateSubsidiary;