import { useEffect, useState } from "react";
import { useSearchTags } from "../../../../api";
import { useResources } from "../../../../improve-lib";
import { DropDown, IconButton, SearchBox, useDebounce } from "../../../../lib";
import { ApplicationAreaPermission, DocumentsApplicationAreaPermissionType, DocumentTagModel, IdValuePair } from "../../../../model";
import DocumentTagPicker from "../../_lib/tags/TagPicker";
import DocumentsTagsList from "../../_lib/tags/TagsList";
import { DocumentOrderProperty } from "./documentOrderProperty";
import "./DocumentSearch.tsx.scss";

export type DocumentSearchParameter = {
    orderBy: DocumentOrderProperty;
    orderAsc: boolean;
    fileName: string;
    fileContent: string;
    tagIds: number[];
}

type DocumentSearchProps = {
    onSearchUpdate: (parameter: DocumentSearchParameter) => void;
    subsidiariesCount: number;
    applicationAreas: ApplicationAreaPermission[];
    fullCount: number;
    filteredCount: number;
}

const DocumentSearch = (props: DocumentSearchProps): JSX.Element | null => {
    const resources = useResources();

    const [fileName, setFileName] = useState("");
    const fileNameDb = useDebounce(fileName);
    const [fileContent, setFileContent] = useState("");
    const fileContentDb = useDebounce(fileContent);
    const [selectedTags, setSelectedTags] = useState<DocumentTagModel[]>([]);
    const [sortFieldId, setSortField] = useState(0);
    const [orderAsc, setOrderAsc] = useState(true);

    const { searchTags } = useSearchTags();

    // Diese müssen übereinstimmen
    const [sortOptions, setSortOptions] = useState<IdValuePair[]>([{ id: 0, value: resources.filename }, { id: 1, value: resources.size }, { id: 2, value: resources.date }]);
    const [sortOptionsMap] = useState([DocumentOrderProperty.Name, DocumentOrderProperty.Size, DocumentOrderProperty.Date]);
    useEffect(() => {
        setSortOptions([{ id: 0, value: resources.filename }, { id: 1, value: resources.size }, { id: 2, value: resources.date }]);
    }, [resources.filename, resources.size, resources.date]);

    const onSearchUpdate = props.onSearchUpdate;

    useEffect(() => {
        onSearchUpdate({
            fileName: fileNameDb,
            fileContent: fileContentDb,
            orderAsc: orderAsc,
            orderBy: sortOptionsMap[sortOptions.find(so => so.id === sortFieldId)!.id],
            tagIds: selectedTags.map(t => t.id),
        });
    }, [fileNameDb, fileContentDb, selectedTags, sortFieldId, orderAsc, sortOptions, sortOptionsMap, onSearchUpdate])

    useEffect(() => {
        const subsidiariesWithReadPermission: IdValuePair[] = [];
        for (let i = 0; i < props.applicationAreas.length; i++) {
            if (props.applicationAreas[i].permission === DocumentsApplicationAreaPermissionType.Read ||
                props.applicationAreas[i].permission === DocumentsApplicationAreaPermissionType.Write ||
                props.applicationAreas[i].permission === DocumentsApplicationAreaPermissionType.Delete) {
                props.applicationAreas[i].subsidiaries.forEach((s: IdValuePair) => {
                    if (subsidiariesWithReadPermission.findIndex(x => x.id === s.id) === -1) {
                        subsidiariesWithReadPermission.push(s);
                    }
                });
            }
        }
        subsidiariesWithReadPermission.unshift({ id: -1, value: "" });
    }, [resources.isoCode, props.applicationAreas])

    useEffect(() => {
        const updateTags = async () => {
            const result = await searchTags("", []);
            setSelectedTags(result.filter(t => selectedTags.map(t => t.id).includes(t.id)))
        }
        if (selectedTags.length > 0)
            void updateTags();
    }, [resources.isoCode]);// eslint-disable-line react-hooks/exhaustive-deps

    const onOrderChange = () => {
        setOrderAsc(asc => !asc);
    };

    const onSortChange = (item: IdValuePair): void => {
        setSortField(item.id);
    };

    const pickerOnItemSelected = (tag: DocumentTagModel) => {
        const newTags = [...selectedTags];
        newTags.push(tag);
        setSelectedTags(newTags);
    }

    const onRemoveTagClick = (id: number) => {
        const newTags = selectedTags.filter(t => t.id !== id);
        setSelectedTags(newTags);
    }

    const pickerOnTextChanged = async (filterText: string): Promise<DocumentTagModel[]> =>
        await searchTags(filterText, selectedTags.map(tag => tag.id));

    return (
        <div className="document-search-container">
            <SearchBox
                text={fileName}
                onChange={setFileName}
                placeholder={resources.filename}
            />
            <SearchBox
                text={fileContent}
                onChange={setFileContent}
                placeholder={resources.filecontent}
            />

            <DocumentTagPicker onTextChanged={pickerOnTextChanged} onItemSelected={pickerOnItemSelected} label={resources.tags} />

            <div className="sort-btn">
                <DropDown
                    label={resources.sort}
                    placeholder={resources.sort}
                    selectedId={sortFieldId}
                    onChange={onSortChange}
                    options={sortOptions}
                />

                <IconButton
                    iconName={orderAsc ? "SortUp" : "SortDown"}
                    onClick={onOrderChange}
                    size="large"
                    tooltipText={resources.changeSortOrder}
                />
            </div>

            <DocumentsTagsList tags={selectedTags} size="normal" onRemoveClick={onRemoveTagClick} />
            
            <label className="counting-label">{props.filteredCount + " " + resources.of + " " + props.fullCount}</label>
        </div>
    )
}

export default DocumentSearch;
