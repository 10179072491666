import { ColorPicker, getColorFromRGBA, IColor } from "@fluentui/react";
import { Color } from "../../lib";

type ColorSelectorProps = {
    onColorChange: (color: Color) => void;
    selectedColor: Color;
    hiddenAlpha?: boolean;
}

const ColorSelector = (props: ColorSelectorProps): JSX.Element => {
    const onChange = (_ev: any, colorObj: IColor) => {
        const color: Color = {
            a: colorObj.a,
            r: colorObj.r,
            g: colorObj.g,
            b: colorObj.b
        }

        props.onColorChange(color);
    }

    return (
        <div>
            <ColorPicker               
                onChange={onChange}
                alphaType={props.hiddenAlpha ? "none" : "alpha"}
                showPreview
                color={getColorFromRGBA({ a: props.selectedColor.a, r: props.selectedColor.r, g: props.selectedColor.g, b: props.selectedColor.b })}
            />
        </div>
    )
}

export default ColorSelector;