import { DetailsList, DetailsListLayoutMode, IColumn, Selection, SelectionMode } from "@fluentui/react";
import { useState } from "react";
import { useToggle } from "../../improve-lib";
import { helperFunctions, OrderColumn, TableData } from "../../lib";
import { MultiSelectRowAction } from ".";
import ListActions from "./ListActions";
import "./MultiSelectList.tsx.scss";


type MultiSelectListProps<T> = {
    data: TableData<T>;
    actions?: MultiSelectRowAction[];
    selectedItems: (items: T[]) => void;
}

const MultiSelectList = <T extends { id: number }>(props: MultiSelectListProps<T>): JSX.Element => {
    const [orderColumn, setOrderColumn] = useState<OrderColumn>({ columnName: props.data.columns[0].name, ascending: true });

    const [selectionKey, resetSelection] = useToggle();

    const onColumnClick = (_ev: React.MouseEvent<HTMLElement>, column: IColumn): void => {
        if (orderColumn.columnName === column.fieldName)
            setOrderColumn({ ...orderColumn, ascending: !orderColumn.ascending });
        else
            setOrderColumn({ columnName: column.fieldName!, ascending: true });
    };

    const sortedItems = helperFunctions.sort(
        props.data.rows,
        helperFunctions.getColumnToBeSorted(props.data.columns, orderColumn),
        orderColumn.ascending);

    const columns: IColumn[] = props.data.columns.map((column, i) => {
        return {
            key: i.toString(),
            name: column.displayName,
            fieldName: column.name,
            minWidth: 100,
            maxWidth: 200,
            isResizable: true,
            onColumnClick: onColumnClick,
            isSorted: column.name === orderColumn.columnName,
            isSortedDescending: !orderColumn.ascending
        } as IColumn;
    });

    if (props.actions) {
        const actionResetWrapper = (onActionExecute: () => void | Promise<any>) => {
            void onActionExecute();
            resetSelection();
        };

        const mappedActions = props.actions.map(action => {
            return {
                ...action, onClick: action.keepSelection ? action.onClick : () => { actionResetWrapper(action.onClick) }
            }
        });

        columns.push({
            key: "_more_column",
            name: "",
            minWidth: 30,
            maxWidth: 30,
            isResizable: false,
            onRender: () => {
                return <ListActions items={mappedActions} />
            },
        });
    }

    const selection = new Selection({
        onSelectionChanged: () => {
            props.selectedItems(selection.getSelection() as T[]);
        }
    });

    return (
        <DetailsList
            items={sortedItems}
            columns={columns}
            layoutMode={DetailsListLayoutMode.justified}
            compact={true}
            selectionMode={SelectionMode.multiple}
            selection={selection}
            selectionPreservedOnEmptyClick
            setKey={selectionKey.toString()}
        />
    )
}

export default MultiSelectList;
