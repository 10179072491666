import { INavLink, INavLinkGroup, IRenderGroupHeaderProps, Nav } from "@fluentui/react";
import { useCallback, useEffect, useState } from "react";
import { IdValuePair } from "../../model";

type NavigationListProps = {
    items: IdValuePair[];
    groups?: IdValuePair[];
    nestedItems?: {id: number, groupId?: number, childId?: number}[];
    onRenderLink?: (name: string) => JSX.Element;
    onRenderGroupHeader?: (group: IdValuePair, onExpandCollapseClick: (groupId: number, isExpand: boolean) => void) => JSX.Element;
    onLinkClick: (id: number, path: number[]) => void;
}

const NavigationList = (props: NavigationListProps): JSX.Element => {
    const [groupedNestedList, setGroupedNestedList] = useState<INavLinkGroup[]>([]);

    const expandCollapseAll = (nodes: INavLink[], isExpand: boolean) => {
        for (const node of nodes) {
            node.isExpanded = !isExpand;

            if (node.links && node.links.length > 0) {
                node.links = expandCollapseAll(node.links, isExpand);
            }
        }

        return nodes;
    }

    const onExpandCollapseClick = (groupId: number, isExpand: boolean) => {
        const node = groupedNestedList.find(n => n.groupData === groupId);

        if (node) {
            node.links = expandCollapseAll(node.links, isExpand);
            setGroupedNestedList(JSON.parse(JSON.stringify(groupedNestedList)) as INavLinkGroup[]);
        }
    }

    const renderLink = (link?: INavLink): JSX.Element | null => {
        return link ? props.onRenderLink!(link.name) : null;
    }

    const renderGroupHeader = (group?: IRenderGroupHeaderProps): JSX.Element | null => {
        return group ? props.onRenderGroupHeader!({ id: group.groupData as number, value: group.name ?? "" }, onExpandCollapseClick) : null;
    }

    const onLinkClick = (_?: React.MouseEvent<HTMLElement>, item?: INavLink): void => {
        if (item && item.key && !isNaN(Number(item.key)))
            props.onLinkClick(Number(item.key), item.path as number[]);
    }

    const createNestedList = useCallback((ItemIds: number[], path: number[], groupId: number): INavLink[] => {
        return ItemIds.flatMap(id => {
            const itemGroupId = props.nestedItems?.find(item => item.id === id)?.groupId;
            if (props.items.some(p => p.id === id) && (itemGroupId === null || itemGroupId === groupId)) {
                const childItems = props.nestedItems!.flatMap(item => item.id === id ? item.childId ?? [] : []);
                const nestedList = createNestedList(childItems, [...path, id], groupId);
                return {
                    key: id.toString(),
                    name: props.items.find(item => item.id === id)?.value ?? "",
                    url: "",
                    links: nestedList,
                    path: [...path, id]
                } as INavLink;
            }
            else
                return [];
        });
    }, [props.nestedItems, props.items]);

    useEffect(() => {
        if (props.groups && props.nestedItems) {
            const nestedListByGroup: INavLinkGroup[] = [];
            const sortedGroups = props.groups.sort((a, b) => a.value.localeCompare(b.value));

            const mainItemIds = props.nestedItems.flatMap(
                item => props.nestedItems!.some(itemToCompare => itemToCompare.childId === item.id) ? [] : item.id
            );
            const distinctItemIds = Array.from(new Set(mainItemIds));

            for (const group of sortedGroups) {
                const nestedList = createNestedList(distinctItemIds, [], group.id);
                nestedListByGroup.push(
                    {
                        name: group.value,
                        groupData: group.id,
                        links: nestedList
                    }
                );
            }
            setGroupedNestedList(nestedListByGroup);
        }
        else
            setGroupedNestedList([{ links: props.items.map(item => ({ key: item.id.toString(), name: item.value, url: "" } as INavLink)) }]);
    }, [props.groups, props.nestedItems, props.items, createNestedList]);

    return (
        <Nav
            groups={groupedNestedList}
            onRenderLink={props.onRenderLink ? renderLink : undefined}
            onRenderGroupHeader={renderGroupHeader ?? undefined}
            onLinkClick={onLinkClick}
        />
    );
}

export default NavigationList;