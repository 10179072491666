import { useState } from "react";
import { EditProcessModel, ITracked, ProcessGroupPermissionType, ProcessModel } from "../../../model";
import ProcessModeler from "./diagram/ProcessModeler";
import ProcessViewer from "./diagram/ProcessViewer";

type ProcessDetailsProps = {
    process: ProcessModel;
    processPermission: ProcessGroupPermissionType;
    flag: boolean;
    reload: () => void;
    onProcessIdChanged: (newId: number) => void;
}

const ProcessDetails = (props: ProcessDetailsProps): JSX.Element => {
    const [readonly, setReadonly] = useState(localStorage.getItem("processesEditMode") === "true" ? false : true);

    const onChangeReadonly = (value: boolean) => {
        setReadonly(value);
        localStorage.setItem("processesEditMode", (!value).toString());
    }

    const processData: EditProcessModel = {
        name: props.process.name,
        owner: props.process.owner,
        description: props.process.description,
        links: props.process.links,
        customFields: props.process.customFields ?? []
    };

    const tracked: ITracked = {
        userCreated: props.process.userCreated,
        dateTimeCreated: props.process.dateTimeCreated,
        userLastEdited: props.process.userLastEdited,
        dateTimeLastEdited: props.process.dateTimeLastEdited
    };

    return (
        <>
            {readonly || props.processPermission < ProcessGroupPermissionType.Write ?
                <ProcessViewer
                    id={props.process.id}
                    flag={props.flag}
                    process={processData}
                    elements={props.process.elements}
                    bpmn={props.process.bpmn}
                    tracked={tracked}
                    showHeader
                    processPermission={props.processPermission}
                    onChangeToEditMode={() => onChangeReadonly(false)}
                    onProcessIdChanged={props.onProcessIdChanged}
                />
                :
                <ProcessModeler
                    id={props.process.id}
                    flag={props.flag}
                    process={processData}
                    elements={props.process.elements}
                    bpmn={props.process.bpmn}
                    subsidiary={props.process.subsidiary}
                    processGroup={props.process.processGroup}
                    versionCount={props.process.versionCount}
                    tracked={tracked}
                    processPermission={props.processPermission}
                    reload={props.reload}
                    onChangeToReadOnlyMode={() => onChangeReadonly(true)}
                    onProcessIdChanged={props.onProcessIdChanged}
                />
            }
        </>
    );
}

export default ProcessDetails;
