import { del } from "../../improve-lib";

const useDeleteProcessGroup = (afterDelete?: () => void) => {
    const deleteProcessGroup = async (id: number) => {
        const response = await del("processgroups/" + id);

        if (response.status === 200) {
            if (afterDelete)
                afterDelete();
        }
        else
            throw response;
    }

    return { deleteProcessGroup }
}

export default useDeleteProcessGroup;