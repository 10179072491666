import { ContextualMenu as FluentContextualMenu, DirectionalHint, IContextualMenuItem, Point } from "@fluentui/react";

type ContextualMenuProps = {
    items: IContextualMenuItem[];
    point: Point;
    hidden: boolean;
    onDismiss: () => void;
}

const ContextualMenu = (props: ContextualMenuProps): JSX.Element => {
    return (
        <FluentContextualMenu
            styles={{ container: { width: 48 }, list: { width: 40 } }}
            items={props.items}
            directionalHint={DirectionalHint.bottomLeftEdge}
            hidden={props.hidden}
            target={props.point}
            onItemClick={props.onDismiss}
            onDismiss={props.onDismiss}
        />
    );
}

export default ContextualMenu;
