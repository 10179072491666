import { helperFunctions , OrderColumn, RowAction,TableData } from "../../lib";
import Table from "./Table";

type MemoryTableProps<T> = {
    data: TableData<T>;
    orderColumn: OrderColumn;
    onReorder: (orderColumn: OrderColumn) => void;
    onRowClick: (item: T) => void;
    onActionsCreate?: (rowData: T) => RowAction[];
}

const MemoryTable = <T extends { id: number }>(props: MemoryTableProps<T>): JSX.Element => {
    const sortedData = { ...props.data };

    sortedData.rows = helperFunctions.sort(
        props.data.rows,
        helperFunctions.getColumnToBeSorted(props.data.columns, props.orderColumn),
        props.orderColumn.ascending);

    return (
        <Table
            data={sortedData}
            onReorder={props.onReorder}
            orderColumn={props.orderColumn}
            onRowClick={props.onRowClick}
            onActionsCreate={props.onActionsCreate}
        />
    )
}

export default MemoryTable;
