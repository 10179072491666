import { TextBox } from "../../../lib";
import { CustomFieldModel, CustomFieldSettingsModel, FieldType } from "../../../model";
import FieldBase from "./fieldBase";

class TextField extends FieldBase {
    private text: string;
    private readonly dbText: string;
    private multiline: boolean;

    readonly fieldType: FieldType = FieldType.Text;

    constructor(id: number, name: string, mandatory: boolean, settings: CustomFieldSettingsModel | null, text: string | null) {
        super(id, name, mandatory);
        this.dbText = text ?? "";
        this.text = text ?? "";
        this.multiline = settings?.multiline ?? false;
    }

    isValid() {
        if (this.mandatory)
            return this.text.trim().length > 0;
        else
            return true;
    }

    hasChange() { return this.text !== this.dbText; }

    getModel(): CustomFieldModel {
        return {
            id: this.id,
            fieldType: this.fieldType,
            settings: { multiline: this.multiline },
            name: this.name,
            mandatory: this.mandatory,
            text: this.text === "" ? null : this.text,
            boolean: null,
            date: null,
            number: null,
            selection: null
        };
    }

    getEditControl(onChange: (newField: FieldBase) => void) {
        return (
            <TextBox
                label={this.name}
                text={this.text}
                placeholderText={""}
                onChange={newValue => { this.text = newValue; onChange(this) }}
                mandatory={this.mandatory}
                multiline={this.multiline}
            />
        );
    }
}



export default TextField;