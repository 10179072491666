import { Button, Dialog, DialogFooter } from "../../lib";
import { useResources } from "..";

type SaveCheckDialogProps = {
    hidden: boolean;
    onCancel: () => void;
    onLeaveAndDiscard: () => void;
}

const SaveCheckDialog = (props: SaveCheckDialogProps): JSX.Element => {
    const resources = useResources();

    return (
        <Dialog
            title={resources.sureToLeaveThePage}
            description={resources.discardOrCancel}
            hidden={props.hidden}
            onDismiss={props.onCancel}
        >
            <DialogFooter>
                <Button onClick={props.onLeaveAndDiscard} text={resources.discardChanges} />
                <Button onClick={props.onCancel} text={resources.cancel} secondary />
            </DialogFooter>
        </Dialog>
    )
}

export default SaveCheckDialog;
