import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { MemoryTable, OrderColumn, TableData } from "../../../lib";
import { OpenTasksTableRowModel } from "../../../model";

type OpenTasksTableProps = {
    data: TableData<OpenTasksTableRowModel>
}

const OpenTasksTable = (props: OpenTasksTableProps): JSX.Element => {
    const navigate = useNavigate();

    const [orderColumn, setOrderColumn] = useState<OrderColumn>({ ascending: true, columnName: "dueDate" });

    const onReorder = (orderColumn: OrderColumn) => {
        setOrderColumn(orderColumn);
    }

    const onRowClick = (item: OpenTasksTableRowModel) => {
        navigate("/" + item.link);
    }

    return (
        <MemoryTable 
            data={props.data}
            onReorder={onReorder}
            orderColumn={orderColumn}
            onRowClick={onRowClick}
        />
    )
}

export default OpenTasksTable;
