import { Icon } from "@fluentui/react";
import Logo from "../../public/image/improve-logo.svg";
import { useResources } from "../language";
import "./ErrorTheme.tsx.scss";

type ErrorThemeProps = {
    statusCode: number,
    bodyResponse: string,
    title: string,
    subTitle?: string,
    message: string,
}




function iconSwitch(statusCode: number) {
    switch (statusCode) {
        case 4:
            return <Icon styles={{
                root: {
                    fontSize: 50,
                    height: 50,
                    width: 50,
                    margin: "0 0 15px 15px",
                    color: "#ffb900",
                }
            }} iconName="Warning" />
        case 5:
            return <Icon styles={{
                root: {
                    fontSize: 50,
                    height: 50,
                    width: 50,
                    margin: "0 0 15px 15px",
                    color: "rgb(168, 0, 0)",
                }
            }} iconName="ErrorBadge" />
    }
}

const refresh = () => {
    window.location.reload()
}

const ErrorTheme = (props: ErrorThemeProps) => {
    const resources = useResources();
    return (
        <>
            <div className={"top-bar-header-wrapper" + " error-style-" + Number(String(props.statusCode).charAt(0))}>
                <img className={"top-bar-error"} src={Logo} alt="logo" />
            </div>
            <div className="error-message">
                <div className="error-container">

                    {iconSwitch(Number(String(props.statusCode).charAt(0)))}

                    <h2 className="error-title">{props.statusCode + " "} {props.title}</h2>
                    <h3 className="error-title">{props.subTitle}</h3>
                    <div className="error-text"><div className="hl">{resources.message + ":"} </div>{" " + props.message}</div>
                    <div onClick={refresh} className="error-link">Refresh</div>
                    {props.bodyResponse != undefined ?? <div className="error-body"><pre>{props.bodyResponse}</pre></div>}
                </div>
            </div>
        </>
    );
};

export default ErrorTheme;