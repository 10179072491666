import { CommandButton, ICommandBarItemProps } from "@fluentui/react";
import { RowAction } from "..";
import "./ListActions.tsx.scss";

type ListActionsProps = {
    items: RowAction[];
}

const ListActions = (props: ListActionsProps): JSX.Element => {
    const items: ICommandBarItemProps[] = props.items.map((item, i) => {
        return {
            key: i.toString(),
            text: item.text,
            disabled: item.disabled,
            style: item.disabled ? { cursor: "default" } : undefined,
            iconProps: { iconName: item.iconName },
            onClick: item.onClick
        }
    });

    return (
        <span>
            <CommandButton
                menuProps={{ items: items }}
                menuIconProps={{ iconName: "More" }}
                className="table-row-action-bar command-button-action-context"
            />
        </span>
    )
}

export default ListActions;
