import { useResources } from "../../../improve-lib";
import { SelectionList } from "../../../lib";
import { IdValuePair } from "../../../model";

type RolesListProps = {
    roles: IdValuePair[];
    onRoleIdChanged: (newId: number) => void;
    selectedRoleId: number | undefined;
}

const RolesList = (props: RolesListProps): JSX.Element => {
    const resources = useResources();

    const changeSelectedId = (id: number) => {
        props.onRoleIdChanged(id);
    }

    return (
        <SelectionList
            items={props.roles}
            columnHeader={resources.roles}
            onSelectionChanged={changeSelectedId}
            selectedItemId={props.selectedRoleId}
        />
    )
}


export default RolesList;
