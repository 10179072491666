import { Dropdown as DropdownFluent, IDropdownOption } from "@fluentui/react";
import { IdValuePair } from "../../model";
import "./DropDown.tsx.scss";

type DropDownProps = {
    label: string;
    placeholder: string;
    selectedId?: number;
    mandatory?: boolean;
    options: IdValuePair[];
    onClick?: () => void;
    onChange: (item: IdValuePair) => void;
}

const DropDown = (props: DropDownProps): JSX.Element => {
    let className = "";

    if (props.selectedId === -1 && props.mandatory)
        className = "drop-down-mandatory";

    const dropdownOptions: IDropdownOption[] = props.options.map(x => ({ key: x.id, text: x.value }));

    const onChange = (_event: React.FormEvent<HTMLDivElement>, option?: IDropdownOption, _index?: number): void => {
        props.onChange({ id: option!.key as number, value: option!.text });
    };

    return (
        <DropdownFluent
            className={className}
            label={props.label}
            placeholder={props.placeholder}
            selectedKey={props.selectedId}
            onChange={onChange}
            onClick={props.onClick}
            options={dropdownOptions}
        />
    )
}

export default DropDown;
