import { useEffect, useState } from "react";
import { get } from "../../improve-lib";

const useDeletableProcessGroup = (id: number) => {
    const [processGroupCanBeDeleted, setProcessGroupCanBeDeleted] = useState(false);

    useEffect(() => {
        const checkIfApplicationAreaCanBeDeleted = async () => {
            const response = await get("processgroups/" + id + "/deletable");

            if (response.status !== 200)
                throw response;

            setProcessGroupCanBeDeleted(response.body as boolean);
        }

        void checkIfApplicationAreaCanBeDeleted();
    }, [id]);

    return { processGroupCanBeDeleted }
}

export default useDeletableProcessGroup;