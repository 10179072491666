import { useRef, useState } from "react";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { useActivateDeactivateUser, useDeleteUser, useUserTable } from "../../api";
import { Container, PasswordResetDialog, useQueryValue, useResources } from "../../improve-lib";
import { OrderColumn, RowAction, TableFilter } from "../../lib";
import UserTable from "./components/UserTable";

const UsersPage = (): JSX.Element => {
    const navigate = useNavigate()
    const location = useLocation()

    const resources = useResources();

    const [params, setParams] = useSearchParams();
    const [orderBy] = useQueryValue("orderBy", value => value ?? "displayName");
    const [orderAsc] = useQueryValue("orderAsc", value => value === undefined ? true : value === "true");
    const [fulltextQuery, setFulltextQuery] = useQueryValue("fulltext", value => value ?? "");
    const [records, setRecords] = useQueryValue("records", value => {
        if (value) {
            const number = parseInt(value);

            if (!isNaN(number))
                return number;
        }
        return 20;
    });

    const [fulltext, setFulltext] = useState(fulltextQuery);

    const { usersData, reload } = useUserTable(orderBy, orderAsc, fulltext, records);
    const { setUserActiveInactive } = useActivateDeactivateUser(() => {
        reload();
    })
    const { deleteUser } = useDeleteUser(() => {
        reload();
    })

    const onLoadMore = () => setRecords((records + 20).toString());

    const onReorder = (newOrderColumn: OrderColumn) => {
        params.set("records", "20");
        params.set("orderBy", newOrderColumn.columnName);
        params.set("orderAsc", newOrderColumn.ascending ? "true" : "false");

        setParams(params);
    }

    const onUserClick = (id: number) => navigate("/settings/users/" + id + location.search);

    const onCreateClick = () => navigate("/settings/users/new");

    const orderColumn: OrderColumn = { ascending: orderAsc, columnName: orderBy };

    const idForDialogs = useRef(-1);
    const displayNameForDialogs = useRef("");

    //#region Dialogs 

    const [resetPasswordDialogHidden, setResetPasswordDialogHidden] = useState(true);

    const showResetPasswordDialog = () => {
        setResetPasswordDialogHidden(false);
    }

    const resetPasswordDialogCancelOrClose = () => {
        setResetPasswordDialogHidden(true);
    }

    const resetPassword = (id: number, displayName: string) => {
        idForDialogs.current = id;
        displayNameForDialogs.current = displayName;
        showResetPasswordDialog();
    }

    //#endregion

    const newAction: RowAction = { iconName: "ReminderPerson", onClick: onCreateClick, text: resources.userCreate };

    return (
        <section>
            <div className="content-container">
            <Container title={resources.useradministration} actions={[newAction]}>
                {usersData &&
                    <TableFilter
                        fulltextFilter={fulltext}
                        setFulltextFilter={setFulltext}
                        onDebounce={() => setFulltextQuery(fulltextQuery)}
                        fullCount={usersData.fullCount}
                        filteredCount={usersData.filteredCount}
                    />}
                {usersData &&
                    <UserTable
                        onDeleteUser={deleteUser}
                        onLoadMore={onLoadMore}
                        onReorder={onReorder}
                        onToggleUserActive={(id: number, active: boolean) => setUserActiveInactive(id, active)}
                        onResetPassword={resetPassword}
                        onUserClick={onUserClick}
                        orderColumn={orderColumn}
                        usersData={usersData}
                    />}
                <PasswordResetDialog
                    userId={idForDialogs.current}
                    userDisplayName={displayNameForDialogs.current}
                    hidden={resetPasswordDialogHidden}
                    onDismiss={resetPasswordDialogCancelOrClose}
                />
                </Container>
            </div>
        </section>
    )
}

export default UsersPage;
