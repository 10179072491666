import { Container, useResources } from "../../../../../../improve-lib";
import { RowAction, SelectionList } from "../../../../../../lib";
import { IdValuePair } from "../../../../../../model";

type ListProps = {
    tags: IdValuePair[];
    onTagIdChanged: (newId: number) => void;
    selectedTagId: number | undefined;
    onTagCreate: () => void;
}

const List = (props: ListProps): JSX.Element => {
    const resources = useResources();

    const changeSelectedId = (id: number) => props.onTagIdChanged(id);

    const newAction: RowAction = { iconName: "Add", onClick: props.onTagCreate, text: resources.new };

    return (
        <Container title={resources.tags} actions={[newAction]}>
            <SelectionList
                items={props.tags}
                columnHeader={resources.tags}
                onSelectionChanged={changeSelectedId}
                selectedItemId={props.selectedTagId}
            />
        </Container>
    )
}


export default List;