import { useState } from "react";
import { useDisplayDocument } from "../../../../api";
import { useResources } from "../../../../improve-lib";
import { Popup, Tabs } from "../../../../lib";
import DocumentDisplay from "../../../documents/document-container/display/DocumentDisplay";
import DocumentViewer from "../../../documents/document-container/viewer/Viewer";

type DocumentPopupProps = {
    isOpen: boolean;
    documentId: number;
    onCancel: () => void;
}

const DocumentPopup = (props: DocumentPopupProps): JSX.Element => {
    const resources = useResources();

    const [tabIndex, setTabIndex] = useState(0);

    const { document } = useDisplayDocument(props.documentId);

    const getTabItems = () => {
        const tabItems = [];

        if (document) {
            if (!document.locked) {
                tabItems.push({
                    header: resources.view,
                    control: <DocumentViewer documentId={props.documentId} />
                });
            }
            tabItems.push({
                header: resources.information,
                control: <DocumentDisplay document={document} />
            });
        }
        return tabItems;
    }

    return (
        <>
            {document &&
                <Popup
                    isOpen={props.isOpen}
                    title={document.filename}
                    onDismiss={props.onCancel}
                    closeText={resources.close}
                    size="big"
                >
                    <Tabs selectedIndex={tabIndex} onClick={setTabIndex} tabs={getTabItems()} />
                </Popup>
            }
        </>
    );
}

export default DocumentPopup;