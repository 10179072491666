import { MessageBar as FluentMessageBar, MessageBarType } from "@fluentui/react";
import { MessageBarProps } from "..";
import "./MessageBar.tsx.scss";

const MessageBar = (props: MessageBarProps): JSX.Element => {
    let type: MessageBarType = MessageBarType.severeWarning;

    switch (props.type) {
        case "success":
            type = MessageBarType.success;
            break;
        case "warning":
            type = MessageBarType.warning;
            break;
        case "error":
            type = MessageBarType.error;
            break;
        case "info":
            type = MessageBarType.info;
            break;
    }

    return (
        <div className="messagebar-main">
            <FluentMessageBar messageBarType={type}>
                {props.message}
            </FluentMessageBar>
        </div>
    )
}

export default MessageBar;
