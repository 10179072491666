import { useState } from "react";
import { get } from "../../improve-lib";
import { IdValuePair } from "../../model";

const useSelectionFieldItems = (id: number) => {
    const [items, setItems] = useState<IdValuePair[]>();

    const requestItems = (selectedItem: IdValuePair) => {
        const getSelectionFieldItems = async () => {
            const response = await get("customFields/getSelectionFieldItems/" + id);

            if (response.status !== 200)
                throw response;

            const emptyItem = { id: -1, value: "" };
            const dbItems = response.body as IdValuePair[];
                
            if (selectedItem.id === -1 || dbItems.some(i => i.id === selectedItem.id))
                setItems([emptyItem, ...dbItems]);
            else
                setItems([emptyItem, ...dbItems, selectedItem]);  
        }

        if (!items)
            void getSelectionFieldItems();
    }

    return { items, requestItems };
}

export default useSelectionFieldItems;