import { useState } from "react";
import { useResources, useSaveCheck, useUserSettings } from "../../../improve-lib";
import { Popup, Tabs } from "../../../lib";
import CustomFieldSettings from "./custom-fields/CustomFieldSettings";
import ProcessGroupSettings from "./process-group/ProcessGroupSettings";

type ProcessesSettingsPopupProps = {
    isOpen: boolean;
    onClose: () => void;
}

const ProcessesSettingsPopup = (props: ProcessesSettingsPopupProps): JSX.Element => {
    const resources = useResources();
    const userSettings = useUserSettings();

    const [tabIndex, setTabIndex] = useState(0);

    const onTabClick = useSaveCheck((index: number) => setTabIndex(index));

    const onClose = useSaveCheck(props.onClose);

    return (
        <Popup
            isOpen={props.isOpen}
            title={resources.settings}
            onDismiss={onClose}
            closeText={resources.close}
            size={"big"}
        >
            <Tabs selectedIndex={tabIndex}
                onClick={onTabClick}
                tabs={userSettings.permissions.processesEditProcessGroups ?
                    [
                        {
                            header: resources.processGroups,
                            control: <ProcessGroupSettings />
                        },
                        {
                            header: resources.customFields,
                            control: <CustomFieldSettings />
                        }
                    ] : []}
            />
        </Popup>
    );
}

export default ProcessesSettingsPopup;
