import { useSelectionFieldItems } from "../../../api";
import { DropDown } from "../../../lib";
import { IdValuePair } from "../../../model";

type SelectionFieldProps = {
    fieldId: number;
    label: string;
    mandatory: boolean;
    selectedItem: IdValuePair;
    onChange: (item: IdValuePair) => void;
}

const SelectionFieldControl = (props: SelectionFieldProps): JSX.Element => {
    const { items, requestItems } = useSelectionFieldItems(props.fieldId);

    return (
        <DropDown
            label={props.label}
            placeholder={""}
            mandatory={props.mandatory}
            selectedId={props.selectedItem.id}
            options={items ?? [props.selectedItem]}
            onChange={props.onChange}
            onClick={() => requestItems(props.selectedItem)}
        />
    );
}

export default SelectionFieldControl;