import { IBasePickerSuggestionsProps, ISuggestionItemProps, ITag, Label, TagPicker as FluentTagPicker } from "@fluentui/react";
import { useResources } from "../../../../improve-lib";
import { DocumentTagModel } from "../../../../model";
import Tag from "./Tag";

type TagPickerProps = {
    onTextChanged: (value: string) => Promise<DocumentTagModel[]>;
    onItemSelected: (item: DocumentTagModel) => void;
    label?: string;
}

const TagPicker = (props: TagPickerProps): JSX.Element => {
    const resources = useResources();

    const onResolveSuggestions = async (filterText: string, _tagList?: ITag[]): Promise<ITag[]> => (await props.onTextChanged(filterText)) as any as ITag[];

    const onItemSelected = (item?: DocumentTagModel) => {
        props.onItemSelected(item!);
        return null;
    }

    const pickerSuggestionsProps: IBasePickerSuggestionsProps = {
        noResultsFoundText: resources.noSearchResults,
    }

    const onResolveEmpty = async (_added?: ITag[]): Promise<ITag[]> => (await props.onTextChanged("")) as any as ITag[];

    const onRenderSuggestion = (props: DocumentTagModel, _itemProps: ISuggestionItemProps<DocumentTagModel>): JSX.Element => {
        return (
            <div style={{ padding: "2px" }}>
                <Tag tag={{ id: props.id, name: props.name, color: props.color }} size="normal" />
            </div>
        );
    }

    return (
        <div>
            {props.label &&
                <Label>
                    {props.label}
                </Label>}
            <FluentTagPicker
                onResolveSuggestions={onResolveSuggestions}
                onItemSelected={onItemSelected as any}
                pickerSuggestionsProps={pickerSuggestionsProps}
                resolveDelay={100}
                inputProps={{
                    placeholder: resources.searchAndAdd
                }}
                onEmptyResolveSuggestions={onResolveEmpty}
                onRenderSuggestionsItem={onRenderSuggestion as any}
            />
        </div>
    )
}

export default TagPicker;
