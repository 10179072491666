import { useEffect, useState } from "react";
import { IdValuePair } from "../../model";
import { useSubsidiaries } from ".";

const useSubsidiariesList = () => {
    const [subsidiariesMapped, setSubsidiariesMapped] = useState<IdValuePair[]>([]);

    const { subsidiaries } = useSubsidiaries()

    useEffect(() => {
        setSubsidiariesMapped(subsidiaries.map(s => { return { id: s.id, value: s.name } as IdValuePair }))
    }, [subsidiaries]);

    return { subsidiaries: subsidiariesMapped }
}

export default useSubsidiariesList;