import { useEffect, useRef } from "react";
import { useResources } from "../../../../improve-lib";
import { EditProcessModel, ITracked, ProcessElementsModel, ProcessGroupPermissionType } from "../../../../model";
import InfoPanel from "../info/InfoPanel";
import ProcessHeader from "../info/ProcessHeader";
import BpmnViewer from "./bpmn/BpmnViewer";
import useViewerElements from "./elements/useViewerElements";
import ZoomControls from "./zoom/ZoomControls";
import "./ProcessViewer.tsx.scss";

type ProcessViewerProps = {
    id: number;
    flag: boolean;
    process: EditProcessModel;
    elements: ProcessElementsModel;
    bpmn: string;
    tracked: ITracked;
    showHeader?: boolean;
    expandInfoPanel?: boolean;
    processPermission: ProcessGroupPermissionType;
    onChangeToEditMode: () => void;
    onProcessIdChanged: (id: number) => void;
}

const ProcessViewer = (props: ProcessViewerProps): JSX.Element => {
    const resources = useResources();

    const bpmnContainer = useRef(null);
    const bpmnViewer = useRef<BpmnViewer>();

    const { selectedElement, onSelectionChange, elementHelper } = useViewerElements(props.elements, props.onProcessIdChanged);

    const initViewer = () => {
        const viewer = new BpmnViewer(
            bpmnContainer.current,
            resources,
            elementHelper,
            onSelectionChange
        );

        return viewer;
    };

    useEffect(() => {
        void bpmnViewer.current?.updateResources(resources);
    }, [resources]);

    useEffect(() => {
        if (!bpmnViewer.current)
            bpmnViewer.current = initViewer();

        void bpmnViewer.current?.updateDiagram(props.bpmn);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.flag]);

    return (
        <>
        {props.showHeader &&
            <ProcessHeader
                processId={props.id}
                readonly={true}
                processPermission={props.processPermission}
                onReadonlyChanged={props.onChangeToEditMode}
                onProcessIdChanged={props.onProcessIdChanged}
            />
        }
            <div className="viewer-main-container">
                <div className="viewer-bpmn-container" ref={bpmnContainer} >
                    <ZoomControls
                        onZoomReset={() => bpmnViewer.current?.zoomReset()}
                        onZoomIn={() => bpmnViewer.current?.zoomIn()}
                        onZoomOut={() => bpmnViewer.current?.zoomOut()}
                        className={"viewer-zoom-controls"}
                    />
                </div>
                <InfoPanel
                    process={props.process}
                    selectedElement={selectedElement}
                    expand={props.expandInfoPanel}
                    tracked={props.tracked}
                />
            </div>
        </>
    );
}

export default ProcessViewer;