import { put, useToggleActiveDialog } from "../../improve-lib";

const useActivateDeactivateUser = (afterSet?: () => void) => {
    const setUserActiveInactive = useToggleActiveDialog(async (id: number, active: boolean): Promise<boolean> => {
        const response = await put("users/" + id + "/active?active=" + active);

        switch (response.status) {
            case 200:
                if (afterSet)
                    afterSet();
                return true;
            case 400:
                return false;
            default:
                throw response;
        }
    });
    return { setUserActiveInactive }
}

export default useActivateDeactivateUser;