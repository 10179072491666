export function colorToHex(color: Color): string {
    return "#" + [color.r, color.g, color.b].map(x => x.toString(16).padStart(2, "0")).join("");
}

export function hexToColor(hex: string): Color {
    const rgb = hex.replace(/^#?([a-f\d])([a-f\d])([a-f\d])$/i, (r, g, b) => "#" + r + r + g + g + b + b)
        .substring(1).match(/.{2}/g)
        ?.map(x => parseInt(x, 16)) || [0,0,0];

    return { a: 100, r: rgb[0], g: rgb[1], b: rgb[2] }
}

export type Color = {
    a: number | undefined;
    r: number;
    g: number;
    b: number;
};