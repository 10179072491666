import { useState } from "react";
import { put, useSaveCallout } from "../../improve-lib";
import { TagUpdateModel } from "../../model";

const useUpdateTag = (afterSuccessfulSave?: () => void) => {
    const saveCallout = useSaveCallout();

    const [duplicatedIsoCodes, setDuplicatedIsoCodes] = useState<string[]>([]);

    const updateTag = async (id: number, tag: TagUpdateModel) => {
        const response = await put("tags/" + id, tag)

        switch (response.status) {
            case 200:
                saveCallout.show();
                if (afterSuccessfulSave)
                    afterSuccessfulSave();
                break;
            case 400:
                setDuplicatedIsoCodes(response.body as string[]);
                break;
            default:
                throw response;
        }
    }

    return { updateTag, duplicatedIsoCodes }
}

export default useUpdateTag;