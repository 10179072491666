import { put, useSaveCallout } from "../../improve-lib";
import { ImproveModule, UpdateCustomFieldOrderModel } from "../../model";

const useUpdateCustomFieldsOrder = () => {
    const saveCallout = useSaveCallout();

    const updateCustomFieldsOrder = async (module: ImproveModule, customFields: UpdateCustomFieldOrderModel[]) => {
        const response = await put("customFields/" + module, customFields);

        switch (response.status) {
            case 200:
                saveCallout.show();
                break;
            default:
                throw response;
        }
    }

    return { updateCustomFieldsOrder }
}

export default useUpdateCustomFieldsOrder;