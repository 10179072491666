import { isAny } from "bpmn-js/lib/features/modeling/util/ModelingUtil";
import RuleProvider from "diagram-js/lib/features/rules/RuleProvider";


export default class CustomRules extends RuleProvider {
    static $inject = ["eventBus"];

    constructor(eventBus) {
        super(eventBus);

        this.init();
    }

    init() {
        // Erlaubt die Grösse eines Elements vom bestimmten Typ zu verändern
        this.addRule("shape.resize", 1500, context => {
            return isAny(context.shape, ["bpmn:Task", "bpmn:Artifact", "bpmn:Participant", "bpmn:Lane"]);
        });

        // Erlaubt Elemente vom bestimmten Typ über andere zu schieben
        this.addRule("elements.move", 1500, context => {
            if (context.shapes.length === 1 && isAny(context.shapes[0], ["bpmn:Artifact"]))
                return true;
        });

        // Erlaubt Elemente vom bestimmten Typ über andere zu erstellen
        this.addRule("shape.create", 1500, context => {
            if (isAny(context.shape, ["bpmn:Artifact"]))
                return true;
        });
    }
}