import { DetailsList, getTheme, IDragDropEvents, mergeStyles, SelectionMode } from "@fluentui/react";
import { useState } from "react";
import { IdValuePair } from "../../model";
import GroupedListItem from "./model/groupedListItem";

type DragDropListProps = {
    items: GroupedListItem[];
    groups: IdValuePair[];
    canDropInOtherGroup?: boolean;
    onChange: (changedItems: GroupedListItem[]) => void;
}

const DragDropList = (props: DragDropListProps): JSX.Element => {
    const theme = getTheme();

    const [draggedItem, setDraggedItem] = useState<GroupedListItem>();

    const getDragDropEvents = (): IDragDropEvents => {
        return {
            canDrag: item => {
                const listItem = item as GroupedListItem;
                return (listItem.id && listItem.value && listItem.groupId) ? true : false;
            },
            canDrop: (dropContext, dragContext) => {
                if (props.canDropInOtherGroup && dropContext?.isGroup) {
                    const group = dropContext?.data as { key: string };
                    const groupId = parseInt(group.key);

                    return !dragContext?.isGroup && props.items.filter(i => i.groupId === groupId).length === 0
                }
                else if (dropContext?.data && draggedItem && !dragContext?.isGroup) {
                    const dropField = dropContext.data as GroupedListItem;
                    return props.canDropInOtherGroup ? true : draggedItem.groupId === dropField.groupId;
                }
                else
                    return false;
            },
            canDragGroups: false,
            onDragEnter: () => mergeStyles({ backgroundColor: theme.palette.neutralLight + " !important" }),
            onDrop: item => insertItem(item),
            onDragStart: (item: GroupedListItem) => { setDraggedItem(item); },
            onDragEnd: () => setDraggedItem(undefined),
        };
    }

    const insertItem = (item: any) => {
        const listItem = item as GroupedListItem;
        if (listItem.id && listItem.value && listItem.groupId) {
            insertBeforeItem(listItem);
        }
        else
            insertToGroup(item as { key: string })
    }

    const insertBeforeItem = (item: GroupedListItem) => {
        if (draggedItem) {
            const newItems = props.items.filter(itm => draggedItem.id !== itm.id);

            const insertIndex = props.items.indexOf(item);
            newItems.splice(insertIndex, 0, { id: draggedItem.id, value: draggedItem.value, groupId: item.groupId });

            props.onChange(newItems);
        }
    }

    const insertToGroup = (group: { key: string }) => {
        if (draggedItem) {
            const newItems = props.items.filter(itm => draggedItem.id !== itm.id);

            const groupId = parseInt(group.key);
            const insertIndex = props.items.filter(i => i.groupId === groupId).length + 1;
            newItems.splice(insertIndex, 0, { id: draggedItem.id, value: draggedItem.value, groupId: groupId });

            props.onChange(newItems.sort((i1, i2) => (i1.groupId - i2.groupId)));
        }
    }

    const sortedItems = props.items.sort((i1, i2) => (i1.groupId - i2.groupId));

    const columns = [{ key: "0", name: "", fieldName: "value", minWidth: 100 }];

    const groups = props.groups.map(g => (
        {
            key: g.id.toString(),
            name: g.value,
            startIndex: sortedItems.findIndex(i => i.groupId === g.id),
            count: sortedItems.filter(i => i.groupId === g.id).length,
            level: 0
        }
    ));

    return (
        <DetailsList
            items={sortedItems}
            columns={columns}
            groups={groups}
            groupProps={{ showEmptyGroups: true }}
            selectionMode={SelectionMode.none}
            dragDropEvents={getDragDropEvents()}
            isHeaderVisible={false}
            compact
        />
    );
}

export default DragDropList;