import { createContext, ReactNode, useContext } from "react";
import { useCurrentUserSettings } from "../../api";
import UserSettingsModel from "./model/userSettingsModel";

const UserSettingsContext = createContext<UserSettingsModel>(undefined as unknown as UserSettingsModel);

const useUserSettings = () => useContext(UserSettingsContext);

type UserSettingsProviderProps = {
    children: ReactNode;
}

const UserSettingsProvider = (props: UserSettingsProviderProps) => {
    const { userSettings } = useCurrentUserSettings();

    if (!userSettings)
        return null;

    return (
        <UserSettingsContext.Provider value={userSettings}>
            {props.children}
        </UserSettingsContext.Provider>
    );
};

export { UserSettingsProvider, useUserSettings };
