import { del, useConfirmDeleteCallback } from "../../improve-lib";
import { ImproveModule } from "../../model";

const useDeleteCustomField = (module: ImproveModule, afterDelete: () => void) => {
    const deleteCustomField = useConfirmDeleteCallback(async (customFieldId: number) => {
        const response = await del("customFields/" + module + "/" + customFieldId);

        if (response.status !== 200)
            throw response;

        afterDelete();
    }, true);

    return { deleteCustomField }
}

export default useDeleteCustomField;