import { useContext } from "react";
import { useResources } from "../language";
import { DialogContext } from "./DialogContext";

const useConfirmLockDialog = <T extends (id: number, lockStatus: boolean) => any>(onClick: T): (id: number, lockStatus: boolean) => void => {
    const resources = useResources();
    const ctx = useContext(DialogContext);

    return (id: number, lockStatus: boolean) => ctx.openDialog({ callback: () => onClick(id, lockStatus), dialog: { type: "confirm", confirmationText: lockStatus ? resources.confirmDocumentLocked : resources.confirmDocumentUnlocked } })
}

export default useConfirmLockDialog;
