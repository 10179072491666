import { useState } from "react";

const useToggle = (): [boolean, () => void] => {
    const [flag, setFlag] = useState(false);

    const toggleFlag = () => setFlag(f => !f);

    return [flag, toggleFlag];
}

export default useToggle;
