import { useEffect, useState } from "react";

const useDebounce = (value: string): string => {
    const [debounced, setDebounced] = useState(value);

    useEffect(() => {
        const handle = setTimeout(() => setDebounced(value), 300);

        return () => clearTimeout(handle);
    }, [value])


    return debounced;
}

export default useDebounce;