import { CheckBoxList, CheckedListType } from "../../../../lib";
import { SubsidiarySelectionType } from "../users/UsersDetails";
import "./SubsidiariesDetails.tsx.scss";

type RolesDetailsSubsidiariesProps = {
    subsidiaries: SubsidiarySelectionType[];
    alterSubsidiaries: (newSubsidiaries: SubsidiarySelectionType[]) => void;
}

const RolesDetailsSubsidiaries = (props: RolesDetailsSubsidiariesProps): JSX.Element => {
    const items: CheckedListType[] = props.subsidiaries.map(s => { return { id: s.id, text: s.name, checked: s.checked, indeterminate: s.indeterminate } });

    const checkedChanged = (id: number, newValue: boolean) => {
        const newSubs = [...props.subsidiaries];
        newSubs.forEach(s => {
            if (s.id === id) {
                s.checked = newValue;
                if (s.indeterminate)
                    s.indeterminate = false;
            }
        });
        props.alterSubsidiaries(newSubs);
    }

    return (
        <div className="roles-details-subsidiaries-wrapper">
            <CheckBoxList
                items={items}
                onCheckedChanged={checkedChanged}
            />
        </div>
    )
}

export default RolesDetailsSubsidiaries;
