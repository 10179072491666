import { initializeIcons, loadTheme } from "@fluentui/react";
import fluentTheme from "./fluentTheme";
import "./index.scss";

const initTheme = () => {
    // Icons werden in Produktiver Umgebung nicht von der CDN geladen.
    // kopieren der Fonts findet in postBuild.js statt
    if (!process.env.NODE_ENV || process.env.NODE_ENV === "development")
        initializeIcons();
    else
        initializeIcons("/static/media/");

    loadTheme(fluentTheme);
}

export default initTheme;