import { useEffect, useState } from "react";
import { get } from "../../improve-lib";
import { IdValuePair } from "../../model";

const useAssignedSubsidiaries = () => {
    const [subsidiaries, setSubsidiaries] = useState<IdValuePair[]>([]);

    useEffect(() => {
        const getSubsidiaries = async () => {
            const response = await get("roles/assignedsubsidiaries");

            if (response.status !== 200)
                throw response;

            setSubsidiaries(response.body as IdValuePair[]);
        }
        void getSubsidiaries();
    }, []);

    return { subsidiaries }
}

export default useAssignedSubsidiaries;