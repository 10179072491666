import { useCallback, useMemo, useState } from "react";
import { useResources } from "../../../improve-lib";
import { RowAction } from "../../../lib";
import { IdValuePair, ProcessGroupPermissionType } from "../../../model";
import ProcessGroupPermission from "../../../model/process/processGroupPermission";

const useNewAction = (
    subsidiariesCount: number,
    permittedProcessGroups: ProcessGroupPermission[]
) => {
    const resources = useResources();

    const [newProcessPopupVisible, setNewProcessPopupVisible] = useState(false);
    const [newProcessInfo, setNewProcessInfo] = useState<{ processGroup: IdValuePair, subsidiary: IdValuePair | null }>();

    const onClick = useCallback((processGroup: IdValuePair, subsidiary: IdValuePair | null) => {
        setNewProcessInfo({ processGroup, subsidiary: subsidiariesCount > 1 ? subsidiary : null });
        setNewProcessPopupVisible(true);
    }, [subsidiariesCount]);

    const onPopupClose = () => {
        setNewProcessPopupVisible(false);
        setNewProcessInfo(undefined);
    }

    const getSubsidiarySelection = useCallback((processGroup: IdValuePair, subsidiaryPermissions: { subsidiary: IdValuePair; permission: ProcessGroupPermissionType }[]) => {
        const subsidiariesWithCreatePermission = subsidiaryPermissions.filter(sp => sp.permission >= ProcessGroupPermissionType.Create);
        if (subsidiariesWithCreatePermission.length === 1)
            return undefined;

        const subsidiarySelection = subsidiariesWithCreatePermission.map(s => ({ text: s.subsidiary.value, onClick: () => onClick(processGroup, s.subsidiary) }));
        if (subsidiariesCount === subsidiariesWithCreatePermission.length)
            subsidiarySelection.unshift({ text: resources.allSubsidiaries, onClick: () => onClick(processGroup, null) });

        return subsidiarySelection;
    }, [subsidiariesCount, onClick, resources.allSubsidiaries]);

    const getProcessGroupSelection = useCallback((processGroups: ProcessGroupPermission[]) => {
        if (processGroups.length === 1)
            return getSubsidiarySelection({ id: processGroups[0].id, value: processGroups[0].name }, processGroups[0].subsidiaryPermissions);
        else
            return processGroups.map(pg => (
                {
                    text: pg.name,
                    onClick: () => {
                        const subsidiariesWithCreatePermission = pg.subsidiaryPermissions.filter(sp => sp.permission >= ProcessGroupPermissionType.Create);
                        if (subsidiariesWithCreatePermission.length === 1)
                            onClick({ id: pg.id, value: pg.name }, subsidiariesWithCreatePermission[0].subsidiary);
                    },
                    childrenAction: getSubsidiarySelection({ id: pg.id, value: pg.name }, pg.subsidiaryPermissions)
                })
            );
    }, [getSubsidiarySelection, onClick]);

    const newAction = useMemo(() => {
        const processGroups = permittedProcessGroups.filter(pg => pg.subsidiaryPermissions.some(sp => sp.permission >= ProcessGroupPermissionType.Create));
        if (processGroups.length <= 0)
            return [];

        const newProcessAction: RowAction = {
            iconName: "Add",
            onClick: () => {
                if (processGroups.length === 1) {
                    const subsidiaryPermissions = processGroups[0].subsidiaryPermissions.filter(sp => sp.permission >= ProcessGroupPermissionType.Create);
                    if (subsidiaryPermissions.length === 1)
                        onClick({ id: processGroups[0].id, value: processGroups[0].name }, subsidiaryPermissions[0].subsidiary);
                }
            },
            text: resources.new,
            childrenAction: getProcessGroupSelection(processGroups)
        };
        return [newProcessAction];
    }, [permittedProcessGroups, resources.new, getProcessGroupSelection, onClick]);

    return { newAction, newProcessPopupVisible, newProcessInfo, onPopupClose };
}

export default useNewAction;