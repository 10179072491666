import { useEffect, useRef, useState } from "react";

const useDocument = (id: number) => {
    const [url, setUrl] = useState("");
    const urlCopy = useRef<string>();
    const [contentType, setContentType] = useState<string>();

    useEffect(() => {
        const getDocument = async () => {
            const response = await fetch("/api/documents/" + id + "/file", { method: "GET" });

            if (response.status !== 200)
                throw response;

            const blob = await response.blob();
            const contentType = response.headers.get("Content-Type")!;

            const url = URL.createObjectURL(blob);
            urlCopy.current = url;

            setUrl(url);

            setContentType(contentType);
        }

        void getDocument();

        return () => {
            if (urlCopy.current)
                URL.revokeObjectURL(urlCopy.current);
        };
    }, [id]);

    return { url, contentType }
}

export default useDocument;
