import { useEffect, useState } from "react";
import { get, useResources, useToggle } from "../../improve-lib";
import { EditCustomFieldModel, ImproveModule } from "../../model";

const useCustomField = (module: ImproveModule, customFieldId: number) => {
    const resources = useResources();
    const [reloadFlag, reload] = useToggle();

    const [customField, setCustomField] = useState<EditCustomFieldModel>();

    useEffect(() => {
        const getCustomField = async () => {
            const response = await get("customFields/" + module + "/" + customFieldId);

            if (response.status !== 200)
                throw response;

            const fieldModel: EditCustomFieldModel = response.body;
            fieldModel.dateTimeCreated = new Date(fieldModel.dateTimeCreated);
            fieldModel.dateTimeLastEdited = new Date(fieldModel.dateTimeLastEdited);
            fieldModel.selections = fieldModel.selections?.sort((a, b) => a.name.find(n => n.isoCode === resources.isoCode)!.label.localeCompare(b.name.find(n => n.isoCode === resources.isoCode)!.label))
            setCustomField(fieldModel);
        }

        void getCustomField();
    }, [customFieldId, module, reloadFlag, resources.isoCode]);

    return { customField, reload }
}

export default useCustomField;