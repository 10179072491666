import { TextField } from "@fluentui/react";

type PasswordBoxProps = {
    text: string;
    onChange: (newText: string) => void;
    onEnter?: () => void;

    label: string;

    placeholder?: boolean;
    placeholderText?: string;

    autoFocus?: boolean;
    readOnly?: boolean;
}

const PasswordBox = (props: PasswordBoxProps): JSX.Element | null => {
    const onKeyDown = (e: React.KeyboardEvent) => {
        if (e.keyCode === 13 && props.onEnter)
            props.onEnter();
    }

    const onChange = (_event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string) => props.onChange(newValue ? newValue : "");

    const placeHolder = props.placeholderText ? props.placeholderText : (props.placeholder ? props.label : undefined);

    return (
        <TextField
            autoFocus={props.autoFocus}
            onKeyDown={onKeyDown}
            type="password"
            label={props.label}
            placeholder={placeHolder}
            value={props.text}
            onChange={onChange}
            readOnly={props.readOnly}
            canRevealPassword
        />
    );
};

export default PasswordBox;
