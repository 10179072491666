import isArray from "lodash/isArray";
import isEqual from "lodash/isEqual";
import sortBy from "lodash/sortBy";
import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { useRegisterSaveCheck } from "../save-check";

const useCopy = <T>(original: T, comparePropertyName?: string): [T, Dispatch<SetStateAction<T>>, boolean] => {
    const [copy, setCopy] = useState(original);
    const [equal, setEqual] = useState(true);

    useRegisterSaveCheck(!equal);

    useEffect(() => {
        setCopy(structuredClone(original));
    }, [original]);

    useEffect(() => {
        if (isArray(original)) {
            const aa = sortBy(original, [comparePropertyName]);
            const arr2 = sortBy(copy as unknown as [], [comparePropertyName]);
            setEqual(isEqual(aa, arr2));
        }
        else
            setEqual(isEqual(original, copy));
    }, [original, copy, comparePropertyName]);

    return [copy, setCopy, !equal];
}

export default useCopy;
