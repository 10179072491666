import { useContext } from "react";
import { SaveCheckContext } from "./SaveCheckContext";

const useHasChanges = () => {
    const handle = useContext(SaveCheckContext);

    return handle.hasChanges
}

export default useHasChanges;
