import { useState } from "react";
import { put, useSaveCallout } from "../../improve-lib";
import { RoleUpdateModel } from "../../model";

const useUpdateRole = (afterSuccessfulSave?: () => void) => {
    const saveCallout = useSaveCallout();

    const [duplicatedIsoCodes, setDuplicatedIsoCodes] = useState<string[]>([]);

    const updateRole = async (id: number, role: RoleUpdateModel) => {
        const response = await put("roles/" + id, role);

        switch (response.status) {
            case 200:
                saveCallout.show();
                if (afterSuccessfulSave)
                    afterSuccessfulSave();
                break;
            case 400:
                setDuplicatedIsoCodes(response.body as string[]);
                break;
            default:
                throw response;
        }
    }

    return { updateRole, duplicatedIsoCodes }
}

export default useUpdateRole;