import { getTheme } from "@fluentui/react";
import { useResources } from "../../../improve-lib";

const Copyright = (): JSX.Element => {
    const theme = getTheme();

    const resources = useResources();

    const linkStyle = {
        color: theme.palette.themePrimary,
        fontSize: theme.fonts.small.fontSize,
        textDecoration: "none"
    }

    return (
        <label style={{ color: theme.palette.themePrimary, fontSize: theme.fonts.small.fontSize }}>
            © {process.env.NODE_ENV === "production" ? process.env.REACT_APP_COPYRIGHT_YEAR : new Date().getFullYear()}
            <a rel="noopener noreferrer" href="https://www.synprovis.ch" target="_blank" style={linkStyle}> Synprovis GmbH </a>
            |
            <a rel="noopener noreferrer" href="tel:+41417852070" style={linkStyle}> {resources.phone} +41 41 785 20 70 </a>
            |
            <a rel="noopener noreferrer" href="mailto:support@synprovis.ch" style={linkStyle}> support@synprovis.ch</a>
        </label>
    )
}

export default Copyright;
