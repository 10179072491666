import { get } from "../../improve-lib";
import { DocumentTagModel } from "../../model";

const useSearchTags = () => {
    const searchTags = async (filterText: string, selectedTagIds: number[]): Promise<DocumentTagModel[]> => {
        let idQueryString = "";

        if (selectedTagIds.length > 0) {
            const idValueStrings: string[] = [];

            for (let i = 0; i < selectedTagIds.length; i++)
                idValueStrings.push("selectedIds=" + selectedTagIds[i]);

            idQueryString = idValueStrings.join("&");
        }

        const response = await get("tags/search?filterText=" + filterText + "&" + idQueryString);

        if (response.status !== 200)
            throw response;

        return response.body;
    }

    return { searchTags }
}

export default useSearchTags;