import { useState } from "react";
import { useLabels, useResources } from "../../../improve-lib";
import { Tabs } from "../../../lib";
import ContactContainer from "./ContactContainer";
import ContactLabelsContainer from "./ContactLabelsContainer";

type ContactPageProps = {
    kind: "customer" | "supplier";
};

const ContactPage = (props: ContactPageProps): JSX.Element => {
    const resources = useResources();
    const labels = useLabels();

    const [index, setIndex] = useState(0);

    return (
            <Tabs
                onClick={setIndex}
                selectedIndex={index}
                tabs={[
                    { header: props.kind === "customer" ? labels.customer : labels.supplier, control: <ContactContainer kind={props.kind} /> },
                    { header: resources.labels, control: <ContactLabelsContainer kind={props.kind} /> }
                ]} />
    )
}

export default ContactPage;
