import { ContactKind, useContact, useDeleteContact, useUpdateContact } from "../../../api/contacts";
import { useConfirmDeleteCallback, useCopy, useHasChanges, useLabels, useResources, useSaveCheck } from "../../../improve-lib";
import { CheckBoxList, ChildWithLabel, Popup, TextBox } from "../../../lib";
import "./EditContactPopup.tsx.scss";

type EditContactPopupProps = {
    contactId: number;
    onEdited: () => void;
    onCancel: () => void;
}

const EditContactPopup = (props: EditContactPopupProps) => {
    const resources = useResources();
    const labels = useLabels();
    const hasChanges = useHasChanges();

    const originalContact = useContact(props.contactId);
    const [contact, setContact] = useCopy(originalContact);

    const deleteContact = useDeleteContact();
    const onDelete = useConfirmDeleteCallback(async () => {
        await deleteContact(props.contactId);
        props.onEdited();
    }, true);
    const updateContact = useUpdateContact();
    const onDismiss = useSaveCheck(props.onCancel);

    if (!contact || !originalContact)
        return null;

    const onSubsidiaryCheckedChanged = (id: number, checked: boolean) => {
        const copy = [...contact.subsidiaries];

        copy.find(s => s.id === id)!.assigned = checked;

        setContact(c => ({ ...c!, subsidiaries: copy }))
    }

    const onSaveAddress = async () => {
        await updateContact(props.contactId, contact);
        props.onEdited();
    }

    const nameNrLabel = originalContact.kind === ContactKind.Customer ? labels.customerNumber : labels.supplierNumber;
    const nameLabel = originalContact.kind === ContactKind.Customer ? labels.customer : labels.supplier;

    const mandatoryFilled = contact.nr.trim().length > 0 && contact.name.trim().length > 0;

    return (
        <Popup
            title={`${resources.edit}: ${originalContact.name}`}
            onDismiss={onDismiss}
            size="medium"
            onSave={onSaveAddress}
            saveDisabled={!hasChanges || !mandatoryFilled}
            actions={[{ onClick: () => onDelete(props.contactId, contact.nr), text: resources.delete, iconName: "delete" }]}
            closeText={(hasChanges ? resources.cancel : resources.close)}
            isOpen
        >
            <article>
                <div className="article-content">
                    <TextBox label={nameNrLabel} text={contact.nr} onChange={newValue => setContact(c => ({ ...c!, nr: newValue }))} autoFocus mandatory />

                    <TextBox label={nameLabel} text={contact.name} onChange={newValue => setContact(c => ({ ...c!, name: newValue }))} mandatory />
                </div>
                <div className="article-content">
                    <TextBox label={resources.street} text={contact.street} onChange={newValue => setContact(c => ({ ...c!, street: newValue }))} />

                    <TextBox label={resources.zipCode} text={contact.zipCode} onChange={newValue => setContact(c => ({ ...c!, zipCode: newValue }))} />

                    <TextBox label={resources.city} text={contact.city} onChange={newValue => setContact(c => ({ ...c!, city: newValue }))} />

                    <TextBox label={resources.country} text={contact.country} onChange={newValue => setContact(c => ({ ...c!, country: newValue }))} />
                </div>
                <div className="ip-check-box-list">
                    <ChildWithLabel label={resources.subsidiaries}>
                        <CheckBoxList
                            onCheckedChanged={onSubsidiaryCheckedChanged}
                            items={contact.subsidiaries.map(s =>
                            ({
                                id: s.id,
                                text: s.name,
                                checked: s.assigned
                            }))}
                        />
                    </ChildWithLabel>
                </div>
            </article>
        </Popup>
    )
}

export default EditContactPopup;
