import { useEffect, useState } from "react";
import { get, useLanguage, useToggle } from "../../improve-lib";
import { EditApplicationAreaModel } from "../../model";

const useApplicationArea = (id: number) => {
    const lang = useLanguage();

    const [reloadFlag, reload] = useToggle();
    const [applicationArea, setApplicationArea] = useState<EditApplicationAreaModel>();

    useEffect(() => {
        const getApplicationArea = async () => {
            const response = await get("applicationareas/" + id);

            if (response.status !== 200)
                throw response;

            const applicationArea: EditApplicationAreaModel = response.body;
            applicationArea.dateTimeCreated = new Date(applicationArea.dateTimeCreated);
            applicationArea.dateTimeLastEdited = new Date(applicationArea.dateTimeLastEdited);
            setApplicationArea(applicationArea);
        }

        void getApplicationArea();
    }, [id, lang, reloadFlag]);

    return { applicationArea, reload }
}

export default useApplicationArea;
