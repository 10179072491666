import { MessageBar,MessageBarProps } from "..";

type MessageStackProps = {
    messages: MessageBarProps[];
}

const MessageStack = (props: MessageStackProps): JSX.Element => {
    return (
        <>
            {props.messages.map((m, i) => <MessageBar key={i} message={m.message} type={m.type} />)}
        </>
    )
}

export default MessageStack;
