import { is } from "bpmn-js/lib/util/ModelUtil";
import "./CustomContextPad.js.scss";

export default class CustomContextPad {
    static $inject = ["contextPad", "config.getResources", "config.customContextPadEntries"];

    static EventName = "element.event";

    constructor(contextPad, getResources, entries) {
        contextPad.registerProvider(this);
        this.getResources = getResources;
        this.Entries = entries ?? [];
    }

    getContextPadEntries(element) {
        return entries => {
            let customEntries = {};
            if (is(element, "bpmn:BaseElement") && element.type !== "label") {
                delete entries["append.text-annotation"];
                delete entries["append.intermediate-event"];

                if (is(element, "bpmn:Task"))
                    delete entries["replace"];

                if (entries["connect"])
                    entries["connect"].group = "edit";

                if (entries["delete"])
                    entries["delete"].group = "custom";

                for (let i = 0; i < this.Entries.length; i++) {
                    if (this.Entries[i].showEntry(element)) {
                        customEntries[CustomContextPad.EventName + String(i)] = {
                            group: "custom",
                            className: this.Entries[i].className,
                            title: this.getResources()[this.Entries[i].title],
                            action: {
                                click: (event, element) => {
                                    this.Entries[i].func(event, element);
                                }
                            }
                        };
                    }
                }
            }

            return Object.assign(customEntries, entries);
        };
    }
}