import { useCallback, useState } from "react";
import { MultiLanguageDescriptionDialog, useResources } from "../../improve-lib";
import { Button, MultiLineTextBox } from "../../lib";
import { MultiLanguageDescriptionModel } from "../../model";
import "./MultiLanguageDescription.tsx.scss";


type MultiLanguageDescriptionProps = {
    languageDescriptions: MultiLanguageDescriptionModel;
    onChange: (content: MultiLanguageDescriptionModel) => void;
    textBoxHeader?: string;
    mandatory?: boolean;
    minRows?: number;
}

const MultiLanguageDescription = (props: MultiLanguageDescriptionProps): JSX.Element => {
    const resources = useResources();

    const [dialogOpen, setDialogOpen] = useState(false);

    const onChangeText = (newValue: string) => {
        const newLanguageDescriptions = { ...props.languageDescriptions };
        const descriptions = Object.values(newLanguageDescriptions);

        if (descriptions.length > 1) {
            if (descriptions.every(d => d === descriptions[0])) {
                for (const isoCode in props.languageDescriptions)
                    newLanguageDescriptions[isoCode] = newValue;
            }
            else {
                setDialogOpen(true);
                newLanguageDescriptions[resources.isoCode] = newValue;
            }
        }
        else
            newLanguageDescriptions[resources.isoCode] = newValue;

        props.onChange(newLanguageDescriptions);
    }

    const isValid = useCallback(() => {
        const anyFieldFilled = Object.values(props.languageDescriptions).some(ld => ld.length > 0);
        const allFieldsFilled = Object.values(props.languageDescriptions).every(ld => ld.trim().length > 0);

        if (props.mandatory || anyFieldFilled)
            return allFieldsFilled;

        return true;
    }, [props.languageDescriptions, props.mandatory]);
    
    return Object.keys(props.languageDescriptions).length > 1 ?
        <>
            <div className="multi-language-desc-main-wrapper">
                <div className="multi-language-desc-wrapper">
                    <MultiLineTextBox
                        label={props.textBoxHeader === undefined ? "" : props.textBoxHeader}
                        text={props.languageDescriptions[resources.isoCode]}
                        onChange={onChangeText}
                        mandatory={props.mandatory}
                        valid={isValid()}
                        minRows={props.minRows}
                        autoAdjustheight
                    />
                </div>
                <div className="multi-language-desc-btn-wrapper">
                    <Button onClick={() => setDialogOpen(true)} text={resources.edit} secondary />
                </div>
            </div>
            <MultiLanguageDescriptionDialog
                languageDescriptions={props.languageDescriptions}
                mandatory={props.mandatory}
                onChange={props.onChange}
                hidden={!dialogOpen}
                resourceLabel={props.textBoxHeader ?? ""}
                onDismiss={() => setDialogOpen(false)}
            />
        </>
        :
        <div className="single-language-desc-wrapper">
            <MultiLineTextBox
                minRows={props.minRows}
                autoFocus
                autoAdjustheight
                placeholder
                label={props.textBoxHeader ?? ""}
                text={props.languageDescriptions[resources.isoCode]}
                mandatory={props.mandatory}
                onChange={onChangeText}
            />
        </div>
}

export default MultiLanguageDescription;