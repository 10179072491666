import { useEffect, useRef, useState } from "react";
import { useCreateDocument, useSearchTags, useUsersWithWritePersmissionDocuments } from "../../../../api";
import { PdfFileUpload, useIsEqual, useLabels, useLanguage, useRegisterSaveCheck, useResources, useSaveCheck, useUserSettings } from "../../../../improve-lib";
import { DatePicker, MultiLanguageDescription, Popup, TextBox, TextWithLabel, UserSelection } from "../../../../lib";
import { DocumentTagModel, IdValuePair, MultiLanguageDescriptionModel, NewDocumentModel } from "../../../../model";
import DocumentTagPicker from "../../_lib/tags/TagPicker";
import DocumentsTagsList from "../../_lib/tags/TagsList";

type NewDocumentPopupProps = {
    isOpen: boolean;
    subsidiariesCount: number;
    onDocumentCreated: (newDocumentId: number) => void;
    onAbort: () => void;
    subsidiary: IdValuePair | null;
    applicationArea: IdValuePair;
}

const NewDocumentPopup = (props: NewDocumentPopupProps): JSX.Element => {
    const lang = useLanguage();
    const userSettings = useUserSettings();
    const labels = useLabels();
    const resources = useResources();

    const { searchTags } = useSearchTags();
    const { userList } = useUsersWithWritePersmissionDocuments(props.applicationArea.id, props.subsidiary?.id ?? null);

    const initDocument = useRef<NewDocumentModel>({
        filename: "",
        file: new File([""], ""),
        subsidiary: props.subsidiary,
        applicationArea: { id: props.applicationArea.id, value: props.applicationArea.value },
        tags: [],
        owner: { id: userSettings.userId, value: userSettings.displayName },
        version: "",
        dateValidUntil: null,
        description: lang.reduce((dic, isoCode) => ({ ...dic, [isoCode]: "" }), {}),
        convertToPdf: true
    });
    const [document, setDocument] = useState<NewDocumentModel>(initDocument.current);

    const { createDocument } = useCreateDocument((id: number) => {
        props.onDocumentCreated(id);
        props.onAbort();
    })

    useEffect(() => {
        if (props.isOpen)
            setDocument(initDocument.current);  //to make sure it has initial state on reload of popup
    }, [props.isOpen])

    const onFileChanged = (file?: File) => {
        if (file)
            setDocument(d => { return { ...d, file: file, filename: file.name } });
    }

    const onConvertToggle = (checked: boolean) => setDocument({ ...document, convertToPdf: checked });

    const onFilenameChanged = (filename: string) => {
        const newDoc = { ...document, filename: filename };
        setDocument(newDoc);
    }

    const pickerOnTextChanged = async (filterText: string): Promise<DocumentTagModel[]> =>
        await searchTags(filterText, document.tags.map(tag => tag.id));

    const pickerOnItemSelected = (tag: DocumentTagModel) => {
        const newDoc = { ...document, tags: [...document.tags] };
        newDoc.tags.push(tag);
        setDocument(newDoc);
    }

    const onRemoveTagClick = (id: number) => {
        const newDoc = { ...document, tags: [...document.tags] };
        newDoc.tags = newDoc.tags.filter(t => t.id !== id);
        setDocument(newDoc);
    }

    const userOnGet = (_search: string | null): Promise<{ results: IdValuePair[], full: boolean }> => {
        return new Promise((resolve, _reject) => {
            resolve({ results: userList, full: true })
        });
    }

    const onUserChanged = (user: IdValuePair) => {
        const newDoc = { ...document, owner: user };
        setDocument(newDoc);
    }

    const onVersionChanged = (version: string) => {
        const newDoc = { ...document, version: version };
        setDocument(newDoc);
    }

    const onDateChanged = (date: Date | null) => {
        const newDoc = { ...document, dateValidUntil: date };
        setDocument(newDoc);
    }

    const onDescriptionChanged = (description: MultiLanguageDescriptionModel) => {
        const newDoc = { ...document, description: description };
        setDocument(newDoc);
    }

    const documentIsValid = () => document?.file !== undefined && document?.filename !== "" && document?.applicationArea?.id !== -1;

    const anyChanges = !useIsEqual(document, initDocument.current);

    useRegisterSaveCheck(anyChanges);

    const onAbort = useSaveCheck(props.onAbort);

    const requestCreateDocument = async () => await createDocument(document);


    return (
        <Popup
            isOpen={props.isOpen}
            title={resources.importDocument}
            onDismiss={onAbort}
            onSave={requestCreateDocument}
            saveDisabled={!documentIsValid() || !anyChanges}
            closeText={(documentIsValid() ? resources.cancel : resources.close)}
            size="medium"
        >
            <article>
                <div className="article-content">
                    <TextWithLabel
                        label={resources.applicationArea}
                        text={props.applicationArea.value}
                    />
                    {props.subsidiariesCount > 1 &&
                        <TextWithLabel
                            label={resources.subsidiary}
                            text={props.subsidiary?.value ?? resources.allSubsidiaries}
                        />
                    }
                </div>
                <div className="article-form-element">
                    <PdfFileUpload
                        onFileChanged={onFileChanged}
                        onConvertToggle={onConvertToggle}
                        onFilenameChanged={onFilenameChanged}
                        filename={document.filename}
                        convertToPdfDefault={document.convertToPdf}
                        requireFile
                    />
                </div>
                <div className="article-content">
                    <DocumentTagPicker label={resources.tags} onTextChanged={pickerOnTextChanged} onItemSelected={pickerOnItemSelected} />
                    <DocumentsTagsList tags={document.tags} size="normal" onRemoveClick={onRemoveTagClick} />
                </div>
                <div className="article-content">
                    <UserSelection
                        label={labels.documentOwner}
                        selectedUser={document.owner}
                        onUserChanged={onUserChanged}
                        onGet={userOnGet}
                        mandatory
                    />
                    <TextBox label={labels.documentVersion} maxLength={40} text={document.version} onChange={newVersion => onVersionChanged(newVersion)} />
                    <DatePicker label={labels.documentValidUntil} date={document.dateValidUntil} onDateChanged={onDateChanged} />
                </div>
                <div className="article-form-element">
                    <MultiLanguageDescription
                        languageDescriptions={document.description}
                        onChange={onDescriptionChanged}
                        textBoxHeader={labels.documentDescription}
                    />
                </div>
            </article>
        </Popup>
    )
}

export default NewDocumentPopup;
