import { createContext } from "react";

export type SaveCalloutContextType = {
    show: () => void;
}

const SaveCalloutContext = createContext<SaveCalloutContextType>({
    show: () => { }
});

export { SaveCalloutContext };