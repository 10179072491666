import { useState } from "react";
import { useConfirmDeleteCallback, useLanguage, useResources } from "../../improve-lib";
import { MultiLanguageFieldModel } from "../../model";
import { ErrorType, IconButton, MultiLanguageTextField } from "..";
import "./EditableMultiLanguageList.tsx.scss";

type EditableMultiLanguageListProps = {
    entries: { id?: number, name: MultiLanguageFieldModel}[];
    onChange: (changedEntries: { id?: number, name: MultiLanguageFieldModel }[]) => void;
}

const EditableMultiLanguageList = (props: EditableMultiLanguageListProps): JSX.Element => {
    const lang = useLanguage();
    const resources = useResources();

    const emptyEntry: MultiLanguageFieldModel = lang.map(l => { return { isoCode: l, label: "" } });

    const [newEntry, setNewEntry] = useState(emptyEntry);
    const [newEntryValid, setNewEntryValid] = useState(false);
    const [newEntryDuplicatedIsoCodes, setNewEntryDuplicatedIsoCodes] = useState<string[]>([]);

    const [duplicatedEntries, setDuplicatedEntries] = useState <{index: number, isoCodes: string[]}[]>([]);

    const onNewEntryChange = (newValue: MultiLanguageFieldModel) => {
        const allFieldsFilled = newValue.every(n => n.label.trim().length > 0);
        const duplicatedIsoCodes = newValue.flatMap(n => {
            const trimmedLabel = n.label.trim().toLowerCase();
            const duplicated = props.entries.some(entry => entry.name.find(l => l.isoCode === n.isoCode)?.label.toLowerCase() === trimmedLabel);
            return trimmedLabel.length > 0 && duplicated ? n.isoCode : [];
        });

        setNewEntry(newValue);
        setNewEntryDuplicatedIsoCodes(duplicatedIsoCodes);
        setNewEntryValid(allFieldsFilled && duplicatedIsoCodes.length < 1);
    }

    const onEntryAdd = () => {
        if (newEntryValid) {
            props.onChange([...props.entries, { name: newEntry }]);
            setNewEntry(emptyEntry);
            setNewEntryValid(false);
        }
    }

    const onEntryChange = (index: number, newValue: MultiLanguageFieldModel) => {
        const newEntries = [...props.entries];
        newEntries[index].name = newValue;
        onEntriesChange(newEntries, index);
    }

    const onEntryDelete = useConfirmDeleteCallback((index: number) => {
        const newEntries = [...props.entries];
        newEntries.splice(index, 1);
        onEntriesChange(newEntries);
    });

    const onEntriesChange = (newEntries: { id?: number, name: MultiLanguageFieldModel }[], index?: number) => {
        const duplicates = newEntries.flatMap((entry1, idx1) => {
            const duplicatedIsoCodes = entry1.name.flatMap(lf1 => {
                const trimmedLabel = lf1.label.trim().toLowerCase();
                const duplicated = newEntries.some((entry2, idx2) => {
                    const indexToCheck = (idx1 === index && idx1 !== idx2) || (idx1 > idx2 && idx2 !== index);
                    return indexToCheck && entry2.name.find(lf2 => lf2.isoCode === lf1.isoCode)?.label.toLowerCase() === trimmedLabel;
                });
                return trimmedLabel.length > 0 && duplicated ? lf1.isoCode : [];
            });

            return duplicatedIsoCodes.length > 0 ? { index: idx1, isoCodes: duplicatedIsoCodes } : [];
        });

        setDuplicatedEntries(duplicates);

        props.onChange(newEntries);
    }

    return (
        <>
            {props.entries.map((entry, index) => (
                <div key={index} className="edit-multi-lang-list-entry" >
                    <div className="edit-multi-lang-list-txtfield" >
                        <MultiLanguageTextField
                            languageFields={entry.name}
                            placeholderText={resources.label}
                            onChange={content => onEntryChange(index, content)}
                            duplicatedIsoCodes={duplicatedEntries.find(d => d.index === index)?.isoCodes ?? []}
                            mandatory
                            error={duplicatedEntries.some(d => d.index === index) ? ErrorType.duplicated : undefined}
                        />
                    </div>
                    <IconButton iconName={"Delete"} onClick={() => onEntryDelete(index, entry.name.find(n => n.isoCode === resources.isoCode)?.label ?? "")} />
                </div>
            ))}
            <div className="edit-multi-lang-list-newentry" >
                <div className="edit-multi-lang-list-txtfield" >
                    <MultiLanguageTextField
                        languageFields={newEntry}
                        placeholderText={resources.label}
                        onChange={onNewEntryChange}
                        onEnter={onEntryAdd}
                        duplicatedIsoCodes={newEntryDuplicatedIsoCodes}
                        autoFocus
                        error={newEntryDuplicatedIsoCodes.length > 0 ? ErrorType.duplicated : undefined}
                    />
                </div>
                <IconButton iconName={"Add"} onClick={onEntryAdd} disabled={!newEntryValid} />
            </div>
        </>
    );
}

export default EditableMultiLanguageList;