import { useState } from "react";
import { useNavigate } from "react-router";
import { TopBar } from "../../improve-lib";
import img from "./../../public/image/LoginImage.jpg";
import Copyright from "./components/Copyright";
import Login from "./components/Login";
import PasswordReset from "./components/PasswordReset";
import "./LoginPage.tsx.scss";

const LoginPage = (): JSX.Element => {
    const navigate = useNavigate()
    const loginRedirect = () => navigate("/opentasks"); //TODO: change to dashboard once available

    const [resetPassword, setResetPassword] = useState(false);

    return (
        <>
            <TopBar open={false} onToggle={() => { }} />
            <div className="login-page-wrapper">
                <div className="login-page-image-container">
                    <img className="login-page-image" src={img} alt="" />
                </div>
                <div className="login-page-login-wrapper">
                    <div className="login-page-login">
                        {resetPassword ?
                            <PasswordReset onSuccsessfulChange={loginRedirect} /> :
                            <Login onSuccsessfulLogin={loginRedirect} onPasswordTemporary={() => setResetPassword(true)} />}
                    </div>
                    <div className="login-page-copyright">
                        <Copyright />
                    </div>
                </div>
            </div>
        </>
    )
}

export default LoginPage;
