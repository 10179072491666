import { useState } from "react";
import { useUpdateCustomFieldsOrder } from "../../../api";
import { DragDropList, RowAction, Text } from "../../../lib";
import GroupedListItem from "../../../lib/list/model/groupedListItem";
import { CustomFieldItem, IdValuePair, ImproveModule } from "../../../model";
import UpdateCustomFieldOrderModel from "../../../model/customFields/updateCustomFieldOrderModel";
import { Container, useRegisterSaveCheck, useResources } from "../..";


type ReorderCustomFieldsProps = {
    customFields: CustomFieldItem[];
    module: ImproveModule;
    locations: IdValuePair[];
    canChangeLocation?: boolean;
    refresh: () => void;
    onCancel: () => void;
}

const ReorderCustomFields = (props: ReorderCustomFieldsProps): JSX.Element => {
    const resources = useResources();

    const [items, setItems] = useState(props.customFields.map(af => ({ id: af.id, value: af.name, groupId: af.location })));

    const [anyChanges, setAnyChanges] = useState(false);
    useRegisterSaveCheck(anyChanges);
    const { updateCustomFieldsOrder } = useUpdateCustomFieldsOrder();

    const detectChanges = (editedItems: GroupedListItem[]) => {
        if (props.customFields.some((af, idx) => idx !== editedItems.findIndex(i => i.id === af.id)))
            return true;
        if (props.customFields.some(af => af.location !== editedItems.find(i => i.id === af.id)?.groupId))
            return true;

        return false;
    }

    const onChange = (newItems: GroupedListItem[]) => {
        setItems(newItems);
        setAnyChanges(detectChanges(newItems));
    }

    const onSave = async () => {
        const updateModel: UpdateCustomFieldOrderModel[] = [];

        for (const location of props.locations) {
            const filteredItems = items.filter(i => i.groupId === location.id);
            const mappedItems = filteredItems.map((i, idx) => ({ id: i.id, location: i.groupId, position: idx }));
            updateModel.push(...mappedItems);
        }

        await updateCustomFieldsOrder(props.module, updateModel);

        setAnyChanges(false);
        props.refresh();
    }

    const saveAction: RowAction = { iconName: "Save", onClick: onSave, text: resources.save, disabled: !anyChanges };
    const cancelAction: RowAction = { iconName: "Cancel", onClick: props.onCancel, text: resources.cancel };

    return (
        <Container title={resources.reorderFields} actions={[saveAction, cancelAction]}>
            <div style={{ marginLeft: 5, marginBottom: 10 }}>
                <Text size={"mediumPlus"} text={resources.reorderByDragging} />
            </div>
            <DragDropList
                items={items}
                groups={props.locations}
                canDropInOtherGroup={props.canChangeLocation}
                onChange={onChange}
            />
        </Container>
    );
}

export default ReorderCustomFields;