import { useState } from "react";
import { post, useSaveCallout } from "../../improve-lib";
import { NewSubsidiaryModel } from "../../model";

const useCreateSubsidiary = (afterCreated?: (id: number) => void) => {
    const saveCallout = useSaveCallout();

    const [nameDuplicated, setNameDuplicated] = useState(false);

    const createSubsidiary = async (subsidiary: NewSubsidiaryModel) => {
        const response = await post("subsidiaries", subsidiary);

        switch (response.status) {
            case 200:
                saveCallout.show();
                if (afterCreated)
                    afterCreated(response.body as number);
                break;
            case 400:
                setNameDuplicated(response.body as boolean);
                break;
            default:
                throw response;
        }
    }

    return { createSubsidiary, nameDuplicated }
}

export default useCreateSubsidiary;