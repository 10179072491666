import { Breadcrumb as FluentBreadcrumb, IBreadcrumbItem } from "@fluentui/react";
import { useMemo } from "react";
import { IdValuePair } from "../../model";

type BreadcrumbProps = {
    items: IdValuePair[];
    firstItemText?: string;
    currentItemId: number;
    maxDisplayedItems?: number;
    onItemClick: (id: number) => void;
    onFirstItemClick?: () => void;
}

const Breadcrumb = (props: BreadcrumbProps): JSX.Element => {
    const breadcrumbItems = useMemo(() => {
        const items = props.items.map(i => (
            {
                key: i.id.toString(),
                text: i.value,
                isCurrentItem: (i.id === props.currentItemId),
                onClick: () => props.onItemClick(i.id)
            } as IBreadcrumbItem)
        );

        if (props.firstItemText) {
            items.unshift({
                key: "home",
                text: props.firstItemText,
                onClick: props.onFirstItemClick
            });
        }

        return items;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.items, props.currentItemId]);

    return (
        <FluentBreadcrumb
            items={breadcrumbItems}
            maxDisplayedItems={props.maxDisplayedItems}
            style={{ marginTop: 4 }}
        />
    );
}

export default Breadcrumb;