import { useContext } from "react";
import { useResources } from "../language";
import { DialogContext } from "./DialogContext";

const useToggleActiveDialog = <T extends (id: number, active: boolean) => any>(onToggle: T): (id: number, active: boolean) => void => {
    const resources = useResources();
    const ctx = useContext(DialogContext);

    return (id: number, active: boolean) => ctx.openDialog({ callback: () => onToggle(id, active), dialog: { type: "confirm", confirmationText: active ? resources.active : resources.deactivate } })
}

export default useToggleActiveDialog;
