import isArray from "lodash/isArray";
import isEqual from "lodash/isEqual";
import sortBy from "lodash/sortBy";
import { useEffect, useState } from "react";

const useIsEqual = <T>(obj1: T, obj2: T, comparePropertyName?: string): boolean => {
    const [equal, setEqual] = useState(true);

    useEffect(() => {
        if (isArray(obj1)) {
            const aa = sortBy(obj1, [comparePropertyName]);
            const arr2 = sortBy(obj2 as unknown as [], [comparePropertyName]);
            setEqual(isEqual(aa, arr2));
        }
        else
            setEqual(isEqual(obj1, obj2));
    }, [obj1, obj2, comparePropertyName])

    return equal
}

export default useIsEqual;
