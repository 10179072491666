import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useCountAllSubsidiaries, usePermittedProcessGroups, useProcess, useProcessLandscapePerSubsidiary } from "../../api";
import { Container, useResources } from "../../improve-lib";
import { useUserSettings } from "../../improve-lib/settings";
import { RowAction } from "../../lib";
import { IdValuePair, ProcessGroupPermissionType } from "../../model";
import ProcessesSearch from "./components/ProcessesSearch";
import ProcessViewer from "./details/diagram/ProcessViewer";
import NewProcessPopup from "./new/NewProcessPopup";
import useNewAction from "./new/useNewAction";
import { ProcessesProvider } from "./ProcessesProvider";
import ProcessesSettingsPopup from "./settings/ProcessesSettingsPopup";
import "./ProcessesPage.tsx.scss";

const ProcessesPage = (): JSX.Element | null => {
    const navigate = useNavigate();
    const resources = useResources();
    const userSettings = useUserSettings();

    const [settingsPopupVisible, setSettingsPopupVisible] = useState(false);

    const { subsidiariesCount } = useCountAllSubsidiaries();
    const [subsidiaries, setSubsidiaries] = useState<IdValuePair[]>([]);

    const [editProcessLandscapeAllowed, setEditProcessLandscapeAllowed] = useState(false);
    const [selectedProcessLandscape, setSelectedProcessLandscape] = useState<number>();

    const { permittedProcessGroups } = usePermittedProcessGroups();
    const { dbProcess, processPermission, dataReadyFlag } = useProcess(selectedProcessLandscape, permittedProcessGroups);
    const { processLandscapePerSubsidiary } = useProcessLandscapePerSubsidiary(permittedProcessGroups);

    const onProcessIdChanged = (newId: number, path: number[], editMode = false) => {
        onPopupClose();
        navigate("/process/" + newId, {
            state: {
                processPath: path
            }
        });
        if (editMode)
            localStorage.setItem("processesEditMode", editMode.toString());
    }

    const onSubsidiaryClick = (subsidiaryId: number) => {
        if (processLandscapePerSubsidiary && processLandscapePerSubsidiary.length > 0) {
            const processId =
                processLandscapePerSubsidiary.find(p => p.subsidiaryId === subsidiaryId)?.processId ??
                processLandscapePerSubsidiary.find(p => p.subsidiaryId === null)?.processId ??
                processLandscapePerSubsidiary[0].processId;

            setSelectedProcessLandscape(processId);
        }
    }


    const onNewProcessCreated = (newProcess: IdValuePair) => {
        onPopupClose();
        navigate("/process/" + newProcess.id);
    }

    useEffect(() => {
        if (processLandscapePerSubsidiary.length > 0)
            setSelectedProcessLandscape(processLandscapePerSubsidiary.find(p => p.subsidiaryId === null)?.processId ?? processLandscapePerSubsidiary[0].processId);

        const subsidiariesWithReadPermission: IdValuePair[] = [];

        for (const processGroup of permittedProcessGroups) {
            for (const sp of processGroup.subsidiaryPermissions) {
                if (sp.permission >= ProcessGroupPermissionType.Read &&
                    !subsidiariesWithReadPermission.some(s => s.id === sp.subsidiary.id))
                    subsidiariesWithReadPermission.push(sp.subsidiary);
            }
        }

        setSubsidiaries(subsidiariesWithReadPermission);
    }, [permittedProcessGroups, processLandscapePerSubsidiary]);

    useEffect(() => {
        if (processPermission) {
            setEditProcessLandscapeAllowed(processPermission >= ProcessGroupPermissionType.Write);
        }
    }, [processPermission, dbProcess?.id]);

    const { newAction, newProcessPopupVisible, newProcessInfo, onPopupClose } = useNewAction(subsidiariesCount, permittedProcessGroups);

    const actions = [...newAction];

    const settingsAction: RowAction = { iconName: "Settings", onClick: () => { setSettingsPopupVisible(true) }, text: resources.settings, disabled: false };
    const editAction: RowAction = { iconName: "Edit", onClick: () => { onProcessIdChanged(dbProcess!.id, [dbProcess!.id], true) }, text: resources.edit };

    if (userSettings.permissions.processesEditProcessGroups)
        actions.push(settingsAction);

    return (
        <section className="split-view-4-8">
            <ProcessesProvider>
                <div className="content-container">
                    <Container title={resources.processes} actions={actions}>
                        <div className="process-search-bar">
                            <ProcessesSearch
                                subsidiaries={subsidiaries}
                                onSubsidiaryClick={onSubsidiaryClick}
                                onProcessClick={onProcessIdChanged}
                            />
                        </div>
                        {newProcessInfo &&
                            <NewProcessPopup
                                processGroup={newProcessInfo.processGroup}
                                subsidiary={newProcessInfo.subsidiary}
                                subsidiariesCount={subsidiariesCount}
                                isOpen={newProcessPopupVisible}
                                processLandscapes={processLandscapePerSubsidiary}
                                onAbort={onPopupClose}
                                onProcessCreated={onNewProcessCreated}
                            />
                        }

                        <ProcessesSettingsPopup
                            isOpen={settingsPopupVisible}
                            onClose={() => { setSettingsPopupVisible(false) }}
                        />
                    </Container>
                </div>
                <div className="content-container">
                    {dbProcess &&
                        <Container
                            title={dbProcess.name.find(n => n.isoCode === resources.isoCode)!.label}
                            actions={editProcessLandscapeAllowed ? [editAction] : []}
                        >
                            <ProcessViewer
                                id={dbProcess.id}
                                flag={dataReadyFlag}
                                process={{
                                    name: dbProcess.name,
                                    owner: dbProcess.owner,
                                    description: dbProcess.description,
                                    links: dbProcess.links,
                                    customFields: dbProcess.customFields
                                }}
                                elements={dbProcess.elements}
                                bpmn={dbProcess.bpmn}
                                expandInfoPanel={false}
                                tracked={{
                                    userCreated: dbProcess.userCreated,
                                    dateTimeCreated: dbProcess.dateTimeCreated,
                                    userLastEdited: dbProcess.userLastEdited,
                                    dateTimeLastEdited: dbProcess.dateTimeLastEdited
                                }}
                                processPermission={ProcessGroupPermissionType.None}
                                onChangeToEditMode={() => { }}
                                onProcessIdChanged={id => onProcessIdChanged(id, [])}
                            />
                        </Container>

                    }
                </div>
            </ProcessesProvider>
        </section>
    );
}

export default ProcessesPage;
