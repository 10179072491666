import { CreatedEditedFooter, useResources } from "../../../improve-lib";
import { ErrorType, TextBox } from "../../../lib"
import { BadUserCreationResponse, EditUserModel } from "../../../model";
import "./UserForm.tsx.scss";

type UserFormProps = {
    user: EditUserModel;
    alterUser: (user: EditUserModel) => void;
    persistedUser?: EditUserModel;
    errors: BadUserCreationResponse;
}

const UserForm = (props: UserFormProps): JSX.Element => {
    const resources = useResources();

    const editMode = props.persistedUser !== undefined;

    const createDisplayName = (lastName: string, firstName: string) => {
        if (firstName === "")
            return lastName;

        if (lastName === "")
            return firstName;

        return (lastName + ", " + firstName).slice(0, 40);
    };

    const changeLastName = (newValue: string) => {
        if (createDisplayName(props.user.lastName, props.user.firstName) === props.user.displayName)
            props.alterUser({ ...props.user, lastName: newValue, displayName: createDisplayName(newValue, props.user.firstName) });
        else
            props.alterUser({ ...props.user, lastName: newValue });
    };

    const changeFirstName = (newValue: string) => {
        if (createDisplayName(props.user.lastName, props.user.firstName) === props.user.displayName)
            props.alterUser({ ...props.user, firstName: newValue, displayName: createDisplayName(props.user.lastName, newValue) });
        else
            props.alterUser({ ...props.user, firstName: newValue });
    };

    const changeUsername = (newValue: string) => props.alterUser({ ...props.user, username: newValue });

    const changeDisplayName = (newValue: string) => props.alterUser({ ...props.user, displayName: newValue });

    return (
        <div className="user-form-wrapper">
            <div className="form-content">
                <div className="user-form-username">
                    <TextBox label={resources.username} placeholder text={props.user.username} mandatory
                        onChange={changeUsername} error={props.errors.usernameDuplicated ? ErrorType.duplicated : undefined} maxLength={40} autoFocus />
                </div>
                <div className="user-form-container">
                    <TextBox label={resources.lastname} text={props.user.lastName ?? ""} placeholder={!editMode}
                        onChange={changeLastName} maxLength={40} />

                    <TextBox label={resources.firstname} text={props.user.firstName ?? ""} placeholder={!editMode}
                        onChange={changeFirstName} maxLength={40} />

                    <TextBox label={resources.displayname} text={props.user.displayName} placeholder mandatory
                        onChange={changeDisplayName} error={props.errors.displayNameDuplicated ? ErrorType.duplicated : undefined} maxLength={40} />

                </div>
                <CreatedEditedFooter
                    tracked={{
                        dateTimeCreated: props.user.dateTimeCreated,
                        dateTimeLastEdited: props.user.dateTimeLastEdited,
                        userCreated: { id: -1, value: props.user.userNameCreated },
                        userLastEdited: { id: -1, value: props.user.userNameLastEdited }
                    }}
                />
            </div>
        </div>
    )
}

export default UserForm;
