import { Dialog, ErrorType, TextBox } from "../../lib";
import { MultiLanguageFieldModel } from "../../model";
import { useResources } from "..";

type MultiLanguageDialogProps = {
    hidden: boolean;
    resourceLabel: string;
    onDismiss: () => void;
    languageFields: MultiLanguageFieldModel;
    onChange: (content: MultiLanguageFieldModel) => void;
    duplicatedIsoCodes: string[];
    mandatory?: boolean;
}

const MultiLanguageDialog = (props: MultiLanguageDialogProps): JSX.Element => {
    const resources = useResources();

    const changeTextMultiLanguage = (isoCode: string, newValue: string) => {
        const newLanguageFields = [...props.languageFields];
        newLanguageFields.find(n => n.isoCode === isoCode)!.label = newValue;

        props.onChange(newLanguageFields);
    }

    const sorted: MultiLanguageFieldModel = props.languageFields.sort((a, b) => (a.isoCode > b.isoCode) ? 1 : -1).filter(l => l.isoCode !== resources.isoCode);
    sorted.unshift(props.languageFields.find(n => n.isoCode === resources.isoCode)!);

    const showAsMandatory = () => {
        const anyFieldFilled = props.languageFields.some(lf => lf.label.length > 0);

        return props.mandatory || anyFieldFilled;
    }

    return (
        <Dialog
            hidden={props.hidden}
            onDismiss={props.onDismiss}
            title={props.resourceLabel}
        >
            {sorted.map((langField, index) =>
                <TextBox
                    autoFocus={index === 0}
                    key={index}
                    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
                    label={(resources as any)[langField.isoCode.toLocaleLowerCase() + "Dynamic"]}
                    text={langField.label}
                    onChange={newValue => changeTextMultiLanguage(sorted[index].isoCode, newValue)}
                    maxLength={40}
                    error={props.duplicatedIsoCodes.includes(langField.isoCode) ? ErrorType.duplicated : undefined}
                    placeholderText={resources.label}
                    mandatory={showAsMandatory()}
                />
            )}

        </Dialog>
    )
}

export default MultiLanguageDialog;
