import { get } from "../../improve-lib";
import { IdValuePair } from "../../model";

const useSearchUser = () => {
    const searchUser = async (filterText: string) => {
        const response = await get("users/search?fulltext=" + filterText);

        if (response.status !== 200)
            throw response;

        return response.body as IdValuePair[];
    };

    return { searchUser }
}

export default useSearchUser;