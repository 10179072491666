import { CommandButton } from "@fluentui/react";
import TopBarCommandButtonAction from "./model/topBarCommandButtonAction";

type TopBarCommandButtonProps = {
    text: string;
    commands: TopBarCommandButtonAction[];
    iconName?: string;
}

const TopBarCommandButton = (props: TopBarCommandButtonProps): JSX.Element => {
    return (
        <CommandButton
            text={(props.iconName ? undefined : props.text)}
            iconProps={props.iconName ? {
                iconName: props.iconName,
            } : undefined}
            menuProps={{
                title: props.iconName ? props.text : undefined,
                items: props.commands.map((command, index) => ({
                    text: command.text,
                    onClick: command.onClick,
                    key: index.toString(),
                    iconProps: command.iconName ? { iconName: command.iconName } : undefined
                }))
            }}
            styles={{
                rootExpanded: { color: "inherit" },
                rootPressed: { color: "inherit" },
                root: { color: "inherit" },
                rootHovered: { color: "inherit" },
                menuIcon: { color: "inherit" },
                icon: { color: "inherit" },
                iconPressed: { color: "inherit" },
                iconHovered: { color: "inherit" }
            }} />
    )
}

export default TopBarCommandButton;
