import { useEffect, useState } from "react";
import { get, useResources, useToggle } from "../../improve-lib";
import { ProcessGroupPermissionType, ProcessModel } from "../../model";
import ProcessGroupPermission from "../../model/process/processGroupPermission";

const useProcess = (id: number | undefined, permittedProcessGroups: ProcessGroupPermission[]) => {
    const resources = useResources();

    const [dbProcess, setDbProcess] = useState<ProcessModel>();
    const [processPermission, setProcessPermission] = useState<ProcessGroupPermissionType>();

    const [reloadFlag, reload] = useToggle();
    const [dataReadyFlag, setDataReadyFlag] = useState(false);

    useEffect(() => {
        const getProcess = async () => {
            const response = await get("process/" + id);

            if (response.status !== 200)
                throw response;

            const data: ProcessModel = response.body;

            data.dateTimeCreated = new Date(data.dateTimeCreated);
            data.dateTimeLastEdited = new Date(data.dateTimeLastEdited);

            setDbProcess(data);
            setDataReadyFlag(dr => !dr);
        }
        if (id)
            void getProcess();
    }, [id, resources.isoCode, reloadFlag]);

    useEffect(() => {
        if (dbProcess?.processGroup.id) {
            const processGroup = permittedProcessGroups.find(pg => pg.id === dbProcess.processGroup.id);
            if (processGroup) {
                const permission =
                    dbProcess.subsidiary ?
                        processGroup.subsidiaryPermissions.find(sp => sp.subsidiary.id === dbProcess.subsidiary?.id)?.permission :
                        Math.min(...processGroup.subsidiaryPermissions.map(sp => sp.permission));
                setProcessPermission(permission);
            }
            else
                setProcessPermission(undefined);
        }
    }, [dbProcess?.processGroup.id, dbProcess?.subsidiary, permittedProcessGroups]);

    return { dbProcess, processPermission, reload, dataReadyFlag };
}

export default useProcess;