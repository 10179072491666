import { Dialog, MultiLineTextBox } from "../../lib";
import { MultiLanguageDescriptionModel, MultiLanguageFieldModel } from "../../model";
import { useResources } from "..";

type MultiLanguageDescriptionDialogProps = {
    hidden: boolean;
    resourceLabel: string;
    onDismiss: () => void;
    languageDescriptions: MultiLanguageDescriptionModel;
    onChange: (content: MultiLanguageDescriptionModel) => void;
    mandatory?: boolean;
}

const MultiLanguageDescriptionDialog = (props: MultiLanguageDescriptionDialogProps): JSX.Element => {
    const resources = useResources();

    const changeTextMultiLanguage = (isoCode: string, newValue: string) => {
        const newDescriptions = { ...props.languageDescriptions };
        newDescriptions[isoCode] = newValue;

        props.onChange(newDescriptions);
    }

    const sorted: MultiLanguageFieldModel = Object.keys(props.languageDescriptions).flatMap(key => (
         (key === resources.isoCode) ? [] :{ isoCode: key, label: props.languageDescriptions[key] }
    )).sort((a, b) => (a.isoCode > b.isoCode) ? 1 : -1);

    sorted.unshift({ isoCode: resources.isoCode, label: props.languageDescriptions[resources.isoCode] });

    const showAsMandatory = () => {
        const anyFieldFilled = Object.values(props.languageDescriptions).some(ld => ld.length > 0);

        return props.mandatory || anyFieldFilled;
    }

    return (
        <Dialog
            hidden={props.hidden}
            onDismiss={props.onDismiss}
            title={props.resourceLabel}
            minWidth={900}
        >
            {sorted.map((description, index) =>
                <MultiLineTextBox                  
                    key={index}
                    autoFocus={index === 0}
                    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
                    label={(resources as any)[description.isoCode.toLocaleLowerCase() + "Dynamic"]}
                    text={description.label}
                    placeholderText={resources.description}
                    mandatory={showAsMandatory()}
                    onChange={newValue => changeTextMultiLanguage(description.isoCode, newValue)}
                />
            )}

        </Dialog>
    )
}

export default MultiLanguageDescriptionDialog;
