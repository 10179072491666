import { del, useConfirmDeleteCallback } from "../../improve-lib";

const useDeleteUser = (afterDelete?: () => void) => {
    const deleteUser = useConfirmDeleteCallback(async (id: number) => {
        const response = await del("users/" + id);

        if (response.status === 200) {
            if (afterDelete)
                afterDelete();
        }
        else
            throw response;
    }, false);

    return { deleteUser }
}

export default useDeleteUser;