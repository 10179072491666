import { CreatedEditedFooter,useResources } from "../../../../../../../improve-lib";
import { MultiLanguageTextbox,Section } from "../../../../../../../lib";
import { EditApplicationAreaModel,MultiLanguageFieldModel } from "../../../../../../../model";

type FormProps = {
    applicationArea: EditApplicationAreaModel;
    onNameChange: (newName: MultiLanguageFieldModel) => void;
    duplicatedIsoCodes: string[];
}

const Form = (props: FormProps): JSX.Element => {
    const resources = useResources();

    return (
        <>
            <Section headerText={resources.label}>
                <MultiLanguageTextbox
                    languageFields={props.applicationArea.name}
                    onChange={props.onNameChange}
                    duplicatedIsoCodes={props.duplicatedIsoCodes}
                    textBoxHeader={resources.label + " " + resources.applicationArea}
                    mandatory
                />
            </Section>
            <CreatedEditedFooter
                tracked={props.applicationArea}
            />
        </>
    )
}

export default Form;
