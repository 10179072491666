// Der Zugriff auf 'body' soll nur erlaubt weden, wenn vorgängig eine Abfrage auf 'status' erfolgte
// Dadurch wird verhindert, dass das Resultat ('body') ausgelesen werden kann ohne vorhergehend 'status' geprüft zu haben
type ApiResponseType = ApiResponse | { status: -1 };

export class ApiResponse {
    status: ExpectedStatusCode;
    body: any;

    constructor(status: ExpectedStatusCode, body: any) {
        this.status = status;
        this.body = body;
    }
}

// eslint-disable-next-line @typescript-eslint/ban-types
type ValidBodyTypes = object | string | number;

type ValidMethodTypes = "GET" | "POST" | "DELETE" | "PUT";

// Alle http-codes sind gültig, wenn Einträge fehlen, einfach ergänzen (Rest wurde wegen grosser Anzahl weggelassen)
type ExpectedStatusCode = 200 | 400 | 401 | 403 | 404 | 500;

const api = async (method: ValidMethodTypes, url: string, body?: ValidBodyTypes): Promise<ApiResponse> => {
    const requestInfo: RequestInit = { method: method };

    // Daten sollen immer als JSON zurückgegeben werden.
    // Dies wird benötigt, da ASP.NET Core sonst für 'String'-Responses dies als Text(text/plain) und nicht als JSON(application/json) zurückgibt.
    // Wodurch das auslesen im Client eine Fehlermeldung werfen würde.
    const headers = new Headers({ Accept: "application/json" });

    if (body !== undefined) {
        if (body instanceof FormData)
            requestInfo.body = body;
        else {
            requestInfo.body = JSON.stringify(body);
            headers.append("Content-Type", "application/json");
        }
    }

    requestInfo.headers = headers;

    const response = await fetch("/api/" + url, requestInfo);

    const contentTypeResponseHeader = response.headers.get("Content-Type");

    const hasResponse = contentTypeResponseHeader && contentTypeResponseHeader.startsWith("application/json");

    // Es is egal, ob 'status' tatsächlich übereinstimmt wichtig ist, dass er geprüft werden muss um an 'body' zu kommen
    const apiResponse: ApiResponseType = new ApiResponse(response.status as ExpectedStatusCode, hasResponse ? await response.json() : undefined);

    return apiResponse;
}

const get = async (url: string) => await api("GET", url);
const del = async (url: string, body?: ValidBodyTypes) => await api("DELETE", url, body);
const post = async (url: string, body?: ValidBodyTypes) => await api("POST", url, body);
const put = async (url: string, body?: ValidBodyTypes) => await api("PUT", url, body);

export {
    del,
    get,
    post,
    put
}