import { TextField } from "@fluentui/react";
import { useResources } from "../../improve-lib";
import { ErrorType } from "..";
import "./TextBox.tsx.scss";

type TextBoxProps = {
    label: string;
    text: string;
    onChange: (newValue: string) => void;

    onEnter?: () => void;
    onClick?: () => void;

    placeholder?: boolean;
    placeholderText?: string;

    password?: boolean;

    autoFocus?: boolean;

    disabled?: boolean;
    readOnly?: boolean;
    mandatory?: boolean;
    valid?: boolean;// kann Standardverhalten von 'mandatory' übersteuern

    underlined?: boolean;

    maxLength?: number;

    error?: ErrorType;

    multiline?: boolean;
}

const TextBox = (props: TextBoxProps): JSX.Element => {
    const resources = useResources();

    const onChange = (_event?: React.FormEvent, newValue?: string) => {
        props.onChange(newValue ?? "");
    }

    const onKeyDown = (event: React.KeyboardEvent) => {
        if (event.keyCode === 13 && props.onEnter)
            props.onEnter();
    }

    const placeHolder = props.placeholderText ?? (props.placeholder ? props.label : undefined);

    const type = props.password ? "password" : "text";

    let cssClass = "";
    // mandatory überschreibt readonly
    // 1. ungültiges feld immer rot
    // 2. ein plfichtfeld mit leerem text
    if (props.valid === false || (props.mandatory && props.text.trim().length < 1))
        cssClass = "mandatory-save-txt";
    else if (props.readOnly)
        cssClass = "read-only-txt";

    let errorMessage = undefined;

    switch (props.error) {
        case ErrorType.duplicated:
            errorMessage = resources.validationErrorDuplicated;
            break;
        case ErrorType.mandatory:
            errorMessage = resources.validationErrorMandatory;
            break;
        case ErrorType.format:
            errorMessage = resources.validationErrorFormat;
            break;
    }

    return (
        <TextField
            label={props.label}
            value={props.text}
            onChange={onChange}
            onKeyDown={onKeyDown}
            placeholder={placeHolder}
            autoFocus={props.autoFocus}
            type={type}
            disabled={props.disabled}
            readOnly={props.readOnly}
            inputClassName={cssClass}
            errorMessage={errorMessage}
            maxLength={props.maxLength}
            onClick={props.onClick}
            underlined={props.underlined}
            multiline={props.multiline}
            autoAdjustHeight={props.multiline ?? false}
        />
    );
}

export default TextBox;
