import { Label } from "@fluentui/react";
import { useId } from "@fluentui/react-hooks";
import { useLabelStyles } from "../../improve-lib/theme/fluent-styles/fluentStyles";
import Text from "./Text";
import "./TextWithLabel.tsx.scss";

type TextWithLabelProps = {
    label: string;
    text: string;
    numberOfLines?: number;
}

const TextWithLabel = (props: TextWithLabelProps): JSX.Element => {
    const tooltipId = useId("tooltip");
    const labelStyles = useLabelStyles();

    return (
        <div>
            <Label htmlFor={tooltipId} className={"textwithlabel-label " + labelStyles } >{props.label}</Label>
            <Text size={"mediumPlus"} text={props.text} key={tooltipId} numberOfLines={props.numberOfLines} />
        </div>
    );
}

export default TextWithLabel;
