import { useEffect, useState } from "react";
import { useSubsidiariesList } from "../../../api";
import { ContactKind, NewContact, useCreateContact } from "../../../api/contacts";
import { useLabels, useResources } from "../../../improve-lib";
import { CheckBoxList, CheckedListType, ChildWithLabel, Popup, TextBox } from "../../../lib";

type NewContactPopupProps = {
    kind: "customer" | "supplier";
    onCreated: () => void;
    onClose: () => void;
}

const NewContactPopup = (props: NewContactPopupProps) => {
    const resources = useResources();
    const labels = useLabels();

    const { subsidiaries } = useSubsidiariesList();
    const createContact = useCreateContact();

    const [nr, setNr] = useState("");
    const [name, setName] = useState("");
    const [street, setStreet] = useState("");
    const [zipCode, setZipCode] = useState("");
    const [city, setCity] = useState("");
    const [country, setCountry] = useState("");
    const [subsidiariesList, setSubsidiariesList] = useState<CheckedListType[]>([]);

    const allowSave = nr.trim().length > 0 && name.trim().length > 0;

    useEffect(() => {
        if (subsidiaries)
            setSubsidiariesList(subsidiaries.map(s => ({ id: s.id, text: s.value, checked: true })))
    }, [subsidiaries]);

    if (!subsidiaries)
        return null;

    const onAddressCreate = async () => {
        const contact: NewContact = {
            nr,
            name,
            street,
            city,
            zipCode,
            country,
            kind: props.kind === "customer" ? ContactKind.Customer : ContactKind.Supplier,
            subsidiaries: subsidiariesList.filter(s => s.checked).map(s => s.id)
        }

        if (subsidiariesList.length === 1)
            contact.subsidiaries.push(subsidiariesList[0].id);

        await createContact(contact)

        props.onCreated();
    };

    const onSubsidiaryCheckedChanged = (id: number, checked: boolean) => {
        const copy = [...subsidiariesList];

        copy.find(s => s.id === id)!.checked = checked;

        setSubsidiariesList(copy);
    }

    const nameNrLabel = props.kind === "customer" ? labels.customerNumber : labels.supplierNumber;
    const nameLabel = props.kind === "customer" ? labels.customer : labels.supplier;

    return (
        <Popup
            title={`${resources.new}: ${nameLabel}`}
            onDismiss={props.onClose}
            size="medium"
            onSave={onAddressCreate}
            saveDisabled={!allowSave}           
            closeText={(allowSave ? resources.cancel : resources.close)}
            isOpen
        >
            <article>
                <div className="article-content">
                    <TextBox label={nameNrLabel} text={nr} onChange={setNr} autoFocus mandatory />
                    <TextBox label={nameLabel} text={name} onChange={setName} mandatory />
                </div>
                <div className="article-content">
                    <TextBox label={resources.street} text={street} onChange={setStreet} />
                    <TextBox label={resources.zipCode} text={zipCode} onChange={setZipCode} />
                    <TextBox label={resources.city} text={city} onChange={setCity} />
                    <TextBox label={resources.country} text={country} onChange={setCountry} />
                </div>
                {subsidiaries.length > 1 &&
                    <div className="ip-check-box-list">
                        <ChildWithLabel label={resources.subsidiaries}>
                            <CheckBoxList items={subsidiariesList} onCheckedChanged={onSubsidiaryCheckedChanged} />
                        </ChildWithLabel>
                    </div>}
            </article>
        </Popup>
    )
}

export default NewContactPopup;
