import { del, useConfirmDeleteCallback } from "../../improve-lib";

const useDeleteRole = (afterDelete?: () => void) => {
    const deleteRole = useConfirmDeleteCallback(async (id: number) => {
        const response = await del("roles/" + id);

        if (response.status === 200) {
            if (afterDelete)
                afterDelete();
        }
        else
            throw response;
    }, true);

    return { deleteRole }
}

export default useDeleteRole;