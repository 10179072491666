import { CommandBar as CommandBarFluent, ICommandBarItemProps, IContextualMenuItem } from "@fluentui/react";
import { useState } from "react";
import { Spinner } from "../../lib/display";
import { RowAction } from "../../lib/input";
import { useResources } from "../language";
import "./ContainerActions.tsx.scss";

type ContainerActionsProps = {
    actions: RowAction[];
    overflow: RowAction[];
    farItems: RowAction[];
    onSave?: () => Promise<any> | void;
    saveDisabled?: boolean;
}
const ContainerActions = (props: ContainerActionsProps): JSX.Element => {
    const resources = useResources();
    const [isLoading, setIsLoading] = useState(false);
    const saveAction: ICommandBarItemProps = {
        iconProps: { iconName: "Save" },
        text: (resources.save),
        key: "_save",
        disabled: (isLoading || props.saveDisabled ? true : false),
        className: isLoading ? "is-loading" : "",
        onClick: () => {
            async function click() {
                setIsLoading(true);
                await props.onSave!();
                setIsLoading(false);
            }
            void click();
        }
    };

    let actions: ICommandBarItemProps[] = props.actions.map(action =>
    ({
        text: action.text,
        key: action.text,
        disabled: action.disabled,
        style: action.disabled ? { cursor: "default" } : undefined,
        iconProps: { iconName: action.iconName },
        onClick: action.onClick,
        subMenuProps: action.childrenAction ? {
            items: action.childrenAction.map((item, index) => {
                return {
                    text: item.text,
                    key: index.toString(),
                    onClick: item.onClick,
                    subMenuProps: item.childrenAction ? {
                        items: item.childrenAction.map((item, index) => {
                            return {
                                text: item.text,
                                key: index.toString(),
                                onClick: item.onClick
                            } as IContextualMenuItem
                        })
                    } : undefined
                } as IContextualMenuItem
            })
        } : undefined
    }));

    const overflow: ICommandBarItemProps[] = props.overflow.map(o =>
    ({
        text: o.text,
        key: o.text,
        disabled: o.disabled,
        style: o.disabled ? { cursor: "default" } : undefined,
        iconProps: { iconName: o.iconName },
        onClick: o.onClick
    }));

    const farItems: ICommandBarItemProps[] = props.farItems.map(o =>
    ({
        text: o.text,
        key: o.text,
        disabled: o.disabled,
        style: o.disabled ? { cursor: "default" } : undefined,
        iconProps: { iconName: o.iconName },
        onClick: o.onClick,

    }));

    if (props.onSave)
        actions = [saveAction, ...actions];

    return (
        <>
            {isLoading &&
                <div className="spinner-container">
                    <Spinner />
                </div>
            }
            <CommandBarFluent
                styles={{ root: { padding: 0 }, primarySet: "command-bar-container" }}
                overflowButtonProps={{ styles: { root: "dot-nav" } }}
                items={actions}
                overflowItems={overflow}
                farItems={farItems}
            />
        </>
    )
}

export default ContainerActions;
