import { useState } from "react";
import { put, useSaveCallout } from "../../improve-lib";
import { ProcessGroupUpdateModel } from "../../model";

const useUpdateProcessGroup = (afterSuccessfulSave?: () => void) => {
    const saveCallout = useSaveCallout();

    const [duplicatedIsoCodes, setDuplicatedIsoCodes] = useState<string[]>([]);

    const updateProcessGroup = async (id: number, processGroup: ProcessGroupUpdateModel) => {
        const response = await put("processgroups/" + id, processGroup);

        switch (response.status) {
            case 200:
                saveCallout.show();
                if (afterSuccessfulSave)
                    afterSuccessfulSave();
                break;
            case 400:
                setDuplicatedIsoCodes(response.body as string[]);
                break;
            default:
                throw response;
        }
    }

    return { updateProcessGroup, duplicatedIsoCodes }
}

export default useUpdateProcessGroup;