import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { get, UserSettingsModel } from "../../improve-lib";

const useCurrentUserSettings = () => {
    const [userSettings, setUserSettings] = useState<UserSettingsModel>();

    const navigate = useNavigate();

    useEffect(() => {
        const getCurrentUserSettings = async () => {
            const response = await get("settings/me");

            switch (response.status) {
                case 200:
                    setUserSettings(response.body as UserSettingsModel);
                    break;
                case 401:
                    navigate("/login");
                    break;
                default:
                    throw response;
            }
        }

        void getCurrentUserSettings();
    }, [navigate]);

    return { userSettings }
}

export default useCurrentUserSettings;