import { useEffect, useState } from "react";
import { del, get, post, put, useLanguageHandle, useResources, useSaveCallout, useToggle } from "../../improve-lib";
import { MultiLanguageFieldModel } from "../../model";

type ContactData = {
    nr: string;
    name: string;
    street: string;
    zipCode: string;
    city: string;
    country: string;
}
type UpdateContact = ContactData & {
    subsidiaries: number[];
};

export type NewContact = ContactData & {
    kind: ContactKind;
    subsidiaries: number[];
}

export type Contact = ContactData & {
    kind: ContactKind;
    id: number;
}

type EditContact = Contact & {
    subsidiaries: ContactSubsidiary[];
}

type ContactSubsidiary = {
    id: number;
    name: string;
    assigned: boolean;
}

export enum ContactKind {
    Customer,
    Supplier
}

type ContactSearchResult = {
    results: Contact[];
    contactCount: number;
}

type ContactLabels = {
    name: MultiLanguageFieldModel;
    nr: MultiLanguageFieldModel;
}

export const useContactLabels = (kind: ContactKind): ContactLabels | undefined => {
    const [labels, setLabels] = useState<ContactLabels>();

    useEffect(() => {
        const fetch = async () => {
            const response = await get(`contacts/labels?kind=${kind}`);

            if (response.status !== 200)
                throw response;

            setLabels(response.body as ContactLabels);
        };

        void fetch();
    }, [kind])

    return labels;
}

export const useUpdateContactLabels = () => {
    const setLang = useLanguageHandle();
    const resources = useResources();
    const { show } = useSaveCallout();

    return async (kind: ContactKind, labels: ContactLabels) => {
        const response = await put(`contacts/labels?kind=${kind}`, labels);

        if (response.status !== 200)
            throw response;

        show();

        // reload labels globally
        setLang(resources.isoCode);
    }
}

export const useCreateContact = () => {
    const { show } = useSaveCallout();
    return async (contact: NewContact) => {
        const response = await post("contacts", contact);

        if (response.status !== 200)
            throw response;
        show()
        return response.body as number;
    }
}

export const useUpdateContact = () => {
    const { show } = useSaveCallout();

    return async (contactId: number, contact: EditContact) => {
        const model: UpdateContact = {
            city: contact.city,
            country: contact.country,
            name: contact.name,
            nr: contact.nr,
            street: contact.street,
            subsidiaries: contact.subsidiaries.filter(s => s.assigned).map(s => s.id),
            zipCode: contact.zipCode
        };

        const response = await post(`contacts/${contactId}`, model);

        if (response.status !== 200)
            throw response;

        show();
    }
}

export const useDeleteContact = () => {
    const { show } = useSaveCallout();
    return async (contactId: number) => {
        const response = await del(`contacts/${contactId}`);

        if (response.status !== 200)
            throw response;

        show();
    }
}

export const useContacts = (kind: ContactKind, searchText: string | undefined) => {
    const [results, setResult] = useState<ContactSearchResult>();
    const [flag, reload] = useToggle();

    useEffect(() => {
        const fetch = async () => {
            const response = await get(`contacts?kind=${kind}&searchText=${searchText ?? ""}`);

            if (response.status !== 200)
                throw response;

            setResult(response.body as ContactSearchResult);
        }

        void fetch();
    }, [kind, searchText, flag]);

    return { contacts: results, reload };
};

export const useContact = (id: number): EditContact | undefined => {
    const [contact, setContact] = useState<EditContact>();

    useEffect(() => {
        const fetch = async () => {
            const response = await get(`contacts/${id}`);

            if (response.status !== 200)
                throw response;

            setContact(response.body as EditContact);
        };

        void fetch();
    }, [id]);

    return contact;
};