import { useCallback, useEffect, useState } from "react";
import { get, LanguageModel, setCookie } from "../../improve-lib";

const useLanguageResource = () => {
    const [languageModel, setLanguageModel] = useState<LanguageModel>();

    const getLanguageResource = useCallback(async (isoCode?: string) => {
        const response = await get("languages" + (isoCode ? "?isoCode=" + isoCode : ""));

        if (response.status !== 200)
            throw response;

        const model = response.body as LanguageModel;

        setLanguageModel(model);

        setCookie("lang", model.resources.isoCode, 365);
    }, []);

    useEffect(() => {
        void getLanguageResource();
    }, [getLanguageResource]);

    return { languageModel, setLanguageFromIsoCode: getLanguageResource }
}

export default useLanguageResource;
