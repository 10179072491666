import { useEffect, useState } from "react";
import { get } from "../../improve-lib";
import ProcessGroupPermission from "../../model/process/processGroupPermission";

const useProcessLandscapePerSubsidiary = (permittedProcessGroups: ProcessGroupPermission[]) => {
    const [processLandscapePerSubsidiary, setProcessLandscapePerSubsidiary] = useState<{ processId: number, subsidiaryId: number | null }[]>([]);

    useEffect(() => {
        const getGetProcessLandscapePerSubsidiary = async () => {
            const response = await get("process/getProcessLandscapePerSubsidiary");

            if (response.status !== 200)
                throw response;

            setProcessLandscapePerSubsidiary(response.body as { processId: number, subsidiaryId: number | null }[])
        }

        void getGetProcessLandscapePerSubsidiary();
    }, [permittedProcessGroups]);

    return { processLandscapePerSubsidiary }
}

export default useProcessLandscapePerSubsidiary;