import { useEffect, useState } from "react";
import { useAllUsers } from "../../../api";
import { useResources, useUserSettings } from "../../../improve-lib";
import { CheckBox, ItemPicker, UserSelection } from "../../../lib";
import { IdValuePair } from "../../../model";

type OpenTasksFilterProps = {
    assignedSubsidiaries: IdValuePair[];
    onSubsidiarySelectionChanged: (ids: number[]) => void;
    onUserSelectionChangend: (id: number | null) => void;
    basedOnDate: boolean;
    onBasedOnDateChanged: (checked: boolean) => void;
}

const OpenTasksFilter = (props: OpenTasksFilterProps): JSX.Element => {
    const resources = useResources();
    const userSettings = useUserSettings();

    const [onlyInvolved, setOnlyInvolved] = useState(true);
    const [userList, setUserList] = useState<IdValuePair[]>([]);
    const { allUsers } = useAllUsers();
    const [selectedUser, setSelectedUser] = useState<IdValuePair>({ id: -1, value: "" });

    useEffect(() => {
        const getUsers = async () => {
            const users = await allUsers();
            users.unshift({ id: -1, value: resources.allUsers });
            setUserList(users);
        };
        void getUsers();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [resources.allUsers])

    const onSubsidiarySelectionChanged = (ids: number[]) => {
        props.onSubsidiarySelectionChanged(ids);
    }

    const onOnlyInvolvedChanged = (checked: boolean) => {
        props.onUserSelectionChangend(checked ? userSettings.userId : selectedUser.id === -1 ? null : selectedUser.id);
        setOnlyInvolved(checked);
    }

    const userOnGet = (_search: string | null): Promise<{ results: IdValuePair[], full: boolean }> => {
        return new Promise((resolve, _reject) => {
            resolve({ results: userList, full: true })
        });
    }

    const onUserChanged = (user: IdValuePair) => {
        props.onUserSelectionChangend(user.id === -1 ? null : user.id);
        setSelectedUser(user);
    }

    return (
        <article>
            <div className="article-content">
                <div className="selection-checkbox">
                    {props.assignedSubsidiaries.length > 1 &&
                        <div>
                            <ItemPicker
                                title={resources.subsidiaries}
                                placeholder={resources.allSubsidiaries}
                                possibleValues={props.assignedSubsidiaries}
                                onSelectionChanged={onSubsidiarySelectionChanged}
                            />
                        </div>
                    }
                    <div style={{ paddingTop: 10 }}>
                        <CheckBox
                            checked={props.basedOnDate}
                            label={resources.allByDate}
                            onChange={props.onBasedOnDateChanged}
                        />
                    </div>
                </div>
                <div className="selection-checkbox">
                    <UserSelection
                        label={resources.user}
                        selectedUser={onlyInvolved ? { id: -2, value: "" } : selectedUser}
                        onUserChanged={onUserChanged}
                        onGet={userOnGet}
                        readOnly={onlyInvolved}
                    />
                    <div style={{ paddingTop: 10 }}>
                        <CheckBox
                            checked={onlyInvolved}
                            label={resources.onlyMyResponsibilities}
                            onChange={onOnlyInvolvedChanged}
                        />
                    </div>
                </div>
            </div>
        </article>
    )
}

export default OpenTasksFilter;
