import { useEffect, useState } from "react";
import { get } from "../../improve-lib";
import { ProcessGroupPermissionType } from "../../model";

const useDeletableProcess = (id: number, processPermission: ProcessGroupPermissionType) => {
    const [processCanBeDeleted, setProcessCanBeDeleted] = useState(false);

    useEffect(() => {
        const checkIfProcessCanBeDeleted = async () => {
            const response = await get("process/" + id + "/deletable");

            if (response.status !== 200)
                throw response;

            return response.body as boolean;
        }

        let isMounted = true;

        void checkIfProcessCanBeDeleted().then(
            data => {
                if (isMounted)
                    setProcessCanBeDeleted(data && (processPermission >= ProcessGroupPermissionType.Delete));
            }
        );

        return () => { isMounted = false };
    }, [id, processPermission]);

    return { processCanBeDeleted }
}

export default useDeletableProcess;