import { MultiLanguageBase, useResources } from "../../improve-lib";
import { Button, TextBox } from "../../lib";
import { MultiLanguageFieldModel } from "../../model";
import "./MultiLanguageTextbox.tsx.scss";

type MultiLanguageTextboxProps = {
    languageFields: MultiLanguageFieldModel;
    onChange: (content: MultiLanguageFieldModel) => void;
    duplicatedIsoCodes: string[];
    textBoxHeader?: string;
    mandatory?: boolean;
}

const MultiLanguageTextbox = (props: MultiLanguageTextboxProps): JSX.Element => {
    const resources = useResources();

    const render = (multilang: boolean, isValid: boolean, onChangeText: (newValue: string) => void, onEdit: () => void) => {
        return (multilang ?
            <div className="multi-language-wrapper">
                <div className="multi-language-txt-wrapper">
                    <TextBox
                        label={props.textBoxHeader ?? ""}
                        text={props.languageFields.find(n => n.isoCode === resources.isoCode)!.label}
                        onChange={onChangeText}
                        valid={isValid}
                        mandatory={props.mandatory}
                    />
                </div>
                <Button onClick={onEdit} text={resources.edit} secondary />
            </div>
            :
            <div className="single-language-txt-wrapper">
                <TextBox
                    label={props.textBoxHeader ?? ""}
                    autoFocus
                    placeholder
                    text={props.languageFields.find(n => n.isoCode === resources.isoCode)!.label}
                    onChange={onChangeText}
                    maxLength={40}
                    mandatory={props.mandatory}
                />
            </div>
        );
    }

    return (
        <MultiLanguageBase
            languageFields={props.languageFields}
            onChange={props.onChange}
            onRender={render}
            duplicatedIsoCodes={props.duplicatedIsoCodes}
            textBoxHeader={props.textBoxHeader}
            mandatory={props.mandatory}
        />
    );
}

export default MultiLanguageTextbox;
