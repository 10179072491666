import { DetailsList, DetailsRow, getTheme, GroupHeader, IDetailsRowProps, SelectionMode } from "@fluentui/react";
import { IdValuePair } from "../../model";
import GroupedListItem from "./model/groupedListItem";

type SelectionListProps = {
    items: GroupedListItem[];
    groups: IdValuePair[];
    selectedItemId?: number;
    onSelectionChanged: (newSelectedId: number) => void;
}

const GroupedList = (props: SelectionListProps): JSX.Element => {
    const theme = getTheme();

    const sortedItems = props.items.sort((i1, i2) => (i1.groupId - i2.groupId));

    const columns = [{ key: "0", name: "", fieldName: "value", minWidth: 100 }];

    const groups = props.groups.map(g => (
        {
            key: g.id.toString(),
            name: g.value,
            startIndex: sortedItems.findIndex(i => i.groupId === g.id),
            count: sortedItems.filter(i => i.groupId === g.id).length,
            level: 0
        }
    ));

    const onActiveItemChanged = (item: { [property: string]: string }): void => {
        (document.activeElement as HTMLElement).blur();
        const selectedId = parseInt(item["id"]);
        if (selectedId !== props.selectedItemId)
            props.onSelectionChanged(selectedId);
    };

    const onRenderHeader = (headerProps: any) => {
        return (
            <GroupHeader
                {...headerProps}
                styles={{ root: { backgroundColor: "transparent !important" } }}
            />
        );
    }

    const onRenderRow = (rowProps?: IDetailsRowProps) => {
        if (rowProps) {
            const itemId = (rowProps.item as { [property: string]: number })["id"];
            if (itemId === props.selectedItemId)
                return <DetailsRow {...rowProps} styles={{ root: { backgroundColor: theme.palette.neutralLight } }} />
            else
                return <DetailsRow {...rowProps} styles={{ cell: { cursor: "pointer" } }} />
        }
        else
            return null;
    }

    return (
        <DetailsList
            items={sortedItems}
            columns={columns}
            groups={groups}
            groupProps={{
                showEmptyGroups: true,
                onRenderHeader: onRenderHeader
            }}
            onRenderRow={onRenderRow}
            selectionMode={SelectionMode.none}
            onActiveItemChanged={onActiveItemChanged}
            isHeaderVisible={false}
            compact
        />
    );
}

export default GroupedList;
