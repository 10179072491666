import {
    append as svgAppend,
    attr as svgAttr,
    create as svgCreate
} from "tiny-svg";

/**
 * A diagram-js extension that shows a (0, 0) origin indicator
 * on the canvas as well as (optionally) the canvas borders.
 */
export default class ConfigureOrigin {
    static $inject = ["canvas"];

    constructor(canvas) {
        var showBorder = true;

        var borderColor = "#CCC",
            crossColor = "rgba(0,0,0,0.1)";

        var width = 15,
            strokeWidth = 2,
            lineStart = width + 7,
            stroke = -strokeWidth / 2;


        var parent = canvas.getLayer("djs-origin", -1);

        var g,
            line,
            cross,
            label;

        g = createSvgElement("g", { "pointer-events": "none" });

        svgAppend(parent, g);

        if (showBorder) {
            // the dashed lines, marking positive document coordinates
            line = createSvgElement("path", {
                class: cls("border"),
                d: path(["M", -lineStart, stroke, "H", -1000, "M", lineStart, stroke, "H", 6000, "M", stroke, -lineStart, "V", -1000, "M", stroke, lineStart, "V", 3000]),
                fill: "none",
                stroke: borderColor,
                "stroke-width": strokeWidth + "px",
                "stroke-dasharray": "6, 6",
                "shape-rendering": "crispedges"
            });

            svgAppend(g, line);
        }

        // the cross that marks the diagram origin
        cross = createSvgElement("path", {
            d: path(["M", -width, stroke, "H", width, "M", stroke, -width, "V", width]),
            class: cls("cross"),
            fill: "none",
            stroke: crossColor,
            "stroke-width": strokeWidth + "px",
            "stroke-linecap": "round"
        });

        svgAppend(g, cross);

        label = createSvgElement("text", {
            x: -40,
            y: -10,
            fill: crossColor,
            class: cls("label"),
        });

        label.textContent = "(0, 0)";

        svgAppend(g, label);
    }
}

function path(parts) {
    return parts.join(" ");
}

function cls(name) {
    return "djs-origin-" + name;
}

function createSvgElement(type, attrs) {
    var el = svgCreate(type);

    svgAttr(el, attrs);

    return el;
}