import { DatePicker } from "../../../lib";
import { CustomFieldModel, FieldType } from "../../../model";
import FieldBase from "./fieldBase";

class DateField extends FieldBase {
    private date: Date | null;
    private readonly dbDate: Date | null;

    readonly fieldType: FieldType = FieldType.Date;

    constructor(id: number, name: string, mandatory: boolean, date: Date | null) {
        super(id, name, mandatory);
        this.dbDate = date ? new Date(date) : null;
        this.date = date ? new Date(date) : null;
    }

    isValid() {
        if (this.mandatory)
            return this.date !== null;
        else
            return true;
    }

    hasChange() { return this.date?.toLocaleDateString() !== this.dbDate?.toLocaleDateString(); }

    getModel(): CustomFieldModel {
        return {
            id: this.id,
            fieldType: this.fieldType,
            settings: null,
            name: this.name,
            mandatory: this.mandatory,
            date: this.date,
            boolean: null,
            number: null,
            selection: null,
            text: null
        };
    }

    getEditControl(onChange: (newField: FieldBase) => void) {
        return (
            <DatePicker
                date={this.date}
                label={this.name}
                onDateChanged={newValue => { this.date = newValue; onChange(this); }}
                mandatory={this.mandatory}
            />
        );
    }
}



export default DateField;