import { useEffect, useState } from "react";
import { Outlet } from "react-router-dom";
import { ErrorBoundary, GlobalSaveCheckProvider, SaveCheckProvider, UserSettingsProvider } from "..";
import Menu from "./Menu";
import TopBar from "./TopBar";
import "./MainLayout.tsx.scss";

const MainLayout = (): JSX.Element => {
    const [menuOpen, setMenuOpen] = useState(false);

    const toggleMenu = () => setMenuOpen(open => !open);

    const afterNavigation = () => setMenuOpen(false);

    // avoid doubble scroll when menu is open
    useEffect(() => {
        if (menuOpen)
            document.body.style.overflow = "hidden";
        else
            document.body.style.overflow = "unset";
    }, [menuOpen]);

    return (
        <UserSettingsProvider>
            <GlobalSaveCheckProvider>
                <SaveCheckProvider>
                    <TopBar open={menuOpen} onToggle={toggleMenu} />
                    <div className="main-layout-container-wrapper">
                        <div className={"main-layout-menu-wrapper" + (menuOpen ? " main-layout-menu-wrapper-open" : "")}>
                            <Menu onAfterNavigation={afterNavigation} />
                        </div>
                        <div className={"main-layout-main-content-wrapper"}>
                            <ErrorBoundary>
                                <Outlet />
                            </ErrorBoundary>
                        </div>
                    </div>
                </SaveCheckProvider>
            </GlobalSaveCheckProvider>
        </UserSettingsProvider>);
}

export default MainLayout;
