import { Label } from "@fluentui/react";
import { useId } from "@fluentui/react-hooks";
import { ReactNode } from "react";
import { useLabelStyles } from "../../improve-lib/theme/fluent-styles/fluentStyles";
import "./ChildWithLabel.tsx.scss";

type ChildWithLabelProps = {
    label: string;
    children: ReactNode;
    multiline?: boolean;
}

const ChildWithLabel = (props: ChildWithLabelProps): JSX.Element => {
    const childId = useId("child");
    const labelStyles = useLabelStyles();

    return (
        <>
            <Label htmlFor={childId} className={"childwithlabel-label " + labelStyles}>{props.label}</Label>
            <div className={(props.multiline ? "multiline" : "")} id={childId}>
                {props.children}
            </div>
        </>
    )
}

export default ChildWithLabel;