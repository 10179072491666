import { CustomFieldModel, FieldType } from "../../../model";


abstract class FieldBase {
    readonly id: number;
    readonly name: string;
    readonly mandatory: boolean;
    abstract fieldType: FieldType;

    constructor(id: number, name: string, mandatory: boolean) {
        this.id = id;
        this.name = name;
        this.mandatory = mandatory;
    }

    abstract getModel(): CustomFieldModel;

    abstract isValid(): boolean;
    abstract hasChange(): boolean;

    abstract getEditControl(onChange: (newField: FieldBase) => void): JSX.Element;
}

export default FieldBase;