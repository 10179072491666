import { useEffect, useState } from "react";
import { useGlobalSettings, useResources } from "../../../improve-lib";
import { PasswordBox, RadioButton, TextBox } from "../../../lib"
import { NewUserModel } from "../../../model";
import AuthenticationMethod from "../../../model/authentication/authenticationMethod";
import "./UserFormNew.tsx.scss";

type UserFormNewProps = {
    user: NewUserModel;
    alterUser: (user: NewUserModel) => void;
}

const UserFormNew = (props: UserFormNewProps): JSX.Element => {
    const settings = useGlobalSettings();
    const resources = useResources();

    const [useAd, setUseAd] = useState(settings.allowAd);

    useEffect(() => {
        if (useAd)
            props.alterUser({ ...props.user, password: null })
        else
            props.alterUser({ ...props.user, password: "" })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [useAd]);

    const createDisplayName = (lastName: string, firstName: string) => {
        if (firstName === "")
            return lastName;

        if (lastName === "")
            return firstName;

        return (lastName + ", " + firstName).slice(0, 40);
    };

    const changeLastName = (newValue: string) => {
        if (createDisplayName(props.user.lastName, props.user.firstName) === props.user.displayName)
            props.alterUser({ ...props.user, lastName: newValue, displayName: createDisplayName(newValue, props.user.firstName) });
        else
            props.alterUser({ ...props.user, lastName: newValue });
    };

    const changeFirstName = (newValue: string) => {
        if (createDisplayName(props.user.lastName, props.user.firstName) === props.user.displayName)
            props.alterUser({ ...props.user, firstName: newValue, displayName: createDisplayName(props.user.lastName, newValue) });
        else
            props.alterUser({ ...props.user, firstName: newValue });
    };

    const changeUsername = (newValue: string) => props.alterUser({ ...props.user, username: newValue });

    const changeDisplayName = (newValue: string) => props.alterUser({ ...props.user, displayName: newValue });

    const onAuthenticationMethodChange = (method: AuthenticationMethod) => setUseAd(method === AuthenticationMethod.ActiveDirectory);

    const onPasswordChanged = (newPassword: string) => props.alterUser({ ...props.user, password: newPassword });

    return (
        <div className="user-form-content">
            <div className="user-form-username">
                <TextBox
                    label={resources.username}
                    text={props.user.username}
                    onChange={changeUsername}
                    maxLength={40}
                    mandatory
                    autoFocus
                    placeholder
                />
            </div>
            <div className="user-form-new-wrapper">

                <TextBox
                    label={resources.lastname} text={props.user.lastName ?? ""}
                    onChange={changeLastName} maxLength={40}
                    placeholder
                />
                <TextBox
                    label={resources.firstname} text={props.user.firstName ?? ""}
                    onChange={changeFirstName} maxLength={40}
                    placeholder
                />
                <TextBox
                    label={resources.displayname} text={props.user.displayName}
                    onChange={changeDisplayName} maxLength={40}
                    placeholder
                    mandatory
                />
            </div>
            <div className="user-form-authentification">
                {settings.allowAd &&
                    < RadioButton
                        label={resources.authenticationMethod}
                        options={[
                            { value: AuthenticationMethod.ActiveDirectory.toString(), text: resources.authenticateWithAd, },
                            { value: AuthenticationMethod.Credentials.toString(), text: resources.authenticateWithImprove }]
                        }
                        selectedValue={(useAd ? AuthenticationMethod.ActiveDirectory : AuthenticationMethod.Credentials).toString()}
                        onChange={newValue => onAuthenticationMethodChange(parseInt(newValue))}
                    />
                }
                {!useAd &&
                    < PasswordBox label={resources.temporaryPassword}
                        text={props.user.password ?? ""}
                        onChange={onPasswordChanged}
                        placeholder
                    />
                }
            </div>
        </div>
    )
}

export default UserFormNew;
