import { DefaultButton,PrimaryButton } from "@fluentui/react";

type ButtonProps = {
    text: string;
    onClick: () => void;

    disabled?: boolean;
    secondary?: boolean;
}

const Button = (props: ButtonProps): JSX.Element => {
    return props.secondary ?
        <DefaultButton
            text={props.text}
            onClick={props.onClick}
            disabled={props.disabled}
        />
        :
        <PrimaryButton
            text={props.text}
            onClick={props.onClick}
            disabled={props.disabled}
        />
}

export default Button;
