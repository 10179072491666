import { IconButton as IconButtonFluent, TooltipHost } from "@fluentui/react";
import { Color, colorToHex } from ".";
import "./IconButton.tsx.scss";

type IconButtonProps = {
    iconName: string;
    color?: Color;
    size?: "small" | "large" | "inherit";
    checked?: boolean;
    disabled?: boolean;
    tooltipText?: string;
    onClick: () => void;
}

const IconButton = (props: IconButtonProps): JSX.Element => {
    return (
        <TooltipHost
            content={props.tooltipText}
            calloutProps={{ gapSpace: 7 }}
        >
            <IconButtonFluent
                checked={props.checked}
                className={props.size ? ("iconbutton-" + props.size) : undefined}
                iconProps={{ iconName: props.iconName }}
                style={{ color: props.color ? colorToHex(props.color) : undefined }}
                disabled={props.disabled}
                onClick={props.onClick} />
        </TooltipHost>
    );
}

export default IconButton;
