import { useMemo } from "react";
import { NavigationList } from "../../../lib";
import { IdValuePair, ProcessSubprocess } from "../../../model";
import GroupHeader from "./GroupHeader";

type ProcessTreeProps = {
    processes: IdValuePair[];
    subsidiaries: IdValuePair[];
    processSubprocess: ProcessSubprocess[];
    onSubsidiaryClick: (subsidiaryid: number) => void;
    onProcessClick: (id: number, path: number[]) => void;
}

const ProcessTree = (props: ProcessTreeProps): JSX.Element => {
    const nestedProcesses = useMemo(() => {
        return props.processSubprocess.flatMap(ps => {
            if (props.processes.some(p => p.id === ps.processId)) {
                return {
                    id: ps.processId,
                    groupId: ps.subsidiaryId,
                    childId: ps.subprocessId
                };
            }
            else
                return [];
        });
    }, [props.processes, props.processSubprocess]);

    const onRenderGroupHeader = (group: IdValuePair, onExpandCollapseClick: (groupId: number, isExpand: boolean) => void) => {
        return (
            <GroupHeader
                title={props.subsidiaries.length > 1 ? group.value ?? "" : ""}
                onTitleClick={() => { props.onSubsidiaryClick(group.id) }}
                onExpandCollapseClick={isExpand => { onExpandCollapseClick(group.id, isExpand) }}
            />
        );
    }

    return (
        <NavigationList
            items={props.processes}
            groups={props.subsidiaries}
            nestedItems={nestedProcesses}
            onRenderGroupHeader={onRenderGroupHeader}
            onLinkClick={props.onProcessClick}
        />
    );
}

export default ProcessTree;