import { TextField } from "@fluentui/react";
import "./TextBox.tsx.scss";

type MultiLineTextBoxProps = {
    label: string;
    text: string;
    minRows?: number;
    onChange: (newValue: string) => void;

    onEnter?: () => void;
    onClick?: () => void;

    placeholder?: boolean;
    placeholderText?: string;

    autoFocus?: boolean;
    autoAdjustheight?: boolean;

    readOnly?: boolean;
    mandatory?: boolean;
    valid?: boolean;// kann Standardverhalten von 'mandatory' übersteuern
}

const MultiLineTextBox = (props: MultiLineTextBoxProps): JSX.Element => {
    const onChange = (_event?: React.FormEvent, newValue?: string) => {
        props.onChange(newValue ?? "");
    }

    const onKeyDown = (event: React.KeyboardEvent) => {
        if (event.keyCode === 13 && props.onEnter)
            props.onEnter();
    }

    const placeHolder = props.placeholderText ?? (props.placeholder ? props.label : undefined);

    let cssClass = "";
    // mandatory überschreibt readonly
    // 1. ungültiges feld immer rot
    // 2. ein plfichtfeld mit leerem text
    if (props.valid === false || (props.mandatory && props.text.length < 1))
        cssClass = "mandatory-save-txt";
    else if (props.readOnly)
        cssClass = "read-only-txt";

    return (
        <TextField
            styles={{ fieldGroup: { minHeight: 0 } }}
            label={props.label}
            value={props.text}
            rows={props.minRows ?? 2}
            onChange={onChange}
            onKeyDown={onKeyDown}
            placeholder={placeHolder}
            autoFocus={props.autoFocus}
            autoAdjustHeight={props.autoAdjustheight}
            readOnly={props.readOnly}
            inputClassName={cssClass}
            onClick={props.onClick}
            multiline
            onFocus={e =>
                e.currentTarget.setSelectionRange(e.currentTarget.value.length, e.currentTarget.value.length)
            }
        />
    );
}

export default MultiLineTextBox;
