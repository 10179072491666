import { post } from "../../improve-lib";

const useChangePassword = (afterChange?: () => void) => {
    const changePassword = async (password: string) => {
        const response = await post("authentication/passwords", password);

        if (response.status !== 200)
            throw response;

        if (afterChange)
            afterChange();
    }

    return { changePassword }
}

export default useChangePassword;