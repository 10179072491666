import { useEffect, useState } from "react";
import { get, useResources, useToggle } from "../../improve-lib";
import { ProcessGroupEditModel } from "../../model";

const useProcessGroup = (id: number) => {
    const resources = useResources();

    const [reloadFlag, reload] = useToggle();
    const [processGroup, setProcessGroup] = useState<ProcessGroupEditModel>();

    useEffect(() => {
        const getProcessGroup = async () => {
            const response = await get("processgroups/" + id);

            if (response.status !== 200)
                throw response;

            const processGroup: ProcessGroupEditModel = response.body;
            processGroup.dateTimeCreated = new Date(processGroup.dateTimeCreated);
            processGroup.dateTimeLastEdited = new Date(processGroup.dateTimeLastEdited);
            setProcessGroup(processGroup);
        }

        void getProcessGroup();
    }, [id, resources.isoCode, reloadFlag]);

    return { processGroup, reload }
}

export default useProcessGroup;