import { List, RowAction } from "../../../../lib";
import { DocumentTableModel } from "../../../../model";
import DocumentsListItem from "./DocumentsListItem";

type DocumentsListProps = {
    items: DocumentTableModel[];
    selectedItemId?: number;
    onSelectionChanged: (newSelectedId: number) => void;
    rowAction?: RowAction[];
    onLoadMore: () => void;
    filteredCount: number;
}

const DocumentsList = (props: DocumentsListProps): JSX.Element => {
    const allowLoadMore = props.items.length < props.filteredCount;
    return (
        <List
            items={props.items}
            onSelectionChanged={props.onSelectionChanged}
            selectedItemId={props.selectedItemId}
            onRender={item => <DocumentsListItem item={item} rowAction={props.rowAction} />}
            onLoadMore={allowLoadMore ? props.onLoadMore : undefined}
        />
    )
}

export default DocumentsList;