import { useResources } from "../../improve-lib";
import { Button, Dialog, DialogFooter } from "../../lib";

type ConfirmDialogProps = {
    hidden: boolean;
    onCancel: () => void;
    onConfirm: () => void;
    confirmationText: string;
}

const ConfirmDialog = (props: ConfirmDialogProps): JSX.Element => {
    const resources = useResources();

    return (
        <Dialog
            title={resources.confirmationTitle}
            description={props.confirmationText}
            hidden={props.hidden}
            onDismiss={props.onCancel}
        >
            <DialogFooter>
                <Button onClick={props.onConfirm} text={resources.ok} />
                <Button onClick={props.onCancel} text={resources.cancel} secondary />
            </DialogFooter>
        </Dialog>
    )
}

export default ConfirmDialog;
