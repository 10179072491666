import { DialogFooter as FluentDialogFooter } from "@fluentui/react";
import { ReactNode } from "react";

type DialogFooterPros = {
    children: ReactNode;
}

const DialogFooter = (props: DialogFooterPros): JSX.Element => {
    return (
        <FluentDialogFooter>
            {props.children}
        </FluentDialogFooter>
    )
}

export default DialogFooter;
