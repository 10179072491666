import { ReactNode, useRef, useState } from "react";
import { GlobalSaveCheckContext } from "./GlobalSaveCheckContext";

type GlobalSaveCheckProviderProps = {
    children: ReactNode;
}

const GlobalSaveCheckProvider = (props: GlobalSaveCheckProviderProps) => {
    const changeCount = useRef(0);
    const [hasChanges, setHasChanges] = useState(false);

    const add = () => {
        changeCount.current++;

        if (changeCount.current === 1)
            setHasChanges(true);
    };
    const remove = () => {
        changeCount.current--;

        if (changeCount.current === 0)
            setHasChanges(false);
    }

    return (
        <GlobalSaveCheckContext.Provider value={{ add, remove, hasChanges }}>
            {props.children}
        </GlobalSaveCheckContext.Provider >
    );
};

export default GlobalSaveCheckProvider;
