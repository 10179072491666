import { useState } from "react";
import { useChangePassword } from "../../../api";
import { useResources } from "../../../improve-lib";
import { Button, MessageBar, PasswordBox, Stack } from "../../../lib";

type PasswordResetProps = {
    onSuccsessfulChange: () => void;
}

const PasswordReset = (props: PasswordResetProps): JSX.Element => {
    const resources = useResources();

    const [password, setPassword] = useState("");

    const { changePassword } = useChangePassword(() => {
        props.onSuccsessfulChange();
    })

    return (
        <Stack gap={15}>
            <PasswordBox autoFocus placeholder label={resources.password} text={password} onChange={setPassword} onEnter={() => changePassword(password)} />
            <Button text={resources.change} onClick={() => changePassword(password)} />
            <MessageBar message={resources.temporaryPasswordNotification} type="warning" />
        </Stack>
    )
}

export default PasswordReset;
