import { useEffect, useState } from "react";
import { get, GlobalSettingsModel } from "../../improve-lib";

const useFetchGlobalSettings = () => {
    const [globalSettings, setGlobalSettings] = useState<GlobalSettingsModel>();

    useEffect(() => {
        const getGlobalSettings = async () => {
            const response = await get("settings");

            if (response.status !== 200)
                throw response;

            setGlobalSettings(response.body as GlobalSettingsModel);
        }

        void getGlobalSettings();
    }, []);

    return { globalSettings }
}

export default useFetchGlobalSettings;