import { useState } from "react";
import { useCreateCustomField } from "../../../api";
import { DropDown, MultiLanguageTextbox, RowAction } from "../../../lib";
import { FieldType, IdValuePair, ImproveModule, MultiLanguageFieldModel, NewCustomFieldModel } from "../../../model";
import { Container, useLanguage, useResources } from "../..";

type NewCustomFieldProps = {
    module: ImproveModule;
    locations: IdValuePair[];
    onCreated: (newId: number) => void;
    onCancel: () => void;
}

const NewCustomField = (props: NewCustomFieldProps): JSX.Element => {
    const lang = useLanguage();
    const resources = useResources();

    const fieldTypes: IdValuePair[] = [
        { id: FieldType.Boolean, value: "Checkbox" },
        { id: FieldType.Date, value: resources.date },
        { id: FieldType.Number, value: resources.numeric },
        { id: FieldType.Text, value: resources.text },
        { id: FieldType.Selection, value: resources.selection }
    ];

    const [customFieldModel, setCustomFieldModel] = useState<NewCustomFieldModel>(
        {
            location: props.locations.length === 1 ? props.locations[0].id : -1,
            name: lang.map(l => { return { isoCode: l, label: "" } }),
            fieldType: -1
        }
    );

    const onNameChange = (newName: MultiLanguageFieldModel) => setCustomFieldModel({ ...customFieldModel, name: newName });
    const onFieldTypeChange = (newType: IdValuePair) => setCustomFieldModel({ ...customFieldModel, fieldType: newType.id });
    const onLocationChange = (newLocation: IdValuePair) => setCustomFieldModel({ ...customFieldModel, location: newLocation.id });

    const customFieldIsValid = () => {
        if (customFieldModel.name?.some(n => n.label.trim().length < 1))
            return false;
        if (customFieldModel.fieldType < 0)
            return false;

        return true;
    }

    const { createCustomField, duplicatedIsoCodes } = useCreateCustomField((id: number) => {
        props.onCreated(id);
    });

    const saveAction: RowAction = { iconName: "Save", onClick: () => createCustomField(props.module, customFieldModel), text: resources.save, disabled: !customFieldIsValid() };
    const cancelAction: RowAction = { iconName: "Cancel", onClick: props.onCancel, text: resources.cancel };

    return (
        <Container title={resources.newField} actions={[saveAction, cancelAction]}>
            <article>
                <div className="article-form-element">
                    <MultiLanguageTextbox
                        languageFields={customFieldModel.name}
                        onChange={onNameChange}
                        duplicatedIsoCodes={duplicatedIsoCodes}
                        textBoxHeader={resources.label}
                        mandatory
                    />
                </div>
                <div className="article-form-element">
                    <DropDown
                        label={resources.fieldType}
                        placeholder={resources.fieldType}
                        selectedId={customFieldModel.fieldType}
                        onChange={onFieldTypeChange}
                        options={fieldTypes.sort((a, b) => a.value.localeCompare(b.value))}
                        mandatory
                    />
                </div>
                <div className="article-form-element">
                    <DropDown
                        label={resources.location}
                        placeholder={resources.location}
                        selectedId={customFieldModel.location}
                        onChange={onLocationChange}
                        options={props.locations}
                        mandatory
                    />
                </div>
            </article>
        </Container>
    );
}

export default NewCustomField;