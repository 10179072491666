import { useState } from "react";
import { useCreateTag } from "../../../../../../api";
import { Container, useLanguage, useResources } from "../../../../../../improve-lib";
import { RowAction } from "../../../../../../lib";
import { TagModel } from "../../../../../../model";
import FormNew from "./components/FormNew";

type NewProps = {
    onCreated: (newTagId: number) => void;
    onCancel: () => void;
}

const New = (props: NewProps): JSX.Element => {
    const resources = useResources();
    const lang = useLanguage();

    const [tag, setTag] = useState<TagModel>({
        name: lang.map(l => { return { isoCode: l, label: "" } }),
        color: {
            a: 100,
            r: 255,
            g: 255,
            b: 255
        }
    });

    const { createTag, duplicatedIsoCodes } = useCreateTag((id: number) => {
        props.onCreated(id);
    })

    const onTagChanged = (newTag: TagModel) => setTag(newTag);

    let allowSave = true;

    if (tag?.name) {
        for (let i = 0; i < tag.name.length; i++)
            if (tag.name[i].label.length < 1)
                allowSave = false;
    }

    const cancelAction: RowAction = {
        onClick: props.onCancel,
        iconName: "Cancel",
        text: resources.cancel
    };

    const onCreateTag = async () => {
        await createTag(tag);
    }

    return (
        <>
            {tag &&
                <Container title={resources.newTag} onSave={onCreateTag} saveDisabled={!allowSave} actions={[cancelAction]}>
                    <FormNew tag={tag} onTagChange={onTagChanged} duplicatedIsoCodes={duplicatedIsoCodes} />
                </Container>
            }
        </>
    )
}


export default New;
