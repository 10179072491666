import { createBrowserRouter, RouteObject, RouterProvider } from "react-router-dom";
import { DialogProvider, ErrorBoundary, GlobalSettingsProvider, initTheme, LanguageProvider, MainLayout, SaveCalloutProvider } from "./improve-lib";
import {
    ContactPage,
    CreateSubsidaryPage,
    CreateUserPage,
    DocumentsPage,
    EditSubsidiaryPage,
    EditUserPage,
    LoginPage,
    NotFoundPage,
    OpenTasksPage,
    ProcessDetailsPage,
    ProcessesPage,
    RolesPage,
    SubsidiariesPage,
    UsersPage
} from "./page";

initTheme();

const App = (): JSX.Element => {
    const routes: RouteObject[] = [
        { path: "/", element: <LoginPage /> },
        { path: "/login", element: <LoginPage /> },
        { path: "*", element: <NotFoundPage /> },
        {
            element: <MainLayout />,
            children: [
                { path: "/process", element: <ProcessesPage /> },
                { path: "/process/:id", element: <ProcessDetailsPage /> },

                { path: "/documents", element: <DocumentsPage /> },

                { path: "/opentasks", element: <OpenTasksPage /> },
                {
                    path: "/settings",
                    children: [
                        { path: "users", element: <UsersPage /> },
                        { path: "users/new", element: <CreateUserPage /> },
                        { path: "users/:id", element: <EditUserPage /> },

                        { path: "roles", element: <RolesPage /> },

                        { path: "subsidiaries", element: <SubsidiariesPage /> },
                        { path: "subsidiaries/new", element: <CreateSubsidaryPage /> },
                        { path: "subsidiaries/:id", element: <EditSubsidiaryPage /> },

                        { path: "customers", element: <ContactPage kind="customer" /> },
                        { path: "suppliers", element: <ContactPage kind="supplier" /> },
                    ]
                }
            ]
        }
    ];

    const router = createBrowserRouter(routes);

    return (
        <LanguageProvider>
            <ErrorBoundary>
                <GlobalSettingsProvider>
                    <DialogProvider>
                        <SaveCalloutProvider>
                            <RouterProvider router={router} />
                        </SaveCalloutProvider>
                    </DialogProvider>
                </GlobalSettingsProvider>
            </ErrorBoundary>
        </LanguageProvider>
    );
}

export default App;
