import { useState } from "react";
import { useResources } from "../../../../../improve-lib";
import { DropDown, TextBox } from "../../../../../lib";
import { ExternalLinkModel, IdValuePair } from "../../../../../model";
import SubprozessSelector from "./SubprocessSelector";

type NavigationSelectorProps = {
    link?: ExternalLinkModel;
    subprocess: IdValuePair | null;
    processId: number;
    subsidiary: IdValuePair | null;
    onLinkChanged: (link?: ExternalLinkModel) => void;
    onSubprocessChanged: (subprocess: IdValuePair | null) => void
}

const NavigationSelector = (props: NavigationSelectorProps): JSX.Element => {
    const resources = useResources();

    const getLinkSelectorType = () => {
        return props.subprocess ? 2 : 1;
    }

    const [selectedType, setSelectedType] = useState(getLinkSelectorType());

    const navigationSelectorTypes = [{ id: 1, value: resources.link }, { id: 2, value: resources.subprocess }];

    const onSelectedTypeChange = (id: number) => {
        if (id === 2)
            props.onLinkChanged();
        else
            props.onSubprocessChanged(null);

        setSelectedType(id);
    }

    const onUrlChanged = (url: string) => {
        props.onLinkChanged({ url: url, linkText: "" });
    }

    const urlIsValid = () => {
        try {
            if (props.link)
                new URL(props.link.url);
        } catch {
            return false;
        }

        return true;
    }

    const getNavigationSelectorComponent = () => {
        switch (selectedType) {
            case 1:
                return (
                    <TextBox
                        label={resources.link}
                        text={props.link?.url ?? ""}
                        onChange={onUrlChanged}
                        valid={urlIsValid()}
                    />
                );
            case 2:
                return (
                    <SubprozessSelector
                        subprocess={props.subprocess}
                        processId={props.processId}
                        subsidiary={props.subsidiary}
                        onSubprocessChanged={props.onSubprocessChanged}
                    />
                );
        }
    }

    return (
        <div style={{ display: "flex", alignItems: "flex-end" }}>
            <div style={{ marginRight: 10, flex: 0.2 }}>
                <DropDown
                    label={""}
                    placeholder={""}
                    selectedId={selectedType}
                    onChange={item => onSelectedTypeChange(item.id)}
                    options={navigationSelectorTypes}
                />
            </div>
            <div style={{ flex: 1 }}>
                {getNavigationSelectorComponent()}
            </div>
        </div>
    )
}

export default NavigationSelector;