import isEqual from "lodash/isEqual";
import { useEffect, useRef, useState } from "react";
import { useRegisterSaveCheck } from "../../../../../improve-lib";
import { ProcessElementModel, ProcessElementsModel } from "../../../../../model";
import { ElementHelper } from "./elementHelper";
import useElementHelper from "./useElementHelper";

const useModelerElements = (
    dbElements: ProcessElementsModel,
    onProcessIdChanged: (id: number) => void
) => {
    const elements = useRef(dbElements);
    const [elementsChanged, setElementsChanged] = useState(false);
    useRegisterSaveCheck(elementsChanged);

    const { selectedElement, setSelectedElement, elementHelperBase } = useElementHelper(onProcessIdChanged);

    const getElementName = (id: string) => elementHelperBase.getElementName(elements.current[id]);

    const hasElementSubprocess = (id: string) => elementHelperBase.hasElementSubprocess(elements.current[id]);

    const getElementIcons = (id: string) => elementHelperBase.getElementIcons(elements.current[id]);

    const onElementDoubleClick = (id: string) => elementHelperBase.onElementDoubleClick(elements.current[id]);

    const onSelectionChange = (id?: string) => setSelectedElement(id ? (elements.current[id] ?? { bpmnId: id }) : undefined);

    const onElementChanged = (element: ProcessElementModel) => {
        const tempElements = { ...elements.current };
        tempElements[element.bpmnId] = element;
        elements.current = tempElements;

        setElementsChanged(!isEqual(dbElements, elements.current));
        setSelectedElement(elements.current[element.bpmnId]);
    }

    const onElementDelete = (id: string) => {
        if (elements.current[id]) {
            const processElements = { ...elements.current };
            delete processElements[id];

            elements.current = processElements;
            setElementsChanged(true);
        }
    }

    const elementHelper = useRef<ElementHelper>({ getElementName, hasElementSubprocess, getElementIcons, onElementDoubleClick, onElementDelete });

    useEffect(() => {
        setElementsChanged(false);
        elements.current = structuredClone(dbElements);
        elementHelper.current = { getElementName, hasElementSubprocess, getElementIcons, onElementDoubleClick, onElementDelete };
        //eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dbElements]);

    return { elements: elements.current, selectedElement, onSelectionChange, onElementChanged, elementsChanged, elementHelper };
}

export default useModelerElements;