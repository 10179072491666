import { useEffect, useState } from "react";
import { get, useResources, useToggle } from "../../improve-lib";
import { SubsidiaryModel } from "../../model";

const useSubsidiaries = () => {
    const [reloadFlag, reload] = useToggle();

    const resources = useResources();

    const [subsidiaries, setSubsidiaries] = useState<SubsidiaryModel[]>([]);

    useEffect(() => {
        const getSubsidiaries = async () => {
            const response = await get("subsidiaries");

            if (response.status !== 200)
                throw response;

            setSubsidiaries(response.body as SubsidiaryModel[]);
        }
        void getSubsidiaries();
    }, [reloadFlag, resources.allSubsidiaries]);

    return { subsidiaries, reload }
}

export default useSubsidiaries;