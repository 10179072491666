import { isAny } from "bpmn-js/lib/features/modeling/util/ModelingUtil";
import OrderingProvider from "diagram-js/lib/features/ordering/OrderingProvider";


export default class CustomOrderingProvider extends OrderingProvider  {
    static $inject = ["eventBus", "canvas"];

    constructor(eventBus, canvas) {
        super(eventBus);
        this.canvas = canvas;
    }

    getOrdering(element, newParent) {
        if (isAny(element, ["custom:Image"])) {
            return {
                parent: newParent,
                index: -10
            };
        }
        return null;
    }
}