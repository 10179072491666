import { useState } from "react";
import { useCustomFields } from "../../../../api";
import { Container, CustomFieldDetails, NewCustomField, ReorderCustomFields, useResources, useSaveCheck } from "../../../../improve-lib";
import { GroupedList, RowAction } from "../../../../lib";
import { ImproveModule, Location } from "../../../../model";

enum CustomFieldConfigView {
    Default = 0,
    New,
    Details,
    Reorder
}

const CustomFieldSettings = (): JSX.Element => {
    const resources = useResources();

    const [selectedId, setSelectedId] = useState<number>();
    const { customFields, refresh } = useCustomFields(ImproveModule.Processes);
    const [view, setView] = useState(CustomFieldConfigView.Default);

    const onSelectedIdChanged = useSaveCheck((newId: number) => { setSelectedId(newId); setView(CustomFieldConfigView.Details); });
    const onDeleted = () => { refresh(); setSelectedId(undefined); setView(CustomFieldConfigView.Default); }
    const onCreate = useSaveCheck(() => { setSelectedId(undefined); setView(CustomFieldConfigView.New); });
    const onCancel = () => { setSelectedId(undefined); setView(CustomFieldConfigView.Default); }
    const onCreated = (newId: number) => { refresh(); setView(CustomFieldConfigView.Details); setSelectedId(newId); }
    const onReorder = () => { setSelectedId(undefined); setView(CustomFieldConfigView.Reorder); }

    const locations = [
        { id: Location.Process, value: resources.process }
    ];

    const newAction: RowAction = { iconName: "Add", text: resources.new, onClick: onCreate };
    const reorderAction: RowAction = { iconName: "DragObject", text: resources.reorderFields, onClick: onReorder };

    const renderSwitch = () => {
        switch (view) {
            case CustomFieldConfigView.Default:
                return;
            case CustomFieldConfigView.New:
                return <NewCustomField module={ImproveModule.Processes} locations={locations} onCancel={onCancel} onCreated={onCreated} />;
            case CustomFieldConfigView.Details:
                return selectedId && <CustomFieldDetails module={ImproveModule.Processes} customFieldId={selectedId} refresh={refresh} onDeleted={onDeleted} />;
            case CustomFieldConfigView.Reorder:
                return customFields &&
                    <ReorderCustomFields
                        module={ImproveModule.Processes}
                        customFields={customFields}
                        locations={locations}
                        onCancel={onCancel}
                        refresh={refresh}
                    />;
        }
    }

    return (
        <section className="split-view-4-8">
            <div className="content-container">

                <Container title={resources.customFields} actions={[newAction, reorderAction]}>
                    {customFields &&
                        <GroupedList
                            items={customFields.map(af => ({ id: af.id, value: af.name, groupId: af.location }))}
                            groups={locations}
                            selectedItemId={selectedId}
                            onSelectionChanged={onSelectedIdChanged}
                        />
                    }
                </Container>

            </div>
            <div className="content-container">
                {renderSwitch()}
            </div>
        </section>
    );
}

export default CustomFieldSettings;