import { useContext } from "react";
import { useQueryValue } from "../misc";
import { SaveCheckContext } from "./SaveCheckContext";

const useUsafeQueryValue = <T,>(name: string, convert: (value?: string) => T): [T, (value?: string) => void] => {
    const saveCheck = useContext(SaveCheckContext);
    const [q, setQ] = useQueryValue(name, convert);

    return [q, (value?: string) => {
        saveCheck.optOut(() => setQ(value));
    }];
}

export default useUsafeQueryValue;
