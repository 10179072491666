import { useCallback } from "react";
import { useResources } from "../../../../improve-lib";
import { MultiLanguageDescription, MultiLanguageTextbox, Section } from "../../../../lib";
import { ExternalLinkModel, FontStyle, IdValuePair, LinkModel, LinkType, MultiLanguageDescriptionModel, MultiLanguageFieldModel } from "../../../../model";
import DisplaySelector from "./components/DisplaySelector";
import EditFont from "./components/EditFont";
import FileSelector from "./components/FileSelector";
import NavigationSelector from "./components/NavigationSelector";

type EditElementFormProps = {
    processId: number;
    subsidiary: IdValuePair | null;
    subprocess: IdValuePair | null;
    name: MultiLanguageFieldModel;
    description: MultiLanguageDescriptionModel;
    links: LinkModel[];
    font?: FontStyle;
    imageSource?: string;
    showLabel: boolean;
    showNavigation: boolean;
    showSubprocess: boolean;
    showDescription: boolean;
    onSubprocessChanged: (subprocess: IdValuePair | null) => void;
    onNameChanged: (name: MultiLanguageFieldModel) => void;
    onDescriptionChanged: (description: MultiLanguageDescriptionModel) => void;
    onLinksChanged: (links: LinkModel[]) => void;
    onFontChanged: (font: FontStyle) => void;
    onImageSourceChanged: (source: string) => void;
}

const EditElementForm = (props: EditElementFormProps): JSX.Element => {
    const resources = useResources();

    const onLinkChanged = (link?: ExternalLinkModel) => {
        const links = link ?
            [
                {
                    sourceType: LinkType.ProcessElements,
                    targetType: LinkType.ExternalLink,
                    label: link.linkText,
                    targetLink: link.url
                }
            ] : [];

        props.onLinksChanged(links);
    }

    const getLink = useCallback(() => {
        const link = props.links.find(l => l.targetType === LinkType.ExternalLink);
        return link ? { url: link.targetLink, linkText: link.label } as ExternalLinkModel : undefined;
    }, [props.links]);
    
    return (
       
        <article>
            <div className="article-content">
                {props.showSubprocess ?
                    <DisplaySelector
                        label={props.name}
                        subprocess={props.subprocess}
                        processId={props.processId}
                        subsidiary={props.subsidiary}
                        onLabelChanged={props.onNameChanged}
                        onSubprocessChanged={props.onSubprocessChanged}
                    />
                    : props.showLabel &&
                    <MultiLanguageTextbox
                        languageFields={props.name}
                        onChange={props.onNameChanged}
                        duplicatedIsoCodes={[]}
                        textBoxHeader={resources.label}
                    />
                }
            </div>
            <div className="article-form-element">
                {props.showDescription &&
                    <MultiLanguageDescription
                        languageDescriptions={props.description}
                        onChange={props.onDescriptionChanged}
                        textBoxHeader={resources.description}
                    />
                }
            </div>
            <div className="article-content">
                {props.font &&
                    <Section headerText={resources.display} >
                        <EditFont
                            name={props.name}
                            font={props.font}
                            onNameChanged={props.onNameChanged}
                            onFontChanged={props.onFontChanged}
                        />
                    </Section>
                }
                {props.imageSource !== undefined &&
                    <Section headerText={resources.display} >
                        <FileSelector imageSource={props.imageSource ?? ""} onFileChanged={props.onImageSourceChanged} />
                    </Section>
                }
                {props.showNavigation &&
                    <Section headerText={resources.navigation} >
                        <NavigationSelector
                            link={getLink()}
                            subprocess={props.subprocess}
                            processId={props.processId}
                            subsidiary={props.subsidiary}
                            onSubprocessChanged={props.onSubprocessChanged}
                            onLinkChanged={onLinkChanged}
                        />
                    </Section>
                }
            </div>
        </article>
    );
}

export default EditElementForm;