import { useState } from "react";
import { useProcessGroups } from "../../../../api";
import NewProcessGroup from "./components/NewProcessGroup";
import ProcessGroupDetails from "./components/ProcessGroupDetails";
import ProcessGroupList from "./components/ProcessGroupList";

const ProcessGroupSettings = (): JSX.Element => {
    const [selectedId, setSelectedId] = useState<number>();
    const [newProcessGroup, setNewProcessGroup] = useState(false);

    const onSelectedIdChanged = (newId: number) => setSelectedId(newId);
    const onDeleted = () => { reload(); setSelectedId(undefined); setNewProcessGroup(false); }
    const onCreate = () => { reload(); setNewProcessGroup(true); }
    const onCreateCancel = () => { setSelectedId(undefined); setNewProcessGroup(false); }
    const onCreated = (newId: number) => { reload(); setNewProcessGroup(false); setSelectedId(newId); }

    const { processGroups, reload } = useProcessGroups();

    return (
        <section className="split-view-4-8">
                <div className="content-container">
                    {processGroups && <ProcessGroupList
                        onProcessGroupCreate={onCreate}
                        processGroups={processGroups}
                        onProcessGroupIdChanged={onSelectedIdChanged}
                        selectedProcessGroupId={selectedId}
                    />}
                </div>
            <div className="content-container">
                    {newProcessGroup && <NewProcessGroup onCancel={onCreateCancel} onCreated={onCreated} />}
                    {!newProcessGroup && selectedId && <ProcessGroupDetails processGroupId={selectedId} refresh={reload} onDeleted={onDeleted} />}
                </div>

        </section>
    )
}

export default ProcessGroupSettings;
