import { DetailsList, DetailsListLayoutMode, DetailsRow, getTheme, IColumn, SelectionMode } from "@fluentui/react";
import { useState } from "react";
import { helperFunctions } from "../../lib";
import { IdValuePair } from "../../model";
import "./SelectionList.tsx.scss";

type SelectionListProps = {
    items: IdValuePair[];
    selectedItemId?: number;
    columnHeader: string;
    onSelectionChanged: (newSelectedId: number) => void;
}

const SelectionList = (props: SelectionListProps): JSX.Element => {
    const theme = getTheme();

    const [orderAsc, setOrderAsc] = useState(true);

    const onColumnClick = (_ev: React.MouseEvent<HTMLElement>): void => {
        setOrderAsc(!orderAsc);
    };

    const sortedItems = helperFunctions.sortIdValuePairs(props.items, orderAsc);

    const columns: IColumn[] = [
        {
            key: "0",
            name: props.columnHeader,
            fieldName: "value",
            minWidth: 200,
            isResizable: true,
            onColumnClick: onColumnClick,
            isSorted: true,
            isSortedDescending: !orderAsc,
            className: "fluent-width-lock",
        }
    ];

    const onItemSelect = (id: string): void => {
        props.onSelectionChanged(parseInt(id));
    };

    return (
        <div>
            <DetailsList
                className="abc"
                items={sortedItems}
                columns={columns}
                layoutMode={DetailsListLayoutMode.fixedColumns}
                compact={true}
                selectionMode={SelectionMode.none}
                onRenderRow={s => {
                    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
                    if (s?.item["id"] === props.selectedItemId)
                        return <div><DetailsRow styles={{ root: { backgroundColor: theme.palette.neutralLight }, cell: { minWidth: "100% !important", maxWidth: "100%" } }} {...s!} /></div>
                    else
                        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
                        return <div onClick={() => onItemSelect(s?.item.id as string)}><DetailsRow styles={{ cell: { cursor: "pointer" } }} {...s!} /></div>
                }}
            />
        </div>
    )
}

export default SelectionList;
