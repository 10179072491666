import { ContextualMenu, IDragOptions, Modal, Text } from "@fluentui/react";
import { ReactNode } from "react";
import { ContainerActions } from "../../improve-lib";
import { RowAction, Separator } from "../../lib";
import "./Popup.tsx.scss";

type PopupProps = {
    isOpen: boolean;
    title: string;
    onDismiss: () => void;
    children: ReactNode;
    size: "tiny" | "small" | "medium" | "big";
    actions?: RowAction[];
    closeText: string;
    onSave?: () => Promise<any>;
    saveDisabled?: boolean;
}
const Popup = (props: PopupProps): JSX.Element => {
    const dragOptions: IDragOptions = {
        moveMenuItemText: "Move",
        closeMenuItemText: "Close",
        menu: ContextualMenu,
        dragHandleSelector: ".popup-drag",
        keepInBounds: true
    }

    return (
        <Modal
            isOpen={props.isOpen}
            onDismiss={props.onDismiss}
            isBlocking={false}
            dragOptions={dragOptions}
            containerClassName={"popup-size-" + props.size}
            scrollableContentClassName="popup-scrollable-content"
        >
            <div className="popup-drag" />
            <div className="popup-wrapper">
                <div>
                    <ContainerActions
                        actions={props.actions ?? []}
                        overflow={[]}
                        farItems={[{
                            onClick: props.onDismiss,
                            text: props.closeText,
                            iconName: "ChromeClose"
                        }]}
                        onSave={props.onSave}
                        saveDisabled={props.saveDisabled}
                    />
                </div>
                <Separator color="gray" />
                <div className="popup-header">
                    <Text variant="xLarge">
                        {props.title}
                    </Text>
                </div>
                {props.children}
            </div>
        </Modal>
    )
}

export default Popup;
