import { useEffect, useState } from "react";
import { get, useResources, useToggle } from "../../improve-lib";
import EditTagModel from "../../model/configuration/documents/tag/editTagModel";

const useTag = (id: number) => {
    const resources = useResources();

    const [reloadFlag, reload] = useToggle();
    const [tag, setTag] = useState<EditTagModel>();

    useEffect(() => {
        const getApplicationArea = async () => {
            const response = await get("tags/" + id);

            if (response.status !== 200)
                throw response;

            const tag: EditTagModel = response.body;
            tag.dateTimeCreated = new Date(tag.dateTimeCreated);
            tag.dateTimeLastEdited = new Date(tag.dateTimeLastEdited);
            setTag(tag);
        }
        void getApplicationArea();
    }, [id, resources.isoCode, reloadFlag]);

    return { tag, reload }
}

export default useTag;