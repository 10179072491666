import { createContext } from "react";

export type DialogOptions = {
    callback: () => void;
    dialog: ConfirmDialog | DeleteDialog | SaveCheckDialog;
}

type ConfirmDialog = {
    type: "confirm";
    confirmationText: string;
}

type DeleteDialog = {
    type: "delete";
    toBeDeleted: string;
    extended?: boolean;
}

type SaveCheckDialog = {
    type: "savecheck"
}

export type DialogContextType = {
    openDialog: (options: DialogOptions) => void;
}

const DialogContext = createContext<DialogContextType>({
    openDialog: () => { }
});

export { DialogContext };