import { post } from "../../improve-lib";

const useLoginOverKerberos = (afterLogin?: () => void) => {
    const loginOverKerberos = async () => {
        const response = await post("authentication/kerberos");

        if (response.status !== 200)
            throw response;

        if (afterLogin)
            afterLogin();
    }

    return { loginOverKerberos }
}

export default useLoginOverKerberos;