import { del, useConfirmDeleteCallback } from "../../improve-lib";

const useDeleteTag = (afterDelete?: () => void) => {
    const deleteTag = useConfirmDeleteCallback(async (id: number) => {
        const response = await del("tags/" + id);

        if (response.status === 200) {
            if (afterDelete)
                afterDelete();
        }
        else
            throw response;
    });

    return { deleteTag }
}

export default useDeleteTag;