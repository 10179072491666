import { useState } from "react";
import { useTags } from "../../../../../api";
import Details from "./components/Details";
import List from "./components/List";
import New from "./components/New";

const TagSettings = (): JSX.Element => {
    const [selectedId, setSelectedId] = useState<number>();
    const [newTag, setNewTag] = useState(false);

    const { tags, reload } = useTags();

    const onSelectedIdChanged = (newId: number) => setSelectedId(newId);
    const onDeleted = () => { reload(); setSelectedId(undefined); setNewTag(false); }
    const onCreate = () => { reload(); setNewTag(true); }
    const onCreateCancel = () => { setSelectedId(undefined); setNewTag(false); }
    const onCreated = (newId: number) => { reload(); setNewTag(false); setSelectedId(newId); }

    return (
        <section className="split-view-6-6">
            <div className="content-container">
                {tags && <List
                    tags={tags}
                    onTagCreate={onCreate}
                    onTagIdChanged={onSelectedIdChanged}
                    selectedTagId={selectedId}
                />}
            </div>
            <div className="content-container">
                {newTag && <New onCancel={onCreateCancel} onCreated={onCreated} />}
                {!newTag && selectedId && <Details onDeleted={onDeleted} refresh={reload} tagId={selectedId} />}
            </div>
        </section>
    )
}

export default TagSettings;
