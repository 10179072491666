import { useResources } from "../../../../../../../improve-lib";
import { Color, ColorSelector, MultiLanguageTextbox,Section } from "../../../../../../../lib";
import { MultiLanguageFieldModel, TagModel } from "../../../../../../../model";

type FormNewProps = {
    tag: TagModel;
    onTagChange: (newTag: TagModel) => void;
    duplicatedIsoCodes: string[];
}

const FormNew = (props: FormNewProps): JSX.Element => {
    const resources = useResources();

    const onNameChange = (newName: MultiLanguageFieldModel) => props.onTagChange({ ...props.tag, name: newName });
    const onColorChange = (newColor: Color) => props.onTagChange({ ...props.tag, color: newColor });

    return (
        <>
            <Section headerText={resources.label}>
                <MultiLanguageTextbox
                    languageFields={props.tag.name}
                    onChange={onNameChange}
                    duplicatedIsoCodes={props.duplicatedIsoCodes}
                    textBoxHeader={resources.label + " " + resources.tag}
                    mandatory
                />
            </Section>
            <Section headerText={resources.color}>
                <ColorSelector selectedColor={props.tag.color} onColorChange={onColorChange} />
            </Section>
        </>
    )
}

export default FormNew;
