import { getFillColor, getStrokeColor } from "bpmn-js/lib/draw/BpmnRenderUtil";
import { isAny } from "bpmn-js/lib/features/modeling/util/ModelingUtil";
import Modeler from "bpmn-js/lib/Modeler";
import GridModule from "diagram-js/lib/features/grid-snapping/visuals";
import CustomContextPadModule from "./contextpad";
import CustomOrderingModule from "./custom";
import CustomModdle from "./custom/CustomElements.json";
import OriginModule from "./origin";
import CustomPaletteModule from "./palette";
import CustomRendererModule from "./renderer";
import CustomRulesModule from "./rules";
import CustomTranslateModule from "./translate";
import "bpmn-js/dist/assets/diagram-js.css";
import "bpmn-js/dist/assets/bpmn-js.css";
import "bpmn-js/dist/assets/bpmn-font/css/bpmn-embedded.css";

//import EventBusLoggerModule from "./logger"; // Kann zum Debuggen eingeschalten werden.

export default class BpmnModeler {
    constructor(
        container,
        resources,
        elementHelper,
        contextPadEntries,
        onSelectedElementChange,
        onBpmnChange
    ) {
        this.resources = resources;

        this.Modeler = new Modeler({
            container: container,
            keyboard: {
                // ACHTUNG: nicht 'document.body' verwenden
                // http://app2019/Synprovis_2019/Improve/_workitems/edit/161/
                bindTo: container
            },
            additionalModules: [
                GridModule,
                OriginModule,
                CustomRulesModule,
                CustomPaletteModule,
                CustomContextPadModule,
                CustomRendererModule,
                CustomOrderingModule,
                CustomTranslateModule(() => this.resources)
                //EventBusLoggerModule // Kann zum Debuggen eingeschalten werden.
            ],
            getResources: () => this.resources,
            elementHelper: elementHelper,
            customContextPadEntries: contextPadEntries,
            moddleExtensions: {
                custom: CustomModdle
            }
        });

        this.elementRegistry = this.Modeler.get("elementRegistry");

        this.Modeler.on("element.dblclick", 1500, event => {
            event.stopPropagation();
            elementHelper.current.onElementDoubleClick(event.element.id);
        });

        this.Modeler.on("commandStack.changed", event => {
            if (event.trigger !== "clear") {
                onBpmnChange();
            }
        });

        const eventBus = this.Modeler.get("eventBus");

        eventBus.on("commandStack.postExecuted", event => {
            if (event.command === "elements.delete" && event.context.elements) {
                for (let i = 0; i < event.context.elements.length; i++) {
                    elementHelper.current.onElementDelete(event.context.elements[i].id);
                }
            }
        });

        eventBus.on("directEditing.activate", () => {
            const directEditing = this.Modeler.get("directEditing");
            directEditing.cancel();
        });

        eventBus.on("selection.changed", event => {
            if (event.newSelection.length === 1)
                onSelectedElementChange(event.newSelection[0].id);
            else
                onSelectedElementChange(undefined);
        });
    }

    updateResources = resources => {
        this.resources = resources;
        this.Modeler.get("customPalette").Palette._update();
    }

    updateDiagram = async xml => {
        await this.Modeler.importXML(xml);
        this.zoomReset();
    }

    zoomReset() {
        const canvas = this.Modeler.get("canvas");
        canvas.zoom("fit-viewport");
        canvas.scroll({ dx: 80, dy: 0 });
    }
    zoomIn() { this.Modeler.get("zoomScroll").stepZoom(1); }
    zoomOut() { this.Modeler.get("zoomScroll").stepZoom(-1); }

    getElementColor = id => {
        const element = this.elementRegistry.get(id);

        return { fill: this.getFillColor(element), stroke: this.getStrokeColor(element) };
    }

    getFillColor = element => { return getFillColor(element, "#ffffff"); }

    getStrokeColor = element => { return getStrokeColor(element, "#000000"); }

    getXML = async () => {
        const output = await this.Modeler.saveXML();
        return output.xml;
    }

    getSVG = async () => {
        const output = await this.Modeler.saveSVG();
        return output.svg;
    }

    getElementFont = id => {
        const element = this.elementRegistry.get(id);
        if (this.allowToAddFontStyle(id)) {
            const font = element.di.label?.labelStyle?.font;
            const color = this.getStrokeColor(element);
            if (font)
                return {
                    size: font.size,
                    isBold: font.isBold,
                    isItalic: font.isItalic,
                    isUnderline: font.isUnderline,
                    rotation: element.businessObject.rotation ?? 0,
                    color: color
                };
            else
                return { size: 12, isBold: false, isItalic: false, isUnderline: false, rotation: 0, color: color };
        }
        else
            return undefined;
    }

    getElementImageSource = id => {
        const element = this.elementRegistry.get(id);
        if (this.allowToAddFile(id)) {
            return element.businessObject.source ?? "";
        }
        else
            return undefined;
    }

    updateElement = (id, font, imageSource) => {
        const element = this.elementRegistry.get(id);

        this.updateElementImageSource(element, imageSource);
        this.Modeler.get("modeling").updateLabel(element, id);
        this.updateElementFont(element, font);
    }

    updateElementFont = (element, font) => {
        if (font) {
            const elementFont = element.di.label?.labelStyle?.font;
            if (elementFont) {
                elementFont.size = font.size;
                elementFont.isBold = font.isBold;
                elementFont.isItalic = font.isItalic;
                elementFont.isUnderline = font.isUnderline;
            }
            else if (element.di.label) {
                const canvas = this.Modeler.get("canvas");
                const moddle = this.Modeler.get("moddle");

                const rootElement = canvas.getRootElement();
                const diagram = rootElement.di.$parent;

                const labelStyle = moddle.create("bpmndi:BPMNLabelStyle", {
                    id: element.id + "_ls",
                    font: moddle.create("dc:Font", {
                        size: font.size,
                        isBold: font.isBold,
                        isItalic: font.isItalic,
                        isUnderline: font.isUnderline
                    })
                });

                if (diagram.labelStyle)
                    diagram.labelStyle.push(labelStyle);
                else
                    diagram.labelStyle = [labelStyle];

                element.di.label.labelStyle = labelStyle;
            }
            element.businessObject.rotation = font.rotation;

            const modeling = this.Modeler.get("modeling");
            modeling.setColor(element, { stroke: font.color });
        }
    }

    updateElementImageSource = (element, source) => {
        element.businessObject.source = source;
    }

    updateElementColor = (id, fillColor, strokeColor) => {
        const modeling = this.Modeler.get("modeling");

        const element = this.elementRegistry.get(id);

        modeling.setColor(element, {
            fill: fillColor,
            stroke: strokeColor,
        });
    }

    showFillColor = id => this.isElementOfType(id, ["bpmn:Task", "bpmn:Event", "bpmn:Gateway", "bpmn:Participant", "bpmn:Lane"]);
    showStrokeColor = id => this.isElementOfType(id, ["bpmn:Task", "bpmn:SequenceFlow", "bpmn:Event", "bpmn:Gateway", "bpmn:Participant", "bpmn:Lane", "bpmn:Group"]);

    showContextPadEditEntry = element => isAny(element, ["bpmn:Task", "bpmn:SequenceFlow", "bpmn:Event", "bpmn:Gateway", "bpmn:Participant", "bpmn:Lane", "bpmn:Group", "custom:TextBox", "custom:Image"]);
    showContextPadColorEntry = element => isAny(element, ["bpmn:Task", "bpmn:SequenceFlow", "bpmn:Event", "bpmn:Gateway", "bpmn:Participant", "bpmn:Lane", "bpmn:Group"]);

    allowToAddLinks = id => this.isElementOfType(id, ["bpmn:Task"]);
    allowToAddLabel = id => this.isElementOfType(id, ["bpmn:Task", "bpmn:Event", "bpmn:Gateway", "bpmn:Participant", "bpmn:Lane", "bpmn:SequenceFlow", "bpmn:Group"]);
    allowToAddFontStyle = id => this.isElementOfType(id, ["custom:TextBox"]);
    allowToAddSubprocess = id => this.isElementOfType(id, ["bpmn:Task"]);
    allowToAddNavigation = id => this.isElementOfType(id, ["custom:Image", "custom:TextBox"]);
    allowToAddDescription = id => this.isElementOfType(id, ["bpmn:Task", "bpmn:Event", "bpmn:Gateway", "bpmn:Participant", "bpmn:Lane", "bpmn:Group"]);
    allowToAddFile = id => this.isElementOfType(id, ["custom:Image"]);

    isElementOfType = (id, types) => {
        const element = this.elementRegistry.get(id);

        return isAny(element, types);
    }
}