import { post, useSaveCallout } from "../../improve-lib";
import { EditDocumentModel } from "../../model";

const useUpdateDocument = (afterSuccessfulSave?: () => void) => {
    const saveCallout = useSaveCallout();

    const updateDocument = async (documentId: number, document: EditDocumentModel, databaseDocument: EditDocumentModel) => {
        if (document && databaseDocument) {
            const formData = new FormData();

            if (document.file !== undefined)
                formData.append("file", document.file);

            // TODO: mit remodel anders lösbar
            formData.append("convertToPdf", (document.convertToPdf ?? true).toString());

            if (document.filename !== databaseDocument.filename)
                formData.append("name", document.filename);

            formData.append("applicationAreaIdForPermissionCheck", document.applicationArea.id.toString());

            for (let i = 0; i < document.tags.length; i++)
                formData.append("tagIds", document.tags[i].id.toString());

            if (document.owner.id !== databaseDocument.owner.id)
                formData.append("ownerId", document.owner.id.toString());

            if (document.version !== databaseDocument.version)
                formData.append("version", document.version);

            formData.append("dateValidUntilchanged", (document.dateValidUntil !== databaseDocument.dateValidUntil).toString());
            if (document.dateValidUntil !== databaseDocument.dateValidUntil && document.dateValidUntil !== null)
                formData.append("dateValidUntil", document.dateValidUntil.toDateString());

            if (document.descriptionId)
                formData.append("descriptionId", document.descriptionId.toString());

            if (document.versionCount)
                formData.append("versionCount", document.versionCount.toString());

            if (document.description) {
                const keys = Object.keys(document.description);

                for (let i = 0; i < keys.length; i++) {
                    const key = keys[i];
                    const value = document.description[key];

                    // if one value is empty, save should not be allowed and can therefore be treated as null
                    if (value.trim().length === 0)
                        break;

                    formData.append(`description.${key}`, value);
                }
            }

            const response = await post("documents/" + documentId, formData);

            if (response.status !== 200)
                throw response;

            if (afterSuccessfulSave)
                afterSuccessfulSave();

            saveCallout.show();
        }
    }

    return { updateDocument }
}

export default useUpdateDocument;