import { put, useSaveCallout } from "../../improve-lib"

const useChangeDocumentSubsidiary = () => {
    const saveCallout = useSaveCallout();

    const changeDocumentSubsidiary = async (documentId: number, subsidiaryId: number | null) => {
        let requestUrl = `documents/${documentId}/changeSubsidiary`;
        if (subsidiaryId !== null)
            requestUrl += `?subsidiaryId=${subsidiaryId}`;

        const response = await put(requestUrl);

        switch (response.status) {
            case 200:
                saveCallout.show();
                break;
            default:
                throw response;
        }
    }

    return { changeDocumentSubsidiary }
}

export default useChangeDocumentSubsidiary;