import { useState } from "react";
import { post, useSaveCallout } from "../../improve-lib";
import { BadUserCreationResponse, UserUpdateModel } from "../../model";

const useUpdateUser = (afterSuccessfulSave?: () => void) => {
    const saveCallout = useSaveCallout();

    const [errors, setErrors] = useState<BadUserCreationResponse>({
        usernameDuplicated: false,
        displayNameDuplicated: false
    });

    const updateUser = async (id: number, updateModel: UserUpdateModel) => {
        const response = await post("users/" + id, updateModel);

        switch (response.status) {
            case 200:
                saveCallout.show();
                if (afterSuccessfulSave)
                    afterSuccessfulSave();
                break;
            case 400:
                setErrors(response.body as BadUserCreationResponse);
                break;
            default:
                throw response;
        }
    }

    return { updateUser, setErrors, errors }
}

export default useUpdateUser;