import { useEffect, useState } from "react";
import { useChangeProcessSubsidiary, usePermittedProcessGroups } from "../../../../../api";
import { useResources, useSaveCheck } from "../../../../../improve-lib";
import { Button, Dialog, DialogFooter, DropDown } from "../../../../../lib";
import { IdValuePair, ProcessGroupPermissionType } from "../../../../../model";

type ChangeSubsidiaryDialogProps = {
    processId: number;
    subsidiaryId: number | null;
    processGroupId: number;
    subsidiariesCount: number;
    hidden: boolean;
    onDismiss: () => void;
    onChanged: () => void;
}

const ChangeSubsidiaryDialog = (props: ChangeSubsidiaryDialogProps): JSX.Element => {
    const resources = useResources();

    const [subsidiaries, setSubsidiaries] = useState<IdValuePair[]>([]);
    const [subsidiaryId, setSubsidiaryId] = useState(props.subsidiaryId);
    const [subsidiaryChanged, setSubsidiaryChanged] = useState(false);

    const { changeProcessSubsidiary } = useChangeProcessSubsidiary();
    const { permittedProcessGroups } = usePermittedProcessGroups();

    useEffect(() => {
        if (props.subsidiariesCount > 1) {
            const processGroup = permittedProcessGroups.find(pg => pg.id === props.processGroupId);

            if (processGroup) {
                const subsidiariesWithWritePermission =
                    processGroup.subsidiaryPermissions
                        .filter(sp => sp.permission >= ProcessGroupPermissionType.Create).map(sp => sp.subsidiary);

                if (props.subsidiaryId === null || props.subsidiariesCount === subsidiariesWithWritePermission.length)
                    subsidiariesWithWritePermission.unshift({ id: -1, value: resources.allSubsidiaries });

                setSubsidiaries(subsidiariesWithWritePermission);
            }
        }
    }, [props.subsidiariesCount, permittedProcessGroups, resources.allSubsidiaries, props.processGroupId, props.subsidiaryId]);

    useEffect(() => {
        setSubsidiaryChanged(subsidiaryId !== props.subsidiaryId);
    }, [subsidiaryId, props.subsidiaryId]);

    useEffect(() => {
        if (!props.hidden) {
            setSubsidiaryId(props.subsidiaryId);
        }
    }, [props.hidden, props.subsidiaryId]);

    const onChange = useSaveCheck(async () => {
        await changeProcessSubsidiary(props.processId, subsidiaryId);
        props.onChanged();
    });

    return (
        <Dialog
            title={resources.changeSubsidiary}
            hidden={props.hidden}
            onDismiss={props.onDismiss}
        >
            <DropDown
                label={resources.subsidiary}
                placeholder={resources.subsidiary}
                selectedId={subsidiaryId ?? -1}
                onChange={item => setSubsidiaryId(item.id > 0 ? item.id : null)}
                options={subsidiaries}
            />
            <DialogFooter>
                <Button onClick={onChange} text={resources.save} disabled={!subsidiaryChanged} />
                <Button onClick={props.onDismiss} text={resources.cancel} secondary />
            </DialogFooter>
        </Dialog >
    )
}

export default ChangeSubsidiaryDialog;
