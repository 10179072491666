import { DetailsList, DetailsListLayoutMode, DetailsRow, getTheme, IColumn, IDetailsRowProps, SelectionMode } from "@fluentui/react";
import { useResources } from "../../improve-lib";
import { Button, OrderColumn, RowAction, TableColumnType, TableData } from "../../lib";
import { ListActions } from "../list";
import "./Table.tsx.scss"

type TableProps<T> = {
    data: TableData<T>;
    onReorder: (newOrderColumn: OrderColumn) => void;
    orderColumn: OrderColumn;
    onRowClick: (item: T) => void;
    onActionsCreate?: (rowData: T) => RowAction[];
    onLoadMore?: () => void;
}

const Table = <T extends { id: number }>(props: TableProps<T>): JSX.Element => {
    const theme = getTheme();

    const resources = useResources();

    const onColumnClick = (_ev: React.MouseEvent<HTMLElement>, column: IColumn): void => {
        if (props.orderColumn.columnName === column.fieldName)
            props.onReorder({ ...props.orderColumn, ascending: !props.orderColumn.ascending })
        else
            props.onReorder({ columnName: column.fieldName!, ascending: true })
    };

    const onRowDoubleClick = (item: T): void => {
        props.onRowClick(item);
    };

    const renderDate = (item: T, field: string) => {
        const date: Date = item[field as keyof T] as Date;
        const dateString = new Date(date).toLocaleDateString(undefined, { year: "numeric", month: "2-digit", day: "2-digit" });

        switch (field) {
            case "dueDate": {
                const dateToday = new Date();
                dateToday.setHours(0, 0, 0, 0);
                const daysDiff = Math.floor((new Date(date).getTime() - dateToday.getTime()) / 86400000); // 24 * 60 * 60 * 1000 = 86'400'000 (milliseconds per day)
                let fontColor = "inherit";  //TODO: hacky? how to set style to default? (needed on sorting...)
                let backColor = "inherit";
                if (daysDiff < 0) {
                    fontColor = "white";
                    backColor = "indianRed";
                }
                else if (daysDiff < 3)
                    backColor = "khaki";

                return (
                    <div style={{ backgroundColor: backColor, color: fontColor, backgroundClip: "content-box", textAlign: "center" }}>
                        {dateString}
                    </div>
                )
            }
            default:
                return (
                    <div style={{ textAlign: "center" }}>
                        {dateString}
                    </div>
                )
        }
    }

    const renderBoolean = (item: T, field: string) => {
        const boolValue = item[field as keyof T] as boolean;
        const boolString = boolValue ? resources.yes : resources.no;
        return (
            <div>
                {boolString}
            </div>
        )
    }

    const renderNumber = (item: T, field: string) => {
        return (
            <div style={{ textAlign: "right", paddingRight: "10px" }}>
                {item[field as keyof T] as number}
            </div>
        )
    }

    const columns: IColumn[] = props.data.columns.map((column, i) => {
        const col: IColumn = {
            key: i.toString(),
            name: column.displayName,
            fieldName: column.name,
            minWidth: 100,
            maxWidth: 200,
            isResizable: true,
            onColumnClick: onColumnClick,
            isSorted: column.name === props.orderColumn.columnName,
            isSortedDescending: !props.orderColumn.ascending
        };
        switch (column.dataType) {
            case TableColumnType.Date:
            case TableColumnType.DueDate:
                col.minWidth = 75;
                col.maxWidth = 75;
                col.onRender = (item: T) => (renderDate(item, column.name));
                return col;
            case TableColumnType.Boolean:
                col.minWidth = 100;
                col.maxWidth = 150;
                col.onRender = (item: T) => (renderBoolean(item, column.name));
                return col;
            case TableColumnType.Number:
                col.minWidth = 75;
                col.maxWidth = 75;
                col.onRender = (item: T) => (renderNumber(item, column.name));
                return col;
            default:
                return col;
        }
    });

    if (props.onActionsCreate) {
        columns.push({
            key: "_more_column",
            name: "",
            minWidth: 30,
            maxWidth: 30,
            isResizable: false,
            onRender: (item: { [property: string]: any }) => {
                const actions = props.onActionsCreate!(item as T);
                return <ListActions items={actions} />
            },
        });
    }

    const onRenderRow = (row?: IDetailsRowProps) => {
        let styles;
        const isOdd = row?.item !== undefined && row?.itemIndex % 2 === 0;
        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
        if (row?.item["active"] !== undefined && !row?.item["active"]) {
            if (isOdd) {
                styles = {
                    root: {
                        color: theme.palette.neutralQuaternary,
                        backgroundColor: "#F7FAFC",
                    }
                };
            }
            else {
                styles = {
                    root: {
                        color: theme.palette.neutralQuaternary
                    }
                };
            }
        }
        else if (isOdd) {
            styles = {
                root: {
                    backgroundColor: "#F7FAFC", //TODO: add color to theme-palette or use / change to 'theme.palette.themeLighterAlt',
                }
            };
        }
        return <DetailsRow styles={styles} {...row!} />
    }

    return (
        <DetailsList
            items={!props.onLoadMore ? props.data.rows : [...props.data.rows, null]}
            columns={columns}
            layoutMode={DetailsListLayoutMode.justified}
            selectionMode={SelectionMode.none}
            compact={true}
            onItemInvoked={onRowDoubleClick}
            onRenderRow={onRenderRow}
            onRenderMissingItem={() => {
                return <div className="table-load-more-wrapper">
                    <Button
                        onClick={props.onLoadMore!}
                        text={resources.showMore}
                    />
                </div>
            }}
        />
    )
}

export default Table;