import { useState } from "react";
import { ContactKind, useContacts, useDeleteContact } from "../../../api/contacts";
import { Container, useConfirmDeleteCallback, useLabels, useResources } from "../../../improve-lib";
import { MemoryTable, SearchBox, TableColumnType, TableData, useDebounce } from "../../../lib";
import EditContactPopup from "./EditContactPopup";
import NewContactPopup from "./NewContactPopup";
import "./ContactContainer.tsx.scss";

type ContactContainerProps = {
    kind: "customer" | "supplier";
}

const ContactContainer = (props: ContactContainerProps) => {
    const resources = useResources();
    const labels = useLabels();
    const [editContactId, setEditContactId] = useState<number | null>(null);
    const [newContactOpen, setNewContactOpen] = useState(false);
    const [search, setSearch] = useState("");
    const searchDebounced = useDebounce(search);

    const { contacts, reload } = useContacts(props.kind === "customer" ? ContactKind.Customer : ContactKind.Supplier, searchDebounced);
    const deleteContact = useDeleteContact();

    const onAddContactClick = () => setNewContactOpen(true);
    const onNewCustomerClosed = () => setNewContactOpen(false);
    const onCustomerCreated = () => {
        setNewContactOpen(false);
        reload();
    }

    const onContactEdited = () => {
        setEditContactId(null);
        reload();
    }
    const onEditCancel = () => setEditContactId(null);
    const onContactEdit = (id: number) => setEditContactId(id);
    const onAddressDelete = useConfirmDeleteCallback<number>(async (contactId: number) => {
        await deleteContact(contactId)
        reload();
    }, true);

    if (!contacts)
        return null;

    const table: TableData<{ id: number, nr: string }> = {
        columns: [
            { displayName: props.kind === "customer" ? labels.customerNumber : labels.supplierNumber, name: "nr", dataType: TableColumnType.Text },
            { displayName: props.kind === "customer" ? labels.customer : labels.supplier, name: "name", dataType: TableColumnType.Text },
            { displayName: resources.street, name: "street", dataType: TableColumnType.Text },
            { displayName: resources.zipCode, name: "zipCode", dataType: TableColumnType.Text },
            { displayName: resources.city, name: "city", dataType: TableColumnType.Text },
            { displayName: resources.country, name: "country", dataType: TableColumnType.Text },
        ],
        rows: contacts.results.map(c => ({
            id: c.id,
            nr: c.nr,
            name: c.name,
            street: c.street,
            zipCode: c.zipCode,
            city: c.city,
            country: c.country
        }))
    };

    return (
        <section>
            {editContactId && <EditContactPopup contactId={editContactId} onCancel={onEditCancel} onEdited={onContactEdited} />}
            {newContactOpen && <NewContactPopup kind={props.kind} onCreated={onCustomerCreated} onClose={onNewCustomerClosed} />}
            <div className="content-container">
                <Container
                    title={`${props.kind === "customer" ? labels.customer : labels.supplier} (${contacts.contactCount})`}
                    actions={[
                        {
                            text: resources.new,
                            iconName: "Add",
                            onClick: onAddContactClick
                        }
                    ]}>
                    <div className="contact-container-searchbar">
                        <SearchBox
                            placeholder={resources.filter}
                            onChange={setSearch}
                            text={search}

                        />
                    </div>
                    <MemoryTable
                        data={table}
                        orderColumn={{ ascending: true, columnName: "name" }}
                        onActionsCreate={row => ([{
                            text: resources.edit,
                            iconName: "Edit",
                            onClick: () => onContactEdit(row.id)
                        }, {
                            text: resources.delete,
                            iconName: "Delete",
                            onClick: () => onAddressDelete(row.id, row.nr)
                        }])}
                        onRowClick={row => onContactEdit(row.id)}
                        onReorder={() => { }}
                    />
                </Container>
            </div>
        </section>
    )
}

export default ContactContainer;
