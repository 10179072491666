import { useMemo } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useResources } from "../../../../improve-lib";
import { Breadcrumb } from "../../../../lib";
import { IdValuePair, ProcessSubprocess } from "../../../../model";
import { useProcessesContext, useProcessSubprocessContext } from "../../ProcessesProvider";

type ProcessBreadcrumbProps = {
    processId: number;
    onProcessIdChanged: (id: number) => void;
}

const ProcessBreadcrumb = (props: ProcessBreadcrumbProps): JSX.Element => {
    const location = useLocation();
    const navigate = useNavigate();
    const resources = useResources();

    const processes = useProcessesContext();
    const processSubprocess = useProcessSubprocessContext();

    const goToProcessesPage = () => {
        navigate("/process/");
    }

    const createProcessPath = (processId: number, processes: IdValuePair[], processSubprocess: ProcessSubprocess[]): IdValuePair[] => {
        if (processes.some(p => p.id === processId)) {
            const current: IdValuePair = {
                id: processId,
                value: processes.find(p => p.id === processId)?.value ?? "",
            }

            if (processSubprocess) {
                const parentProcesses = processSubprocess.filter(ps => ps.subprocessId === processId);
                if (parentProcesses.length === 1)
                    return [...createProcessPath(parentProcesses[0].processId, processes, processSubprocess), current];
            }
            return [current];
        }
        return [];
    }

    const processPath = useMemo(() => {
        let path: IdValuePair[] = [];

        if (processSubprocess.some(ps => ps.subprocessId === props.processId)) {
            const { processPath } = (location?.state ?? { processPath: [] }) as { processPath: number[] };

            if (processPath != undefined && processPath.length > 0) {
                path = processPath.flatMap(id => (processes.find(p => p.id === id) ?? []));
            }
            else {
                const parentProcesses = processSubprocess.filter(ps => ps.subprocessId === props.processId);
                if (parentProcesses.length === 1) {
                    path = createProcessPath(props.processId, processes, processSubprocess);
                    location.state = { processPath: path.map(p => p.id) };
                }
            }
        }

        if (path.length === 0) {
            const currentProcess: IdValuePair = {
                id: props.processId,
                value: processes.find(p => p.id === props.processId)?.value ?? ""
            };

            path = [currentProcess];
            location.state = { processPath: [props.processId] };
        }
        return path;

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.processId, processSubprocess, processes]);

    return (
        <>
            {processPath.length > 0 &&
                <Breadcrumb
                    items={processPath}
                    currentItemId={props.processId}
                    firstItemText={resources.processes}
                    maxDisplayedItems={5}
                    onItemClick={props.onProcessIdChanged}
                    onFirstItemClick={goToProcessesPage}
                />
            }
        </>
    );
}

export default ProcessBreadcrumb;