import { useMemo, useState } from "react";
import { useCreateProcess, useUserWithWritePermissionProcess } from "../../../api";
import { useLanguage, useResources, useUserSettings } from "../../../improve-lib";
import { CheckBox, ChildWithLabel, MultiLanguageTextbox, Popup, TextWithLabel, UserSelection } from "../../../lib";
import { IdValuePair, MultiLanguageFieldModel } from "../../../model";
import newBpmnProcess from "../resources/initial.bpmn";

type NewProcessPopupProps = {
    processGroup: IdValuePair;
    subsidiary: IdValuePair | null;
    subsidiariesCount: number;
    isOpen: boolean;
    processLandscapes?: { processId: number, subsidiaryId: number | null }[];
    onAbort: () => void;
    onProcessCreated: (newProcess: IdValuePair) => void;
}

const NewProcessPopup = (props: NewProcessPopupProps): JSX.Element => {
    const lang = useLanguage();
    const resources = useResources();
    const userSettings = useUserSettings();

    const [owner, setOwner] = useState<IdValuePair>(({ id: userSettings.userId, value: userSettings.displayName }));
    const [name, setName] = useState<MultiLanguageFieldModel>(lang.map(l => ({ isoCode: l, label: "" })));
    const [isProcessLandscape, setIsProcessLandscape] = useState(false);

    const { userList } = useUserWithWritePermissionProcess(props.processGroup.id, props.subsidiary?.id ?? null);

    const userOnGet = (): Promise<{ results: IdValuePair[], full: boolean }> => {
        return new Promise(resolve => {
            resolve({ results: userList, full: true })
        });
    }

    const { createProcess } = useCreateProcess((id: number) => {
        props.onProcessCreated({ id, value: name.find(n => n.isoCode === resources.isoCode)!.label });
        props.onAbort();
    });

    const onCreateProcess = async () => {
        await createProcess({
            bpmn: newBpmnProcess,
            name: name,
            subsidiaryId: props.subsidiary?.id ?? null,
            processGroupId: props.processGroup.id,
            owner: owner,
            isProcessLandscape: isProcessLandscape
        });
    }

    const processIsValid = useMemo(() => {
        if (name.some(n => n.label.trim().length < 1))
            return false;

        return true;
    }, [name]);

    const processLandscapeExist = props.processLandscapes?.some(p => p.subsidiaryId === (props.subsidiary?.id ?? null));

    return (
        <Popup
            isOpen={props.isOpen}
            title={resources.process}
            onSave={onCreateProcess}
            onDismiss={props.onAbort}
            saveDisabled={!processIsValid}
            closeText={resources.close}
            size={"small"}
        >
            <div>
                <article>
                    <div className="article-content">
                        <TextWithLabel
                            label={resources.processGroup}
                            text={props.processGroup.value}
                        />
                        {props.subsidiariesCount > 1 &&
                            <TextWithLabel
                                label={resources.subsidiary}
                                text={props.subsidiary?.value ?? resources.allSubsidiaries}
                            />
                        }
                    </div>
                    <MultiLanguageTextbox
                        languageFields={name}
                        onChange={setName}
                        duplicatedIsoCodes={[]}
                        textBoxHeader={resources.label + " " + resources.process}
                        mandatory
                    />

                    <UserSelection
                        label={resources.owner}
                        selectedUser={owner}
                        onUserChanged={setOwner}
                        onGet={userOnGet}
                        mandatory
                    />

                    {props.processLandscapes && !processLandscapeExist &&

                        <ChildWithLabel label={resources.isProcessLandscape} >
                            <CheckBox
                                label={""}
                                checked={isProcessLandscape}
                                onChange={setIsProcessLandscape}
                            />
                        </ChildWithLabel>
                    }
                </article>
            </div>
        </Popup >
    );
}

export default NewProcessPopup;