import { useApplicationArea, useDeletableApplicationArea, useDeleteApplicationArea, useUpdateApplicationArea } from "../../../../../../api";
import { Container, useCopy, useResources } from "../../../../../../improve-lib";
import { RowAction } from "../../../../../../lib";
import Form from "./components/Form";

type DetailsProps = {
    applicationAreaId: number;
    refresh: () => void;
    onDeleted: () => void;
}

const Details = (props: DetailsProps) => {
    const resources = useResources();

    const { applicationArea, reload } = useApplicationArea(props.applicationAreaId);
    const { applicationAreaCanBeDeleted } = useDeletableApplicationArea(props.applicationAreaId);
    const { deleteApplicationArea } = useDeleteApplicationArea(() => {
        props.onDeleted();
    })
    const { duplicatedIsoCodes, updateApplicationArea } = useUpdateApplicationArea(() => {
        reload();
        props.refresh();
    });

    const [name, setName, hasChanged] = useCopy(applicationArea?.name, "isoCode");

    if (!applicationArea)
        return null;
    if (!name)
        return null;

    let allowSave = true;

    if (name) {
        for (let i = 0; i < name.length; i++)
            if (name[i].label.length < 1)
                allowSave = false;
    }

    const deleteAction: RowAction = {
        onClick: () => deleteApplicationArea(props.applicationAreaId),
        iconName: "Delete",
        text: resources.delete
    };

    const onUpdateApplicationAreaAction = async () => {
        await updateApplicationArea(props.applicationAreaId, { name, versionCount: applicationArea.versionCount })
    }

    return (
        <Container
            title={applicationArea.name.find(n => n.isoCode === resources.isoCode)!.label}
            onSave={onUpdateApplicationAreaAction}
            saveDisabled={!hasChanged || !allowSave }
            actions={[...(applicationAreaCanBeDeleted ? [deleteAction] : [])]}>
            <Form duplicatedIsoCodes={duplicatedIsoCodes} applicationArea={{ ...applicationArea, name: name }} onNameChange={setName} />
        </Container>
    )
}

export default Details;