import { useState } from "react";
import { useCreateApplicationArea } from "../../../../../../api";
import { Container, useLanguage, useResources } from "../../../../../../improve-lib";
import { RowAction } from "../../../../../../lib";
import { ApplicationAreaModel, MultiLanguageFieldModel } from "../../../../../../model";
import FormNew from "./components/FormNew";

type NewProps = {
    onCreated: (newApplicationAreaId: number) => void;
    onCancel: () => void;
}

const New = (props: NewProps): JSX.Element => {
    const resources = useResources();

    const lang = useLanguage();

    const [applicationAreaModel, setApplicationAreaModel] = useState<ApplicationAreaModel>({ name: lang.map(l => { return { isoCode: l, label: "" } }) });

    const { createApplicationArea, duplicatedIsoCodes } = useCreateApplicationArea((id: number) => {
        props.onCreated(id);
    })

    const onNameChange = (newName: MultiLanguageFieldModel) => setApplicationAreaModel({ name: newName });

    let allowSave = true;

    if (applicationAreaModel?.name) {
        for (let i = 0; i < applicationAreaModel.name.length; i++)
            if (applicationAreaModel.name[i].label.length < 1)
                allowSave = false;
    }

    const cancelAction: RowAction = {
        onClick: props.onCancel,
        iconName: "Cancel",
        text: resources.cancel
    };

    const onCreateApplicationArea = async () => {
        await createApplicationArea(applicationAreaModel)
    }

    return (
        <>
            <Container title={resources.newApplicationArea} actions={[cancelAction]} onSave={onCreateApplicationArea} saveDisabled={!allowSave}>
                <FormNew duplicatedIsoCodes={duplicatedIsoCodes} name={applicationAreaModel.name} onNameChange={onNameChange} />
            </Container>
        </>
    )
}

export default New;