import { IFontStyles, Text as FluentText } from "@fluentui/react";
import { useEffect, useRef, useState } from "react";
import { IconButton } from "../input";
import "./Text.tsx.scss";

type TextProps = {
    text: string;
    size: keyof IFontStyles;
    numberOfLines?: number;
}

const Text = (props: TextProps): JSX.Element => {
    const textContainerRef = useRef<HTMLDivElement>(null);
    const [clamped, setClamped] = useState(props.numberOfLines ? true : false);
    const [showButton, setShowButton] = useState(false);

    useEffect(() => {
        if (props.numberOfLines && textContainerRef.current) {
            const { scrollHeight } = textContainerRef.current;
            const maxFontHeight = 30;
            setShowButton(scrollHeight >= maxFontHeight * props.numberOfLines);
        }
    }, [textContainerRef, props.numberOfLines, props.text]);

    return (
        <FluentText variant={props.size}>
            <div ref={textContainerRef} className={"DropDownText " + (clamped ? "" : "TextClamped")} style={{ "--numberOfLines": props.numberOfLines } as React.CSSProperties}>
                {props.text}
            </div>
            {showButton &&
                <div className={"TextExpand"}>             
                    <IconButton size="small" iconName={(clamped ? "ChevronUpSmall" : "ChevronDownSmall")} onClick={() => setClamped(!clamped)} />
                </div>
            }
        </FluentText>
    );
}

export default Text;
