import { useResources } from "../../../improve-lib";
import { OrderColumn, RowAction, Table, TableColumnType, TableData } from "../../../lib";
import { FetchedTableData, TableUserViewModel } from "../../../model";

type UserTableProps = {
    onDeleteUser: (id: number, displayName: string) => void;
    onToggleUserActive: (id: number, active: boolean) => void;
    onResetPassword: (id: number, displayName: string) => void;
    onUserClick: (id: number) => void;
    orderColumn: OrderColumn;
    onReorder: (orderColumn: OrderColumn) => void;
    usersData: FetchedTableData<TableUserViewModel>;
    onLoadMore: () => void;
}

const UserTable = (props: UserTableProps): JSX.Element => {
    const resources = useResources();

    const onActionsCreate = (data: TableUserViewModel) => {
        const deleteAction: RowAction = {
            text: resources.delete,
            iconName: "Delete",
            onClick: () => props.onDeleteUser(data.id, data.displayName)
        };

        const toggleAction: RowAction = {
            text: data.active ? resources.deactivate : resources.activate,
            iconName: "UserWarning",
            onClick: () => props.onToggleUserActive(data.id, !data.active)
        };
        const resetPasswordAction: RowAction = {
            onClick: () => props.onResetPassword(data.id, data.displayName),
            disabled: data.usesAdAuthentication,
            iconName: "Rotate90CounterClockwise",
            text: resources.passwordReset
        }

        return [toggleAction, resetPasswordAction, deleteAction];
    }

    const onUserClick = (item: TableUserViewModel) => {
        props.onUserClick(item.id);
    }

    const allowLoadMore = props.usersData.records.length < props.usersData.filteredCount;

    const data: TableData<TableUserViewModel> = {
        columns: [
            { name: "displayName", displayName: resources.displayname, dataType: TableColumnType.Text },
            { name: "lastName", displayName: resources.lastname, dataType: TableColumnType.Text },
            { name: "firstName", displayName: resources.firstname, dataType: TableColumnType.Text },
            { name: "username", displayName: resources.username, dataType: TableColumnType.Text }
        ],
        rows: props.usersData.records
    };

    return (
        <Table
            onActionsCreate={onActionsCreate}
            onRowClick={onUserClick}
            orderColumn={props.orderColumn}
            onReorder={props.onReorder}
            onLoadMore={allowLoadMore ? props.onLoadMore : undefined}
            data={data}
        />
    )
}

export default UserTable;
