import { useState } from "react";
import { post, useSaveCallout } from "../../improve-lib";
import { RoleUpdateModel } from "../../model";

const useCreateRole = (afterCreated?: (id: number) => void) => {
    const saveCallout = useSaveCallout();

    const [duplicatedIsoCodes, setDuplicatedIsoCodes] = useState<string[]>([]);

    const createRole = async (role: RoleUpdateModel) => {
        const response = await post("roles/", role);

        switch (response.status) {
            case 200:
                saveCallout.show();
                if (afterCreated)
                    afterCreated(response.body as number);
                break;
            case 400:
                setDuplicatedIsoCodes(response.body as string[]);
                break;
            default:
                throw response;
        }
    }

    return { createRole, duplicatedIsoCodes }
}

export default useCreateRole;