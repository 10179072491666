import { getTheme } from "@fluentui/react";
import { ReactNode } from "react";
import "./Elevation.tsx.scss";

type ElevationProps = {
    children: ReactNode;
    big?: boolean;
}

const Elevation = (props: ElevationProps): JSX.Element => {
    const theme = getTheme();

    return (
        <div className={"elevation-main"} style={props.big ? { boxShadow: theme.effects.elevation8 } : { boxShadow: theme.effects.elevation4 }}>
            {props.children}
        </div>
    )
}

export default Elevation;
