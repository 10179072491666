import { useState } from "react";
import { useResources } from "../../../../../improve-lib";
import { LinkType, ProcessElementModel } from "../../../../../model";
import { ElementHelperBase } from "./elementHelper";

const useElementHelper = (onProcessIdChanged: (id: number) => void) => {
    const resources = useResources();

    const [selectedElement, setSelectedElement] = useState<ProcessElementModel>();

    const getElementName = (element: ProcessElementModel | undefined) => {
        return element?.subprocess?.value ?? element?.label?.find(n => n.isoCode === resources.isoCode)!.label ?? "";
    }

    const hasElementSubprocess = (element: ProcessElementModel | undefined) => {
        return (element?.subprocess && element.subprocess.id > 0) ?? false;
    }

    const getElementIcons = (element: ProcessElementModel | undefined) => {
        const icons =
            [
                {
                    title: "",
                    iconName: "Page",
                    func: (_eId: string) => { }
                },
                {
                    title: "",
                    iconName: "Link",
                    func: (_eId: string) => { }
                }
            ];

        const elementIcons = [];

        if (element) {
            if (element.links?.some(l => l.targetType === LinkType.Documents))
                elementIcons.push(icons[0]);
            if (element.links?.some(l => l.targetType === LinkType.ExternalLink))
                elementIcons.push(icons[1]);
        }
        return elementIcons;
    }

    const onElementDoubleClick = (element: ProcessElementModel | undefined) => {
        if (element?.subprocess && element.subprocess.id > 0)
            onProcessIdChanged(element.subprocess.id);
        else {
            const link = element?.links?.find(l => l.targetType === LinkType.ExternalLink);
            if (link && link.targetLink) {
                if (window.location.host === new URL(link.targetLink).host)
                    window.location.href = link.targetLink;
                else
                    window.open(link.targetLink, "_blank")?.focus();
            }
        }
    }

    const elementHelperBase: ElementHelperBase = { getElementName, hasElementSubprocess, getElementIcons, onElementDoubleClick };

    return { selectedElement, setSelectedElement, elementHelperBase };
}

export default useElementHelper;