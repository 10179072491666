import { mergeStyles, useTheme } from "@fluentui/react";

const useLabelStyles = () => {
    const theme = useTheme();

    const className = mergeStyles({
        color: theme.palette.themeDarker,
        ...theme.fonts.small
    });

    return className;
}

export { useLabelStyles };