import { useState } from "react";
import { post, useSaveCallout } from "../../improve-lib";
import { ProcessGroupUpdateModel } from "../../model";

const useCreateProcessGroup = (afterCreated?: (id: number) => void) => {
    const saveCallout = useSaveCallout();

    const [duplicatedIsoCodes, setDuplicatedIsoCodes] = useState<string[]>([]);

    const createProcessGroup = async (processGroup: ProcessGroupUpdateModel) => {
        const response = await post("processgroups/", processGroup);

        switch (response.status) {
            case 200:
                saveCallout.show();
                if (afterCreated)
                    afterCreated(response.body as number);
                break;
            case 400:
                setDuplicatedIsoCodes(response.body as string[]);
                break;
            default:
                throw response;
        }
    }

    return { createProcessGroup, duplicatedIsoCodes }
}

export default useCreateProcessGroup;