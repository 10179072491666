import { Container, useResources } from "../../../../../../improve-lib";
import { RowAction, SelectionList } from "../../../../../../lib";
import { IdValuePair } from "../../../../../../model";

type ListProps = {
    applicationAreas: IdValuePair[];
    onApplicationAreaIdChanged: (newId: number) => void;
    selectedApplicationAreaId: number | undefined;
    onApplicationAreaCreate: () => void;
}

const List = (props: ListProps): JSX.Element => {
    const resources = useResources();

    const newAction: RowAction = { iconName: "Add", onClick: props.onApplicationAreaCreate, text: resources.new };

    return (
        <Container title={resources.applicationAreas} actions={[newAction]}>
            <SelectionList
                items={props.applicationAreas}
                columnHeader={resources.applicationAreas}
                onSelectionChanged={props.onApplicationAreaIdChanged}
                selectedItemId={props.selectedApplicationAreaId}
            />
        </Container>
    )
}

export default List;
